import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
    baseURL: `${baseURL}/bx_block_order_management/`
});

const emailLink = axios.create({
    baseURL: `${baseURL}/account_block/accounts/`,
    timeout: 5000
})

emailLink.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

export const sentEmail = async () => {
    try {
        return await emailLink.post(`resend_email_verification_link`);
    } catch (e) {
        return e.response;
    }
}

API.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

export const getCartList = async () => {
    try {
        return await API.get(`/carts`);
    } catch (e) {
        return e.response;
    }
};

export const finalOrder = async () => {
    try {
        return await API.post(`/orders`);
    } catch (e) {
        return e.response;
    }
};

