import React from "react";
import { Typography, Button, Modal, Box, Stack } from "@mui/material";

interface IProps {
  showModal: boolean,
  handleClose: any,
  handleConfirmNavigation: any
}

export default function WarningModal({
  showModal, 
  handleClose, 
  handleConfirmNavigation 
}: IProps) {
  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.modalBox}>
        <Typography id="modal-modal-title" sx={styles.text}>
          Changes have been made but not saved. Do you wish to continue without
          saving?
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={styles.btnContainer}
        >
          <Button variant="outlined" sx={styles.noBtn} onClick={handleClose}>
            no
          </Button>
          <Button variant="contained" sx={styles.yesBtn} onClick={handleConfirmNavigation}>
            yes
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

const styles = {
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    width: {
      xs: "80%",
      sm: 500,
    },
    backgroundColor: "#fff",
    boxShadow: 24,
  } as const,
  text: {
    textAlign: "center",
    fontSize: {
      xs: "16px",
      sm: "20px",
    },
    fontWeight: "700",
    color: "#472264",
    marginTop: 4,
    paddingX: 2,
  },
  btnContainer: {
    paddingY: 4.5,
    paddingX: {
      xs: 1.5,
      sm: 6.5,
    },
  },
  noBtn: {
    color: "#824bad",
    borderColor: "#824bad !important",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: "500",
    marginRight: 2,
    textTransform: "uppercase",
    width: "250px",
    p: 1,
    "&:hover": {
      background: "#fff",
    },
  },
  yesBtn: {
    background: "#693a8e",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    width: "250px",
    p: 1,
    "&:hover": {
      background: "#693a8e",
    },
  },
};
