import {
  Alert,
  Box,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import { menuImage, getBankDetails } from "./assets";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import { toast } from "react-toastify";

import ChangeDetails from "./ChangeDetails";
import LoaderPage from "../../../components/src/LoaderPage";
import { saveBankDetails, updateBankDetails } from "./assets";

export interface BankDetails {
  full_name: string;
  bank_name: string;
  bank_country: string;
  account_number: string;
  account_number_confirmation: string;
  routing_number: string;
  swift_key: string;
}

const initialBankDetails = {
  full_name: "",
  bank_name: "",
  bank_country: "",
  account_number: "",
  account_number_confirmation: "",
  routing_number: "",
  swift_key: "",
}

interface IData {
  full_name: string;
  bank_name: string;
  bank_country: string;
  account_number: string;
  account_number_confirmation: string;
  routing_number: string;
  swift_key: string;
}

const BankDetails = () => {
  const [bankEditPage, setBankEditPage] = useState(false);
  const [bankDetails, setBankDetails] = useState<BankDetails>(initialBankDetails);
  const [bankDetailsExist, setBankDetailExist] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState("");
  const [error, setError] = useState<{ message: string }[]>([]);
  const [message, setMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  
  const handleClickMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeDetails = () => {
    setBankEditPage(true);
    setAnchorEl(null);
  };

  const handleSubmit = async (data: IData) => {
    setIsSaving(true);
    if (bankDetailsExist) {
      // Update details
      try {
        const response = await updateBankDetails(id, data);
        setIsSaving(false);
        if (response.status === 200) {
          setBankEditPage(false);
          toast.success('Updated');
        } else if (response.status === 422) {
          response.data.errors.forEach((e: any) => {
            toast.error(e.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch(err) {
        toast.error("Something went wrong");
      }
    } else {
      // Save details
      try {
        const response = await saveBankDetails(data);
        setIsSaving(false);
        if (response.status === 201) {
          setBankEditPage(false);
          toast.success('Saved');
        } else if (response.status === 422) {
          response.data.errors.forEach((e: any) => {
            toast.error(e.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch {
        toast.error("Something went wrong");
      }
    }
  }

  const getDetails = async () => {
    const { data } = await getBankDetails();
    setIsLoading(false);
    if (data.data?.attributes) {
      setId(data.data?.id);
      setBankDetails(data.data?.attributes);
      setBankDetailExist(true);
    } else {
      setBankEditPage(true);
      setBankDetailExist(false);
    }
  };

  // Load data
  useEffect(() => {
    getDetails();
  }, [bankEditPage]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();
  
  return (
    <Fragment>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Box>
          <Box
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: "20px",
            }}
          >
            {bankEditPage ? (
              <ChangeDetails 
                initialBankDetails={initialBankDetails}
                bankDetails={bankDetails} 
                setBankDetails={setBankDetails} 
                bankDetailsExist={bankDetailsExist}
                isSaving={isSaving}
                error={error}
                message={message}
                handleSubmit={handleSubmit}
                setBankEditPage={setBankEditPage}
                setError={setError}
                setMessage={setMessage}
              />
            ) : (
              <Grid
                container
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12} lg={6}>
                  <Box className={classes.boxproduct}>
                    <Box
                      style={{
                        backgroundColor: "#f0f0f0",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "flex-end",
                            }}
                          >
                            <img
                              src={menuImage}
                              width="20px"
                              height="5px"
                              style={{ margin: "20px", cursor: "pointer" }}
                              onClick={handleClickMenu}
                            />
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              style={{ boxShadow: "none" }}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                className={classes.menuLink}
                                onClick={handleChangeDetails}
                              >
                                Change Details
                              </MenuItem>
                            </Menu>
                          </div>
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: "bold",
                              marginLeft: "30px",
                              marginBottom: "30px",
                              marginTop: "10px",
                            }}
                            display="block"
                            gutterBottom
                          >
                            {bankDetails?.bank_name ? bankDetails.bank_name.slice(0, 1).toUpperCase() + bankDetails.bank_name.slice(1) : ""}
                          </Typography>
                          <div className={classes.detail}>
                            <Typography
                              variant="subtitle2"
                              className={classes.title}
                              display="block"
                              gutterBottom
                            >
                              Account Name
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bold" }}
                              className={classes.title}
                              display="block"
                              gutterBottom
                            >
                              {bankDetails?.full_name}
                            </Typography>
                          </div>
                          <div className={classes.detail}>
                            <Typography
                              variant="subtitle2"
                              className={classes.title}
                              display="block"
                              gutterBottom
                            >
                              Account Number
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{ fontWeight: "bold" }}
                              className={classes.title}
                              display="block"
                              gutterBottom
                            >
                              {bankDetails?.account_number}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
export default BankDetails;

const useStyles = makeStyles({
  menuLink: {
    textDecoration: "none",
    color: "#472264",
    marginBottom: 5,
    boxShadow: "none",
  },
  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "50px 30px",
  },
  title: {
    fontWeight: "normal",
    fontSize: 14,
  },
  detail: {
    margin: "30px",
  },

  boxproduct: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
  },
});
