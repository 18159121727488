import React, { Fragment,useState,useEffect } from "react";
import './Style.css'
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LoaderPage from "./Loader";
import {withRouter} from 'react-router-dom'
import {manageLoading} from './assets'
import {useDispatch} from 'react-redux'

// @ts-ignore
const LandingPage = ({history}) => {
    const dispatch = useDispatch
    const [isLoading, setIsLoading] = useState(true);
    const [imageCounter, setImageCounter] = useState(0);

    const setImageLoading:any = () => {
        setImageCounter(imageCounter + 1)
    }

    useEffect(() => {
        if(imageCounter === 0){
            setIsLoading(false)
        }
    }, [imageCounter]);

    return (
    <Fragment>
        <LoaderPage isLoading={isLoading}/>
        <Section1 isLoading={isLoading}/>
        <Section2 isLoading={isLoading}/>
        <Section3 isLoading={isLoading}/>
        <Section4 isLoading={isLoading}/>
        <Section5 isLoading={isLoading}/>
        <Section6 setImageLoading={setImageLoading} isLoading={isLoading}/>
    </Fragment>
  );
};

export default withRouter(LandingPage);
