export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.png");
export const VisiblityOn = require("../assets/VisiblityOn.png");
export const background = require("../assets/background.png");
export const check = require("../assets/check.png");
export const cross = require("../assets/cross.png");
export const SideImage = require("../assets/SideImage.png");
export const thankyou = require("../assets/thankyou.png");
export const logoDark = require("../assets/logoDark.png");
export const SuccessCheck = require("../assets/successCheck.png")
export const FailedLogo = require("../assets/wrong.png")
export const  {setLoginModelTrue,setRegistrationModelTrue,setRegistrationModelFalse} = require("../../../components/src/Redux/LandingPage/reduxLandingPageActions")