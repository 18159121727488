import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { profilePic, setUnreadCountZero, cartZero,setLoginModelTrue,setNotificationBlank,setProfileEmpty } from "./importAssets";
import MenuItem from "@mui/material/MenuItem";
import { withRouter } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import StyledMenu from "./StyledMenu";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
const ProfileButton = ({ history, setIsLogin,location,match }) => {
  const dispatch = useDispatch();
  const [anchorEl5, setAnchorEl5] = useState(null);
  const { user_role } = useSelector((state) => state.landingPage.profile);
  const open = Boolean(anchorEl5);
  // @ts-ignore
  const handleClick1 = (event) => {
    setAnchorEl5(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl5(null);
  };

  const [auth, setAuth] = useState("");
  const { profile } = useSelector((state) => state.landingPage);
  const token = localStorage.getItem("authToken") || null;
  const profileDetails = window.localStorage.getItem("profile") || null;

  // Auth user as per role and redirect to respective dashboard
  useEffect(() => {
    const path = location.pathname.split("/")[1]
    if (token && profileDetails) {
      if(path === "EmailVerification" || path === "Catalogue" || path === "catalogue" || path === "InfoPage" || path === "infopage"){
        if(path === "EmailVerification"){
          let {type} = match.params
          if( JSON.parse(profileDetails).user_role === "super_administrator" || JSON.parse(profileDetails).user_role === "administrator") {
            history.push(`/admin/EmailVerification/${type}`);
          }
          if (JSON.parse(profileDetails).user_role === "vendor") {
            history.push(`/vendor/EmailVerification/${type}`);
          }
        }
      }else{
        if (
            JSON.parse(profileDetails).user_role === "super_administrator" ||
            JSON.parse(profileDetails).user_role === "administrator"
        ) {
          history.push("/admin/dashboard");
        }
        if (JSON.parse(profileDetails).user_role === "vendor") {
          if (JSON.parse(profileDetails).vendor_status === "approved") {
            history.push("/vendor/dashboard");
          } else {
            history.push("/vendor/profile");
          }
        }
      }
      setAuth(token);
      setIsLogin(true);
    }
  }, [auth, token]);

  // Manage Login/Logout Button functionality
  const handleNavigation = () => {
    if (auth) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("profile");
      localStorage.removeItem("cart");
      dispatch(setNotificationBlank());
      dispatch(setProfileEmpty())
      dispatch(cartZero());
      setAuth("");
      history.push("/");
      setIsLogin(false);
      setAnchorEl5(null);
    } else {
      dispatch(setLoginModelTrue())
    }
  };

  return (
    <>
      {!auth ? (
        <Button
          style={{
            width: "100px",
            backgroundColor: "#d6b236",
            color: "black",
            border: "2px solid white",
            borderRadius: "5px",
            fontWeight: "bold",
          }}
          onClick={handleNavigation}
        >
          Login
        </Button>
      ) : (
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick1}
        >
          <img
            src={profile?.avatar?.url || profilePic}
            height="50px"
            width="50px"
            alt="profile pic"
            style={{borderRadius:"50%"}}
          />
          <Box
            id="basic-button"
            sx={{
              margin: "0px 10px",
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <Typography
              variant="subtitle1"
              noWrap
              component="span"
              sx={{ fontWeight: "bold" }}
            >
              {profile.full_name || "Loading.."}
            </Typography>
            <Typography
              variant="subtitle1"
              noWrap
              component="span"
              marginTop="-7px"
            >
              {profile?.role_name || "loading.."}
            </Typography>
          </Box>
        </Box>
      )}
      <StyledMenu anchorEl={anchorEl5} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            if(user_role === "super_administrator" || user_role === "administrator" ) {
              history.push("/admin/profile");
            }
            if (user_role === "vendor") {
              history.push("/vendor/profile");
            }else{
              history.push("/CustomisableUserProfiles");
            }
          }}
          sx={{textAlign:"center"}}
        >
          Profile
        </MenuItem>
        {
          user_role !== "buyer" &&
          <Divider />
        }
        {
          user_role !== "buyer" &&
          <MenuItem
              onClick={() => {
                if(user_role === "super_administrator" || user_role === "administrator" ) {
                  history.push("/admin/dashboard");
                }
                if (user_role === "vendor") {
                  history.push("/vendor/dashboard");
                }
              }}
              sx={{textAlign:"center"}}
          >
            Dashboard
          </MenuItem>
        }
        <Divider />
        <MenuItem sx={{textAlign:"center"}} onClick={handleNavigation}>Logout</MenuItem>
      </StyledMenu>
    </>
  );
};

export default withRouter(ProfileButton);