import React from "react"
import { toast } from 'react-toastify';
import {
    adminOrderDetails,
    adminOrderList,
    adminOrderListById, orderSearchAdmin, orderSearchByVendorIdAdmin, orderSearchVendor,
    vendorOrderDetails,
    vendorOrderList,
    vendorOrderUpdate,
    getBuyerOrdersDetails
} from "./reduxOrdersApi";
import store from "../store";

// get Order List Action
export const getOrderList = (count,page,id,filter) => async dispatch => {
    dispatch({type:'SET_LOADING_TRUE'})
    const profile = JSON.parse(localStorage.getItem("profile"))
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        if(profile.user_role === "vendor"){
            const {data} = await vendorOrderList(page,count,filter)
            if(data.errors){
                data.errors.map((e)=>{
                    toast.error(e.message)
                })
            }else{
                dispatch({type:'SET_ORDER_LIST', payload: data?.data})
                dispatch({type:'SET_PAGINATION', payload: data?.meta.pagination})
            }
        }else{
            if(id){
                const {data} = await adminOrderListById(id,page,count,filter)
                if(data.errors){
                    data.errors.map((e)=>{
                        toast.error(e.message)
                    })
                }else{
                    console.log("THIS IS FIRM NAME",data?.firm_name)
                    dispatch({type:'SET_ORDER_LIST', payload: data?.data})
                    dispatch({type:'SET_PAGINATION', payload: data?.meta.pagination})
                    dispatch({type:'SET_ORDER_FIRM_NAME', payload: data?.firm_name });
                }
            }else{
                const {data} = await adminOrderList(page,count,filter)
                if(data.errors){
                    data.errors.map((e)=>{
                        toast.error(e.message)
                    })
                }else{
                    dispatch({type:'SET_ORDER_LIST', payload: data?.data})
                    dispatch({type:'SET_PAGINATION', payload: data?.meta.pagination})
                }
            }
        }
    }
    dispatch({type:'SET_LOADING_FALSE'})
}

// set Current page Action
export const setCurrentPageOrders = (page,count,id,filter) => async dispatch => {
    dispatch({type:'SET_CURRENT_ORDERS_PAGE',payload:page})
    dispatch(getOrderList(count,page,id,filter))
}

// set Show count Action
export const setShowCountOrders = (page,count,id,filter) => async dispatch => {
    dispatch({type:'SET_SHOW_ORDERS_COUNT',payload:count})
    dispatch(getOrderList(count,page,id,filter))
}

// set Search Key Word Action
export const setSearchKeyOrders = (key) => async dispatch => {
    dispatch({type:'SET_ORDER_SEARCH_KEY',payload:key})
}

// set Search Text Order Action
export const setSearchTextOrders = (text) => async dispatch => {
    dispatch({type:'SET_ORDER_SEARCH_TEXT',payload:text})
}

// Order Filter Action
export const setOrderFilter = (filter) => async dispatch => {
    dispatch({type:'SET_ORDER_FILTER',payload:filter})
}

// Update Order Status Action
export const updateOrderStatus = (id,status) => async (dispatch,getState) => {
    dispatch({type:'SET_LOADING_TRUE'})
    const {currentPage,showCount} = store.getState().orders
    const {data} = await vendorOrderUpdate(id,status)
    if(data.errors){
        data.errors.map((e)=>{
            toast.error(e.message)
        })
    }else{
        toast.success("Order Status Updated Successfully")
        dispatch(getOrderList(showCount,currentPage))
    }
    dispatch({type:'SET_LOADING_FALSE'})
}

// Update order Status Action from Details Page
export const updateOrderStatusDetails = (id,status) => async (dispatch,getState) => {
    dispatch({type:'SET_LOADING_TRUE'})
    const {currentPage,showCount} = store.getState().orders
    const {data} = await vendorOrderUpdate(id,status)
    if(data.errors){
        data.errors.map((e)=>{
            toast.error(e.message)
        })
    }else{
        toast.success("Order Status Updated Successfully")
        dispatch(getOrderDetails(id))
    }
    dispatch({type:'SET_LOADING_FALSE'})
}

// get Order Details action
export const getOrderDetails = (id) => async dispatch => {
    dispatch({type:'SET_LOADING_TRUE'})
    const profile = JSON.parse(localStorage.getItem("profile"))
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        if(profile.user_role === "vendor"){
            const {data} = await vendorOrderDetails(id)
            if(data.errors){
                data.errors.map((e)=>{
                    toast.error(e.message)
                })
            }else{
                dispatch({type:'SET_ORDER_DETAILS', payload: data?.data?.attributes})
            }
        }else{
            const {data} = await adminOrderDetails(id)
            if(data.errors){
                data.errors.map((e)=>{
                    toast.error(e.message)
                })
            }else{
                dispatch({type:'SET_ORDER_DETAILS', payload: data?.data?.attributes})
            }
        }
    }
    dispatch({type:'SET_LOADING_FALSE'})
}

// get Order List by Search API Call Action
export const getOrderListBySearch = (count,page,key,text,id,filter) => async dispatch => {
    dispatch({type:'SET_LOADING_TRUE'})
    const profile = JSON.parse(localStorage.getItem("profile"))
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        if(profile.user_role === "vendor"){
            const {data} = await orderSearchVendor(count,page,key,text,filter)
            if(data.errors){
                data.errors.map((e)=>{
                    toast.error(e.message)
                })
            }else{
                dispatch({type:'SET_ORDER_LIST', payload: data?.data})
                dispatch({type:'SET_PAGINATION', payload: data?.meta.pagination})
            }
        }else{
            if(id){
                const {data} = await orderSearchByVendorIdAdmin(id,count,page,key,text,filter)
                if(data.errors){
                    data.errors.map((e)=>{
                        toast.error(e.message)
                    })
                }else{
                    dispatch({type:'SET_ORDER_LIST', payload: data?.data})
                    dispatch({type:'SET_PAGINATION', payload: data?.meta.pagination})
                }
            }else{
                const {data} = await orderSearchAdmin(count,page,key,text,filter)
                if(data.errors){
                    data.errors.map((e)=>{
                        toast.error(e.message)
                    })
                }else{
                    dispatch({type:'SET_ORDER_LIST', payload: data?.data})
                    dispatch({type:'SET_PAGINATION', payload: data?.meta.pagination})
                }
            }
        }
    }
    dispatch({type:'SET_LOADING_FALSE'})
}

// get Order list for Buyer
export const getOrderListAsBuyer = (page) => async dispatch => {
    dispatch({type:'SET_LOADING_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await getBuyerOrdersDetails(page)
        if(data.errors){
            data.errors.map((e)=>{
                toast.error(e.message)
            })
        }else{
            dispatch({type:'SET_ORDER_LIST', payload: data?.data})
            dispatch({type:'SET_PAGINATION', payload: data?.meta.pagination})
        }
    }
    dispatch({type:'SET_LOADING_FALSE'})
}

// Set current page for Buyer order list
export const setCurrentPageBuyerOrders = (page) => async dispatch => {
    dispatch({type:'SET_CURRENT_ORDERS_PAGE',payload:page})
    dispatch(getOrderListAsBuyer(page))
}