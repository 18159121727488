import axios from "axios";
import {baseURL} from "framework/src/config";

// API Initialization
const API = axios.create({
    baseURL: `${baseURL}/bx_block_order_management/`
});

// API Configuration to sent Token
API.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

// Get Orders List for Admin
export const adminOrderList = async (page,count,filter) => {
    try {
        return await API.get(`/admin?per=${count}&page=${page}&filter=${filter}`);
    } catch (e) {
        return e.response;
    }
}

// Get Order list by Vendor ID
export const adminOrderListById = async (id,page,count,filter) => {
    try {
        return await API.get(`/admin?vendor_id=${id}&per=${count}&page=${page}&filter=${filter}`);
    } catch (e) {
        return e.response;
    }
}

// get Order List for Vendor user
export const vendorOrderList = async (page,count,filter) => {
    try {
        return await API.get(`/vendors?per=${count}&page=${page}&filter=${filter}`);
    } catch (e) {
        return e.response;
    }
}

// get Order Details for admin
export const adminOrderDetails = async (id) => {
    try {
        return await API.get(`/admin/${id}`);
    } catch (e) {
        return e.response;
    }
}

// get order Details for vendor
export const vendorOrderDetails = async (id) => {
    try {
        return await API.get(`/vendors/${id}`);
    } catch (e) {
        return e.response;
    }
}

// Update Order status for vendor
export const vendorOrderUpdate = async (id,status) => {
    try {
        return await API.put(`/vendors/${id}?order_status=${status}`);
    } catch (e) {
        return e.response;
    }
}

// Order Search for Vendor
export const orderSearchVendor = async (count,page,type,search,filter) => {
    try {
        return await API.get(`/vendors?search_key=${type}&search=${search}&per=${count}&page=${page}&filter=${filter}`);
    } catch (e) {
        return e.response;
    }
}

// Order Search for Admin
export const orderSearchAdmin = async (count,page,type,search,filter) => {
    try {
        return await API.get(`/admin?search_key=${type}&search=${search}&per=${count}&page=${page}&filter=${filter}`);
    } catch (e) {
        return e.response;
    }
}

// Order Search for admin by vendor id
export const orderSearchByVendorIdAdmin = async (id,count,page,type,search,filter) => {
    try {
        return await API.get(`/admin?search_key=${type}&search=${search}&per=${count}&page=${page}&vendor_id=${id}&filter=${filter}`);
    } catch (e) {
        return e.response;
    }
}

//  get Order List for buyer
export const getBuyerOrdersDetails = async (page) => {
    try {
        return await API.get(`/orders/my_orders?per=${5}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}