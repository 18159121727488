import React from "react"
import { toast } from 'react-toastify';
import {
    catalogList,
    catalogListByCategory,
    catalogListBySubCategory,
    catalogListBySearch,
} from "./reduxCatalogeApi";

// Get Catalog List Action
export const getCatalogList = (count,page) => async dispatch => {
    dispatch({type:'SET_CATALOG_LOADING_TRUE'})
    try {
        const list = await catalogList(page,count)
        dispatch({type:'SET_CATALOG_LIST',payload:list.data.data})
        dispatch({type:'SET_CATALOG_PAGINATION',payload:list.data.meta.pagination})
    }catch (e) {
        toast.error("Check your internet connection")
    }
    dispatch({type:'SET_CATALOG_LOADING_FALSE'})
}

// Get Catalog List By Category Action
export const getCatalogListByCategory = (count,page,category) => async dispatch => {
    dispatch({type:'SET_CATALOG_LOADING_TRUE'})
    try {
        const list = await catalogListByCategory(page,count,category)
        dispatch({type:'SET_CATALOG_LIST',payload:list.data.data})
        dispatch({type:'SET_CATALOG_PAGINATION',payload:list.data.meta.pagination})
    }catch (e) {
        toast.error("Check your internet connection")
    }
    dispatch({type:'SET_CATALOG_LOADING_FALSE'})
}

// Get Catalog List By Sub Category Action
export const getCatalogListBySubCategory = (count,page,category,subCategory) => async dispatch => {
    dispatch({type:'SET_CATALOG_LOADING_TRUE'})
    try {
        const list = await catalogListBySubCategory(page,count,category,subCategory)
        dispatch({type:'SET_CATALOG_LIST',payload:list.data.data})
        dispatch({type:'SET_CATALOG_PAGINATION',payload:list.data.meta.pagination})
    }catch (e) {
        toast.error("Check your internet connection")
    }
    dispatch({type:'SET_CATALOG_LOADING_FALSE'})
}

// Get Catalog List By Search Action
export const getCatalogListBySearch = (count,page,search) => async dispatch => {
    dispatch({type:'SET_CATALOG_LOADING_TRUE'})
    try {
        const list = await catalogListBySearch(page,count,search)
        dispatch({type:'SET_CATALOG_LIST',payload:list.data.data})
        dispatch({type:'SET_CATALOG_PAGINATION',payload:list.data.meta.pagination})
    }catch (e) {
        toast.error("Check your internet connection")
    }
    dispatch({type:'SET_CATALOG_LOADING_FALSE'})
}

// set Current Page Action (Get Data as per page No.)
export const setCatalogCurrentPage = (count,page,search,category,subCategory) => async (dispatch) => {
    dispatch({type:'SET_CATALOG_CURRENT_PAGE',payload:page})
    if(search){
        dispatch(getCatalogListBySearch(count,page,search))
    }else if(subCategory){
        dispatch(getCatalogListBySubCategory(count,page,category,subCategory))
    }else if(category && !subCategory){
        dispatch(getCatalogListByCategory(count,page,category))
    }else{
        dispatch(getCatalogList(count,page))
    }
}

// set show Count Action (Get Data as per show count)
export const setCatalogShowCount = (count,page,search,category,subCategory) => async (dispatch) => {
    dispatch({type:'SET_CATALOG_SHOW_COUNT',payload:count})
    if(search){
        dispatch(getCatalogListBySearch(count,page,search))
    }else if(subCategory){
        dispatch(getCatalogListBySubCategory(count,page,category,subCategory))
    }else if(category && !subCategory){
        dispatch(getCatalogListByCategory(count,page,category))
    }else{
        dispatch(getCatalogList(count,page))
    }
}
