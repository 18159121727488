import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import {noimg,info} from './assets'
import moment from 'moment'
// @ts-ignore
const Notify = ({notification}) => {
    return (
        <div className="notify-grid-main-div" style={{ borderRadius : '15px'}}>
            <Grid className="notify-grid-main" container display="flex"  alignItems="center" sx={{ padding: '15px' , flexWrap:"nowrap" }} >
                <Grid className="notify-grid-img" item >
                    {
                        notification.notifiable_type === "BxBlockCatalogue::Catalogue" ?
                            <img src={info} width='60px'  style={{ padding : '0' }} alt='notification'/> :
                            <img src={notification.image || noimg} width='60px'  style={{ padding : '0' }} alt='notification'/>
                    }
                </Grid>
                <Grid item xs={12} className="notify-grid-content" sx={{ position : 'relative'}}>
                    <Box>
                        <Typography variant="subtitle2" color="#693A8E" sx={{ paddingBottom : '8px'}}>{notification.contents}</Typography>
                        <Typography variant="body1" sx={{ color : '#bdb9b9', fontWeight : 'bold'}} >{moment(notification.created_at).fromNow()}</Typography>
                    </Box>
                    {
                        !notification.is_read ?
                                <Box className="green-dot" sx={{ background : '#52b155', borderRadius : '50%', width : '18px', height :'18px', position : 'absolute' , right : '10px', bottom :'0'}}></Box>
                            : null
                    }
                    </Grid>
            </Grid>
        </div>
    );
};

export default Notify;