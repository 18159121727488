import React, {useCallback,useState} from 'react'
import {useDropzone} from 'react-dropzone'
import Typography from "@mui/material/Typography";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
export default function MyDropzone({setFile}) {
    const [fileName, setFileName] = useState('');
    const onDrop = useCallback(acceptedFiles => {
        setFileName(acceptedFiles[0].name)
        setFile({
            registration_id : acceptedFiles[0]
        })
    }, [setFile])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop,multiple:false})

    return (
        <div {...getRootProps()} className='dropZone' >
            <input {...getInputProps()} accept='image/*' />
            {
                isDragActive ?
                    <Typography variant="subtitle1" component="div" color="purple">
                        <UploadFileIcon fontSize='large' color='secondary'/>
                        Drop the files here ...
                    </Typography> :
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        {fileName ? <CheckCircleIcon fontSize='large' color='secondary'/> : <UploadFileIcon fontSize='large' color='secondary'/> }
                        <Typography variant="subtitle1" component="div" color="purple">
                            {fileName ? `File Selected : ${fileName}` : 'Upload ID'}
                        </Typography>
                    </div>
            }
        </div>
    )
}