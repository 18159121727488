import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { addToCart,updateCartQty,noimg } from "./assets";
import "./style.css";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

export default ({item}) => {
  const {currentVendor,cart} = useSelector(state => state.cart)
  const { user_role } = useSelector((state) => state.landingPage.profile);
  const dispatch = useDispatch()
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const [cartNum, setCartNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const handleClick = async () => {
    setIsLoading(true)
    const isAlreadyAdded =  cart.filter((value)=>{
      return value.attributes.catalogue_id == item?.id
    })
    if(isAlreadyAdded.length !== 0){
      let currentCount = 0
      isAlreadyAdded.map((i)=>{
        currentCount = i.attributes.quantity
      })
      dispatch(updateCartQty(isAlreadyAdded[0]?.id,(currentCount+cartNum)))
    }else{
      dispatch(addToCart({
        current_vendor_id: currentVendor,
        id:item.id,
        attributes:{
          account_id:item.attributes.account_id,
          quantity:cartNum,
          catalogue_id:item?.id,
          unit_price:item?.attributes?.price,
          total_price:item?.attributes?.price * cartNum,
          catalogue_image:{
            url:item?.attributes?.image?.url,
          },
          catalogue_name:item?.attributes?.name,
          vendor_name:item?.attributes?.vendor_name,
          stock_qty:item?.attributes?.stock_qty,
          status:"active"
        },
        catalogue_id:item.id,
        quantity:cartNum,
      }))
    }
    setIsLoading(false)
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ marginBottom: "15px" }}>
      <Card sx={{ maxWidth: 300, paddingBottom: "5px" }}>
        <Link to={`/InfoPage/${item?.id}`} style={{ textDecoration: "none" }}>
          <CardMedia
            component="img"
            height="250px"
            image={item?.attributes?.image?.url || noimg}
            alt={item?.attributes?.name}
          />
          <CardContent sx={{ padding: "10px" }}>
            <Typography
              className="color-main"
              gutterBottom
              component="div"
              variant="h6"
              sx={{ fontWeight: "600", paddingTop: "15px" }}
            >
              {item?.attributes?.name} - {item?.attributes?.vendor_name}
            </Typography>
            <Typography
              className="color-main"
              gutterBottom
              variant="h5"
              component="div"
              sx={{ fontWeight: "bolder" }}
            >
              $ {numberWithCommas(item?.attributes?.price)}
            </Typography>
          </CardContent>
        </Link>
        <CardActions sx={{ padding: "10px" }}>
          <Grid className="cart-items-box" xs={4}>
            <Typography sx={{ paddingRight: "10px" }} variant="h5">
              {cartNum}
            </Typography>
            <Grid className="button-grp">
              <IconButton
                  disabled={user_role === "super_administrator" || user_role === "administrator" || user_role === "vendor"}
                onClick={() => (cartNum < 10 && cartNum <= item?.attributes?.stock_qty ? setCartNum(cartNum + 1) : null)}
                className="btns-inc-dec"
              >
                <ExpandLessIcon />
              </IconButton>
              <IconButton
                disabled={user_role === "super_administrator" || user_role === "administrator" || user_role === "vendor"}
                onClick={() => (cartNum > 1 ? setCartNum(cartNum - 1) : null)}
                className="btns-inc-dec"
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Button
              variant="contained"
              className="add-cart-btn"
              sx={{ width: "100%" }}
              onClick={handleClick}
              disabled={user_role === "super_administrator" || user_role === "administrator" || user_role === "vendor"}
            >
              Add to cart
            </Button>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
}
