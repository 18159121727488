import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell from '@mui/material/TableCell';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import LoaderPage from "../../../components/src/LoaderPage";
import NoRecordPage from "../../../components/src/NoRecordPage";
import {
  currentFilterSet,
  getVendorList,
  getVendorListSearch,
  searchImage,
  selectAllVendor,
  setCurrentPageVendor,
  setSearchKey,
  setSearchText,
  setShowCountVendor,
  vendorActivate,
  vendorBulkActive,
  vendorBulkDeactivate,
  vendorDeactivate,
} from "./assets";
import VendorRows from "./VendorRows";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#472264",
      color: theme.palette.common.white,
      textAlign: "start",
    },
    body: {
      fontSize: 13,
      color: "#472264",
      fontWeight: "bold",
    },
  })
)(TableCell);

// @ts-ignore
const VendorUsersPage = ({ history }) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const {
    vendors,
    vendorStatusCounts,
    isLoading,
    pagination,
    currentPage,
    showCount,
    selected,
    currentStatus,
    searchText,
    searchKey,
  } = useSelector((state: any) => state.vendors);
  // @ts-ignore
  const {vendorPermission} = useSelector(state => state.landingPage)
  const [action, setAction] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [searchPhrase, setSearchPhrase] = useState("");
  const [initiatedSearch, setInitiatedSearch] = useState(false);
  const classes = useStyles();

  // @ts-ignore
  const managePagination = (event, value) => {
    dispatch(setCurrentPageVendor(value, showCount, currentStatus));
  };

  // @ts-ignore
  const handleTab = (status) => {
    dispatch(currentFilterSet(showCount, 1, status));
  };

  const handleSearch = () => {
    if (searchKey && searchText) {
      setInitiatedSearch(true);
      dispatch(getVendorListSearch(showCount, 1, searchKey, searchText));
    }
  };

  const handleChange = () => {
    // @ts-ignore
    if (action == 1) {
      dispatch(vendorBulkActive(selected, currentStatus));
    }
    // @ts-ignore
    if (action == 2) {
      dispatch(vendorBulkDeactivate(selected, currentStatus));
    }
  };

  // Clear search
  const clearSearch = () => {
    setInitiatedSearch(false);
    setSearchBy("");
    setSearchPhrase("");
    dispatch(getVendorList(showCount, currentPage, currentStatus));
  };

  // Set search by
  useEffect(() => {
    dispatch(setSearchKey(searchBy));
  }, [searchBy]);

  // Set search phrase
  useEffect(() => {
    dispatch(setSearchText(searchPhrase));
  }, [searchPhrase]);

  // Load data
  useEffect(() => {
    dispatch(getVendorList(showCount, currentPage, currentStatus));
  }, []);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* Tab contanier */}
          <Box sx={{ marginY: 2}}>
            <Button
              onClick={() => handleTab("all")}
              // @ts-ignore
              sx={{
                ...styles.tabBtn,
                borderBottom: currentStatus === "all" && "2px solid #693a8e",
              }}
            >
              All ({vendorStatusCounts.all_count})
            </Button>
            <Button
              onClick={() => handleTab("approved")}
              // @ts-ignore
              sx={{
                ...styles.tabBtn,
                borderBottom:
                  currentStatus === "approved" && "2px solid #693a8e",
              }}
            >
              Approved ({vendorStatusCounts.approved_count})
            </Button>
            <Button
              onClick={() => handleTab("pending")}
              // @ts-ignore
              sx={{
                ...styles.tabBtn,
                borderBottom:
                  currentStatus === "pending" && "2px solid #693a8e",
              }}
            >
              Pending ({vendorStatusCounts.pending_count})
            </Button>
            <Button
              onClick={() => handleTab("rejected")}
              // @ts-ignore
              sx={{
                ...styles.tabBtn,
                borderBottom:
                  currentStatus === "rejected" && "2px solid #693a8e",
              }}
            >
              Rejected ({vendorStatusCounts.rejected_count})
            </Button>
          </Box>
          {/* Bulk actions container */}
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid xs={12} sm={6}>
              {/* Bulk actions */}
              {
                  vendorPermission &&
                  <Box sx={{ display: "flex",marginBottom:1 }}>
                    <FormControl size="small" sx={{ width: "150px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Bulk Actions
                      </InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={action}
                          label="Bulk Actions"
                          onChange={(e) => setAction(e.target.value)}
                      >
                        <MenuItem value={1}>Bulk Activate</MenuItem>
                        <MenuItem value={2}>Bulk Deactivate</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        size="small"
                        // @ts-ignore
                        sx={{ ...styles.btn, width: "auto", marginLeft: 1 }}
                        onClick={handleChange}
                        disabled={selected.length === 0 || !action}
                    >
                      Apply
                    </Button>
                  </Box>
              }
            </Grid>
            <Grid xs={12} sm={6} sx={{display:"flex",justifyContent: {sm:"flex-end",xs:"flex-start"}}}>
              {/* search */}
              <Box sx={{ display: "flex", alignItems: "center",marginBottom:1 }}>
                <Stack direction="row">
                  <FormControl size="small" sx={styles.formControl}>
                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                    <Select
                        labelId="select-label-id"
                        id="select-id"
                        value={searchBy}
                        label="Select"
                        onChange={(e) => setSearchBy(e.target.value)}
                        sx={styles.select}
                    >
                      <MenuItem value={"firm_name"}>Firm Name</MenuItem>
                      <MenuItem value={"full_name"}>Name</MenuItem>
                      <MenuItem value={"email"}>Email Id</MenuItem>
                      <MenuItem value={"phone_number"}>Phone Number</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                      size="small"
                      value={searchPhrase}
                      onChange={(e) => setSearchPhrase(e.target.value)}
                      sx={styles.textField}
                      InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                              {initiatedSearch && (
                                  <IconButton onClick={clearSearch}>
                                    <CloseIcon />
                                  </IconButton>
                              )}
                              <IconButton onClick={handleSearch}>
                                <img src={searchImage} alt="search" />
                              </IconButton>
                            </InputAdornment>
                        ),
                      }}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {
          isLoading ? <LoaderPage/> :
              <Grid item xs={12}>
                {vendors.length === 0 ? (
                    <NoRecordPage text={"Sorry, no vendor users found!"} />
                ) : (
                    <TableContainer
                        sx={{
                          borderTopLeftRadius: 15,
                          borderTopRightRadius: 15,
                          width:{
                            md:"100%",
                            sm:"700px",
                            xs:"350px"
                          }
                        }}
                    >
                      <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              {" "}
                              <Checkbox
                                  color="secondary"
                                  checked={selected.length > 0 ? true : false}
                                  onChange={() => dispatch(selectAllVendor())}
                              />{" "}
                            </StyledTableCell>
                            <StyledTableCell>Firm Name</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="right">Email Id</StyledTableCell>
                            <StyledTableCell align="right">Phone</StyledTableCell>
                            {
                              vendorPermission &&
                              <StyledTableCell align="right">
                                Activate/Deactivate
                              </StyledTableCell>
                            }
                            <StyledTableCell align="right" />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {// @ts-ignore
                            vendors.map((row) => (
                                <VendorRows key={row.id} row={row} history={history} />
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                )}
              </Grid>
        }
        {/* Show per page */}
        {vendors.length > 0 &&
            <>
              <Grid item xs={12} sm={6}>
                <FormControl size="small" sx={{ width: "150px" }}>
                  <InputLabel id="bulk-actions">Show:</InputLabel>
                  <Select
                      labelId="bulk-actions"
                      id="demo-simple-select"
                      value={showCount}
                      label={`Show:`}
                      onChange={(e) =>
                          dispatch(setShowCountVendor(1, e.target.value, currentStatus))
                      }
                      sx={{ background: "#fff" }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* Pagination */}
              <Grid item xs={12} sm={6} sx={{ display:"flex", justifyContent: {xs:"flex-start",sm:"flex-end"}}}>
                <Stack spacing={5}>
                  <Pagination
                      count={pagination.total_pages}
                      hidePrevButton
                      page={currentPage}
                      color="secondary"
                      variant="outlined"
                      shape="rounded"
                      onChange={managePagination}
                      sx={{
                        "& .Mui-selected": {
                          color: "#fff !important",
                        },
                        "& .MuiPaginationItem-root:hover": {
                          backgroundColor: "#693A8E",
                        },
                        ".css-wjh20t-MuiPagination-ul": {
                          justifyContent: "flex-end",
                        },
                      }}
                  />
                </Stack>
              </Grid>
            </>
        }

      </Grid>
    </Fragment>
  );
};
export default withRouter(VendorUsersPage);

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  paper: {
    color: "black",
    padding: 10,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  menuLink: {
    textDecoration: "none",
    color: "#472264",
    marginBottom: 5,
    boxShadow: "none",
  },
  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    display: "flex",
  },
  select: {
    width: "40%",
    backgroundColor: "transparent",
    margin: "5px 20px 5px 20px",
    borderRight: "1px solid #737373",
    paddingRight: "10px",
  },
  search: {
    width: "60%",
    margin: 5,
    display: "flex",
    alignItems: "space-between",
  },
  showselect: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageselect: {
    backgroundColor: "white",
    borderRadius: "5px",
    height: "33px",
    width: "150px",
    display: "flex",
    justifyContent: "space-between",
  },
  paragragh: {
    padding: 0,
    marginTop: 4,
    marginLeft: 20,
    fontWeight: "bold",
    // width:"50%"
  },
});

const styles = {
  btn: {
    background: "#693a8e",
    borderRadius: "4px",
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "100%",
    padding: "2px 10px",
    "&:hover": { background: "#693a8e" },
  },
  tabBtn: { borderRadius: 0, color: "#693a8e" },
  formControl: {
    width: {
      xs: 150,
      md: 200,
    },
    ".MuiInputBase-root": {
      lineHeight: "1.7em",
    },
    ".MuiOutlinedInput-root": {},
  },
  select: {
    background: "#fff",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  textField: {
    background: "#fff",
    width: {
      xs: 150,
      md: 200,
    },
    "& fieldset": {
      borderLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    ".MuiOutlinedInput-input": {
      height: "1.7em",
      paddingLeft: "10px",
    },
  },
};
