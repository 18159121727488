import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import styles from "./styles";

interface IProps {
  subCategoryControl: any;
  categories: any;
  subCategoryErrors: any;
  handleSubCategoryFormSubmit: any;
  handleSubCategoryCreation: any;
  isSavingSubCategory: boolean;
}

export default function CreateSubCategory({
  subCategoryControl,
  categories,
  subCategoryErrors,
  handleSubCategoryFormSubmit,
  handleSubCategoryCreation,
  isSavingSubCategory,
}: IProps) {
  return (
    <Box sx={styles.box}>
      <Typography sx={styles.boxHeader}>Add Sub-category</Typography>
      <Box sx={{ marginBottom: "15px" }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", color: "#472264", marginBottom: 1 }}
        >
          Category
        </Typography>
        <FormControl
          size="small"
          sx={{ width: "100%" }}
          error={subCategoryErrors["categoryId"] ? true : false}
        >
          <InputLabel id="bulk-actions">Select Category</InputLabel>
          <Controller
            name="categoryId"
            control={subCategoryControl}
            render={({ field }) => (
              <Select
                {...field}
                labelId="bulk-actions"
                id="demo-simple-select"
                label="Select Category"
                sx={{ background: "#f1f1f1" }}
              >
                {categories.map((cat: any) => (
                  <MenuItem value={cat.id} key={cat.id}>
                    {cat.attributes.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {subCategoryErrors["categoryId"] ? (
            <Typography variant="subtitle2" sx={styles.errorText}>
              {subCategoryErrors["categoryId"]?.message}
            </Typography>
          ) : null}
        </FormControl>
      </Box>
      <Typography sx={{ color: "#472264", fontWeight: "bold" }}>
        Sub-category name
      </Typography>
      <Controller
        name="name"
        control={subCategoryControl}
        render={({ field }) => (
          <TextField
            {...field}
            error={subCategoryErrors["name"] ? true : false}
            placeholder="Enter sub-category name"
            size="small"
            sx={{ width: "100%", background: "#f1f1f1", marginTop: 1 }}
          />
        )}
      />
      {subCategoryErrors["name"] ? (
        <Typography variant="subtitle2" sx={styles.errorText}>
          {subCategoryErrors["name"]?.message}
        </Typography>
      ) : null}
      <Button
        variant="contained"
        sx={{ ...styles.btn, marginTop: 2 }}
        onClick={handleSubCategoryFormSubmit(handleSubCategoryCreation)}
      >
        {isSavingSubCategory ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          "Save"
        )}
      </Button>
    </Box>
  );
}
