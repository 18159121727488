import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import useShippingAddress from "../../../components/src/hooks/useShippingAddress";
import LoaderPage from "../../../components/src/LoaderPage";
const { ColorButton } = require("./Styles");

export default function ShippingAddressForm() {
  const {
    isLoading,
    control,
    errors,
    listOfStates,
    countries,
    submitForm,
    handleSubmit,
    isSaving,
  } = useShippingAddress();

  return (
    <Box sx={styles.sectionContainer}>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Grid container>
          <Grid item xs={12} sm={6} sx={styles.leftItem}>
            <Box sx={{ marginBottom: "15px" }}>
              <Typography variant="subtitle1" sx={styles.label}>
                Name
              </Typography>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["name"] ? true : false}
                    placeholder="Enter your name"
                    size="small"
                    sx={{ width: "100%" }}
                  />
                )}
              />
              {errors["name"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["name"]?.message}
                </Typography>
              ) : null}
            </Box>
            <Box sx={{ marginBottom: "15px" }}>
              <Typography variant="subtitle1" sx={styles.label}>
                Address Line 2
              </Typography>
              <Controller
                name="address2"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["address2"] ? true : false}
                    placeholder="Enter your address line 2"
                    size="small"
                    sx={{ width: "100%" }}
                  />
                )}
              />
              {errors["address2"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["address2"]?.message}
                </Typography>
              ) : null}
            </Box>
            <Box sx={{ marginBottom: "15px" }}>
              <Typography variant="subtitle1" sx={styles.label}>
                State
              </Typography>
              <FormControl
                size="small"
                sx={{ width: "100%" }}
                error={errors["state"] ? true : false}
              >
                <InputLabel id="bulk-actions">Select state</InputLabel>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="country-select"
                      id="demo-simple-select"
                      sx={{ width: "100%" }}
                      size="small"
                      label="Select state"
                      displayEmpty
                    >
                      {listOfStates.length > 0 &&
                        listOfStates.map((state) => (
                          <MenuItem key={state.code} value={state.name}>
                            {state.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                {errors["state"] ? (
                  <Typography variant="subtitle2" sx={styles.errorText}>
                    {errors["state"]?.message}
                  </Typography>
                ) : null}
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} sx={styles.rightItem}>
            <Box sx={{ marginBottom: "15px" }}>
              <Typography variant="subtitle1" sx={styles.label}>
                Address Line 1
              </Typography>
              <Controller
                name="address1"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["address1"] ? true : false}
                    placeholder="Enter your address line 1"
                    size="small"
                    sx={{ width: "100%" }}
                  />
                )}
              />
              {errors["address1"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["address1"]?.message}
                </Typography>
              ) : null}
            </Box>
            {/* Country */}
            <Box sx={{ marginBottom: "15px" }}>
              <Typography variant="subtitle1" sx={styles.label}>
                Country
              </Typography>
              {countries.length > 0 && (
                <FormControl
                  size="small"
                  sx={{ width: "100%" }}
                  error={errors["country"] ? true : false}
                >
                  <InputLabel id="bulk-actions">Select country</InputLabel>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="country-select"
                        id="demo-simple-select"
                        sx={{ width: "100%" }}
                        size="small"
                        label="Select country"
                        displayEmpty
                      >
                        {countries.map((country) => (
                          <MenuItem key={country.code} value={country.name}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors["country"] ? (
                    <Typography variant="subtitle2" sx={styles.errorText}>
                      {errors["country"]?.message}
                    </Typography>
                  ) : null}
                </FormControl>
              )}
            </Box>
            {/* Zip */}
            <Box sx={{ marginBottom: "15px" }}>
              <Typography variant="subtitle1" sx={styles.label}>
                Zipcode
              </Typography>
              <Controller
                name="zipcode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["zipcode"] ? true : false}
                    placeholder="Enter your zip code"
                    size="small"
                    sx={{ width: "100%" }}
                  />
                )}
              />
              {errors["zipcode"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["zipcode"]?.message}
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "0 !important" }}>
            <ColorButton
              variant="contained"
              color="secondary"
              sx={{ width: "100%", margin: "20px 0" }}
              onClick={submitForm(handleSubmit)}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {isSaving ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  "Save"
                )}
              </Typography>
            </ColorButton>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

const styles = {
  sectionContainer: {
    background: "#FFF",
    padding: "15px 20px",
    borderRadius: "5px",
  },
  leftItem: {
    paddingRight: {
      xs: 0,
      sm: "15px",
    },
  },
  rightItem: {
    paddingLeft: {
      xs: 0,
      sm: "15px",
    },
  },
  label: { fontWeight: "bold", marginBottom: 1 },
  errorText: { marginBottom: "1", color: "red" },
};
