import React from "react";
import { toast } from "react-toastify";
import {
  productListVendor,
  productDetailsVendor,
  productStatusVendor,
  productRemoveVendor,
  productRemoveAdmin,
  productListAdmin,
  productDetailsAdmin,
  productListVendorSearch,
  productListAdminSearch,
  productStatusAdmin,
} from "./reduxProductsApi";
import store from "../store";


// get Product List
export const getProductList = (id, count, page) => async (dispatch) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  const authToken = localStorage.getItem("authToken");
  const profile = JSON.parse(localStorage.getItem("profile"));
  if (authToken) {
    if (profile.user_role === "vendor") {
      try {
        const { data } = await productListVendor(count, page);
        if (data.errors) {
          data.errors.map((e) => {
            toast.error(e.message);
          });
        } else {
          dispatch({ type: "SET_PRODUCT_LIST", payload: data.data });
          dispatch({
            type: "SET_PRODUCT_PAGINATION",
            payload: data?.meta.pagination,
          });
        }
      }catch (e) {
        toast.error("Something went wrong.")
      }
    } else {
      if (id) {
        try {
          const { data } = await productListAdmin(id, count, page);
          if (data.errors) {
            data.errors.map((e) => {
              toast.error(e.message);
            });
          } else {
            dispatch({ type: "SET_PRODUCT_LIST", payload: data.data });
            dispatch({
              type: "SET_PRODUCT_PAGINATION",
              payload: data?.meta.pagination,
            });
            dispatch({ type: "SET_PRODUCT_FIRM_NAME", payload: data?.firm_name });
          }
        }catch (e) {
          toast.error("Something went wrong.")
        }
      }
    }
  }
  dispatch({ type: "SET_LOADING_FALSE" });
};

// Get Product details
export const getProductDetails = (id) => async (dispatch) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  const authToken = localStorage.getItem("authToken");
  const profile = JSON.parse(localStorage.getItem("profile"));
  if (authToken) {
    if (profile.user_role === "vendor") {
      try {
        const { data } = await productDetailsVendor(id);
        if (data.errors) {
          data.errors.map((e) => {
            toast.error(e.message);
          });
        } else {
          dispatch({
            type: "SET_PRODUCT_PAGE_DETAILS",
            payload: data.data.attributes,
          });
        }
      }catch (e) {
        toast.error("Something went wrong")
      }
    } else {
      try {
        const { data } = await productDetailsAdmin(id);
        if (data.errors) {
          data.errors.map((e) => {
            toast.error(e.message);
          });
        } else {
          dispatch({
            type: "SET_PRODUCT_PAGE_DETAILS",
            payload: data.data.attributes,
          });
        }
      }catch (e) {
        toast.error("Something went wrong")
      }
    }
  }
  dispatch({ type: "SET_LOADING_FALSE" });
};

// Update the product status
export const productStatusUpdate = (id, status, vId, count, page) => async (
  dispatch
) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  const profile = JSON.parse(localStorage.getItem("profile"));
  if (profile.user_role === "vendor") {
    try {
      const { data } = await productStatusVendor(id, status);
      if (data.errors) {
        data.errors.map((e) => {
          toast.error(e.message);
        });
      } else {
        if (status) {
          toast.success("Product activated successfully");
        } else {
          toast.info("Product deactivated successfully");
        }
      }
    }catch (e) {
      toast.error("Something went wrong")
    }
  } else {
    try {
      const { data } = await productStatusAdmin(id, status);
      if (data.errors) {
        data.errors.map((e) => {
          toast.error(e.message);
        });
      } else {
        if (status) {
          toast.success("Product activated successfully");
        } else {
          toast.info("Product deactivated successfully");
        }
      }
    }catch (e) {
      toast.error("Something went wrong")
    }
  }
  dispatch(getProductList(vId, count, page));
  dispatch({ type: "SET_LOADING_FALSE" });
};

export const productDelete = (id, vId, count, page) => async (dispatch) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  const { currentPage, showCount } = store.getState().products;
  const { user_role } = JSON.parse(localStorage.getItem("profile"));
  try {
    const { data } =
        user_role === "super_administrator" || user_role === "administrator"
            ? await productRemoveAdmin(id)
            : await productRemoveVendor(id);
    if (data.errors) {
      data.errors.map((e) => {
        toast.error(e.message);
      });
    } else {
      toast.success("Product deleted successfully");
      dispatch(getProductList(vId, showCount, currentPage));
    }
  }catch (e) {
    toast.error("Something went wrong")
  }
  dispatch({ type: "SET_LOADING_FALSE" });
};

// set current for product list
export const setCurrentPage = (id, page, count) => async (dispatch) => {
  dispatch({ type: "SET_CURRENT_PRODUCT_PAGE", payload: page });
  dispatch(getProductList(id, count, page));
};

// set Show count for product list
export const setShowCount = (id, page, count) => async (dispatch) => {
  dispatch({ type: "SET_SHOW_PRODUCT_COUNT", payload: count });
  dispatch(getProductList(id, count, page));
};

// get Product list by search
export const getProductListSearch = (count, page, type, search, id) => async (
  dispatch
) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  if (type && search) {
    const authToken = localStorage.getItem("authToken");
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (authToken) {
      if (profile.user_role === "vendor") {
        try {
          const { data } = await productListVendorSearch(
              count,
              page,
              search,
              type
          );
          if (data.errors) {
            data.errors.map((e) => {
              toast.error(e.message);
            });
          } else {
            dispatch({ type: "SET_PRODUCT_LIST", payload: data.data });
            dispatch({
              type: "SET_PRODUCT_PAGINATION",
              payload: data?.meta.pagination,
            });
          }
        }catch (e) {
          toast.error("Something went wrong")
        }
      } else {
        if (id) {
          try {
            const { data } = await productListAdminSearch(
                id,
                count,
                page,
                search,
                type
            );
            if (data.errors) {
              data.errors.map((e) => {
                toast.error(e.message);
              });
            } else {
              dispatch({ type: "SET_PRODUCT_LIST", payload: data.data });
              dispatch({
                type: "SET_PRODUCT_PAGINATION",
                payload: data?.meta.pagination,
              });
            }
          }catch (e) {
            toast.error("Something went wrong")
          }
        } else {
          toast.error("Vendor ID not found");
        }
      }
    }
  } else {
    if (search) {
      toast.error("Please select search type");
    } else {
      toast.error("Please enter value in searchbar to search");
    }
  }
  dispatch({ type: "SET_LOADING_FALSE" });
};


