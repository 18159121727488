import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {completedOrders,filedOrders,pendingOrders,totalOrders} from './assets'

const OrderDashboard = () => {

    // @ts-ignore
    const {orders} = useSelector(state => state?.vendors?.vendorDashboard)
    // @ts-ignore
    function nFormatter(num, digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let item = lookup.slice().reverse().find(function(item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    return (
        <Grid container spacing={2} sx={{width:'100%',display:'flex',justifyContent:'space-between'}} >
            <Grid item xs={6} lg={3} >
                <Paper elevation={0} sx={{height:{xs:'180px',md:'150px'},width:'100%'}}>
                    <Typography variant="h6" component="div" color="#6a3a8e" sx={{fontWeight:"bold",padding:'15px'}}>
                        Orders Completed
                    </Typography>
                    <Box sx={{fontWeight:"bold",padding:'20px 0px 20px 10px',display:'flex',alignItems:'center'}}>
                        <img width='35px' src={completedOrders} alt='Completed Orders'/>
                        <Typography variant="h4" component="div" color="#6a3a8e" sx={{fontWeight:"bold",marginLeft:'10px'}}>
                           {orders?.completed_orders_count}
                        </Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={6} lg={3}>
                <Paper elevation={0} sx={{height:{xs:'180px',md:'150px'},width:'100%'}}>
                    <Typography variant="h6" component="div" color="#6a3a8e" sx={{fontWeight:"bold",padding:'15px'}}>
                        Orders Pending
                    </Typography>
                    <Box sx={{fontWeight:"bold",padding:'20px 0px 20px 10px',display:'flex',alignItems:'center'}}>
                        <img width='35px' src={pendingOrders} alt='Completed Orders'/>
                        <Typography variant="h4" component="div" color="#6a3a8e" sx={{fontWeight:"bold",marginLeft:'10px'}}>
                            {orders?.pending_orders_count}
                        </Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={6} lg={3}>
                <Paper elevation={0} sx={{height:{xs:'180px',md:'150px'},width:'100%'}}>
                    <Typography variant="h6" component="div" color="#6a3a8e" sx={{fontWeight:"bold",padding:'15px'}}>
                        Orders Failed
                    </Typography>
                    <Box sx={{fontWeight:"bold",padding:'20px 0px 20px 10px',display:'flex',alignItems:'center'}}>
                        <img width='35px' src={filedOrders} alt='Completed Orders'/>
                        <Typography variant="h4" component="div" color="#6a3a8e" sx={{fontWeight:"bold",marginLeft:'10px'}}>
                            {orders?.failed_orders_count}
                        </Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={6} lg={3}>
                <Paper elevation={0} sx={{height:{xs:'180px',md:'150px'},width:'100%'}}>
                    <Typography variant="h6" component="div" color="#6a3a8e" sx={{fontWeight:"bold",padding:'15px'}}>
                        Total Orders
                    </Typography>
                    <Grid container sx={{fontWeight:"bold",padding:'20px 0px 20px 10px',display:'flex',alignItems:'center'}}>
                        <img width='35px' src={totalOrders} alt='Completed Orders'/>
                        <Typography variant="h4" component="div" color="#6a3a8e" sx={{fontWeight:"bold",marginLeft:'10px'}}>
                             {nFormatter(orders?.total_orders_count,3)}
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default OrderDashboard;
