import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { getSection4, Image6, Image7, manageLoading } from "./assets";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//@ts-ignore
const Section4 = ({ isLoading }) => {
  const [SectionP1, setSectionP1] = useState({
    title: "Loading..!!",
    img: "",
    link: "#",
  });

  const [SectionP2, setSectionP2] = useState({
    title: "Loading..!!",
    img: "",
    link: "#",
  });
  const dispatch = useDispatch();
  // @ts-ignore
  const { section4 } = useSelector((state) => state.landingPage);

  const manageLoader = () => {
    dispatch(manageLoading());
  };

  const fetchData = async () => {
    setSectionP1({
      title: section4[1]?.title,
      link: section4[1]?.link || "/",
      img: section4[1]?.image,
    });
    setSectionP2({
      title: section4[2]?.title,
      link: section4[2]?.link || "/",
      img: section4[2]?.image,
    });
  };

  useEffect(() => {
    fetchData();
  }, [section4]);

  return (
    <Box
      sx={
        isLoading
          ? { display: "none" }
          : {...styles.container}
      }
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <Box
            sx={styles.boxContainer}
          >
            <img
              src={SectionP1.img}
              className="section4image"
              alt="landing page"
            />
            <Box
              sx={styles.titleContainer}
            >
              <Typography
                  variant="h4"
                  color="white"
                  sx={styles.title}
                >
                  {SectionP1.title}
                </Typography>
                <Link to={SectionP1.link} style={{ textDecoration: "none" }}>
                  <Button
                    sx={styles.btn}
                  >
                    Show More
                  </Button>
                </Link>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <Box
            sx={styles.boxContainer}
          >
            <img
              src={SectionP2.img}
              className="section4image"
              alt="landing page"
            />
            <Box
              sx={styles.titleContainer}
            >
              <Typography
                variant="h4"
                color="white"
                sx={styles.title}
              >
                {SectionP2.title}
              </Typography>
              <Link to={SectionP2.link} style={{ textDecoration: "none" }}>
                <Button
                  sx={styles.btn}
                >
                  Show More
                </Button>
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <img
        src={SectionP1.img}
        onLoad={manageLoader}
        style={{ display: "none" }}
      />
      <img
        src={SectionP2.img}
        onLoad={manageLoader}
        style={{ display: "none" }}
      />
    </Box>
  );
};

export default Section4;

const styles = {
  container: {
    margin: {
      xs: "30px 0",
      sm: "80px 0"
    },
    padding: {
      xs: "0 10px",
      sm: "0 30px"
    }
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as const,
  titleContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row"
    },
    justifyContent: "space-between",
    alignItems: "center",
    background: "#6a3a8e",
    width: "100%",
    padding: "15px",
    marginTop: "-1px",
    borderTop: {
      xs: "10px solid #d6b236",
      sm: "20px solid #d6b236",
    },
  } as const,
  title: { fontWeight: "bold", fontSize: {
    xs: "1.5rem",
    lg: "2.125rem"
  }},
  btn: {
    backgroundColor: "#d6b236",
    color: "black",
    borderRadius: "4px",
    fontWeight: "bold",
    padding: {
      xs: "5px",
      lg: "12px 20px"
    },
    marginTop: {
      xs: "5px",
      sm: 0
    }
  }
}
