import React, { useEffect, useState } from "react";
import { topBanner, getSection5, middleBanner2, manageLoading } from "./assets";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
const Section5 = ({ isLoading }) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { section5 } = useSelector((state) => state.landingPage);

  const manageLoader = () => {
    dispatch(manageLoading());
  };
  const [Section, setSection] = useState({
    bg: "",
    title: "Loading...!!!",
    link: "#",
  });

  useEffect(() => {
    fetchData();
  }, [section5]);

  const fetchData = async () => {
    setSection({
      bg: section5?.background_image?.url,
      title: section5?.heading_title,
      link: section5?.link,
    });
  };

  return (
    <Box
      sx={
        isLoading
          ? { display: "none" }
          : {
              width: "100%",
              backgroundImage: `url(${Section.bg})`,
              backgroundSize: "cover",
              display: "flex",
            }
      }
    >
      <Grid
        item
        xs={12}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: {
            xs: "100px 0",
            sm: "200px 0",
          },
        }}
      >
        <Typography
          variant="h2"
          color="white"
          sx={{ fontWeight: "bold", textAlign: "center", marginBottom: "30px" }}
        >
          {Section.title}
        </Typography>
        <Link to={Section.link} style={{ textDecoration: "none" }}>
          <Button
            style={{
              width: "150px",
              height: "50px",
              backgroundColor: "#d6b236",
              color: "black",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
          >
            Show More
          </Button>
        </Link>
      </Grid>
      <img src={Section.bg} onLoad={manageLoader} style={{ display: "none" }} />
    </Box>
  );
};

export default Section5;
