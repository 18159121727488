import React,{useState} from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { ColorButton } from "./Style";
import { withRouter } from "react-router-dom";
import { exlamation } from "./assets";
import {sentEmail} from "./api";
import {toast} from "react-toastify";
import {CircularProgress} from "@mui/material";

const RegisterForm = ({ setFormType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleNext = async () => {
    setIsLoading(true)
    const res = await sentEmail()
    if(res.data.success){
      setFormType("emailSent")
    }else{
      toast.error("Something Went Wrong..!!")
    }
    setIsLoading(false)
  };
  return (
    <Grid item xs={12} className="registerMainDiv">
      <Box className="registerFormFlex" style={{marginLeft:"27px"}}>
        <Grid item xs={12} sx={{ marginTop: "50px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "90%"
            }}
          >
            <img
              src={exlamation}
              alt="success"
              width="100px"
              sx={{ marginBottom: "15px" }}
            />
            <Typography
              variant="h5"
              component="div"
              color="#472264"
              sx={{ fontWeight: "bold", margin: "15px 0px", textAlign:"center" }}
            >
              Your email verification is pending
            </Typography>
            <Typography
              variant="h6"
              component="span"
              color="#925BBD"
              sx={{ textAlign: "center", fontWeight: "bold"}}
            >
              We have sent an email with a confirmation link to your email address. If you did not receive the link or link is expired then please click on below button to receive the email verification link again.
            </Typography>
          </Box>
          <Box>
            <ColorButton
              size="large"
              variant="contained"
              color="secondary"
              sx={{ width: "95%", height: "64px", marginTop: "20px",backgroundColor:"#693A8E"}}
              onClick={handleNext}
            >
              {
                isLoading ? <CircularProgress color="inherit"/> : "Resend Verification Link"
              }
            </ColorButton>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default withRouter(RegisterForm);
