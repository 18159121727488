import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { manageLoading } from "./assets";
import { withRouter } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

const gridStyle = {
  paddingLeft: {
    md: "24px !important",
    xs: "0px !important",
  },
};

// @ts-ignore
const Section2 = (props) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { section2 } = useSelector((state) => state.landingPage);
  const [SectionP1, setSectionP1] = useState({
    title: "Loading..!!",
    img: "",
    link: "#",
  });
  const [SectionP2, setSectionP2] = useState({
    title: "Loading..!!",
    img: "",
    link: "#",
  });
  const [SectionP3, setSectionP3] = useState({
    title: "Loading..!!",
    img: "",
    link: "#",
  });
  const [SectionP4, setSectionP4] = useState({
    title: "Loading..!!",
    img: "",
    link: "#",
  });
  const [SectionP5, setSectionP5] = useState({
    title: "Loading..!!",
    img: "",
    link: "#",
  });

  useEffect(() => {
    fetchData();
  }, [section2]);

  const manageLoader = () => {
    dispatch(manageLoading());
  };

  const fetchData = async () => {
    setSectionP1({
      title: section2[1]?.title,
      link: section2[1]?.link,
      img: section2[1]?.image,
    });
    setSectionP2({
      title: section2[2]?.title,
      link: section2[2]?.link,
      img: section2[2]?.image,
    });
    setSectionP3({
      title: section2[3]?.title,
      link: section2[3]?.link,
      img: section2[3]?.image,
    });
    setSectionP4({
      title: section2[4]?.title,
      link: section2[4]?.link,
      img: section2[4]?.image,
    });
    setSectionP5({
      title: section2[5]?.title,
      link: section2[5]?.link,
      img: section2[5]?.image,
    });
  };

  return (
    <Box
      sx={
        props.isLoading
          ? { display: "none" }
          : {
              width: "90%",
              height: "580x",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              paddingTop: "50px",
              paddingBottom: "50px",
              margin: "10px auto",
            }
      }
    >
      <Grid container sx={{ display: "flex" }} spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            height: {
              xs: "250px",
              md: "auto",
            },
          }}
        >
          <Box
            className="bg-shadow"
            onClick={() => props.history.push(SectionP1.link)}
            sx={{
              cursor: "pointer",
              width: "100%",
              backgroundImage: `url(${SectionP1.img})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              display: "flex",
              borderRadius: "10px",
              margin: "0 auto",
            }}
          >
            <Typography
              variant="h4"
              color="white"
              sx={{
                fontWeight: "bold",
                margin: "30px",
                alignSelf: "flex-end",
              }}
            >
              {SectionP1.title}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <Grid
            container
            columns={{ xs: 12, sm: 12, md: 6, lg: 6 }}
            columnSpacing={3}
            spacing={3}
            sx={{
              width: {
                xs: "100% !important",
                md: "calc(100% + 24px) !important"
              },
              marginLeft: {
                xs: 0,
                md: "-24px"
              }
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={3} sx={gridStyle}>
              <Box
                onClick={() => props.history.push(SectionP2.link)}
                className="bg-shadow"
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: "240px",
                  backgroundImage: `url(${SectionP2.img})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  display: "flex",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h4"
                  color="white"
                  sx={{
                    fontWeight: "bold",
                    margin: "20px",
                    alignSelf: "flex-end",
                  }}
                >
                  {SectionP2.title}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} sx={gridStyle}>
              <Box
                onClick={() => props.history.push(SectionP3.link)}
                className="bg-shadow"
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: "240px",
                  backgroundImage: `url(${SectionP3.img})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  display: "flex",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h4"
                  color="white"
                  sx={{
                    fontWeight: "bold",
                    margin: "20px",
                    alignSelf: "flex-end",
                  }}
                >
                  {SectionP3.title}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} sx={gridStyle}>
              <Box
                onClick={() => props.history.push(SectionP4.link)}
                className="bg-shadow"
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: "240px",
                  backgroundImage: `url(${SectionP4.img})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  display: "flex",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h4"
                  color="white"
                  sx={{
                    fontWeight: "bold",
                    margin: "20px",
                    alignSelf: "flex-end",
                  }}
                >
                  {SectionP4.title}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} sx={gridStyle}>
              <Box
                onClick={() => props.history.push(SectionP5.link)}
                className="bg-shadow"
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: "240px",
                  backgroundImage: `url(${SectionP5.img})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  display: "flex",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h4"
                  color="white"
                  sx={{
                    fontWeight: "bold",
                    margin: "20px",
                    alignSelf: "flex-end",
                  }}
                >
                  {SectionP5.title}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <img
        src={SectionP1.img}
        onLoad={manageLoader}
        style={{ display: "none" }}
      />
      <img
        src={SectionP2.img}
        onLoad={manageLoader}
        style={{ display: "none" }}
      />
      <img
        src={SectionP3.img}
        onLoad={manageLoader}
        style={{ display: "none" }}
      />
      <img
        src={SectionP4.img}
        onLoad={manageLoader}
        style={{ display: "none" }}
      />
      <img
        src={SectionP5.img}
        onLoad={manageLoader}
        style={{ display: "none" }}
      />
    </Box>
  );
};

export default withRouter(Section2);
