import { Grid, TextField } from "@material-ui/core";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputBase,
  ListItemText,
  MenuItem,
  Typography,
  Container,
} from "@mui/material";
import Select from "@mui/material/Select";
import React from "react";
import { Controller } from "react-hook-form";
import { withRouter } from "react-router-dom";
import LoaderPage from "../../../../components/src/LoaderPage";
import useAddEditProduct from "./useAddEditProduct";
import { cancelImage, DropZone } from "../assets";
import { useStyles, styles } from "./styles";
import DeletionModal from "../DeletionModal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddProduct = (props: any) => {
  const classes = useStyles();
  const {
    isLoading,
    isSaving,
    formType,
    profile,
    errors,
    categories,
    subCategories,
    control,
    values,
    setValues,
    setFiles,
    fileUrls,
    handleImageDeletion,
    races,
    countries,
    handleDeleteClick,
    validateSubmission,
    handleSubmit,
    openModal,
    setOpenModal,
    productId,
  } = useAddEditProduct(props);

  if (isLoading) {
    return <LoaderPage />;
  }

  if (isSaving) {
    return <LoaderPage />;
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.formType}>
            {formType === "add" ? "Add" : "Edit"} Product
          </Typography>
          <Typography variant="subtitle2" display="block" gutterBottom>
            {profile.user_role === "administrator" ||
            profile.user_role === "super_administrator"
              ? "Vendors / Products "
              : "My Products "}
            <span style={{ color: "#824bad", fontWeight: "normal" }}>
              / {formType === "add" ? "Add" : "Edit"} Product
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box className={classes.topContainer}>
            {/* Category */}
            <Typography variant="subtitle1" className={classes.label}>
              Category*
            </Typography>
            <FormControl
              className={classes.select}
              error={errors["categoryName"] ? true : false}
              sx={{
                border: errors["categoryName"] && "1px solid red !important",
              }}
            >
              {categories.length > 0 && (
                <Controller
                  name="categoryName"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      displayEmpty
                      input={
                        <InputBase
                          placeholder="Select Product Category"
                          className={classes.inputBase}
                        />
                      }
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <p>Select Product Category</p>;
                        }
                        return selected;
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled>
                        <p>Select Product Category</p>
                      </MenuItem>
                      {categories.map((cat) => (
                        <MenuItem key={cat.id} value={cat.attributes.name}>
                          <ListItemText primary={cat.attributes.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )}
              {errors["categoryName"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["categoryName"]?.message}
                </Typography>
              ) : null}
            </FormControl>
            {/* Sub category */}
            <Typography variant="subtitle1" className={classes.label}>
              Sub-category
            </Typography>
            <FormControl
              className={classes.select}
              error={errors["subCategoryName"] ? true : false}
              sx={{
                border: errors["subCategoryName"] && "1px solid red !important",
              }}
            >
              <Controller
                name="subCategoryName"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    value={
                      subCategories
                        .map((s: { name: string }) => s.name)
                        .includes(field.value)
                        ? field.value
                        : ""
                    }
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    displayEmpty
                    input={
                      <InputBase
                        placeholder="Select Sub Category"
                        className={classes.inputBase}
                      />
                    }
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <p>Select Sub-category</p>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">
                      <p>Select Sub-category</p>
                    </MenuItem>
                    {subCategories.map((subCat: any) => (
                      <MenuItem key={subCat.id} value={subCat.name}>
                        <ListItemText primary={subCat.name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors["subCategoryName"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["subCategoryName"]?.message}
                </Typography>
              ) : null}
            </FormControl>
            {/* Product name */}
            <Typography variant="subtitle1" className={classes.label}>
              Product Name*
            </Typography>
            <Controller
              name="productName"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Product Name"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["productName"] && "1px solid red" }}
                />
              )}
            />
            {errors["productName"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["productName"]?.message}
              </Typography>
            ) : null}
            {/* Price */}
            <Typography variant="subtitle1" className={classes.label}>
              Price*
            </Typography>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Price"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["price"] && "1px solid red" }}
                />
              )}
            />
            {errors["price"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["price"]?.message}
              </Typography>
            ) : null}
            {/* Stock quantity */}
            <div className={classes.stockContainer}>
              <div
                style={{ display: "flex", width: "30%" }}
                className={classes.cart}
              >
                <Typography variant="h5" className={classes.stockQty}>
                  {values.stockQty}
                </Typography>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <IconButton
                    onClick={() => {
                      setValues((prev) => ({
                        ...prev,
                        stockQty: prev.stockQty + 1,
                      }));
                    }}
                    className="btns-inc-dec"
                  >
                    <ExpandLessIcon />
                  </IconButton>
                  <IconButton
                    className="btns-inc-dec"
                    onClick={() => {
                      if (values.stockQty >= 2) {
                        setValues((prev) => ({
                          ...prev,
                          stockQty: prev.stockQty - 1,
                        }));
                      }
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </div>
              </div>
              <Typography variant="subtitle1" className={classes.label}>
                Add Stock
              </Typography>
            </div>
          </Box>
          <Box className={classes.sectionContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="subtitle1" className={classes.heading}>
                    Upload Media
                  </Typography>
                  <Typography variant="subtitle1" className={classes.fileCount}>
                    {values.images.length}/5
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <DropZone setFiles={setFiles} />
              </Grid>
              {/* Image thumbnails */}
              {fileUrls.map((url, index) => (
                // @ts-ignore
                <Grid item xs={12} sm={6} md={6} lg={4} key={url}>
                  <Box sx={styles.previewContaier}>
                    <img
                      // @ts-ignore
                      src={url}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px", objectFit: "cover" }}
                    />
                    <IconButton
                      onClick={() => handleImageDeletion(index)}
                      className={classes.cancelIcon}
                    >
                      <img src={cancelImage} width="20px" height="20px" />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box className={classes.sectionContainer}>
            <Typography variant="subtitle1" className={classes.heading}>
              Product Details
            </Typography>
            {/* Race */}
            <Typography variant="subtitle1" className={classes.label}>
              Race*
            </Typography>
            <FormControl
              className={classes.select}
              error={errors["race"] ? true : false}
              sx={{
                border: errors["race"] && "1px solid red !important",
              }}
            >
              <Controller
                name="race"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    displayEmpty
                    input={<InputBase className={classes.inputBase} />}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <p>Select Race</p>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled>
                      <p>Select Race</p>
                    </MenuItem>
                    {races.map((race: string, index: number) => (
                      <MenuItem key={index} value={race}>
                        <ListItemText primary={race} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors["race"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["race"]?.message}
                </Typography>
              ) : null}
            </FormControl>
            {/* Birth weight */}
            <Typography variant="subtitle1" className={classes.label}>
              Birth Weight*
            </Typography>
            <FormControl
              className={classes.select}
              error={errors["birthWeight"] ? true : false}
              sx={{
                border: errors["birthWeight"] && "1px solid red !important",
              }}
            >
              <Controller
                name="birthWeight"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    displayEmpty
                    input={<InputBase className={classes.inputBase} />}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <p>Select Birth Weight</p>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled>
                      <p>Select Birth Weight</p>
                    </MenuItem>
                    {["20 - 33 KG", "34 - 40 KG", "41 - 50KG"].map((name: any) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors["birthWeight"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["birthWeight"]?.message}
                </Typography>
              ) : null}
            </FormControl>
            {/* Weaning weight */}
            <Typography variant="subtitle1" className={classes.label}>
              Weaning Weight*
            </Typography>
            <FormControl
              className={classes.select}
              error={errors["weaningWeight"] ? true : false}
              sx={{
                border: errors["weaningWeight"] && "1px solid red !important",
              }}
            >
              <Controller
                name="weaningWeight"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    displayEmpty
                    input={<InputBase className={classes.inputBase} />}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <p>Select Weaning Weight</p>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled>
                      <p>Select Weaning Weight</p>
                    </MenuItem>
                    {["170 - 248 KG", "249 - 327 KG"].map((name: any) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors["weaningWeight"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["weaningWeight"]?.message}
                </Typography>
              ) : null}
            </FormControl>
            {/* Weight at the end */}
            <Typography variant="subtitle1" className={classes.label}>
              Weight at the End*
            </Typography>
            <FormControl
              className={classes.select}
              error={errors["weightAtTheEnd"] ? true : false}
              sx={{
                border: errors["weightAtTheEnd"] && "1px solid red !important",
              }}
            >
              <Controller
                name="weightAtTheEnd"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    displayEmpty
                    input={<InputBase className={classes.inputBase} />}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <p>Select Weight at the End</p>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled>
                      <p>Select Weight at the End</p>
                    </MenuItem>
                    {["500 - 604 KG", "710 - 814 KG", "1024 - 1129 KG"].map((name: any) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors["weightAtTheEnd"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["weightAtTheEnd"]?.message}
                </Typography>
              ) : null}
            </FormControl>
            {/* Scrotal cicumference */}
            <Typography variant="subtitle1" className={classes.label}>
              Scrotal Circumference
            </Typography>
            <FormControl
              className={classes.select}
              error={errors["scrotal"] ? true : false}
              sx={{
                border: errors["scrotal"] && "1px solid red !important",
              }}
            >
              <Controller
                name="scrotal"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    displayEmpty
                    input={<InputBase className={classes.inputBase} />}
                    renderValue={(selected) => {
                      if (!selected || selected?.length === 0) {
                        return <p>Select Scrotal Circumference</p>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">
                      <p>Select Scrotal Circumference</p>
                    </MenuItem>
                    {["27 - 33 CM", "34 - 40 CM"].map((name: any) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors["scrotal"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["scrotal"]?.message}
                </Typography>
              ) : null}
            </FormControl>
            {/* Country */}
            <Typography variant="subtitle1" className={classes.label}>
              By Country*
            </Typography>
            <FormControl
              className={classes.select}
              error={errors["byCountry"] ? true : false}
              sx={{
                border: errors["byCountry"] && "1px solid red !important",
              }}
            >
              {countries.length > 0 && (
                <Controller
                  name="byCountry"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      displayEmpty
                      name="byCountry"
                      input={<InputBase className={classes.inputBase} />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <p>Select Country</p>
                      </MenuItem>
                      {countries.map((country: any) => (
                        <MenuItem key={country.code} value={country.name}>
                          <ListItemText primary={country.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )}
              {errors["byCountry"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["byCountry"]?.message}
                </Typography>
              ) : null}
            </FormControl>
            {/* Delivery */}
            <Typography variant="subtitle1" className={classes.label}>
              Delivery*
            </Typography>
            <FormControl
              className={classes.select}
              error={errors["delivery"] ? true : false}
              sx={{
                border: errors["delivery"] && "1px solid red !important",
              }}
            >
              <Controller
                name="delivery"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    displayEmpty
                    input={<InputBase className={classes.inputBase} />}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <p>Select Delivery</p>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled value="">
                      <p>Select Delivery</p>
                    </MenuItem>
                    {["Apto para 24 meses", "No apto para 24 meses"].map(
                      (name: any) => (
                        <MenuItem key={name} value={name}>
                          <ListItemText primary={name} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
              {errors["delivery"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["delivery"]?.message}
                </Typography>
              ) : null}
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box className={classes.topContainer}>
            <Typography variant="subtitle1" className={classes.heading}>
              Father Data
            </Typography>
            <Typography variant="subtitle1" className={classes.label}>
              Animal Name*
            </Typography>
            <Controller
              name="FanimalName"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Animal Name"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["FanimalName"] && "1px solid red" }}
                />
              )}
            />
            {errors["FanimalName"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["FanimalName"]?.message}
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.label}>
              Nick Name*
            </Typography>
            <Controller
              name="FnickName"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Nick Name"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["FnickName"] && "1px solid red" }}
                />
              )}
            />
            {errors["FnickName"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["FnickName"]?.message}
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.label}>
              Date of Birth*
            </Typography>
            <Controller
              name="FDateOfBirth"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ? field.value : ""}
                  id="date"
                  type="date"
                  placeholder="Select Date"
                  style={{ color: "grey" }}
                  className={classes.select}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ disableUnderline: true }}
                  error={errors["FDateOfBirth"] ? true : false}
                />
              )}
            />
            {errors["FDateOfBirth"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["FDateOfBirth"]?.message}
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.label}>
              Breed*
            </Typography>
            <Controller
              name="Fbreed"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Breed"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["Fbreed"] && "1px solid red" }}
                />
              )}
            />
            {errors["Fbreed"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["Fbreed"]?.message}
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.label}>
              DNA Analysis Code
            </Typography>
            <Controller
              name="FAnalysisCode"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter DNA Analysis Code"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["FAnalysisCode"] && "1px solid red" }}
                />
              )}
            />
            {errors["FAnalysisCode"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["FAnalysisCode"]?.message}
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.label}>
              Number of DNA Analysis
            </Typography>
            <Controller
              name="FNumAnalysis"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Number of DNA Analysis"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["FNumAnalysis"] && "1px solid red" }}
                />
              )}
            />
            {errors["FNumAnalysis"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["FNumAnalysis"]?.message}
              </Typography>
            ) : null}
          </Box>
          <Box
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              marginTop: "20px",
              padding: "20px",
            }}
          >
            <Typography variant="subtitle1" className={classes.heading}>
              Mother Data
            </Typography>
            <Typography variant="subtitle1" className={classes.label}>
              Animal Name
            </Typography>
            <Controller
              name="ManimalName"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Animal Name"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["ManimalName"] && "1px solid red" }}
                />
              )}
            />
            {errors["ManimalName"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["ManimalName"]?.message}
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.label}>
              Nick name
            </Typography>
            <Controller
              name="MnickName"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Nick Name"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["MnickName"] && "1px solid red" }}
                />
              )}
            />
            {errors["MnickName"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["MnickName"]?.message}
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.label}>
              Date of Birth
            </Typography>
            <Controller
              name="MDateOfBirth"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ? field.value : ""}
                  id="date"
                  type="date"
                  placeholder="Select Date"
                  style={{ color: "grey" }}
                  className={classes.select}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ disableUnderline: true }}
                  error={errors["MDateOfBirth"] ? true : false}
                />
              )}
            />
            {errors["MDateOfBirth"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["MDateOfBirth"]?.message}
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.label}>
              Breed
            </Typography>
            <Controller
              name="Mbreed"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Breed"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["Mbreed"] && "1px solid red" }}
                />
              )}
            />
            {errors["Mbreed"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["Mbreed"]?.message}
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.label}>
              DNA Analysis Code
            </Typography>
            <Controller
              name="MAnalysisCode"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter DNA Analysis Code"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["MAnalysisCode"] && "1px solid red" }}
                />
              )}
            />
            {errors["MAnalysisCode"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["MAnalysisCode"]?.message}
              </Typography>
            ) : null}
            <Typography variant="subtitle1" className={classes.label}>
              Number of DNA Analysis
            </Typography>
            <Controller
              name="MNumAnalysis"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Number of DNA Analysis"
                  className={`${classes.inputBase} ${classes.select}`}
                  sx={{ border: errors["MNumAnalysis"] && "1px solid red" }}
                />
              )}
            />
            {errors["MNumAnalysis"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["MNumAnalysis"]?.message}
              </Typography>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.sectionContainer}>
            <Typography variant="subtitle1" className={classes.label}>
              Description*
            </Typography>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  placeholder="Enter Description"
                  multiline
                  rows={5}
                  className={classes.description}
                  sx={{ border: errors["FanimalName"] && "1px solid red" }}
                />
              )}
            />
            {errors["description"] ? (
              <Typography
                variant="subtitle2"
                sx={{ ...styles.errorText, paddingLeft: "33px", margin: 0 }}
              >
                {errors["description"]?.message}
              </Typography>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.btnContainer}>
            {formType === "edit" && (
              <Button
                variant="text"
                sx={styles.btn}
                onClick={handleDeleteClick}
              >
                DELETE
              </Button>
            )}
            <Button
              variant="text"
              sx={styles.btn}
              onClick={validateSubmission((formData) =>
                handleSubmit(formData, false)
              )}
            >
              SAVE AS DRAFT
            </Button>
            <Button
              variant="contained"
              onClick={validateSubmission((formData) =>
                handleSubmit(formData, true)
              )}
              sx={styles.btnPublish}
            >
              PUBLISH PRODUCT
            </Button>
          </div>
        </Grid>
      </Grid>
      <DeletionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        deleteId={productId}
      />
    </Container>
  );
};

export default withRouter(AddProduct);
