import axios from "axios";
import {baseURL} from "framework/src/config";
const token = localStorage.getItem('authToken') || '';

const API = axios.create({
  baseURL: baseURL,
  // timeout: 5000
});

export const addProduct = async (data, token) => {
  try {
    return await API.post("/bx_block_catalogue/catalogues", data, {
      headers: {
        token: token,
      },
    });
  } catch (e) {

    return e.response;
  }
};

export const addProductFromAdmin = async (data, token) => {
  try {
    return await API.post("/bx_block_dashboard/vendors/create_product", data, {
      headers: {
        token: token,
      },
    });
  } catch (e) {
    return e.response;
  }
};

export const editProduct = async (data, id, token) => {
  try {
    return await API.put(`/bx_block_catalogue/catalogues/${id}`, data, {
      headers: {
        token: token,
      },
    });
  } catch (e) {
    return e.response;
  }
};

export const editProductFromAdmin = async (data, id, token) => {
  try {
    return await API.put(`/bx_block_dashboard/vendors/edit_product/${id}`, data, {
      headers: {
        token: token,
      },
    });
  } catch (e) {
    return e.response;
  }
};

export const deleteImage = async (id, token) => {
  try {
    return await API.post(`/bx_block_catalogue/catalogues/remove_image?id=${id}`, {}, {
      headers: {
        token: token,
      },
    });
  } catch (e) {
    return e.response;
  }
};

