import { Box, Grid, Menu, MenuItem, Typography } from '@mui/material';
import React, {useEffect, Fragment, useState} from 'react'
import {menuImage} from "./assets";
import { makeStyles } from '@material-ui/core/styles';
import { AsNavFor } from '../../info-page/src/assests';
import {useDispatch,useSelector} from "react-redux";
import {getProductDetails} from "./assets"
import {withRouter} from 'react-router-dom'
import LoaderPage from "../../../components/src/LoaderPage";
import DeletionModal from './DeletionModal';
import IconButton from "@mui/material/IconButton";
import moment from "moment"

// @ts-ignore
const BuyerProductDetail =({match,history})=>{
    // @ts-ignore
    const profile = JSON.parse(localStorage.getItem("profile"));
    // @ts-ignore
    const details = useSelector(state => state.products.productDetails)
    // @ts-ignore
    const {isLoading} = useSelector(state => state.products)
    // @ts-ignore
    const {addRemoveProduct,editProduct} = useSelector(state => state.landingPage)
    const dispatch = useDispatch()
    const {id} = match.params
    const [openModal, setOpenModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    useEffect(() => {
        dispatch(getProductDetails(id))
    }, []);

    const handleClickMenu = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    // @ts-ignore
    const manageDelete = (id) => {
        setAnchorEl(null);
        setOpenModal(true);
        setDeleteId(id)
    }
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const classes = useStyles();
    return(
        <Fragment>
            {
                isLoading ? <LoaderPage/> :
        <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:0,padding:0}} component="div">
                        {details.name}
                    </Typography>
                    <div style={{display:"flex",justifyContent:"space-between" }}>
                    <Typography variant="subtitle2" style={{color:"#472264",fontWeight:"normal", margin:0,padding:0}} display="inline"  gutterBottom>
                        My Products <span style={{color:"#824bad",fontWeight:"normal"}}>/  {details.name}</span>
                    </Typography>
                    <IconButton sx={{height:"40px"}} onClick={handleClickMenu}>
                        <img src ={menuImage} width="25px" />
                    </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            style={{ boxShadow: "none" }}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem
                                className={classes.menuLink}
                                onClick={() => {
                                    history.goBack()
                                }}
                            >
                                Back to Products
                            </MenuItem>
                            {
                                editProduct &&
                                <MenuItem
                                    className={classes.menuLink}
                                    onClick={() => {
                                        setAnchorEl(anchorEl);
                                        if (profile.user_role === "vendor") {
                                            history.push(
                                                `/vendor/myProducts/editProduct/${id}`, {
                                                    productId: id,
                                                    formType: "edit"
                                                }
                                            );
                                        } else if (
                                            profile.user_role === "administrator" ||
                                            profile.user_role === "super_administrator"
                                        ) {
                                            history.push("/admin/Vendors/Products/editProduct", {
                                                vendorId: details.account_id,
                                                productId: id,
                                                formType: "edit",
                                                user: "admin"
                                            });
                                        }
                                    }}
                                >
                                    Edit Product
                                </MenuItem>
                            }
                            {
                                addRemoveProduct ?
                                <MenuItem className={classes.menuLink} onClick={() => {
                                    manageDelete(id)}}>
                                    Delete Product
                                </MenuItem> : null
                            }
                        </Menu>
                    </div>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Grid item xs={12}>
                        <AsNavFor prodImages={details.images}/>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box className={classes.box}>
                    <Typography variant="h6" gutterBottom style={{color:"#472264",fontWeight:"bold", }}display="block"  component="div">
                        {details.name}
                    </Typography>
                    <Typography variant="subtitle2" style={{color:"#824bad",fontWeight:"normal", }} display="block"  gutterBottom>
                        Id: {details.product_number}
                    </Typography>
                    <Typography variant="subtitle2" style={{color:"#472264",fontWeight:"bold",  }} display="block"  gutterBottom>
                        ${details.price}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" style={{color:"#472264",fontWeight:"bold", marginTop:"20px"}} display="block"  gutterBottom>
                                FATHER DATA
                            </Typography>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2"className={classes.title} display="inline"  gutterBottom>
                                    Animal name: 
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    {details?.father_data?.attributes.animal_name || " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Nick name:
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    {details?.father_data?.attributes.nick_name || " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Gender: 
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    MALE
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Breed: 
                                </Typography>
                                <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                    {details?.father_data?.attributes.breed || " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Date of birth: 
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    {details?.father_data?.attributes.date_of_birth ? moment(details?.father_data?.attributes.date_of_birth).format("DD/MM/YYYY")  : " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    DNA analysis code: 
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    {details?.father_data?.attributes.dna_analysis_code || " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Number of DNA analysis: 
                                </Typography>
                                <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                    {details?.father_data?.attributes.number_of_dna_analysis || " -"}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" style={{color:"#472264",fontWeight:"bold",marginTop:"20px"}} display="block"  gutterBottom>
                                MOTHER DATA
                            </Typography>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Animal name: 
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    {details?.mother_data?.attributes.animal_name || " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Nick name:
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    {details?.mother_data?.attributes.nick_name || " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Gender: 
                                </Typography>
                                <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                    FEMALE
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Breed: 
                                </Typography>
                                <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                    {details?.mother_data?.attributes.breed || " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Date of birth:
                                </Typography>
                                <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                    {details?.mother_data?.attributes.date_of_birth ? moment(details?.mother_data?.attributes.date_of_birth).format("DD/MM/YYYY")  :  " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    DNA analysis code: 
                                </Typography>
                                <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                    {details?.mother_data?.attributes.dna_analysis_code || " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Number of DNA analysis: 
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    {details?.mother_data?.attributes.number_of_dna_analysis || " -"}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.boxproduct}>
                    <Typography variant="h6" gutterBottom style={{color:"#472264",fontWeight:"bold", }}display="block"  component="div">
                        Product Details
                    </Typography>
                    <Grid container >

                        <Grid item xs={12} sm={6} lg={4}>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Race: 
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    {details.race || " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Birth Weight: 
                                </Typography>
                                <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                    {details.birth_weight || " -"}
                                </Typography>
                            </div>
                            <div className={classes.textdiv}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Weaning Weight: 
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    {details.weaning_weight || " -"}
                                </Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <div className={classes.textdiv}>
                                    <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                        Weight At The End: 
                                    </Typography>
                                    <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                        {details.weight_at_the_end || " -"}
                                    </Typography>
                                </div>
                                <div className={classes.textdiv}>
                                    <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                        Scrotal Circumference: 
                                    </Typography>
                                    <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                        {details.scrotal_circumference || " -"}
                                    </Typography>
                                </div>
                                <div className={classes.textdiv}>
                                    <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                        By Country: 
                                    </Typography>
                                    <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                        {details.by_country || " -"}
                                    </Typography>
                                </div>


                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                                <div className={classes.textdiv} >
                                    <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                        Ease of Delivery: 
                                    </Typography>
                                    <Typography variant="subtitle2"className={classes.detail} display="inline"  gutterBottom>
                                        Suitable for {details.delivery || " -"}
                                    </Typography>
                                </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.textdiv || " -"}>
                                <Typography variant="subtitle2" className={classes.title} display="inline"  gutterBottom>
                                    Description:
                                </Typography>
                                <Typography variant="subtitle2" className={classes.detail} display="inline"  gutterBottom>
                                    {details.product_details || " -"}
                                </Typography>
                            </div>
                        </Grid>
                        <DeletionModal openModal={openModal} setOpenModal={setOpenModal} deleteId={id} id={details.account_id} />
                    </Grid>
                    </Box>
                </Grid>
        </Grid>
            }
        </Fragment>
    )


}
export default withRouter(BuyerProductDetail);

const useStyles = makeStyles({
   
    menuLink:{
      textDecoration:"none",
      color:"#472264",
      marginBottom:5,
      boxShadow:"none"
    },
    box:{
      backgroundColor:"white",
      borderRadius:"10px",
      padding:"50px 30px"
    },
    title:{
        color:"#824bad",
        fontWeight:"bold", 
        fontSize:14 
    },
    detail:{
        color:"#824bad",
        fontWeight:"normal",  
        fontSize:14,
        marginLeft:"5px",
        
    },
    textdiv:{
        display:"flex-inline",
        marginTop:"15px"
    },
    boxproduct:{
        backgroundColor:"white",
        borderRadius:"10px",
        padding:"30px"
    }
  });