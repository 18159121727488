import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell from '@mui/material/TableCell';
import { Box } from "@mui/system";
import React, { useEffect, useState, Fragment } from "react";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
import {
  searchImage,
  eyeImage,
  getBuyerList,
  selectAllBuyer,
  handleSelectBuyer,
  buyerActivate,
  buyerDeactivate,
  bulkActive,
  bulkDeactivate,
  setCurrentPage,
  setShowCount,
  getBuyerListSearch,
  setSearchKeyBuyer,
  setSearchTextBuyer,
  getVendorListSearch,
} from "./assets";

import {
  Grid,
  Pagination,
  Checkbox,
  Switch,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Select,
  InputAdornment,
  TextField,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import LoaderPage from "../../../components/src/LoaderPage";
import moment from "moment";
import NoRecordPage from "../../../components/src/NoRecordPage";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#472264",
      color: theme.palette.common.white,
      textAlign: "start",
    },
    body: {
      fontSize: 13,
      color: "#472264",
      fontWeight: "bold",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f5ebfc",
      },
    },
  })
)(TableRow);

function createData(
  name: string,
  calories: string,
  fat: string,
  phone: number
) {
  return { name, calories, fat, phone };
}

const PurpleSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#693a8e",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#925BBD",
  },
}));

// @ts-ignore
const BuyerPage = ({ history }) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const Buyers = useSelector((state) => state.buyers);
  const {
    buyers,
    pagination,
    isLoading,
    selected,
    currentPage,
    showCount,
    searchText,
    searchKey,
  } = Buyers;
  // @ts-ignore
  const {buyerPermission} = useSelector(state => state.landingPage)
  const [action, setAction] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [searchPhrase, setSearchPhrase] = useState("");
  const [initiatedSearch, setInitiatedSearch] = useState(false);
  const classes = useStyles();

  const handleClickMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // @ts-ignore
  const managePagination = (event, value) => {
    dispatch(setCurrentPage(value, showCount));
  };

  // @ts-ignore
  const manageStatus = (e, id) => {
    if (e.target.checked) {
      dispatch(buyerActivate(id));
    } else {
      dispatch(buyerDeactivate(id));
    }
  };

  const handleChange = () => {
    // @ts-ignore
    if (action == 1) {
      dispatch(bulkActive(selected));
    }
    // @ts-ignore
    if (action == 2) {
      dispatch(bulkDeactivate(selected));
    }
  };

  // Search
  const handleSearch = () => {
    if (searchKey && searchText) {
      setInitiatedSearch(true);
      dispatch(getBuyerListSearch(showCount, 1, searchKey, searchText));
    }
  };

  // Clear search
  const clearSearch = () => {
    setInitiatedSearch(false);
    setSearchBy("");
    setSearchPhrase("");
    dispatch(getBuyerList(showCount, 1));
  };

  // Set search by
  useEffect(() => {
    dispatch(setSearchKeyBuyer(searchBy));
  }, [searchBy]);

  // Set search phrase
  useEffect(() => {
    dispatch(setSearchTextBuyer(searchPhrase));
  }, [searchPhrase]);

  // Load data
  useEffect(() => {
    dispatch(getBuyerList(showCount, currentPage));
  }, []);
  // @ts-ignore
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container className={classes.box}>
            <Grid xs={12} sm={6}>
              {/* Bulk actions */}
              {
                buyerPermission &&
                <Box sx={styles.bulk}>
                  <FormControl size="small" sx={{ ...styles.formControl, width: 150 }}>
                    <InputLabel id="demo-simple-select-label"> Bulk Actions </InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={action} label="Bulk Actions" onChange={(e) => setAction(e.target.value)}>
                      <MenuItem value={1}>Bulk Activate</MenuItem>
                      <MenuItem value={2}>Bulk Deactivate</MenuItem>
                    </Select>
                  </FormControl>
                  <Button variant="contained" size="small"
                      // @ts-ignore
                          sx={{ ...styles.btn, width: "auto", marginLeft: 1 }}
                          onClick={handleChange}
                          disabled={selected.length === 0 || !action}
                  >
                    Apply
                  </Button>
                </Box>
            }
            </Grid>
            <Grid xs={12} sm={6} sx={{display:"flex",justifyContent: {sm:"flex-end",xs:"flex-start"}}}>
              {/* Search */}
              <Box sx={{ display: "flex", alignItems: "center",marginBottom:1  }}>
                <Stack direction="row">
                  <FormControl size="small" sx={styles.formControl}>
                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                    <Select
                        labelId="select-label-id"
                        id="select-id"
                        value={searchBy}
                        label="Select"
                        onChange={(e) => setSearchBy(e.target.value)}
                        sx={styles.select}
                    >
                      <MenuItem value={"full_name"}>Name</MenuItem>
                      <MenuItem value={"email"}>Email Id</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                      size="small"
                      value={searchPhrase}
                      onChange={(e) => setSearchPhrase(e.target.value)}
                      sx={styles.textField}
                      InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                              {initiatedSearch && (
                                  <IconButton onClick={clearSearch}>
                                    <CloseIcon />
                                  </IconButton>
                              )}
                              <IconButton onClick={handleSearch}>
                                <img src={searchImage} alt="search" />
                              </IconButton>
                            </InputAdornment>
                        ),
                      }}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {isLoading ? (
          <LoaderPage />
        ) : buyers.length !== 0 ? (
          <Grid item xs={12}>
            <TableContainer
              sx={{
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                width:{
                  md:"100%",
                  sm:"700px",
                  xs:"350px"
                }
              }}
            >
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {" "}
                      <Checkbox
                        color="secondary"
                        checked={selected.length > 0 ? true : false}
                        onChange={() => dispatch(selectAllBuyer())}
                      />{" "}
                    </StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Registered</StyledTableCell>
                    <StyledTableCell align="right">Email Id</StyledTableCell>
                    <StyledTableCell align="right">Phone</StyledTableCell>
                    {
                      buyerPermission &&
                      <StyledTableCell align="right">
                        Activate/Deactivate
                      </StyledTableCell>
                    }
                    <StyledTableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {// @ts-ignore
                  buyers.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {" "}
                        <Checkbox
                          color="secondary"
                          checked={row.isChecked}
                          inputProps={
                            {
                              // "aria-labelledby": labelId
                            }
                          }
                          onClick={() => dispatch(handleSelectBuyer(row.id))}
                        />{" "}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "start",
                          }}
                        >
                          {row.attributes.full_name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "start",
                          }}
                        >
                          {moment(row.attributes.created_at).format(
                            "MMM Do YY"
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.attributes.email}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {
                          row.attributes.phone_number ? `+${row.attributes.phone_number}` : "-"
                        }
                      </StyledTableCell>
                      {
                        buyerPermission &&
                        <StyledTableCell align="left">
                          <PurpleSwitch
                              disabled={!buyerPermission}
                              checked={row.attributes.activated}
                              onChange={(e) => manageStatus(e, row.id)}
                              name="show-in-menu"
                          />
                        </StyledTableCell>
                      }
                      <StyledTableCell align="left">
                        {/* <div style={{display:"flex",alignItems:"start", justifyContent:"start"}}> */}
                        <IconButton
                          onClick={() => history.push(`buyerDetail/${row.id}`)}
                        >
                          <img src={eyeImage} width="20px" height="15px" />
                        </IconButton>
                        {/* </div> */}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <NoRecordPage text={"Sorry, no buyer users found!"}/>
        )}
        <Grid item xs={12} sm={6}>
          <FormControl size="small" sx={{ width: "150px" }}>
            <InputLabel id="bulk-actions">Show:</InputLabel>
            <Select
              labelId="bulk-actions"
              id="demo-simple-select"
              value={showCount}
              label={`Show:`}
              onChange={(e) =>
                dispatch(setShowCount(1, e.target.value))
              }
              sx={{ background: "#fff" }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Pagination */}
        <Grid item xs={12} sm={6} sx={{display:'flex',justifyContent: {xs:"flex-start",sm:"flex-end"}}}>
          <Stack spacing={5} >
            <Pagination
              count={pagination.total_pages}
              hidePrevButton
              page={currentPage}
              color="secondary"
              variant="outlined"
              shape="rounded"
              onChange={managePagination}
              sx={{
                "& .Mui-selected": {
                  color: "#fff !important",
                },
                "& .MuiPaginationItem-root:hover": {
                  backgroundColor: "#693A8E",
                },
                ".css-wjh20t-MuiPagination-ul": {
                  justifyContent: "flex-end",
                },
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default withRouter(BuyerPage);

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  paper: {
    color: "black",
    padding: 10,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  menuLink: {
    textDecoration: "none",
    color: "#472264",
    marginBottom: 5,
    boxShadow: "none",
  },
  box: {
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  select: {
    width: "40%",
    backgroundColor: "transparent",
    margin: "10px 20px 10px 10px",
    paddingRight: "20px",
  },
  select1: {
    margin: "10px 20px 5px 20px",
    paddingRight: "10px",
  },
  search: {
    width: "200px",
    margin: 0,
    display: "flex",
    alignItems: "space-between",
    marginLeft: "10px",
  },
  showselect: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageselect: {
    backgroundColor: "white",
    borderRadius: "5px",
    height: "33px",
    width: "150px",
    display: "flex",
    justifyContent: "space-between",
  },
  paragragh: {
    padding: 0,
    marginTop: 4,
    marginLeft: 20,
    fontWeight: "bold",
    // width:"50%"
  },
});

const styles = {
  bulk: {
    display: "flex",
    alignItems: "stretch",
    marginBottom:1
  },
  formControl: {
    width: {
      xs: 150,
      md: 200,
    },
    ".MuiInputBase-root": {
      lineHeight: "1.7em",
    },
    ".MuiOutlinedInput-input": {
      paddingLeft: "10px",
    },
  },
  select: {
    background: "#fff",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  textField: {
    background: "#fff",
    width: {
      xs: 150,
      md: 200,
    },
    "& fieldset": {
      borderLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    ".MuiOutlinedInput-input": {
      height: "1.7em",
      paddingLeft: "10px",
    },
  },
  btn: {
    background: "#693a8e",
    borderRadius: "4px",
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "100%",
    padding: "2px 10px",
    "&:hover": { background: "#693a8e" },
  },
  tabBtn: { borderRadius: 0, color: "#693a8e" },
};
