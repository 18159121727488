import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

interface Category {
  id: string;
  attributes: {
    name: string;
    slug: string;
    sub_categories: SubCategory[];
  };
  isOpen: boolean;
}

interface SubCategory {
  id: number;
  slug: string;
  name: string;
}

function BuyerMenuDrawer({
  openDrawer,
  setOpenDrawer,
  toggleDrawer,
  history,
}: any) {
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const { categories } = useSelector((state: any) => state.landingPage);

  // Open-close submenu or route to category page
  const handleCategoryClick = (category: Category) => {
    if (category.attributes.sub_categories.length === 0) {
      history.push(`/catalogue/${category.attributes.slug}`);
      setOpenDrawer(false);
    } else {
      setCategoryList(
        categoryList.map((cat: Category) =>
          cat.id === category.id ? { ...cat, isOpen: !cat.isOpen } : cat
        )
      );
    }
  };

  const handleSubCategoryClick = (catSlug: string, subCatSlug: string) => {
    history.push(`/catalogue/${catSlug}/${subCatSlug}`);
    setOpenDrawer(false);
  };

  /**
   * Add a property to each category to keep track if
   * it's submenu has opened or not
   */
  useEffect(() => {
    setCategoryList(
      categories.map((cat: any) => ({
        ...cat,
        isOpen: false,
      }))
    );
  }, [categories]);

  return (
    <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
      >
        <List sx={{background: "rgb(214, 178, 54)", height: "100vh"}}>
          {categoryList.map((category: Category) => (
            <div key={category.id}>
              <ListItem onClick={() => handleCategoryClick(category)}>
                <ListItemText primary={category.attributes.name} />
                {category.attributes.sub_categories.length > 0 ? (
                  category.isOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItem>
              <Collapse in={category.isOpen} timeout="auto" unmountOnExit  >
                <List component="div" disablePadding>
                  {category.attributes.sub_categories.map((subCat) => (
                    <ListItem
                      sx={{ pl: 4 }}
                      key={subCat.id}
                      onClick={() =>
                        handleSubCategoryClick(
                          category.attributes.slug,
                          subCat.slug
                        )
                      }
                    >
                      <ListItemText primary={subCat.name} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              <span />
            </div>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}

export default withRouter(BuyerMenuDrawer);
