import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { style } from "./Style";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { withRouter } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import "./style.css";
import { SideImage, logo, cross } from "./assets";
import LandingPage from "../../landingpage/src/LandingPage.web";
import ForgotPasswordFrom from "./ForgotPasswordFrom";
const Forgotpass = ({ history }) => {

  const [openLoginModel, setOpenLoginModel] = useState(true);
  const [formType, setFormType] = useState("login");
  const handleClose = () => {
    setOpenLoginModel(false);
    setFormType("login");
    history.push("/");
  };
  // useEffect(() => {
  //   if (token) {
  //     setFormType("resetPass");
  //   }
  // }, [token]);

  return (
    <div>
      <Modal
        open={openLoginModel}
        aria-labelledby="Login Model"
        aria-describedby="Login Model"
      >
        <Grid className="loginModalMain" sx={style} container>
          <Grid
            item
            xs={0}
            sm={6}
            md={6}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Box
              className="loginSideLogo"
              sx={{
                backgroundImage: `url(${SideImage})`,
                backgroundPosition: "right",
                width: "100%"
              }}
            >
              <Box
                sx={{
                  padding: "10px",
                  height: "98%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between"
                }}
              >
                <img
                  src={logo}
                  width="100px"
                  alt="Brand Logo"
                  style={{ padding: "12px 0 0 15px" }}
                />
                <Typography
                  variant="subtitle2"
                  component="span"
                  sx={{ color: "white" }}
                  style={{ padding: "0 0 15px 15px" }}
                >
                  GenBov @ 2021. All Rights Reserved.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={0} sm={6} md={6} sx={{ width: "100%" }}>
            <Grid container direction="column" alignContent="flex-end">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ position: "absolute", right: "10px", top: "5px" }}
              >
                <img
                  src={cross}
                  width="15px"
                  alt="close Button"
                  sx={{ padding: "10px" }}
                />
              </IconButton>
            </Grid>
              <ForgotPasswordFrom setFormType={setFormType} handleClose={handleClose} />
          </Grid>
        </Grid>
      </Modal>
        <LandingPage/>
    </div>
  );
};

export default withRouter(Forgotpass);
