import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell from '@mui/material/TableCell';
import InputBase from "@material-ui/core/InputBase";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import LoaderPage from "../../../components/src/LoaderPage";
import NoRecordPage from "../../../components/src/NoRecordPage";
import {
  eyeImage,
  getOrderList,
  getOrderListBySearch,
  searchImage,
  setCurrentPageOrders,
  setSearchKeyOrders,
  setSearchTextOrders,
  setShowCountOrders,
  updateOrderStatus,
  whiteArrowImage,
  setOrderFilter
} from "./assets";

const MenuProps = {
  PaperProps: {
    style: {
      //   maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const orderStatus = [
  {
    name: "Pending",
    key: "placed",
  },
  {
    name: "Payment Proceed",
    key: "payment_proceed",
  },
  {
    name: "Processing",
    key: "processing",
  },
  {
    name: "Shipping",
    key: "shipping",
  },
  {
    name: "Complete",
    key: "complete",
  },
  {
    name: "Out Of Stock",
    key: "out_of_stock",
  },
  {
    name: "Payment Declined",
    key: "payment_declined",
  },
  {
    name: "Address Invalid",
    key: "address_invalid",
  },
];

const orderStatusFilter = [
  {
    name: "Pending",
    key: "pending",
  },
  {
    name: "Payment Proceed",
    key: "payment_proceed",
  },
  {
    name: "Processing",
    key: "processing",
  },
  {
    name: "Shipping",
    key: "shipping",
  },
  {
    name: "Complete",
    key: "complete",
  },
  {
    name: "Out Of Stock",
    key: "out_of_stock",
  },
  {
    name: "Payment Declined",
    key: "payment_declined",
  },
  {
    name: "Address Invalid",
    key: "address_invalid",
  },
];

// @ts-ignore
const OrderCatalogue = (props) => {
  // @ts-ignore
  const {
    orders,
    pagination,
    isLoading,
    currentPage,
    showCount,
    searchKey,
    searchText,
    filter,
    firmName,
  } = useSelector((state: any) => state.orders);
  const { id } = props.match.params;
  // @ts-ignore
  const profile = JSON.parse(localStorage.getItem("profile"));
  const dispatch = useDispatch();
  const classes = useStyles();
  // @ts-ignore
  const [currentRole, setCurrentRole] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [searchPhrase, setSearchPhrase] = useState("");
  const [initiatedSearch, setInitiatedSearch] = useState(false);

  const handleChange = () => {
    dispatch(getOrderList(showCount, currentPage, id,filter));
  };

  // @ts-ignore
  const managePagination = (event, value) => {
    dispatch(setCurrentPageOrders(value, showCount, id,filter));
  };

  // @ts-ignore
  const handleStatusUpdate = (value, id) => {
    if(value !== "placed"){
      dispatch(updateOrderStatus(id, value));
    }
  };

  // Search
  const handleSearch = () => {
    if (searchKey && searchText) {
      setInitiatedSearch(true);
      dispatch(
        getOrderListBySearch(showCount, 1, searchKey, searchText, id,filter)
      );
    }
  };

  // Clear search
  const clearSearch = () => {
    setInitiatedSearch(false);
    setSearchBy("");
    setSearchPhrase("");
    dispatch(getOrderList(showCount, 1, id));
  };

  // Set search by
  useEffect(() => {
    dispatch(setSearchKeyOrders(searchBy));
  }, [searchBy]);

  // Set search phrase
  useEffect(() => {
    dispatch(setSearchTextOrders(searchPhrase));
  }, [searchPhrase]);

  // Load data
  useEffect(() => {
    dispatch(getOrderList(showCount, currentPage, id,filter));
    setCurrentRole(profile.user_role);
  }, []);

  return (
    <Fragment>
      <Box>
        <Grid container spacing={3}>
          {id ? (
            <Box sx={{ marginLeft: "22px",marginTop:"10px" }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={styles.firmName}
                component="div"
              >
                {firmName || ""}{" "}
                {firmName && <span className={classes.count}>
                  {pagination.total_count || 0}
                </span>}
              </Typography>
              <Typography variant="subtitle2" display="block" gutterBottom>
                Vendors{" "}
                <span style={{ color: "#824bad", fontWeight: "normal" }}>
                  / Orders
                </span>
              </Typography>
            </Box>
          ) : null}
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex",marginBottom:"10px" }}>
                  <FormControl sx={{ width: "250px", background: "white"}}>
                    <Select
                        size="medium"
                        id="demo-simple-select"
                        value={filter}
                        placeholder="Select Filter"
                        style={{ height: "40px", paddingLeft: "10px" }}
                        onChange={(e) => dispatch(setOrderFilter(e.target.value))}
                    >
                      <MenuItem className={classes.menu} value={"all"}>
                        All
                      </MenuItem>
                      {
                        // @ts-ignore
                        orderStatusFilter.map((item) => (
                            <MenuItem
                                className={classes.menu}
                                key={item.key}
                                value={item.key}
                            >
                              <ListItemText primary={item.name} />
                            </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                  <Button
                      variant="contained"
                      size="small"
                      // @ts-ignore
                      sx={{ ...styles.btn, width: "100px", marginLeft: 1 }}
                      onClick={handleChange}
                  >
                    Filter
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display:"flex", justifyContent: {xs:"flex-start",sm:"flex-end"}}}>
                <Box className={classes.box} sx={{marginBottom: "10px"}}>
                  <Stack direction="row">
                    <FormControl size="small" sx={styles.formControl}>
                      <InputLabel id="demo-simple-select-label">Select</InputLabel>
                      <Select
                          labelId="select-label-id"
                          id="select-id"
                          value={searchBy}
                          label="Select"
                          onChange={(e) => {
                            setSearchBy(e.target.value);
                          }}
                          sx={styles.select}
                      >
                        <MenuItem value="order_id">Order Id</MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                        <MenuItem value="buyer_email">Buyer E-Mail Id</MenuItem>
                        <MenuItem value="buyer_name">Buyer Name</MenuItem>
                        {currentRole !== "vendor" && (
                            <MenuItem value="vendor_email">Vendor E-Mail Id</MenuItem>
                        )}
                        {currentRole !== "vendor" && (
                            <MenuItem value="vendor_name">Vendor Name</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <TextField
                        id="outlined-basic"
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={searchPhrase}
                        onChange={(e) => {
                          setSearchPhrase(e.target.value);
                        }}
                        sx={styles.textField}
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                {initiatedSearch && (
                                    <IconButton onClick={clearSearch}>
                                      <CloseIcon />
                                    </IconButton>
                                )}
                                <IconButton onClick={handleSearch}>
                                  <img src={searchImage} alt="search" />
                                </IconButton>
                              </InputAdornment>
                          ),
                        }}
                    />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            {/* search */}
          </Grid>
          {isLoading ? (
            <LoaderPage />
          ) : orders.length > 0 ? (
            <Grid item xs={12}>
              <TableContainer
                sx={{
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                  width:{
                    md:"100%",
                    sm:"700px",
                    xs:"350px"
                  }
              }}
              >
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>#Order Id</StyledTableCell>
                      <StyledTableCell align="right">Date/Time</StyledTableCell>
                      <StyledTableCell align="right">Buyer</StyledTableCell>
                      {currentRole !== "vendor" ? (
                        <StyledTableCell align="right">Vendor</StyledTableCell>
                      ) : null}
                      <StyledTableCell align="right">Amount</StyledTableCell>
                      <StyledTableCell align="right">
                        {currentRole !== "vendor" ? "Status" : " Change Status"}
                      </StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      // @ts-ignore
                      orders.map((row, key) => (
                        <StyledTableRow key={key}>
                          <StyledTableCell component="th" scope="row">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "start",
                              }}
                            >
                              {row.attributes.order_number}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: "start",
                              }}
                            >
                              <div>
                                {moment(row.attributes.order_date).format(
                                  " DD/MM/YY"
                                )}
                              </div>
                              <div style={{ color: "#824bad" }}>
                                {moment(row.attributes.order_date).format(
                                  "h:mm a"
                                )}
                              </div>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: "start",
                              }}
                            >
                              <div>{row.attributes.buyer.name}</div>
                              <div style={{ color: "#824bad" }}>
                                {row.attributes.buyer.email}
                              </div>
                            </div>
                          </StyledTableCell>
                          {currentRole !== "vendor" ? (
                            <StyledTableCell align="center">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "start",
                                  justifyContent: "start",
                                }}
                              >
                                <div>{row?.attributes?.vendor?.name}</div>
                                <div style={{ color: "#824bad" }}>
                                  {row?.attributes?.vendor?.email}
                                </div>
                              </div>
                            </StyledTableCell>
                          ) : null}

                          <StyledTableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "start",
                              }}
                            >
                              {"$" + row?.attributes?.total}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <FormControl
                              className={classes.status}
                              style={{
                                // @ts-ignore
                                backgroundColor:
                                  (row?.attributes?.status === "complete" &&
                                    "#09B345") ||
                                  (row?.attributes?.status === "pending" &&
                                    "#D6B336") ||
                                  (row?.attributes?.status === "placed" &&
                                    "#D6B336") ||
                                  (row?.attributes?.status ===
                                    "payment_proceed" &&
                                    "#FF8502") ||
                                  (row?.attributes?.status === "processing" &&
                                    "#925BBD") ||
                                  (row?.attributes?.status === "shipping" &&
                                    "#693A8E") ||
                                  (row?.attributes?.status === "out_of_stock" &&
                                    "#A4A4A4") ||
                                  (row?.attributes?.status ===
                                    "payment_declined" &&
                                    "#E13535") ||
                                  (row?.attributes?.status ===
                                    "address_invalid" &&
                                    "#606060"),
                                  width:"100%"
                              }}
                            >
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                displayEmpty
                                disabled={
                                  currentRole === "vendor" ? false : true
                                }
                                IconComponent={
                                  currentRole === "vendor"
                                    ? (_props) => (
                                        <div style={{ position: "relative" }}>
                                          <img
                                            src={whiteArrowImage}
                                            width="15px"
                                            height="8px"
                                          />
                                        </div>
                                      )
                                    : (_props) => (
                                        <div
                                          style={{ position: "relative" }}
                                        ></div>
                                      )
                                }
                                value={row.attributes.status}
                                onChange={(e) => {
                                  // @ts-ignore
                                  handleStatusUpdate(e.target.value, row.id);
                                }}
                                input={
                                  <InputBase
                                    style={{
                                      color: "white",
                                      fontSize: 12,
                                      textAlign: "start",
                                      width:"100%"
                                    }}
                                  />
                                }
                                MenuProps={MenuProps}
                              >
                                {
                                  // @ts-ignore
                                  orderStatus.map((item) => (
                                    <MenuItem
                                      className={classes.menu}
                                      key={item.key}
                                      value={item.key}
                                    >
                                      <ListItemText primary={item.name} />
                                    </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "start",
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  props.history.push(
                                    `orderDetailScreen/${row?.id}`
                                  )
                                }
                              >
                                <img
                                  src={eyeImage}
                                  width="20px"
                                  height="15px"
                                />
                              </IconButton>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <NoRecordPage text={"Sorry, no orders found!"} />
          )}
          {
            orders.length > 0 &&
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                        size="small"
                        sx={{ width: "150px", ...styles.pagination}}
                    >
                      <InputLabel id="bulk-actions">Show</InputLabel>
                      <Select
                          labelId="show-count"
                          id="demo-simple-select"
                          value={showCount}
                          label="Show:"
                          onChange={(e) =>
                              dispatch(setShowCountOrders(1, e.target.value, id))
                          }
                          sx={{ background: "#fff" }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{display:'flex',justifyContent: {xs:"flex-start",sm:"flex-end"}}}>
                    <Stack spacing={5}>
                      <Pagination
                          count={pagination.total_pages}
                          hidePrevButton
                          page={currentPage}
                          color="secondary"
                          variant="outlined"
                          shape="rounded"
                          onChange={managePagination}
                          sx={{
                            "& .Mui-selected": {
                              color: "#fff !important",
                            },
                            "& .MuiPaginationItem-root:hover": {
                              backgroundColor: "#693A8E",
                            },
                            ".css-wjh20t-MuiPagination-ul": {
                              justifyContent: "flex-end",
                            },
                          }}
                      />
                    </Stack>
                  </Grid>
                </>
          }
        </Grid>
      </Box>
    </Fragment>
  );
};
// @ts-ignore
export default withRouter(OrderCatalogue);

const useStyles = makeStyles({
  count: {
    background: "#fff",
    color: "#000",
    width: "30px",
    borderRadius: "8px",
    fontSize: "10px",
    display: "inline-block",
    textAlign: "center",
    padding: "3px",
  },
  table: {
    width: "100%",
  },
  paper: {
    color: "black",
    padding: 10,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  menuLink: {
    textDecoration: "none",
    color: "#472264",
    marginBottom: 5,
  },
  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    display: "flex",
  },
  select: {
    width: "40%",
    backgroundColor: "transparent",
    margin: "5px 20px 5px 20px",
    borderRight: "1px solid #737373",
    paddingRight: "10px",
  },
  search: {
    width: "60%",
    margin: 5,
    display: "flex",
    alignItems: "space-between",
  },
  showselect: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageselect: {
    backgroundColor: "white",
    borderRadius: "5px",
    height: "33px",
    width: "150px",
    display: "flex",
    alignItems: "space-between",
  },
  paragragh: {
    padding: 0,
    marginTop: 4,
    marginLeft: 20,
    fontWeight: "bold",
  },
  status: {
    width: "75%",
    margin: "0px 0px",
    borderRadius: "10px",
    height: "35px",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    padding: "0 15px",
  },
  menu: {
    "&:nth-of-type(even)": {
      backgroundColor: "#f5ebfc",
    },
    height: "40px",
    display: "flex",
    padding: "0px 20px",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#472264",
      color: theme.palette.common.white,
      textAlign: "start",
      justifyContent: "start",
    },
    body: {
      fontSize: 13,
      color: "#472264",
      fontWeight: "bold",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f5ebfc",
      },
    },
  })
)(TableRow);

const styles = {
  firmName: {
    color: "#472264",
    fontWeight: "bold",
    marginLeft: 0,
    padding: 0,
  },
  formControl: {
    width: {
      xs: 150,
      md: 200,
    },
    ".MuiInputBase-root": {
      lineHeight: "1.7em",
    },
    ".MuiOutlinedInput-root": {
      padding: "0 10px",
    },
  },
  select: {
    background: "#fff",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  textField: {
    background: "#fff",
    height: "1.45em !important",
    width: {
      xs: 150,
      md: 200,
    },
    "& fieldset": {
      borderLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    ".MuiOutlinedInput-input": {
      height: "1.657em",
      paddingLeft: "10px",
    },
  },
  btn: {
    background: "#693a8e",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "100%",
    padding: "2px 10px",
    "&:hover": { background: "#693a8e" },
  },
  tabBtn: { borderRadius: 0, color: "#693a8e" },
  status: {
    width: "75%",
    margin: "0px 0px",
    borderRadius: "10px",
    height: "35px",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    padding: "0 15px",
  },
  pagination: {
    ".MuiInputBase-root": {
      lineHeight: "1.7em",
    },
    ".MuiOutlinedInput-root": {
      padding: "0 10px",
    },
  },
};
