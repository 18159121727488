import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
  baseURL: baseURL,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("authToken")) {
    req.headers.token = `${localStorage.getItem("authToken")}`;
  }
  return req;
});

export const saveShippingAdderess = async (data) => {
  try {
    return await API.post(
      `/bx_block_customisableuserprofiles/shipping_addresses`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    return e.response;
  }
};

export const updateShippingAdderess = async (data, id) => {
  try {
    return await API.put(
      `/bx_block_customisableuserprofiles/shipping_addresses/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    return e.response;
  }
};

export const getCountries = async () => {
  try {
    return await API.get(`/account_block/accounts/country`);
  } catch (e) {
    return e.response;
  }
};

export const getStates = async (country) => {
  try {
    return await API.get(`/account_block/accounts/states/${country}`);
  } catch (e) {
    return e.response;
  }
};

export const getShippingAddress = async () => {
  try {
    return await API.get(
      `/bx_block_customisableuserprofiles/shipping_addresses`
    );
  } catch (e) {
    return e.response;
  }
};

export const placeOrder = async (id, method) => {
  try {
    return await API.put(`/bx_block_order_management/orders/${id}`, {
      payment_method: method,
    });
  } catch (e) {
    return e.response;
  }
};

export const getOrderList = async (id) => {
  try {
    return await API.get(`/bx_block_order_management/orders?id=${id}`);
  } catch (e) {
    return e.response;
  }
};
