import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";

const MAX_IMAGE_SIZE = 2097152;

export default function MyDropzone({
  setFile,
  hasRemovedFile,
  setHasRemovedFile,
  backgroundImgName
}) {
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFileName(acceptedFiles[0]?.name);
      acceptedFiles[0] && setFile(acceptedFiles[0]);
    },
    [setFile]
  );

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: MAX_IMAGE_SIZE,
    accept: "image/*",
  });

  // Handle errors
  useEffect(() => {
    fileRejections.length > 0 &&
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((e) => {
          if (e.code === "file-too-large") {
            toast.error("Images must be less than 2 MB");
          } else if (e.code === "file-invalid-type") {
            toast.error("The file type must be an image");
          } else {
            toast.error(e.message);
          }
        });
      });
  }, [fileRejections]);

  /**
   * Remove the file name when file has been removed
   * from the parent component
   */
  useEffect(() => {
    if (hasRemovedFile) {
      setFileName("");
    }
  }, [hasRemovedFile]);

  // Reset the file remove status
  useEffect(() => {
    if (!fileName) {
      setHasRemovedFile(false);
    }
  }, [fileName]);

  return (
    <div {...getRootProps()} style={styles.fullWidth}>
      <input {...getInputProps()} accept="image/*" />
      {isDragActive ? (
        <Box sx={styles.flexCol}>
          <UploadFileIcon fontSize="large" color="secondary" />
          <Typography sx={{ color: "purple" }}>Drop files here</Typography>
        </Box>
      ) : (
        <div style={styles.flexCol}>
          {fileName ? (
            <CheckCircleIcon fontSize="large" color="secondary" />
          ) : (
            <UploadFileIcon fontSize="large" color="secondary" />
          )}
          <Typography variant="subtitle1" color="purple">
            {fileName ? `${fileName}` : "Upload"}
          </Typography>
        </div>
      )}
      {
        backgroundImgName && !fileName &&
        <Typography variant="subtitle1" color="purple">
          {backgroundImgName}
        </Typography>
      }
    </div>
  );
}

const styles = {
  fullWidth: {
    height: "200px",
    width: "100%",
    border: "2px dashed orange",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "15px 0",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
