import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, CircularProgress } from "@mui/material";

import { defaultImage } from "./assets";
import { API } from "./BuyerProfile";
import { OrderItem, viewOrderStatusCircle } from "./Order";

interface OrderDetails {
  shipping_address: {
    attributes: {
      name: string;
      address: {
        address_line1: string;
        address_line2: string;
        state: string;
        zipcode: string;
        country: string;
      };
    };
  };
  payment_method: string;
  total: string;
  order_items: OrderItem[];
  status: string;
  order_status: string;
}

// @ts-ignore
export default function OrderDetails(props) {
  const [isLoading, setIsLoading] = useState(true);
  // @ts-ignore
  const [details, setDetails] = useState<OrderDetails>({});
  const token = localStorage.getItem("authToken") || "";

  // Load data
  useEffect(() => {
    API.get(`/bx_block_order_management/orders/${props.orderId}`, {
      headers: { token },
    })
      .then((response) => {
        setIsLoading(false);
        setDetails(response.data.data.attributes);
      })
      .catch();
  }, [props.orderId]);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid
        container
        xs={12}
        spacing={1}
        // @ts-ignore
        sx={styles.gridContainer}
      >
        <Grid xs={12} lg={4} item>
          <Typography sx={styles.summaryHeader}>Shipping Address</Typography>
          <Typography sx={styles.boldText}>
            {details.shipping_address?.attributes.name}
          </Typography>
          <Typography sx={styles.boldText}>
            {details.shipping_address?.attributes.address.address_line1}
          </Typography>
          <Typography sx={styles.boldText}>
            {details.shipping_address?.attributes.address.address_line2}
          </Typography>
          <Typography sx={styles.boldText}>
            {details.shipping_address?.attributes.address.state} -{" "}
            {details.shipping_address?.attributes.address.zipcode}
          </Typography>
          <Typography sx={styles.boldText}>
            {details.shipping_address?.attributes.address.country}
          </Typography>
        </Grid>
        <Grid xs={12} lg={3} item>
          <Typography sx={styles.summaryHeader}>Payment Method</Typography>
          <Typography sx={styles.boldText}>{details.payment_method}</Typography>
        </Grid>
        <Grid xs={12} lg={5} item>
          <Typography sx={styles.summaryHeader}>Order Summary</Typography>
          <Grid sx={{ ...styles.summaryDetails, justifyContent: "flex-start" }}>
            <Typography>TOTAL AMOUNT:</Typography>
            <Typography sx={{ marginLeft: 1 }}>${details.total}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={styles.orderContianer}>
        {/* Order header */}
        <Grid container sx={styles.ordersHeader}>
          <Typography sx={styles.ordersCount}>
            {details.order_items?.length} Orders
          </Typography>
        </Grid>
        {/* Order items */}
        {details.order_items &&
          details.order_items.map((item) => (
            <Grid
              container
              sx={styles.itemsContainer}
              key={item.id}
            >
              <Grid
                item
                sx={styles.imgContainer}
              >
                <img
                  src={
                    item?.attributes?.catalogue_image
                      ? item.attributes.catalogue_image.url
                      : defaultImage
                  }
                  alt={item?.attributes?.catalogue_name}
                  style={styles.img}
                />
              </Grid>
              <Grid item sx={{ marginLeft: "20px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {item.attributes.catalogue_name}
                </Typography>
                <Typography>QTY: {item.attributes.quantity}</Typography>
              </Grid>
            </Grid>
          ))}
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {viewOrderStatusCircle(details.status)}
          <Typography>{details.order_status?.toUpperCase()}</Typography>
        </Grid>
      </Box>
    </>
  );
}

const styles = {
  gridContainer: {
    background: "#FFF",
    padding: "15px 20px",
    borderRadius: "5px",
    marginBottom: "15px",
    marginLeft: 0,
    marginTop: 0,
    display: "flex",
    justifyConent: "space-between",
  },
  summaryHeader: {
    color: "#0d0d0d",
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "15px",
  },
  summaryDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  boldText: {
    color: "#0d0d0d",
    fontSize: "18px",
    fontWeight: "500",
  },
  orderContianer: {
    background: "#FFF",
    padding: "15px 20px",
    borderRadius: "5px",
    marginBottom: "15px",
  },
  ordersHeader: {
    background: "#693A8E",
    padding: "5px 20px",
    borderRadius: "5px",
  },
  ordersCount: { color: "#FFF", fontWeight: "bold", padding: "10px 0" },
  itemsContainer: { margin: "20px 0", padding: "0 15px" },
  imgContainer: {
    width: "70px",
    height: "80px",
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    objectFit: "cover"
  } as const
};
