import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {ColorButton, registerStpes, registerStpesLine} from "./Style";
import {getCountries, updateBankDetails} from "./api";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import {useForm,Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const initialState = {
    full_name: "",
    bank_name: "",
    bank_country: "Argentina",
    account_number: "",
    account_number_confirmation: "",
    routing_number: '',
    swift_key: ""
}
const RegisterForm = ({setOpenRegisterModel,setOpenSuccessModel}) => {
    const firmDetails = yup.object().shape({
        "full_name":yup.string().trim().required("Please enter full name"),
        "bank_name":yup.string().trim().required("Please enter bank name"),
        "account_number":yup.string().trim().required("Please enter account number").min(8,"Please enter valid account number").max(34,"Please enter valid account number"),
        "account_number_confirmation":yup.string().required("Please enter confirm account number").oneOf([yup.ref("account_number"),null],"Confirm account number and account number does not match"),
        "routing_number":yup.string().required("Please enter routing number"),
        "swift_key":yup.string().required("Please Enter SWIFT key").min(6,"Please enter Valid SWIFT key"),
    })
    const { register, handleSubmit, watch, formState: { errors },control:bankDetailsController } = useForm({
        resolver: yupResolver(firmDetails),
        defaultValues:initialState
    });

    const [values, setValues] = React.useState(initialState);
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [country, setCountry] = useState([]);
    const [countryValue, setCountryValue] = useState("AR");
    const token = window.localStorage.getItem('authToken')
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    useEffect(() => {
        countryList();
    }, []);

    const handleCountry = e => {
        setCountryValue(e.target.value);
        let { data } = "";
        country.filter(x => {
            if (x.code == e.target.value) {
                data = x;
            }
        });
        setValues({ ...values, bank_country: data.name });
    };

    const countryList = async () => {
        const ctry = await getCountries();
        setCountry(ctry?.data);
    };

    const handleSubmit1 = async (formData) => {
        setIsLoading(true)
        if(formData.full_name && formData.bank_name && values.bank_country && formData.account_number && formData.account_number_confirmation && formData.routing_number && formData.swift_key){
            try {
                const {data} = await updateBankDetails({...formData,bank_country:values.bank_country},token)
                if(data.errors){
                    setError(data.errors)
                }else{
                    setOpenRegisterModel(false)
                    setOpenSuccessModel(true)
                }
            }catch (e) {
                setError([
                    {
                        message:'Something Went Wrong'
                    }
                ])
            }
        }else{
            setError([
                {
                    message:'Please Check and Fill Blank fields'
                }
            ])
        }
        setIsLoading(false)
    }

    return (
        <Grid item xs={12} className='registerMainDiv' style={{padding:'50px 0'}}>
            <Box className='registerFormFlex'>
                <Typography variant="h6" component="div" color="purple" sx={{fontWeight:"bold"}}>
                    Vendor Registration in only 3 Steps
                </Typography>
                <Typography variant="subtitle1" component="div" color="purple">
                    provide your firm bank details
                </Typography>
                <Box sx={{display: 'flex',flexDirection:'row',alignItems:'center',margin:'10px 0px'}}>
                    <Box sx={registerStpes} style={{backgroundColor:'purple'}}>

                    </Box>
                    <Box sx={registerStpesLine} style={{backgroundColor:'purple'}}>

                    </Box>
                    <Box sx={registerStpes} style={{backgroundColor:'purple'}}>

                    </Box>
                    <Box sx={registerStpesLine} style={{backgroundColor:'purple'}}>

                    </Box>
                    <Box sx={registerStpes} style={{backgroundColor:'purple'}}>

                    </Box>
                </Box>
                <Grid item xs={12} sx={{marginTop:"5px"}}>
                    {
                        error ?
                            error.map((e,index)=>{
                                return(
                                    <Alert key={index} severity="error" sx={{width:'82%',alignSelf:'center'}}>
                                        {e.message}
                                    </Alert>
                                )
                            })
                            : null
                    }
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold", marginBottom:'1'}}>
                            Full Name
                        </Typography>
                        <Controller
                            name="full_name"
                            control={bankDetailsController}
                            render={({ field }) => (
                                <TextField error={errors["full_name"]} {...field} block placeholder="Enter full name" size='small' sx={{width:"90%"}} />
                            )} />
                        {errors["full_name"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["full_name"]?.message}
                            </Typography> : null
                        }
                    </Box>
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold", marginBottom:'1'}}>
                            Bank Name
                        </Typography>
                        <Controller
                            name="bank_name"
                            control={bankDetailsController}
                            render={({ field }) => (
                                <TextField error={errors["bank_name"]} {...field} block placeholder="Enter bank name" size='small' sx={{width:"90%"}} />
                            )} />
                        {errors["bank_name"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["bank_name"]?.message}
                            </Typography> : null
                        }
                    </Box>
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold", marginBottom:'1'}}>
                            Bank Country
                        </Typography>
                        <FormControl sx={{ width: "90%" }}>
                            <Select
                                size="small"
                                value={countryValue}
                                onChange={e => handleCountry(e)}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                {country?.map((c, index) => {
                                    if (index <= 50) {
                                        return <MenuItem value={c.code} key={index}>{c.name}</MenuItem>;
                                    } else {
                                        return null;
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold", marginBottom:'1'}}>
                            Account Number
                        </Typography>
                        <Controller
                            name="account_number"
                            control={bankDetailsController}
                            render={({ field }) => (
                                <TextField error={errors["account_number"]} {...field} block placeholder="Enter account number" size='small' sx={{width:"90%"}} />
                            )} />
                        {errors["account_number"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["account_number"]?.message}
                            </Typography> : null
                        }
                    </Box>
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold", marginBottom:'1'}}>
                            Confirm Account Number
                        </Typography>
                        <Controller
                            name="account_number_confirmation"
                            control={bankDetailsController}
                            render={({ field }) => (
                                <TextField error={errors["account_number_confirmation"]} {...field} block placeholder="Enter account number" size='small' sx={{width:"90%"}}  />
                            )} />
                        {errors["account_number_confirmation"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["account_number_confirmation"]?.message}
                            </Typography> : null
                        }
                    </Box>
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold", marginBottom:'1'}}>
                            Routing Number
                        </Typography>
                        <Controller
                            name="routing_number"
                            control={bankDetailsController}
                            render={({ field }) => (
                                <TextField type="number" error={errors["routing_number"]} {...field} block placeholder="Enter routing number" size='small' sx={{width:"90%"}}/>
                            )} />
                        {errors["routing_number"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["routing_number"]?.message}
                            </Typography> : null
                        }
                    </Box>
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold", marginBottom:'1'}}>
                            SWIFT Key
                        </Typography>
                        <Controller
                            name="swift_key"
                            control={bankDetailsController}
                            render={({ field }) => (
                                <TextField error={errors["swift_key"]} {...field}  block placeholder="Enter SWIFT Key" size='small' sx={{width:"90%"}} />
                            )} />
                        {errors["swift_key"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["swift_key"]?.message}
                            </Typography> : null
                        }
                    </Box>
                    <Box>
                        <ColorButton variant="contained" color="secondary" sx={{width:"90%"}} onClick={handleSubmit(handleSubmit1)}>{isLoading ? <CircularProgress color="inherit" /> : "Submit"}</ColorButton>
                    </Box>
                </Grid>
            </Box>
        </Grid>
    );
};

export default RegisterForm;
