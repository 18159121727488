import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
  baseURL: baseURL,
  timeout: 5000
});

export const getCatalogeList = async () => {
  try {
    return await API.get(`/bx_block_catalogue/catalogues`);
  } catch (e) {
    return e.response;
  }
};
