import {
    notification,
    unreadNotification,
    updateUnreadNotification
} from "./reduxNotificationApi";

// get all notifications
export const getNotifications = () => async dispatch => {
    dispatch({type:'SET_LOADING_NOTIFICATION_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        try {
            const {data} = await notification()
            if(data.errors){
            }else{
                dispatch({type:'SET_NOTIFICATION_LIST', payload: data})
            }
        }catch (e) {

        }
    }
    dispatch({type:'SET_LOADING_NOTIFICATION_FALSE'})
}

// get unread notifications
export const getUnreadNotifications = () => async dispatch => {
    dispatch({type:'SET_LOADING_NOTIFICATION_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        try {
            const {data} = await unreadNotification()
            if(data.data){
                dispatch({type:'SET_UNREAD_NOTIFICATIONS', payload: data.data})
            }
        }catch (e) {
            
        }
        
    }
    dispatch({type:'SET_LOADING_NOTIFICATION_FALSE'})
}

// set unread notifications
export const setUnreadNotifications = (notifications) => async dispatch => {
    dispatch({type:'SET_LOADING_NOTIFICATION_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        if(notifications.length > 0){
            try {
                const {data} = await updateUnreadNotification(notifications)
            }catch (e) {

            }
        }
    }
    dispatch({type:'SET_LOADING_NOTIFICATION_FALSE'})
}

// set unread notifications count to 0
export const setUnreadCountZero = () => async dispatch => {
    dispatch({type:'SET_UNREAD_COUNT_ZERO'})
}

// set notification object state to blank
export const setNotificationBlank = () => async dispatch => {
    dispatch({type:'SET_NOTIFICATIONS_BLANK'})
}

