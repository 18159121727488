import React from "react";
import { toast } from "react-toastify";
import {
  buyerList,
  bulkRemoveBuyer,
  bulkActiveBuyer,
  bulkDeactivateBuyer,
  activeBuyer,
  deactivateBuyer,
  buyersSearch,
  getBuyersDetailsAdmin,
  getBuyerListVendor,
  getBuyerDetailsVendor,
  getBuyerListVendorSearch,
} from "./reduxBuyerApi";
import store from "../store";

// get Buyer List from API and store in redux
export const getBuyerList = (count, page) => async (dispatch) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    const { data } = await buyerList(count, page);

    if (data.errors) {
      data.errors.map((e) => {
        toast.error(e.message);
      });
    } else {
      dispatch({ type: "SET_BUYER_LIST", payload: data.data });
      dispatch({
        type: "SET_BUYER_PAGINATION",
        payload: data?.meta.pagination,
      });
    }
  }
  dispatch({ type: "SET_LOADING_FALSE" });
};

// get Buyer List from API and store in redux without Loader Flag
export const getBuyerListWithoutLoader = (count, page) => async (dispatch) => {
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    const { data } = await buyerList(count, page);
    if (data.errors) {
      data.errors.map((e) => {
        toast.error(e.message);
      });
    } else {
      dispatch({ type: "SET_BUYER_LIST", payload: data.data });
      dispatch({
        type: "SET_BUYER_PAGINATION",
        payload: data?.meta.pagination,
      });
    }
  }
};

// Select All buyers from redux state
export const selectAllBuyer = () => async (dispatch) => {
  dispatch({ type: "SELECT_ALL" });
};
// Manage Checkbox of buyer list from redux state
export const handleSelectBuyer = (id) => async (dispatch) => {
  dispatch({ type: "HANDLE_SELECT", payload: id });
};
// Buyer Activation API Call Action
export const buyerActivate = (id) => async (dispatch, getState) => {
  const { currentPage, showCount } = store.getState().vendors;
  dispatch({ type: "SET_LOADING_TRUE" });
  const { data } = await activeBuyer(id);

  if (data.errors) {
    data.errors.map((e) => {
      toast.error(e.message);
    });
  } else {
    toast.success("Buyer Activated successfully");
  }
  dispatch(getBuyerListWithoutLoader(showCount, currentPage));
  dispatch({ type: "SET_LOADING_FALSE" });
};
// Buyer Deactivate API Call Action
export const buyerDeactivate = (id) => async (dispatch, getState) => {
  const { currentPage, showCount } = store.getState().vendors;
  dispatch({ type: "SET_LOADING_TRUE" });
  const { data } = await deactivateBuyer(id);
  if (data.errors) {
    data.errors.map((e) => {
      toast.error(e.message);
    });
  } else {
    toast.success("Buyer Deactivated Successfully");
  }
  dispatch(getBuyerListWithoutLoader(showCount, currentPage));
  dispatch({ type: "SET_LOADING_FALSE" });
};
// Bulk Activation API Call Action
export const bulkActive = (list) => async (dispatch, getState) => {
  const { currentPage, showCount } = store.getState().vendors;
  const { data } = await bulkActiveBuyer(list);
  if (data.errors) {
    data.errors.map((e) => {
      toast.error(e.message);
    });
  } else {
    toast.success("Success Bulk Activation");
    dispatch({type:"EMPTY_SELECTED_BUYER"});
  }
  dispatch(getBuyerList(showCount, currentPage));
};

// Bulk Deactivation API Call Action
export const bulkDeactivate = (list) => async (dispatch, getState) => {
  const { currentPage, showCount } = store.getState().vendors;
  const { data } = await bulkDeactivateBuyer(list);
  if (data.errors) {
    data.errors.map((e) => {
      toast.error(e.message);
    });
  } else {
    toast.success("Success Bulk Deactivation");
    dispatch({type:"EMPTY_SELECTED_BUYER"});
  }

  dispatch(getBuyerList(showCount, currentPage));
};

// Bulk Remove buyer Users API Call Action
export const bulkRemove = (list) => async (dispatch) => {
  const { currentPage, showCount } = store.getState().vendors;
  const { data } = bulkRemoveBuyer(list);
  if (data.errors) {
    data.errors.map((e) => {
      toast.error(e.message);
    });
  } else {
    toast.success("Succeed Bulk Remove Action");
  }

  dispatch(getBuyerList(showCount, currentPage));
};
// Manage Current page of buyer list from redux state
export const setCurrentPage = (page, count) => async (dispatch) => {
  dispatch({ type: "SET_CURRENT_PAGE", payload: page });
  dispatch(getBuyerList(count, page));
};

// Manage Show Count of buyer list from redux state
export const setShowCount = (page, count) => async (dispatch) => {
  dispatch({ type: "SET_SHOW_COUNT", payload: count });
  dispatch(getBuyerList(count, page));
};
// Manage Search API Call Action for buyer list
export const getBuyerListSearch = (count, page, type, search) => async (
  dispatch
) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  const authToken = localStorage.getItem("authToken");
  if (type && search) {
    if (authToken) {
      const { data } = await buyersSearch(count, page, type, search);

      if (data.errors) {
        data.errors.map((e) => {
          toast.error(e.message);
        });
      } else {
        dispatch({ type: "SET_BUYER_LIST", payload: data.data });
        dispatch({
          type: "SET_BUYER_PAGINATION",
          payload: data?.meta.pagination,
        });
      }
    }
  } else {
    if (search) {
      toast.error("Please Select Search type");
    } else {
      toast.error("Please Enter value in Searchbar for Search");
    }
  }
  dispatch({ type: "SET_LOADING_FALSE" });
};
// Manage Search API Call Action of buyer list for vendor user
export const getBuyerListSearchVendor = (count, page, type, search) => async (dispatch) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  const authToken = localStorage.getItem("authToken");
  if (type && search) {
    if (authToken) {
      try {
        const { data } = await getBuyerListVendorSearch(
            count,
            page,
            type,
            search
        );
        if (data.errors) {
          data.errors.map((e) => {
            toast.error(e.message);
          });
        } else {
          dispatch({ type: "SET_BUYER_LIST", payload: data.data });
          dispatch({
            type: "SET_BUYER_PAGINATION",
            payload: data?.meta.pagination,
          });
        }
      }catch (e) {
        toast.error("Something went wrong")
      }
    }
  } else {
    if (search) {
      toast.error("Please Select Search type");
    } else {
      toast.error("Please Enter value in Searchbar for Search");
    }
  }
  dispatch({ type: "SET_LOADING_FALSE" });
};

// Buyer Details API Call Action of buyer list for Admin user
export const getBuyerDetails = (id, count, page) => async (dispatch) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  const authToken = localStorage.getItem("authToken");
  const profile = JSON.parse(localStorage.getItem("profile"));
  if (authToken) {
    try {
      if (profile.user_role === "vendor") {
        const { data } = await getBuyerDetailsVendor(id, count, page);
        if (data.errors) {
          data.errors.map((e) => {
            toast.error(e.message);
          });
        } else {
          dispatch({ type: "SET_BUYER_ORDER_DETAILS", payload: data });
        }
      } else {
        const { data } = await getBuyersDetailsAdmin(id, count, page);
        if (data.errors) {
          data.errors.map((e) => {
            toast.error(e.message);
          });
        } else {
          dispatch({ type: "SET_BUYER_ORDER_DETAILS", payload: data });
        }
      }
    }catch (e) {
      toast.error("Something went wrong")
    }
  }
  dispatch({ type: "SET_LOADING_FALSE" });
};

// Buyer Details API Call Action of buyer list for vendor user
export const getVendorBuyerList = (count, page) => async (dispatch) => {
  dispatch({ type: "SET_LOADING_TRUE" });
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    try {
      const { data } = await getBuyerListVendor(count, page);
      if (data.errors) {
        data.errors.map((e) => {
          toast.error(e.message);
        });
      } else {
        dispatch({ type: "SET_BUYER_LIST", payload: data.data });
        dispatch({
          type: "SET_BUYER_PAGINATION",
          payload: data?.meta.pagination,
        });
      }
    }catch (e) {
      toast.error("Something went wrong")
    }
  }
  dispatch({ type: "SET_LOADING_FALSE" });
};

// Search key set Action
export const setSearchKeyBuyer = (key) => async (dispatch) => {
  dispatch({ type: "SET_BUYER_SEARCH_KEY", payload: key });
};
// Search Text set Action
export const setSearchTextBuyer = (text) => async (dispatch) => {
  dispatch({ type: "SET_BUYER_SEARCH_TEXT", payload: text });
};

// set Current Page Action for vendor user
export const setCurrentPageVendor = (page, count) => async (dispatch) => {
  dispatch({ type: "SET_CURRENT_PAGE", payload: page });
  dispatch(getVendorBuyerList(count, page));
};

// set Show Count Action for vendor user
export const setShowCountVendor = (page, count) => async (dispatch) => {
  dispatch({ type: "SET_SHOW_COUNT", payload: count });
  dispatch(getVendorBuyerList(count, page));
};

// set Current Page Action for Buyer details order list
export const setCurrentPageBuyerDetails = (id, page, count) => async (
  dispatch
) => {
  dispatch({ type: "SET_CURRENT_PAGE", payload: page });
  dispatch(getBuyerDetails(id, count, page));
};
// set Show count Action for Buyer details order list
export const setShowCountBuyerDetails = (id, page, count) => async (
  dispatch
) => {
  dispatch({ type: "SET_SHOW_COUNT", payload: count });
  dispatch(getBuyerDetails(id, count, page));
};
