import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material';

import axios from 'axios';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { withRouter } from 'react-router-dom';
import {baseURL} from "./assets";

export const API = axios.create({
  baseURL: baseURL,
  timeout: 12000,
});

interface Roles {
  id: string;
  attributes: {
    id: number;
    name: string;
  };
}

// @ts-ignore
function RolesPermissions(props) {``
  const token = localStorage.getItem('authToken') || '';
  const [roles, setRoles] = useState<Roles[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Load roles
  useEffect(() => {
    API.get('/bx_block_dashboard/roles_and_permissions', {
      headers: { token },
    })
      .then((response) => {
        setRoles(response.data.data);
        setIsLoading(false);
      })
      .catch(() => {
        console.log('Error loading data');
      });
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TableContainer component={Paper} sx={{width:{
          md:"100%",
          sm:"700px",
          xs:"350px"
        }}}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead sx={{ background: '#472264' }}>
            <TableRow>
              <TableCell
                sx={{ width: '40%', color: '#fff', borderRadius: '8px 0 0 0' }}
              >
                Roles
              </TableCell>
              <TableCell align='left' sx={{ width: '40%', color: '#fff' }}>
                Roles & Permissions
              </TableCell>
              <TableCell
                align='left'
                sx={{ color: '#fff', width: '20%', borderRadius: '0 8px 0 0' }}
              >
                View Users
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role) => (
              <TableRow
                key={role.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row' sx={{ width: '40%' }}>
                  {role.attributes.name}
                </TableCell>
                <TableCell align='left' sx={{ width: '40%' }}>
                  <Link to={{
                    pathname: '/Admin/RolesPermissions/ChangePermissions',
                    state: {
                      roleId: role.attributes.id,
                      role: role.attributes.name
                    }
                  }}>
                    CHANGE ROLES & PERMISSIONS
                  </Link>
                </TableCell>
                <TableCell align='left' sx={{ width: '20%' }}>
                  <IconButton
                    onClick={() => props.history.push('/Admin/RolesPermissions/ViewUsers', {
                      role: role.attributes.name
                    })}
                  >
                    <VisibilityIcon sx={{ color: '#472264' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default withRouter(RolesPermissions);
