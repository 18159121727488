import React from 'react';
import { Button, CircularProgress } from '@mui/material';

interface props {
  text: string;
  handleSubmit?: (e: React.MouseEvent<HTMLElement>) => void;
  handleOpen?: () => void;
  isSaving?: boolean;
  isDisabled?: boolean;
}

export const SaveButton = ({ text, handleSubmit, isSaving, isDisabled }: props) => (
  <Button
    variant='contained'
    sx={{
      background: '#693a8e',
      borderRadius: '4px',
      fontSize: '18px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      width: {
        xs: 'auto',
        md: '250px',
      },
      '&:hover': { background: '#693a8e' },
    }}
    onClick={handleSubmit}
    disabled={isDisabled}
  >
    {isSaving ? <CircularProgress color="inherit" size={30} /> : text}
  </Button>
);

export const PreviewButton = ({ text, handleOpen }: props) => (
  <Button
    variant='outlined'
    sx={{
      color: '#693a8e',
      borderColor: '#693a8e',
      borderRadius: '4px',
      fontSize: '18px',
      fontWeight: 'bold',
      marginRight: 2,
      textTransform: 'uppercase',
      width: {
        xs: 'auto',
        md: '250px',
      },
      '&:hover': { borderColor: '#693a8e' },
    }}
    onClick={handleOpen}
  >
    {text}
  </Button>
);
