import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { ColorButton,HtmlTooltip } from "./Style";
import { resatPassword } from "./api";
import Alert from "@mui/material/Alert";
import {withRouter} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDispatch} from "react-redux";
import {setLoginModelTrue} from "../../../components/src/Redux/LandingPage/reduxLandingPageActions"
import {toast} from "react-toastify";

const LoginForm = ({ setFormType,token,history }) => {
  const resetPassword = yup.object().shape({
    "password":yup.string().required("Please enter password").min(8,"Short Password, Minimum 8 characters required").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/, 'Password does not meet the requirements.'),
    "confirmPassword":yup.string().oneOf([yup.ref("password"),null],"Confirm password and password does not match").required("Please confirm your password"),
  })
  const { register, handleSubmit, watch, formState: { errors },trigger } = useForm({
    resolver: yupResolver(resetPassword)
  });
  const dispatch = useDispatch()
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = React.useState({
    showPassword1: false,
    showPassword2: false
  });
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleSubmit1 = async () => {
    setError("")
    setIsLoading(true)
    const finalData = {
      data: {
        password: values.password,
        password_confirmation: values.password_confirmation,
        token:token
      }
    };
    const { data } = await resatPassword(finalData);
    if (data.errors) {
      setError(data.errors);
    } else {
      toast.success("Your password reset successfully please continue with login")
      history.push("/");
      dispatch(setLoginModelTrue())
    }
    setIsLoading(false)
  };

  return (
    <Grid item xs={12} className="loginMainDiv">
      <Box className="loginFormFlex">
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          color="purple"
          sx={{ fontWeight: "bold" }}
        >
          Reset password
        </Typography>
        <Typography
          variant="subtitle1"
          component="div"
          gutterBottom
          color="purple"
          sx={{ width: "90%" }}
        >
          You have to reset your password
        </Typography>
        <Grid item xs={12} sx={{ marginTop: "30px" }}>
          {error
            ? error.map((e, index) => {
                return (
                  <Alert
                    key={index}
                    severity="error"
                    sx={{ width: "90%", alignSelf: "center" }}
                  >
                    {e.message}
                  </Alert>
                );
              })
            : null}
          <Box>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              New Password
            </Typography>
            <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography variant="subtitle2" color="inherit">Password must have</Typography>
                    <Typography variant="subtitle2" color="inherit">- Minimum 8 characters </Typography>
                    <Typography variant="subtitle2" color="inherit">- Minimum 1 capital letter</Typography>
                    <Typography variant="subtitle2" color="inherit">- Minimum 1 special character</Typography>
                    <Typography variant="subtitle2" color="inherit">- Minimum 1 number</Typography>
                  </React.Fragment>
                }
            >
            <FormControl sx={{ m: 0, width: "90%" }}>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword1 ? "text" : "password"}
                value={values.password}
                error={errors["password"]}
                {...register("password")}
                placeholder="Enter your password"
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setValues({
                          ...values,
                          showPassword1: !values.showPassword1
                        })
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword1 ? (
                          <Visibility color="secondary" />
                      ) : (
                          <VisibilityOff color="secondary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            </HtmlTooltip>
            {errors["password"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["password"]?.message}
                </Typography> : null
            }
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Confirm Password
            </Typography>
            <FormControl sx={{ m: 0, width: "90%" }}>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword2 ? "text" : "password"}
                value={values.password_confirmation}
                placeholder="Confirm your password"
                error={errors["confirmPassword"]}
                {...register("confirmPassword")}
                onChange={handleChange("password_confirmation")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setValues({
                          ...values,
                          showPassword2: !values.showPassword2
                        })
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword2 ? (
                          <Visibility color="secondary" />
                      ) : (
                          <VisibilityOff color="secondary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {errors["confirmPassword"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["confirmPassword"]?.message}
                </Typography> : null
            }
          </Box>
          <Box>
            <ColorButton
              variant="contained"
              color="secondary"
              sx={{ width: "90%", marginBottom: "10px", marginTop: "20px" }}
              onClick={handleSubmit(handleSubmit1)}
            >
              {isLoading ? <CircularProgress /> : "Reset Password"}
            </ColorButton>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default withRouter(LoginForm);
