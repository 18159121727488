import React from "react"
import { toast } from 'react-toastify';
import {
    vendorsList,
    bulkActiveVendor,
    bulkDeactivateVendor,
    activeVendor,
    deactivateVendor,
    vendorDetails,
    vendorDetailsCharts,
    approveVendor,
    rejectVendor,
    vendorListByFilter,
    adminDashboard,
    vendorListSearchByFilter
} from "./reduxVendorApi";
import store from "../store";

// get Vendor list
export const getVendorList = (count,page,status) => async (dispatch,getState) => {
    dispatch({type:'SET_LOADING_VENDOR_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        if(status !== 'all'){
            const {data} = await vendorListByFilter(count,page,status)
            if(data.errors){
                data.errors.map((e)=>{
                    toast.error(e.message)
                })
            }else{
                dispatch({type:'SET_VENDOR_LIST', payload: data.data})
                dispatch({type:'SET_VENDOR_PAGINATION', payload: data?.meta.pagination})
                dispatch({type:'SET_VENDOR_STATUS_COUNT', payload:{
                        all_count:data.all_count,
                        approved_count:data.approved_count,
                        pending_count:data.pending_count,
                        rejected_count:data.rejected_count,
                    }})
            }
        }else{
            const {data} = await vendorsList(count,page)
            if(data.errors){
                data.errors.map((e)=>{
                    toast.error(e.message)
                })
            }else{
                dispatch({type:'SET_VENDOR_LIST', payload: data.data})
                dispatch({type:'SET_VENDOR_PAGINATION', payload: data?.meta.pagination})
                dispatch({type:'SET_VENDOR_STATUS_COUNT', payload:{
                        all_count:data.all_count,
                        approved_count:data.approved_count,
                        pending_count:data.pending_count,
                        rejected_count:data.rejected_count,
                    }})
            }
        }
    }
    dispatch({type:'SET_LOADING_VENDOR_FALSE'})
}

// Select all vendor
export const selectAllVendor = () => async dispatch => {
    dispatch({type:'SELECT_ALL_VENDORS'})
}

// Select single vendor
export const handleSelectVendor = (id) => async dispatch => {
    dispatch({type:'HANDLE_SELECT_VENDORS',payload:id})
}

// set current filter
export const currentFilterSet = (count,page,filter) => async dispatch => {
    dispatch({type:'SET_VENDOR_CURRENT_FILTER',payload:filter})
    dispatch(getVendorList(count,page,filter))
}

// set Search Key
export const setSearchKey = (key) => async dispatch => {
    dispatch({type:'SET_VENDOR_SEARCH_KEY',payload:key})
}

// set Search Text
export const setSearchText = (text) => async dispatch => {
    dispatch({type:'SET_VENDOR_SEARCH_TEXT',payload:text})
}

// Set vendor Activate
export const vendorActivate = (id,status) => async (dispatch,getState) => {
    const {currentPage,showCount} = store.getState().vendors
    const {data} = await activeVendor(id)
    if(data.errors) {
        data.errors.map((e) => {
            toast.error(e.message)
        })
    }else{
        toast.success("Vendor Account Activated successfully")
    }
    dispatch(getVendorList(showCount,currentPage,status))
}

// Vendor Deactivate action
export const vendorDeactivate = (id,status) => async (dispatch,getState) => {
    const {currentPage,showCount} = store.getState().vendors
    const {data} = await deactivateVendor(id)
    if(data.errors) {
        data.errors.map((e) => {
            toast.error(e.message)
        })
    }else{
        toast.success("Vendor Account Deactivated Successfully")
    }
    dispatch(getVendorList(showCount,currentPage,status))
}

// Vendor Bulk Activation Action
export const vendorBulkActive = (list,status) => async (dispatch,getState) => {
    const {currentPage,showCount} = store.getState().vendors
    const {data} = await bulkActiveVendor(list)
    if(data.errors) {
        data.errors.map((e) => {
            toast.error(e.message)
        })
    }else{
        toast.success("Success Bulk Activation")
        dispatch({type:"EMPTY_SELECTED_VENDOR"});
    }
    dispatch(getVendorList(showCount,currentPage,status))
}

// Vendor Bulk Deactivation Action
export const vendorBulkDeactivate = (list,status) => async (dispatch,getState) => {
    const {currentPage,showCount} = store.getState().vendors
    const {data} = await bulkDeactivateVendor(list)
    if(data.errors) {
        data.errors.map((e) => {
            toast.error(e.message)
        })
    }else{
        toast.success("Success Bulk Deactivation")
        dispatch({type:"EMPTY_SELECTED_VENDOR"});
    }
    dispatch(getVendorList(showCount|| 10,currentPage || 1,status))
}

// set Current page vendor list
export const setCurrentPageVendor = (page,count,status) => async dispatch => {
    dispatch({type:'SET_CURRENT_VENDOR_PAGE',payload:page})
    dispatch(getVendorList(count,page,status))
}

// set Show count vendor list
export const setShowCountVendor = (page,count,status) => async dispatch => {
    dispatch({type:'SET_SHOW_VENDOR_COUNT',payload:count})
    dispatch(getVendorList(count,page,status))
}

// get vendor list from search
export const getVendorListSearch = (count,page,type,search) => async (dispatch) => {
    const {currentStatus} = store.getState().vendors
    dispatch({type:'SET_LOADING_VENDOR_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(type && search){
        if(authToken){
            const {data} = await vendorListSearchByFilter(count,page,currentStatus,type,search)
            if(data.errors){
                data.errors.map((e)=>{
                    toast.error(e.message)
                })
            }else{
                dispatch({type:'SET_VENDOR_LIST', payload: data.data})
                dispatch({type:'SET_VENDOR_PAGINATION', payload: data?.meta.pagination})
            }
        }
    }else {
        if(search){
            toast.error("Please Select Search type")
        }else{
            toast.error("Please Enter value in Searchbar for Search")
        }
    }
    dispatch({type:'SET_LOADING_VENDOR_FALSE'})
}

// Get Vendor Details Action
export const getVendorDetails = (id,interval) => async dispatch => {
    dispatch({type:'SET_LOADING_VENDOR_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const accounts = await vendorDetails(id,interval)
        if(accounts.data.errors){
            accounts.data.errors.map((e)=>{
                toast.error(e.message)
            })
        }else{
            dispatch({type:'SET_VENDOR_DETAILS_PAGE', payload: accounts.data.data.attributes})
        }
    }
    dispatch({type:'SET_LOADING_VENDOR_FALSE'})
}

// Approve Vendor Request Action
export const approveVendorRequest = (id) => async dispatch => {
    dispatch({type:'SET_BUTTON_LOADING_VENDOR_APPROVE_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await approveVendor(id)
        if(data.errors){
            data.errors.map((e)=>{
                toast.error(e.message)
            })
        }else{
            toast.success("Vendor Profile Approved Successfully")
            dispatch(getVendorDetails(id,"yearly"))
        }
    }
    dispatch({type:'SET_BUTTON_LOADING_VENDOR_APPROVE_FALSE'})
}

// Reject Vendor Request Action
export const rejectVendorRequest = (id) => async dispatch => {
    dispatch({type:'SET_BUTTON_LOADING_VENDOR_REJECT_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await rejectVendor(id)
        if(data.errors){
            data.errors.map((e)=>{
                toast.error(e.message)
            })
        }else{
            toast.info("Vendor Profile Rejected Successfully")
            dispatch(getVendorDetails(id,"yearly"))
        }
    }
    dispatch({type:'SET_BUTTON_LOADING_VENDOR_REJECT_FALSE'})

}

// Get Dashboard Data Action
export const getDashboard = (interval) => async dispatch => {
    dispatch({type:'SET_LOADING_VENDOR_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const charts = await adminDashboard(interval)
        if(charts.data.errors){
            charts.data.errors.map((e)=>{
                toast.error(e.message)
            })
        }else{
            dispatch({type:'SET_VENDOR_DASHBOARD_PAGE', payload: charts.data})
        }
    }
    dispatch({type:'SET_LOADING_VENDOR_FALSE'})
}

// Get Dashboard Data by vendor id Action
export const getDashboardByID = (id,interval) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const charts = await vendorDetailsCharts(id,interval)
        if(charts.data.errors){
            charts.data.errors.map((e)=>{
                toast.error(e.message)
            })
        }else{
            dispatch({type:'SET_VENDOR_DASHBOARD_PAGE', payload: charts.data})
        }
    }
}

