import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Checkbox,
  Alert,
} from "@mui/material";
import { withRouter } from "react-router-dom";

import { SaveButton } from "../../ContentManagement/src/Buttons";
import { API } from "./RolesPermissions2.web";

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: { color: "#693a8e" },
  editHeader: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#693a8e",
    marginBottom: 2,
  },
  btnContainer: { display: "flex", justifyContent: "flex-end", marginTop: 2 },
};

export interface Permission {
  add_remove_product_permission: boolean;
  add_remove_vendor_user_permission: boolean;
  add_remove_buyer_user_permission: boolean;
  approve_vendor_request_permission: boolean;
  change_permission: boolean;
  edit_product_permission: boolean;
  pay_online_permission: boolean;
  pay_offline_permission: boolean;
}

// @ts-ignore
function ChangeRolePermissions(props) {
  const [roleId, setRoleId] = useState(null);
  // @ts-ignore
  const [permission, setPermission] = useState<Permission>({});
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState<{ message: string }[]>([]);

  // Change checkboxes
  const handleChange = (key: string) => {
    setPermission((prev) => ({
      ...prev,
      // @ts-ignore
      [key]: !prev[key],
    }));
  };

  // Submit
  const handleSubmit = () => {
    setIsSaving(true);
    const token = localStorage.getItem("authToken") || "";
    const headers = {
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
    };
    if (roleId === 1) {
      // For buyer
      const data = {
        pay_online_permission: permission.pay_online_permission,
        pay_offline_permission: permission.pay_offline_permission,
      };
      API.post(
        "/bx_block_dashboard/roles_and_permissions/change_buyer_role_permission",
        data,
        headers
      )
        .then((response) => {
          setPermission(response.data.data.attributes.permission);
          setIsSaving(false);
          setMessage("Updated");
          setError([]);
        })
        .catch((err) => {
          setIsSaving(false);
          setMessage("");
          setError([{ message: "Failed to update!" }]);
        });
    } else if (roleId === 2) {
      // For vendor
      const data = {
        add_remove_product_permission: permission.add_remove_product_permission,
        edit_product_permission: permission.edit_product_permission,
      };
      API.post(
        "/bx_block_dashboard/roles_and_permissions/change_vendor_role_permission",
        data,
        headers
      )
        .then((response) => {
          setPermission(response.data.data.attributes.permission);
          setIsSaving(false);
          setMessage("Updated");
          setError([]);
        })
        .catch((err) => {
          setIsSaving(false);
          setMessage("");
          setError([{ message: "Failed to update!" }]);
        });
    } else {
      // For admin
      const data = {
        add_remove_product_permission: permission.add_remove_product_permission,
        add_remove_vendor_user_permission:
          permission.add_remove_vendor_user_permission,
        add_remove_buyer_user_permission:
          permission.add_remove_buyer_user_permission,
        approve_vendor_request_permission:
          permission.approve_vendor_request_permission,
        change_permission: permission.change_permission,
        edit_product_permission: permission.edit_product_permission,
      };
      API.post(
        "/bx_block_dashboard/roles_and_permissions/change_admin_role_permission",
        data,
        headers
      )
        .then((response) => {
          setPermission(response.data.data.attributes.permission);
          setIsSaving(false);
          setMessage("Updated");
          setError([]);
        })
        .catch((err) => {
          setIsSaving(false);
          setMessage("");
          setError([{ message: "Failed to update!" }]);
        });
    }
  };

  // Set role to load data
  useEffect(() => {
    setRoleId(props.location.state.roleId);
  }, [props.location.state.roleId]);

  // Load data
  useEffect(() => {
    const token = localStorage.getItem("authToken") || "";
    if (roleId) {
      API.get(`/bx_block_dashboard/roles_and_permissions/${roleId}`, {
        headers: { token },
      })
        .then((response) => {
          setPermission(response.data.data.attributes.permission);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setMessage("");
          setError([{ message: "Failed to load data!" }]);
        });
    }
  }, [roleId]);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Typography
        sx={{ fontWeight: "bold", fontSize: "20px", color: "#693a8e" }}
      >
        Change Permissions
      </Typography>
      <Typography>
        <span style={{ color: "#693a8e" }}>Roles & Permissions</span>{" "}
        <span style={{ color: "#693a8e" }}>/ Change Roles and Permissions</span>
      </Typography>
      {/* Message container */}
      <Grid container>
        <Grid item md={6} xs={12}>
          {/* Error messages */}
          {error.length > 0 && (
            <Alert severity="error">{error.map((e) => e.message)}</Alert>
          )}
          {/* Info messages */}
          {message && <Alert severity="success">{message}</Alert>}
        </Grid>
      </Grid>
      {/* Main content */}
      <Grid container>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "8px",
              padding: "20px 30px",
              marginTop: 3,
            }}
          >
            <Typography sx={styles.editHeader}>
              {`${props.location.state.role} `}Permissions
            </Typography>
            {/* Buyer */}
            {roleId === 1 && (
              <>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Pay Online</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.pay_online_permission
                        ? permission.pay_online_permission
                        : false
                    }
                    onChange={() => handleChange("pay_online_permission")}
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Pay Offline</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.pay_offline_permission
                        ? permission.pay_offline_permission
                        : false
                    }
                    onChange={() => handleChange("pay_offline_permission")}
                  />
                </Box>
              </>
            )}
            {/* Vendor */}
            {roleId === 2 && (
              <>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Add/Remove Product</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.add_remove_product_permission
                        ? permission.add_remove_product_permission
                        : false
                    }
                    onChange={() =>
                      handleChange("add_remove_product_permission")
                    }
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Edit Product</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.edit_product_permission
                        ? permission.edit_product_permission
                        : false
                    }
                    onChange={() => handleChange("edit_product_permission")}
                  />
                </Box>
              </>
            )}
            {/* Admin */}
            {roleId === 3 && (
              <>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Add/Remove Product</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.add_remove_product_permission
                        ? permission.add_remove_product_permission
                        : false
                    }
                    onChange={() =>
                      handleChange("add_remove_product_permission")
                    }
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>
                    Add/Remove Vendor User
                  </Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.add_remove_vendor_user_permission
                        ? permission.add_remove_vendor_user_permission
                        : false
                    }
                    onChange={() =>
                      handleChange("add_remove_vendor_user_permission")
                    }
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>
                    Add/Remove Buyer User
                  </Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.add_remove_buyer_user_permission
                        ? permission.add_remove_buyer_user_permission
                        : false
                    }
                    onChange={() =>
                      handleChange("add_remove_buyer_user_permission")
                    }
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>
                    Approve Vendor Request
                  </Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.approve_vendor_request_permission
                        ? permission.approve_vendor_request_permission
                        : false
                    }
                    onChange={() =>
                      handleChange("approve_vendor_request_permission")
                    }
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Change Permissions</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.change_permission
                        ? permission.change_permission
                        : false
                    }
                    onChange={() => handleChange("change_permission")}
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>
                    Edit Product Permission
                  </Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.edit_product_permission
                        ? permission.edit_product_permission
                        : false
                    }
                    onChange={() => handleChange("edit_product_permission")}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box sx={styles.btnContainer}>
            <SaveButton
              text="save"
              handleSubmit={handleSubmit}
              isSaving={isSaving}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(ChangeRolePermissions);
