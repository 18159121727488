const initialState = {
    categories:[],
    isLoading:true,
    section1:{link: "#"},
    section2:{},
    section3:{link: "#"},
    section4:{},
    section5:{link: "#"},
    section6:{link: "#"},
    footer1:{},
    footer2:[],
    loaderCounter:0,
    profile:{},
    addRemoveProduct:true,
    editProduct:true,
    vendorPermission:true,
    buyerPermission:true,
    vendorApproval:true,
    managePermissions:true,
    paymentOnline:true,
    paymentOffline:true,
    loginModal:false,
    registerModal:false,
}

export const landingPageReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case "SET_LANDING_PAGE_CATEGORY":
            return {
                ...state,
                categories: payload,
            }
        case "SET_LANDING_PAGE_LOADING_TRUE":
            return {
                ...state,
                isLoading: true,
            }
        case "SET_LANDING_PAGE_LOADING_FALSE":
            return {
                ...state,
                isLoading: false,
            }
        case "SET_LANDING_PAGE_SECTION_1":
            return {
                ...state,
                section1: payload,
            }
        case "SET_LANDING_PAGE_SECTION_2":
            return {
                ...state,
                section2: payload,
            }
        case "SET_LANDING_PAGE_SECTION_3":
            return {
                ...state,
                section3: payload,
            }
        case "SET_LANDING_PAGE_SECTION_4":
            return {
                ...state,
                section4: payload,
            }
        case "SET_LANDING_PAGE_SECTION_5":
            return {
                ...state,
                section5: payload,
            }
        case "SET_LANDING_PAGE_SECTION_6":
            return {
                ...state,
                section6: payload,
            }
        case "SET_LANDING_PAGE_FOOTER_1":
            return {
                ...state,
                footer1: payload,
            }
        case "SET_LANDING_PAGE_FOOTER_2":
            return {
                ...state,
                footer2: payload,
            }
        case "SET_LANDING_PAGE_LOADER_COUNTER_PLUS_ONE":
            if((state.loaderCounter + 1) === 11){
                return {
                    ...state,
                    isLoading: false,
                    loaderCounter: state.loaderCounter + 1
                }
            }else{
                return {
                    ...state,
                    loaderCounter: state.loaderCounter + 1,
                }
            }
        case "SET_PROFILE":
            return {
                ...state,
                profile:payload,
            }
        case "SET_ADD_REMOVE_PRODUCT_PERMISSION":
            return {
                ...state,
                addRemoveProduct:payload
            }
        case "SET_EDIT_PRODUCT_PERMISSION":
            return {
                ...state,
                editProduct:payload
            }
        case "SET_VENDOR_PERMISSION":
            return {
                ...state,
                vendorPermission:payload
            }
        case "SET_BUYER_PERMISSION":
            return {
                ...state,
                buyerPermission:payload
            }
        case "SET_VENDOR_APPROVAL_PERMISSION":
            return {
                ...state,
                vendorApproval:payload
            }
        case "SET_MANAGE_PERMISSION":
            return {
                ...state,
                managePermissions:payload
            }
        case "SET_ONLINE_PAYMENT_PERMISSION":
            return {
                ...state,
                paymentOnline:payload
            }
        case "SET_OFFLINE_PAYMENT_PERMISSION":
            return {
                ...state,
                paymentOffline:payload
            }
        case "SET_LOGIN_MODEL_TRUE":
            return {
                ...state,
                loginModal:true
            }
        case "SET_LOGIN_MODEL_FALSE":
            return {
                ...state,
                loginModal:false
            }
        case "SET_REGISTRATION_MODEL_TRUE":
            return {
                ...state,
                registerModal:true
            }
        case "SET_REGISTRATION_MODEL_FALSE":
            return {
                ...state,
                registerModal:false
            }
        case "SET_PROFILE_EMPTY":
            return {
                ...state,
                profile: {}
            }
        default:
            return state
    }
}