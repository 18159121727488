import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
  baseURL: baseURL,
  timeout: 5000,

});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("authToken")) {
    req.headers.token = `${localStorage.getItem("authToken")}`;
  }
  return req;
});

export const getVendorFirmDetails = async () => {
  try {
    return await API.get(`/bx_block_customisableuserprofiles/vendor_firms`);
  } catch (e) {
    return e.response;
  }
};

export const getCountries = async () => {
  try {
    return await API.get(`/account_block/accounts/country`);
  } catch (e) {
    return e.response;
  }
};

export const getStates = async (country) => {
  try {
    return await API.get(`/account_block/accounts/states/${country}`);
  } catch (e) {
    return e.response;
  }
};

export const getBankDetails = async () => {
  try {
    return await API.get(`/bx_block_customisableuserprofiles/bank_details`);
  } catch (e) {
    return e.response;
  }
};

export const updateFirmDetails = async (id, data) => {
  try {
    return await API.put(
      `/bx_block_customisableuserprofiles/vendor_firms/${id}`,
      data
    );
  } catch (e) {
    return e.response;
  }
};

export const saveFirmDetails = async (data) => {
  try {
    return await API.post(
      "/bx_block_customisableuserprofiles/vendor_firms",
      data
    );
  } catch (e) {
    return e.response;
  }
};

export const removeImage = async (data) => {
  try {
    return await API.post(
      "/bx_block_customisableuserprofiles/vendor_firms/remove_image",
      data
    );
  } catch (e) {
    return e.response;
  }
};

export const updateBankDetails = async (id, data) => {
  try {
    return await API.put(
      `/bx_block_customisableuserprofiles/bank_details/${id}`,
      data
    );
  } catch (e) {
    return e.response;
  }
};

export const saveBankDetails = async (data) => {
  try {
    return await API.post(
      "/bx_block_customisableuserprofiles/bank_details",
      data
    );
  } catch (e) {
    return e.response;
  }
};

export const submitQuery = async (data) => {
  try {
    return await API.post(
      "/bx_block_contact_us/contact_supports",
      data
    );
  } catch (e) {
    return e.response;
  }
};
