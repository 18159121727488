import React,{useEffect,Fragment,useState} from 'react';
import OrderDashboard from "./OrderDashboard";
import OrderChart from "./OrderChart";
import {getDashboard,getDashboardByID} from "./assets"
import {useDispatch,useSelector} from "react-redux";
import LoaderPage from "../../../components/src/LoaderPage";
import {withRouter} from "react-router-dom";
// @ts-ignore
const Dashboard = ({match}) => {
    const {id} = match.params
    const dispatch = useDispatch()
    //@ts-ignore

    const {vendorDashboard,isLoading} = useSelector(state => state.vendors)
    const [year, setYear] = React.useState("yearly");

    // @ts-ignore
    const handleChange = event => {
        if(id){
            dispatch(getDashboardByID(id,event.target.value))
        }else{
            dispatch(getDashboard(event.target.value))
        }
        setYear(event.target.value);
    };
    useEffect(() => {
        if(id){
            dispatch(getDashboardByID(id,year))
        }else{
            dispatch(getDashboard(year))
        }
    }, []);

    return (
        <Fragment>
            {
                isLoading ? <LoaderPage/> :
                    <Fragment>
                        <OrderDashboard/>
                        <OrderChart year={year} handleChange={handleChange}/>
                    </Fragment>
            }
        </Fragment>
    );
};

export default withRouter(Dashboard);
