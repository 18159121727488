import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { ColorButton } from "./Style";
import { withRouter } from "react-router-dom";
import { SuccessCheck } from "./assets";

const RegisterForm = ({ setFormType, match }) => {
  const type = match.params.type;
  const handleNext = () => {
    if (type === "buyer") {
      setFormType("shippingInfo");
    } else {
      setFormType("registerVendor1");
    }
  };
  return (
    <Grid item xs={12} className="registerMainDiv">
      <Box className="registerFormFlex" style={{marginLeft:"27px"}}>
        <Grid item xs={12} sx={{ marginTop: "100px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "90%"
            }}
          >
            <img
              src={SuccessCheck}
              alt="success"
              width="100px"
              sx={{ marginBottom: "30px" }}
            />
            <Typography
              variant="h4"
              component="div"
              color="#472264"
              sx={{ fontWeight: "bold", margin: "30px 0px",textAlign:"center" }}
            >
              Link Sent Successfully
            </Typography>
            <Typography
              variant="h6"
              component="span"
              color="#925BBD"
              sx={{ textAlign: "center", fontWeight: "bold"}}
            >
              We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.
            </Typography>
          </Box>
          <Box>
            <ColorButton
              size="large"
              variant="contained"
              color="secondary"
              sx={{ width: "95%", height: "64px", marginTop: "20px",backgroundColor:"#693A8E"}}
              onClick={handleNext}
            >
              Next
            </ColorButton>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default withRouter(RegisterForm);
