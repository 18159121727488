import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Grid,
  Box,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack,
  Pagination,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// @ts-ignore
import { ColorButton } from "./Styles";
import { API } from "./RolesPermissions2.web";
import CustomizedSnackbar from "../../categoriessubcategories/src/CustomizedSnackbar";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import * as yup from "yup";
import {useForm,Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const styles = {
  input: { width: "100%", background: "#f1f1f1", marginTop: 1 },
  box: { marginY: 1 },
  label: { color: "#472264", fontWeight: "bold" },
};

interface Profile {
  user_role: string;
}

const initialState = {
  firstName: "",
  lastName:"",
  password:"",
  email:"",
  role:"",
}

// @ts-ignore
function AddUser(props) {
  // @ts-ignore
  const {vendorPermission,buyerPermission} = useSelector(state => state.landingPage)
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState<{ message: string }[]>([]);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("authToken") || "";
  const profile: Profile = JSON.parse(localStorage.getItem("profile") || "{}");

  const registerSchema = yup.object().shape({
    "firstName":yup.string().trim().required("First name is required"),
    "lastName":yup.string().trim().required("Last name is required"),
    "password":yup.string().trim().required("Please enter password").min(8,"Short Password, Minimum 8 characters required").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/, 'Password does not meet the requirements.'),
    "email":yup.string().email("Please enter a valid Email").required("Email ID is required"),
  })

  const { handleSubmit:validationSubmit, formState: { errors },control:addUserControl,setValue} = useForm({
    resolver: yupResolver(registerSchema),
    defaultValues: initialState
  });
  // Add user
  const handleSubmit = (formData:any) => {
    console.log(formData)
    setIsSaving(true);
    const headers = {
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
    };
    const data = {
      full_name: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      password: formData.password,
      password_confirmation: formData.password,
      user_role: role.toLowerCase(),
    };
    API.post(
      "bx_block_dashboard/roles_and_permissions/add_users",
      data,
      headers
    )
      .then((response) => {
        setIsSaving(false);
        setError([]);
        toast.success(`New ${role} user has been added`)
        props.history.goBack()
      })
      .catch((err) => {
        setIsSaving(false);
        setOpen(true);
        setMessage("");
        err.response.data.errors
          ? setError(err.response.data.errors)
          : setError([{ message: "Something went wrong" }]);
      });
  };

  // Close custom snackbar
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Set role to load data
  useEffect(() => {
    setRole(props.location.state.role);
  }, [props.location.state.role]);

  return (
    <>
      <Typography
        sx={{ fontWeight: "bold", fontSize: "20px", color: "#693a8e" }}
      >
        Add New User
      </Typography>
      <Typography>
        <span style={{ color: "#693a8e" }}>Roles & Persmissions</span>{" "}
        <span style={{ color: "#693a8e" }}>/ Add User</span>
      </Typography>
      <Grid container>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            background: "#fff",
            borderRadius: "8px",
            padding: "20px 30px",
            marginTop: 3,
          }}
        >
          {/* Email */}
          <Box sx={styles.box}>
            <Typography sx={styles.label}>Email</Typography>
            <Controller
                name="email"
                control={addUserControl}
                render={({ field }) => (
                  <TextField
                    {...field}
                    // @ts-ignore
                    error={errors["email"]}
                    placeholder="Enter email id"
                    size="small"
                    sx={styles.input}/>
                  )}
            />
            {errors["email"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["email"]?.message}
                </Typography> : null
            }
          </Box>
          {/* First name */}
          <Box sx={styles.box}>
            <Typography sx={styles.label}>First Name</Typography>
            <Controller
                name="firstName"
                control={addUserControl}
                render={({ field }) => (
                  <TextField
                    // @ts-ignore
                    error={errors["firstName"]}
                    {...field}
                    placeholder="Enter first name"
                    size="small"
                    sx={styles.input}
                  />)}
            />
            {errors["firstName"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["firstName"]?.message}
                </Typography> : null
            }
          </Box>
          {/* Last name */}
          <Box sx={styles.box}>
            <Typography sx={styles.label}>Last Name</Typography>
            <Controller
                name="lastName"
                control={addUserControl}
                render={({ field }) => (
                  <TextField
                    // @ts-ignore
                    error={errors["lastName"]}
                    {...field}
                    placeholder="Enter last name"
                    size="small"
                    sx={styles.input}
                  />)}
            />
            {errors["lastName"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["lastName"]?.message}
                </Typography> : null
            }
          </Box>
          {/* Password */}
          <Box sx={styles.box}>
            <Typography sx={styles.label}>Password</Typography>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <Controller
                  name="password"
                  control={addUserControl}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      // @ts-ignore
                      error={errors["password"]}
                      size="small"
                      id="outlined-adornment-new-password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword((prevStatus) => !prevStatus)
                            }
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? (
                               <Visibility color="secondary" />
                            ) : (
                               <VisibilityOff color="secondary" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />)}
                  />
            </FormControl>
            {errors["password"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["password"]?.message}
                </Typography> : null
            }
          </Box>
          {/* Role */}
          <Box sx={styles.box}>
            <Typography sx={styles.label}>Role</Typography>
            <FormControl size="small" sx={{ width: "100%" }}>
              <Select
                labelId="role-section"
                id="role-select"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                sx={{ background: "#fff" }}
              >
                {
                  profile.user_role === "super_administrator" ? <MenuItem value={"Administrator"}>Administrator</MenuItem> : null
                }
                {
                  buyerPermission ? <MenuItem value={"Buyer"}>Buyer</MenuItem> : null
                }
                {
                  vendorPermission ? <MenuItem value={"Vendor"}>Vendor</MenuItem> : null
                }
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.box}>
            <ColorButton
              onClick={validationSubmit(handleSubmit)}
            >
              {isSaving ? (
                <CircularProgress color="inherit" size={30} />
              ) : (
                "ADD USER"
              )}
            </ColorButton>
          </Box>
        </Grid>
      </Grid>
      <CustomizedSnackbar
        handleClose={handleClose}
        open={open}
        message={message}
        error={error}
      />
    </>
  );
}

export default withRouter(AddUser);
