import React, { Fragment, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NoRecordPage from "../../../components/src/NoRecordPage";
import "./style.css";
// @ts-ignore
import Product from "./Product.js";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {withRouter} from "react-router-dom";
import LoaderPage from "../../../components/src/LoaderPage";
import {
  getCatalogListBySubCategory,
} from "./assets";
import {useDispatch,useSelector} from "react-redux";
// @ts-ignore
const ProductList = ({match,history}) => {
  const {cat,sub} = match.params
  const dispatch = useDispatch()
  // @ts-ignore
  const {catalog,pagination,isLoading,currentPage,showCount} = useSelector(state => state.catalog)
  useEffect(() => {
    dispatch(getCatalogListBySubCategory(showCount,1,cat,sub))
  }, []);

  useEffect(() => {
    dispatch(getCatalogListBySubCategory(showCount,1,cat,sub))
  }, [match]);

  // @ts-ignore
  const handleShow = (e) => {
    dispatch(getCatalogListBySubCategory(e,currentPage,cat,sub))
  }
  // @ts-ignore
  const handlePagination = (event, value) => {
    dispatch(getCatalogListBySubCategory(showCount,value,cat,sub))
  };
  return (
    <Fragment>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Fragment>
          <Grid sx={{ background: "#fff", display: "flex", padding: "10px 0" }}>
            <Container>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Button
                      size="large"
                      sx={{ color: "#472264", fontWeight: "bold" }}
                      onClick={()=>history.goBack()}
                  ><ArrowBackIcon className="back-btn" /></Button>
                  <Button
                      disabled
                      size="large"
                      sx={{ color: "#472264", fontWeight: "bold", '&:disabled':{color: "#472264"}}}>
                    {cat.replace(/-/g, ' ')} - {sub.replace(/-/g, ' ')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    style={{
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "flex-end",
                      width: "100%",
                      display: "flex"
                    }}
                  >
                    Showing {catalog.length !== 0 ? (showCount * currentPage - showCount + 1) : "0"} -{" "}
                    {pagination.total_count < showCount
                        ? pagination.total_count
                        : (showCount * currentPage - showCount + showCount) > pagination.total_count ?
                            pagination.total_count :
                            (showCount * currentPage - showCount + showCount)
                    }{" "}
                    of {pagination.total_count} item (s)
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          {
            catalog.length !== 0 ?
            <Grid sx={{ background: "#EAEAEA", padding: "25px" }}>
            <Container>
              <Grid
                container
                justifyContent="left"
                alignItems="center"
                spacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {
                  // @ts-ignore
                  catalog.map((item,key)=> {
                    return(
                        <Product item={item} key={key}/>
                    )
                  })
                }
              </Grid>
            </Container>
          </Grid> : <NoRecordPage text={"There are no products for the selected categories"}/>
          }
          {
            catalog.length !== 0 &&
            <Grid
                sx={{
                  background: "#EAEAEA",
                  display: "flex",
                  padding: "10px 25px"
                }}
            >
              <Container>
                <Grid container >
                  <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        alignItems: {
                          paddingBottom: "10px",
                          xs: "center",
                          md: "end"
                        }
                      }}
                  >
                    <FormControl
                        size="small"
                        sx={{ m: 1, minWidth: 140, background: "#fff" }}
                    >
                      <InputLabel id="demo-simple-select-helper-label">
                        Show
                      </InputLabel>
                      <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="Show"
                          value={showCount}
                          onChange={e => {
                            // @ts-ignore
                            handleShow(e.target.value)
                          }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                      item
                      xs={12}
                      md={6}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                  >
                    <Stack
                        spacing={2}
                        width="100%"
                        display="flex"
                        sx={{ alignItems: { xs: "left", md: "end" } }}
                        justifyContent="center"
                    >
                      <Pagination
                          count={pagination.total_pages}
                          page={pagination.current_page}
                          variant="outlined"
                          shape="rounded"
                          sx={{
                            "& .Mui-selected": {
                              color: "#fff !important",
                            },
                            "& .MuiPaginationItem-root:hover": {
                              backgroundColor: "#693A8E",
                            },
                            ".css-wjh20t-MuiPagination-ul": {
                              justifyContent: "flex-end",
                            },
                          }}
                          onChange={handlePagination}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          }

        </Fragment>
      )}
    </Fragment>
  );
};

export default withRouter(ProductList);
