import React, { useState,Fragment } from "react";
import Modal from "@mui/material/Modal";
import { ColorButton, style, SuccessModalStyle } from "./Style";
import { Grid } from "@mui/material";
import RegisterForm from "./RegisterForm";
import RegisterVendorForm1 from "./RegisterVendorForm1";
import RegisterVendorForm2 from "./RegisterVendorForm2";
import RegisterVendorForm3 from "./RegisterVendorForm3";
import ShippingAddress from "./ShippingAddress";
import IconButton from "@mui/material/IconButton";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import "./style.css";
import Typography from "@mui/material/Typography";
import { SideImage, logo, cross, thankyou } from "./assets";
import SuccessMessage from "./SuccessMessage";
import LandingPage from "../../landingpage/src/LandingPage.web";

const Register = ({ history, match }) => {
  const [openRegisterModel, setOpenRegisterModel] = useState(true);
  const [openSuccessModel, setOpenSuccessModel] = useState(false);
  const [vendorRegistration, setVendorRegistration] = useState({});
  const [formType, setFormType] = useState("register");
  const {type} = match.params
  const handleCloseSuccess = () => {
    setOpenSuccessModel(false);
    history.push("/");
  };

  const handleClose = () => {
    setOpenRegisterModel(false);
    setFormType("register");
    if(type === "vendor"){
        if(formType !== "register"){
            history.push("/vendor/dashboard");
        }else {
            history.push("/");
        }
    }else{
        history.push("/");
    }
  };

  return (
    <Fragment>
      <LandingPage/>
      <Modal
        open={openRegisterModel}
        aria-labelledby="Login Model"
        aria-describedby="Login Model"
      >
        <Grid container sx={style}>
          <Grid
            item
            xs={0}
            sm={6}
            md={6}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Box
              className="registerSideLogo"
              sx={{
                backgroundImage: `url(${SideImage})`,
                backgroundPosition: "right",
                width: "100%",
                position: "relative"
              }}
            >
              <Box
                sx={{
                  padding: "0px",
                  height: "98%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between"
                }}
              >
                <img
                  src={logo}
                  width="100px"
                  alt="Brand Logo"
                  style={{ padding: "12px 0 0 15px" }}
                />
              </Box>
              <Typography
                variant="subtitle2"
                component="span"
                sx={{ color: "white", position: "absolute", bottom: "10px" }}
                style={{ padding: "0 0 15px 15px" }}
              >
                GenBov @ 2021. All Rights Reserved.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} xl={6} sm={6} md={6} sx={{ width: "100%", height: "100%", overflowY: "auto" }}>
            <Grid container direction="column" alignContent="flex-end">
              <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  style={{ position: "absolute", right: "10px", top: "5px" }}
              >
                <img
                    src={cross}
                    width="15px"
                    alt="close Button"
                    sx={{ padding: "10px" }}
                />
              </IconButton>
            </Grid>
            {formType === "register" ? (
              <RegisterForm
                setFormType={setFormType}
                setOpenRegisterModel={setOpenRegisterModel}
                setOpenSuccessModel={setOpenSuccessModel}
              />
            ) : null}
            {formType === "registerVendor1" ? (
              <RegisterVendorForm1
                setFormType={setFormType}
                setVendorRegistration={setVendorRegistration}
              />
            ) : null}
            {formType === "registerVendor2" ? (
              <RegisterVendorForm2
                setFormType={setFormType}
                setOpenRegisterModel={setOpenRegisterModel}
                setOpenSuccessModel={setOpenSuccessModel}
                vendorRegistration={vendorRegistration}
              />
            ) : null}
            {formType === "registerVendor3" ? (
              <RegisterVendorForm3
                setOpenRegisterModel={setOpenRegisterModel}
                setOpenSuccessModel={setOpenSuccessModel}
                vendorRegistration={vendorRegistration}
              />
            ) : null}
            {formType === "shippingInfo" ? (
              <ShippingAddress
                setOpenRegisterModel={setOpenRegisterModel}
                setOpenSuccessModel={setOpenSuccessModel}
                vendorRegistration={vendorRegistration}
              />
            ) : null}
            {formType === "success" ? (
              <SuccessMessage setFormType={setFormType} />
            ) : null}
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openSuccessModel}
        onClose={handleCloseSuccess}
        aria-labelledby="Login Model"
        aria-describedby="Login Model"
      >
        <Grid sx={SuccessModalStyle} container>
          <img
            src={thankyou}
            width="300px"
            height="150px"
            alt="Brand Logo"
            sx={{ alignSelf: "center" }}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold", marginTop: "30px", alignSelf: "center" }}
          >
            Thank You for registering
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ marginTop: "10px", alignSelf: "center" }}
          >
            We will review your profile and
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ marginBottom: "30px", alignSelf: "center" }}
          >
            you'll get an email with the details soon
          </Typography>
          <ColorButton
            variant="contained"
            color="secondary"
            sx={{ width: "90%" }}
            onClick={() => {
              history.push("/vendor/profile");
            }}
          >
            Back To Home
          </ColorButton>
        </Grid>
      </Modal>
    </Fragment>
  );
};

export default withRouter(Register);
