import {createStore,applyMiddleware,compose} from "redux";
import thunk from "redux-thunk";
import {reducers} from './reducer'

const Redux_Dev_tool = (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose
const store = createStore(
    reducers,
    compose(applyMiddleware(thunk),
        Redux_Dev_tool
    )
)

export default store