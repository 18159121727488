import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
    baseURL: baseURL,
    timeout: 5000
});

API.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

export const getCatalogeInfo = async (id) => {
    try {
        return await API.get(`/bx_block_catalogue/catalogues/product_detail?id=${id}`);
    } catch (e) {
        return e.response;
    }
};

export const handleBuy = async (id,qty) => {
    try {
        return await API.post(`/bx_block_order_management/orders`,{
            catalogue_id:id,
            quantity:qty,
            checkout_action:'buy'
        });
    } catch (e) {
        return e.response;
    }
};
