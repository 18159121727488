import axios from "axios";
import {baseURL} from "framework/src/config";

// API Initialization
const API = axios.create({
    baseURL: `${baseURL}/bx_block_catalogue/catalogues/`
});

// API Configuration for token set in headers
API.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

// get List of catalog
export const catalogList = async (page,count) => {
    try {
        return await API.get(`/product_listing?per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

// get list of catalog by category
export const catalogListByCategory = async (page,count,category) => {
    try {
        return await API.get(`/product_listing?per=${count}&page=${page}&category_name=${category}`);
    } catch (e) {
        return e.response;
    }
}

// get list of catalog by sub category
export const catalogListBySubCategory = async (page,count,category,subCategory) => {
    try {
        return await API.get(`/product_listing?per=${count}&page=${page}&category_name=${category}&sub_category_name=${subCategory}`);
    } catch (e) {
        return e.response;
    }
}

// get list of catalog by search
export const catalogListBySearch = async (page,count,search) => {
    try {
        return await API.get(`/search_product?per=${count}&page=${page}&search=${search}`);
    } catch (e) {
        return e.response;
    }
}