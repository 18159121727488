import React from 'react'
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Checkbox from "@mui/material/Checkbox";

export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:{
        xs:'100%',
        md:'1000px'
    },
    height: '650px',
    bgcolor: 'background.paper',
    boxShadow: 20
};

export const SuccessModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md:'450px',
        xs:'100%'
    },
    height: '450px',
    bgcolor: 'background.paper',
    overflow:'hidden',
    boxShadow: 20,
    borderRadius:'10px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
};

export const flexBox = {
    display:'flex',
    flexDirection:'row',
    alignContent:'space-between',
    justifyContent:'space-between',
    margin:'20px 0px',
    width: '90%'
}

export const loginFormFlex = {
    display:'flex',
    flexDirection:'column',
    alignContent:'center',
    justifyContent:'center',
    margin:'20px 0px',
}

export const registerTypes = {
    width:'95%',
    height:'150px',
    marginBottom:'40px',
    backgroundColor:'#F0F0F0',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    cursor:'pointer'
}

export const registerStpes = {
    height:'20px',
    width:'20px',
    borderRadius:'40px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
}
export const registerStpesLine = {
    height:'2px',
    width:'50px',
    borderRadius:'40px',
    display:'flex',
    justifyContent:'center',
    margin:'0px 5px',
    alignItems:'center',
}

export const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#472264'),
    borderRadius:'10px',
    height:'50px',
    backgroundColor: '#472264',
    '&:hover': {
        backgroundColor: '#693A8E',
    },
}))

export const ColorCheckBox = styled(Checkbox)(({theme}) => ({
    color: "#D6B336",
    borderRadius:'50%',
    "&:checked": {
        color: "#D6B336"
    },
    checked: {
        color: "#D6B336"
    }
}))

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="right" arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));