import React from 'react';
import {Grid, Typography, Button, CardMedia, CardContent} from "@mui/material";
import {SuccessCheck} from './assets'
import {withRouter} from "react-router-dom";
import {setLoginModelTrue} from "./assets";
import {useDispatch} from "react-redux";

const EmailSent = (props) => {
    const dispatch = useDispatch()
    const token =  localStorage.getItem("authToken")
    const profile = JSON.parse(localStorage.getItem("profile"))

    const manageNavigation = () => {
        if(profile.user_role === "buyer"){
            props.history.push("/CustomisableUserProfiles")
        }else if(profile.user_role === "vendor"){
            props.history.push("/vendor/profile")
        }else if(profile.user_role === "super_administrator" || profile.user_role === "administrator"){
            props.history.push("/admin/profile")
        }
    }

    const manageLoginPath = () => {
        props.history.push("/")
        dispatch(setLoginModelTrue())
    }

    return (
        <Grid container sx={{ height : '100%'}}>
            <Grid sx={{ height : '100%', padding : '15px'}} item xs={12} display="flex" alignItems="center" justifyContent="center">
                <Grid  sx={{ maxWidth: '100%', textAlign : 'center'}}>
                    <CardMedia
                        component="img"
                        alt="right arrow"
                        height="auto"
                        width="auto"
                        image={SuccessCheck}
                        sx={{ maxWidth : '80px', minWidth : '80px', margin : '0 auto'}}
                    />
                    <CardContent  sx={{ width:"100%", paddingLeft : '0'}}>
                        <Typography gutterBottom variant="h5" component="div" color="#472264" sx={{ fontWeight : 'bold'}}>
                            Email Verification Successful
                        </Typography>
                        <Typography variant="subtitle1" color="#693A8E">
                            {
                                token ?
                                    "Your email verification is successful click on button to proceed further" :
                                    "Your email verification is successful click on button to proceed for login"
                            }
                        </Typography>
                        <Grid xs={12} sx={{ paddingTop:'15px'}}>
                            {
                                token ?
                                    <Button onClick={manageNavigation} variant="contained" size="large" sx={{ color : '#fff',background: '#693A8E', fontWeight : 'bold', width:'100%', "&:hover": { background : '#472264bf', color : '#fff'} }} >Go To Profile</Button> :
                                    <Button onClick={manageLoginPath} variant="contained" size="large" sx={{ color : '#fff',background: '#693A8E', fontWeight : 'bold', width:'100%', "&:hover": { background : '#472264bf', color : '#fff'} }} >Login</Button>
                            }
                        </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(EmailSent);