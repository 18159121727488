import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const style = {
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 20,
    width:{
        sx:'100%',
        md:'900px'
    }
};

export const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#472264'),
    borderRadius:'10px',
    height:'50px',
    backgroundColor: '#472264',
    '&:hover': {
        backgroundColor: '#693A8E',
    },
}))

export const flexBox = {
    display:'flex',
    flexDirection:'row',
    alignContent:'space-between',
    justifyContent:'space-between',
    margin:'20px 0px',
    width: '90%'
}

export const loginFormFlex = {
    display:'flex',
    flexDirection:'column',
    alignContent:'center',
    justifyContent:'center',
    margin:'20px 0px',
}

export const registerTypes = {
    width:'95%',
    height:'160px',
    marginBottom:'25px',
    backgroundColor:'#F0F0F0',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    cursor:'pointer',
    borderRadius: '10px'
}

export const registerArrow = {
    height:'30px',
    width:'30px',
    marginRight:'15px'
}


















