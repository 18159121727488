import React from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";
import { darkLogo } from "../../blocks/email-account-login/src/assets";
import Loader from "react-loader-spinner";

const BuyerPage = ({ Component }) => {
  // loading management for buyer page
  const { isLoading, loaderCounter } = useSelector(
    (state) => state.landingPage
  );

  return (
    <>
      <Box sx={!isLoading ? { display: "none" } : null}>
        <Box
          style={{
            height: "100vh",
            width: "100vw",
            flexDirection: "column",
            backgroundColor: "White",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={darkLogo} width="250px" style={{ margin: "30px" }} />
          <Loader
            type={"ThreeDots" || "MutatingDots"}
            color="#472264"
            secondaryColor="#D6B336"
            height={100}
            width={100}
          />
          <Box sx={{ width: "100%", position: "absolute", top: "0px" }}>
            <LinearProgress
              variant="determinate"
              value={loaderCounter * 10}
              sx={{ height: "7px", barColorPrimary: "yellow" }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={isLoading ? { display: "none" } : null}>
        <TopNav />
        <Component />
        <Footer />
      </Box>
    </>
  );
};

export default BuyerPage;
