import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

interface secFourInterface {
  open: boolean;
  handleClose: () => void;
  fileUrl1: string | ArrayBuffer | null;
  fileUrl2: string | ArrayBuffer | null;
  img1: string | ArrayBuffer | undefined;
  img2: string | ArrayBuffer | undefined;
  title1: string;
  title2: string;
  link1: string;
  link2: string;
}

export default function SectionFourPreview({
  open,
  handleClose,
  fileUrl1,
  fileUrl2,
  title1,
  title2,
  img1,
  img2,
  link1,
  link2,
}: secFourInterface) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent dividers={true}>
        <CancelIcon
          fontSize="large"
          sx={styles.cancelIcon}
          onClick={handleClose}
        />
        <Box sx={styles.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={styles.boxContainer}>
                <img
                  src={fileUrl1 ? (fileUrl1 as any) : (img1 as any)}
                  className="section4image"
                  alt="landing page"
                />
                <Box sx={styles.titleContainer}>
                  <Typography variant="h4" color="white" sx={styles.title}>
                    {title1}
                  </Typography>
                  <Link to={link1} style={{ textDecoration: "none" }}>
                    <Button sx={styles.btn}>Show More</Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={styles.boxContainer}>
                <img
                  src={fileUrl2 ? (fileUrl2 as any) : (img2 as any)}
                  className="section4image"
                  alt="landing page"
                />
                <Box sx={styles.titleContainer}>
                  <Typography variant="h4" color="white" sx={styles.title}>
                    {title2}
                  </Typography>
                  <Link to={link2} style={{ textDecoration: "none" }}>
                    <Button sx={styles.btn}>Show More</Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const styles = {
  cancelIcon: {
    position: "absolute",
    top: "10px",
    right: "15px",
    color: "#d6b336",
    cursor: "pointer",
    zIndex: "999",
  } as const,
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "end",
    justifyContent: "space-around",
    padding: "45px 0",
  } as const,
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as const,
  titleContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
    justifyContent: "space-between",
    alignItems: "center",
    background: "#6a3a8e",
    width: "100%",
    padding: "15px",
    marginTop: "-1px",
    borderTop: {
      xs: "10px solid #d6b236",
      sm: "20px solid #d6b236",
    },
  } as const,
  title: {
    fontWeight: "bold",
    textAlign: {
      xs: "center",
      sm: "left",
    },
    fontSize: {
      xs: "1.5rem",
      lg: "2.125rem",
    },
  } as const,
  btn: {
    backgroundColor: "#d6b236",
    color: "black",
    borderRadius: "4px",
    fontWeight: "bold",
    padding: {
      xs: "5px",
      lg: "12px 20px",
    },
    marginTop: {
      xs: "5px",
      sm: 0,
    },
    "&:hover": {
      backgroundColor: "#d6b236"
    }
  },
};
