import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Box,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Stack,
  Pagination,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { withRouter } from "react-router-dom";

import {
  searchImg,
  getUsersList,
  setCurrentPage,
  setShowCount,
  setSearchKey,
  setSearchText,
  getUsersListBySearch,
} from "./assets";
import { API } from "./RolesPermissions2.web";
import UsersTable from "./UsersTable";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import {
  setCurrentPageVendor,
  setShowCountVendor,
} from "../../UserGroups/src/assets";
import LoaderPage from "../../../components/src/LoaderPage";
import NoRecordPage from "../../../components/src/NoRecordPage";
import Grid from "@mui/material/Grid";

const GET_ADMIN_URL = "bx_block_dashboard/roles_and_permissions/admin_users";
const GET_BUYER_URL = "bx_block_dashboard/buyers";
const GET_VENDOR_URL = "bx_block_dashboard/vendors";

export interface User {
  id: number;
  attributes: {
    full_name: string;
    email: string;
  };
}

// @ts-ignore
function ViewUsers(props) {
  // @ts-ignore
  const {
    usersList,
    currentPage,
    showCount,
    pagination,
    searchKey,
    searchText,
    isLoading,
  } = useSelector((state: any) => state.rolesAndPermission);
  // @ts-ignore
  const {vendorPermission,buyerPermission} = useSelector(state => state.landingPage)
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken") || "";
  const [role, setRole] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [searchPhrase, setSearchPhrase] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [url, setUrl] = useState("");
  const [showAddButton, setShowAddButton] = useState(true);
  const [initiatedSearch, setInitiatedSearch] = useState(false);

  // Set url to load data from corresponding api and Show button set as per role
  useEffect(() => {
    if (role && role === "Administrator") {
      setUrl(GET_ADMIN_URL);
    } else if (role && role === "Buyer") {
      setUrl(GET_BUYER_URL);
      if(!buyerPermission){
        setShowAddButton(false)
      }
    } else if (role && role === "Vendor") {
      setUrl(GET_VENDOR_URL);
      if(!vendorPermission){
        setShowAddButton(false)
      }
    }
  }, [role]);

  // @ts-ignore
  const managePagination = (event, value) => {
    dispatch(setCurrentPage(props.location.state.role, showCount, value));
  };

  // Search
  const handleSearch = () => {
    if (searchKey && searchText) {
      setInitiatedSearch(true);
      dispatch(
        getUsersListBySearch(
          props.location.state.role,
          showCount,
          1,
          searchKey,
          searchText
        )
      );
    }
  };

  // Clear search
  const clearSearch = () => {
    setInitiatedSearch(false);
    setSearchBy("");
    setSearchPhrase("");
    dispatch(getUsersList(props.location.state.role, showCount, 1));
  };

  // Set search by
  useEffect(() => {
    dispatch(setSearchKey(searchBy));
  }, [searchBy]);

  // Set search phrase
  useEffect(() => {
    dispatch(setSearchText(searchPhrase));
  }, [searchPhrase]);

  // Set role to load data
  useEffect(() => {
    setRole(props.location.state.role);
    dispatch(getUsersList(props.location.state.role, showCount, 1));
  }, [props.location.state.role]);

  return (
    <>
      {/* Role */}
      <Typography sx={styles.role}>{role}</Typography>
      <Box
        // ts-ignore
        sx={styles.addUserContainer}
      >
        <Typography>
          Roles & Permissions /{" "}
          <span style={{ color: "#693a8e" }}>View Users</span>
        </Typography>
      </Box>
      {/* Select & search */}
      <Grid container sx={styles.searchSelectContainer}>
        <Grid xs={12} sm={6} sx={{marginBottom: 1}}>
          {/* search */}
          <Stack direction="row">
            <FormControl size="small" sx={styles.formControl}>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                  labelId="select-label-id"
                  id="select-id"
                  value={searchBy}
                  label="Select"
                  onChange={(e) => setSearchBy(e.target.value)}
                  sx={styles.select}
              >
                <MenuItem value={"full_name"}>Name</MenuItem>
                <MenuItem value={"email"}>Email</MenuItem>
              </Select>
            </FormControl>
            <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                size="small"
                value={searchPhrase}
                onChange={(e) => setSearchPhrase(e.target.value)}
                sx={styles.textField}
                InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                        {initiatedSearch && (
                            <IconButton onClick={clearSearch}>
                              <CloseIcon />
                            </IconButton>
                        )}
                        <IconButton onClick={handleSearch}>
                          <img src={searchImg} alt="search" />
                        </IconButton>
                      </InputAdornment>
                  ),
                }}
            />
          </Stack>
        </Grid>
        <Grid xs={12} sm={6} sx={{display:'flex',justifyContent: {xs:"flex-start",sm:"flex-end"}}}>
          {
              showAddButton &&
              <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={styles.addUserBtn}
                  onClick={() => props.history.push("AddUser", { role: role })}
              >
                Add User
              </Button>
          }
        </Grid>
      </Grid>
      {/* users table */}
      {isLoading ? (
        <LoaderPage />
      ) : usersList.length !== 0 ? (
        <UsersTable users={usersList} role={role} />
      ) : (
        <NoRecordPage text={`Sorry, no users found!`} />
      )}
      <Grid container
        // ts-ignore
        sx={styles.searchSelectContainer}
      >
        <Grid xs={12} sm={6}>
          <FormControl size="small" sx={{ width: "150px",marginBottom:1 }}>
            <InputLabel id="bulk-actions">Show:</InputLabel>
            <Select
                labelId="bulk-actions"
                id="demo-simple-select"
                value={showCount}
                label="Show:"
                onChange={(e) =>
                    dispatch(
                        setShowCount(props.location.state.role, e.target.value, 1)
                    )
                }
                sx={{ background: "#fff" }}
                disabled={usersList.length === 0}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} sx={{display:'flex',justifyContent: {xs:"flex-start",sm:"flex-end"}}}>
          <Stack spacing={5}>
            <Pagination
                count={pagination.total_pages}
                page={parseInt(currentPage)}
                hidePrevButton
                color="secondary"
                variant="outlined"
                shape="rounded"
                onChange={managePagination}
                sx={styles.pagination}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(ViewUsers);

const styles = {
  role: { fontWeight: "bold", fontSize: "20px", color: "#693a8e" },
  addUserContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addUserBtn: {
    background: "#693a8e",
    borderColor: "#693a8e",
    "&:hover": { background: "#693a8e" },
  },
  btn: {
    background: "#693a8e",
    borderRadius: "4px",
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "100%",
    "&:hover": { background: "#693a8e" },
  },
  searchSelectContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 2,
  },
  formControl: {
    width: {
      xs: 150,
      md: 200,
    },
    ".MuiInputBase-root": {
      lineHeight: "1.7em",
    },
    ".MuiOutlinedInput-input": {
      paddingLeft: "10px",
    },
  },
  select: {
    background: "#fff",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  textField: {
    background: "#fff",
    width: {
      xs: 150,
      md: 200,
    },
    "& fieldset": {
      borderLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    ".MuiOutlinedInput-input": {
      height: "1.7em",
      paddingLeft: "10px",
    },
  },
  pagination: {
    "& .Mui-selected": {
      color: "#fff !important",
    },
    "& .MuiPaginationItem-root:hover": {
      backgroundColor: "#693A8E",
    },
  },
};
