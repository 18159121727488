import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import { productImage } from "./assests";
import { Box } from "@mui/material";
import {noimg} from "../../catalogue/src/assets"


export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <Box className="sliderWidth" sx={{minHeight:'550px'}}>
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          arrows={false}
        >
          {
            this?.props?.prodImages ?
            this?.props?.prodImages?.map((prod,index)=> {
              return(
                  <div key={index} style={{height:"360px"}}>
                    <img src={prod.url} width="100%" height="350px" alt="landing page" />
                  </div>
              )
            }) : <div>
                  <img src={noimg} width="100%" height="350px" alt="landing page" style={{objectFit: 'cover'}}/>
                </div>
          }
        </Slider>
        {
          this?.props?.prodImages?.length > 1 ?
              <Slider
                  asNavFor={this.state.nav1}
                  ref={slider => (this.slider2 = slider)}
                  slidesToShow={this?.props?.prodImages?.length}
                  swipeToSlide={true}
                  focusOnSelect={true}
              >
                {
                  this?.props?.prodImages?.map((prod,index)=> {
                    return(
                        <div key={index}>
                          <h3 style={{ display: 'flex', justifyContent: 'center' }} key={index}>
                            <img src={prod.url} width={this?.props?.prodImages?.length > 3 ? "90%" : "55%" } alt="prod image" style={{height:"70px",objectFit:"cover"}}/>
                          </h3>
                        </div>
                    )
                  })
                }
              </Slider> : null
        }
      </Box>
    );
  }
}
