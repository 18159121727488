import React from 'react';
import Box from "@mui/material/Box";
import {logoDark} from "./importAssets";
import {Typography} from "@mui/material";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
// @ts-ignore
const NoRecordPage = () => {
  return (
    <Box
      style={{
        height: '100vh',
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'White',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
            <img src={logoDark} width="350px" style={{margin: "30px"}} />
            <SentimentDissatisfiedIcon sx={{ fontSize: 200,color:"gray" }}/>
            <Typography variant="h1" sx={{color:"gray"}}>404</Typography>
            <Typography variant="h4" sx={{color:"gray"}}>This page doesn't exist. </Typography>
    </Box>
  );
};

export default NoRecordPage;
