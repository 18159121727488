// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { Switch } from 'react-router-dom';
import { connect } from 'react-firebase';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import UserGroups from '../../blocks/UserGroups/src/UserGroups';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';

import InfoPage from '../../blocks/info-page/src/ProductInfo';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';

import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import CfMercadoPagoPaymentGateway2 from '../../blocks/CfMercadoPagoPaymentGateway2/src/CfMercadoPagoPaymentGateway2';
import ForgotPassword from '../../blocks/forgot-password/src/forgotpass';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/ResatPass';
import PhotoLibrary3 from '../../blocks/PhotoLibrary3/src/PhotoLibrary3';
import VisualAnalytics from '../../blocks/VisualAnalytics/src/VisualAnalytics';
import SingleSignonsso from '../../blocks/SingleSignonsso/src/SingleSignonsso';
import ShoppingCart from '../../blocks/ShoppingCart/src/ShoppingCart.web';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles.web';
import OrderSummary from '../../blocks/OrderSummary/src/OrderSummary.web';
import OrderSuccess from '../../blocks/OrderSummary/src/OrderSuccess';
import OrderOnlineStatus from '../../blocks/OrderSummary/src/OrderOnlineStatus';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import CatalogueSearch from '../../blocks/catalogue/src/CatalogueSearch';
import CatalogueCategory from '../../blocks/catalogue/src/CatalogueCatagories';
import CatalogueSubCategory from '../../blocks/catalogue/src/CatalogueSubCatagories';
import VendorCatalogue from '../../blocks/catalogue/src/VendorCatalogue';
import OrderCatalogue from '../../blocks/ordermanagement/src/Orders';
import AddProduct from '../../blocks/catalogue/src/add-edit-product/AddProduct';
import OrderDetailScreen from '../../blocks/ordermanagement/src/OrderDetailsComponent.web';
import UsersPageVendor from '../../blocks/UserGroups/src/UsersPageVendor';
import VendorUsersPageAdmin from '../../blocks/UserGroups/src/VendorUsersPageAdmin';
import VendorProfile from '../../blocks/UserGroups/src/VendorProfile';
import BuyerUsersPageAdmin from '../../blocks/UserGroups/src/BuyerUsersPageAdmin';
import BuyerDetailPage from '../../blocks/UserGroups/src/BuyerDetailPage';
import BuyerProductDetail from '../../blocks/catalogue/src/BuyerProductDetail';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Dashboard from '../../blocks/dashboard/src/Dahboard.web';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import Payment from '../../blocks/ordermanagement/src/Payment';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2.web';
import ViewUsers from '../../blocks/RolesPermissions2/src/ViewUsers';
import AddUser from '../../blocks/RolesPermissions2/src/AddUser';
import ChangeRolePermissions from '../../blocks/RolesPermissions2/src/ChangeRolePermissions';
import ChangeUserPermissions from '../../blocks/RolesPermissions2/src/ChangeUserPermissions';
import LandingPage from '../../blocks/landingpage/src/LandingPage.web';
import CfGoogleAnalyticsIntegration3 from '../../blocks/CfGoogleAnalyticsIntegration3/src/CfGoogleAnalyticsIntegration3';
import ImageManagement from '../../blocks/ImageManagement/src/ImageManagement';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement.web';
import Notifications from '../../blocks/Notifications/src/Notifications.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/Login';
import TermsAndConditons from '../../blocks/email-account-login/src/Terms';
import Privacy from '../../blocks/email-account-login/src/Privacy';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import CfInventoryManagement10 from '../../blocks/CfInventoryManagement10/src/CfInventoryManagement10';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/Register';
import EmailVerification from '../../blocks/email-account-registration/src/EmailVerification';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories.web';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import FirmTabs from '../../blocks/CustomisableUserProfiles/src/FirmDetails';
import BuyerProfile from '../../blocks/CustomisableUserProfiles/src/BuyerProfile';
import ChangePasswordForm from '../../blocks/CustomisableUserProfiles/src/ChangePasswordForm';
import NoRoutePage from '../../components/src/NoRoutePage';

const routeMap = {
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  // UserGroups: {
  //   component: UserGroups,
  //   path: '/UserGroups'
  // },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  CfMercadoPagoPaymentGateway2: {
    component: CfMercadoPagoPaymentGateway2,
    path: '/CfMercadoPagoPaymentGateway2'
  },
  // core:{
  //  component:core,
  // path:"/core"},
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword/:id'
  },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: '/PhotoLibrary3'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  SingleSignonsso: {
    component: SingleSignonsso,
    path: '/SingleSignonsso'
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: '/ShoppingCart'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  OrderSummary: {
    component: OrderSummary,
    path: '/OrderSummary/:id'
  },
  OrderSuccess: {
    component: OrderSuccess,
    path: '/OrderStatus/:id'
  },
  OrderSuccessQuery: {
    component: OrderOnlineStatus,
    path: '/OrderStatus'
  },
  Payment: {
    component: Payment,
    path: '/Admin/Payments',
    name: 'Payment Options',
    role: 'admin'
  },
  CatalogueSearch: {
    component: CatalogueSearch,
    path: '/Catalogue/Search/:text',
    exact: true
  },
  CatalogueSubCategory: {
    component: CatalogueSubCategory,
    path: '/Catalogue/:cat/:sub'
  },
  CatalogueCategory: {
    component: CatalogueCategory,
    path: '/Catalogue/:cat'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  DashboardVendor: {
    component: Dashboard,
    path: '/Vendor/Dashboard',
    role: 'vendor',
    name: 'Dashboard'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Admin/Dashboard',
    role: 'admin',
    name: 'Dashboard'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  // Contentmanagement: {
  //   component: ContentManagement,
  //   path: '/Admin/cm',
  //   role: 'admin',
  //   name: 'Dashboard'
  // },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/Admin/RolesPermissions',
    role: 'admin',
    exact: true,
    name: 'Roles & Permissions'
  },
  ViewUsers: {
    component: ViewUsers,
    path: '/Admin/RolesPermissions/ViewUsers',
    role: 'admin',
    showBackBtn: true,
    exact: true
  },
  AddUser: {
    component: AddUser,
    path: '/Admin/RolesPermissions/AddUser',
    role: 'admin',
    showBackBtn: true
  },
  ChangeRolePermissions: {
    component: ChangeRolePermissions,
    path: '/Admin/RolesPermissions/ChangePermissions',
    role: 'admin',
    showBackBtn: true
  },
  ChangeUserPermissions: {
    component: ChangeUserPermissions,
    path: '/Admin/RolesPermissions/ViewUsers/ChangePermissions',
    role: 'admin',
    showBackBtn: true
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  CfGoogleAnalyticsIntegration3: {
    component: CfGoogleAnalyticsIntegration3,
    path: '/CfGoogleAnalyticsIntegration3'
  },
  ImageManagement: {
    component: ImageManagement,
    path: '/ImageManagement'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/Admin/ContentManagement/section/:id',
    role: 'admin',
    name: 'Content Management'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },

  NotificationsVendor: {
    component: Notifications,
    path: '/vendor/Notifications',
    role: 'vendor',
    name: 'Notifications'
  },

  // EmailAccountLoginBlock: {
  //   component: EmailAccountLoginBlock,
  //   path: '/EmailAccountLoginBlock'
  // },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  CfInventoryManagement10: {
    component: CfInventoryManagement10,
    path: '/CfInventoryManagement10'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration/:type'
  },
  EmailVerification: {
    component: EmailVerification,
    path: '/EmailVerification/:type'
  },
  EmailVerificationAdmin: {
    component: EmailVerification,
    path: '/admin/EmailVerification/:type',
    role: 'admin',
    name: 'Dashboard'
  },
  EmailVerificationVendor: {
    component: EmailVerification,
    path: '/vendor/EmailVerification/:type',
    role: 'vendor',
    name: 'Dashboard'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Admin/Categoriessubcategories',
    role: 'admin',
    name: 'Category'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage/:id'
  },
  TermsAndConditions: {
    component: TermsAndConditons,
    path: '/terms'
  },
  PrivacyAndPolicy: {
    component: Privacy,
    path: '/privacy'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  BuyerProductDetail: {
    component: BuyerProductDetail,
    path: '/vendor/myProducts/ViewProduct/:id',
    role: 'vendor',
    showBackBtn: true
    // name:"Buyer"
  },
  AdminBuyerProductDetail: {
    component: BuyerProductDetail,
    path: '/admin/Vendors/ViewProduct/:id',
    role: 'admin',
    showBackBtn: true
    // name:"Buyer"
  },
  vendorCatalogue: {
    component: VendorCatalogue,
    path: '/vendor/myProducts',
    role: 'vendor',
    name: 'My Products',
    exact: true
  },
  AddProduct: {
    component: AddProduct,
    path: '/vendor/myProducts/addProduct',
    role: 'vendor',
    showBackBtn: true
    // name: 'Add Product'
  },
  EditProduct: {
    component: AddProduct,
    path: '/vendor/myProducts/editProduct',
    role: 'vendor',
    showBackBtn: true
    // name: 'Add Product'
  },
  AdminAddProduct: {
    component: AddProduct,
    path: '/admin/Vendors/Products/addProduct',
    role: 'admin',
    exact: true,
    showBackBtn: true
    // name: 'Add Product'
  },
  AdminEditProduct: {
    component: AddProduct,
    path: '/admin/Vendors/Products/editProduct',
    role: 'admin',
    exact: true,
    showBackBtn: true
  },
  AdminVendorOrderDetailScreen: {
    component: OrderDetailScreen,
    path: '/admin/Vendors/Orders/orderDetailScreen/:id',
    role: 'admin',
    showBackBtn: true
  },
  VendorProfile: {
    component: VendorProfile,
    path: '/admin/Vendors/ViewProfile/:id',
    role: 'admin',
    showBackBtn: true
  },
  VendorProducts: {
    component: VendorCatalogue,
    path: '/admin/Vendors/Products/:id',
    role: 'admin',
    showBackBtn: true
  },
  VendorOrders: {
    component: OrderCatalogue,
    path: '/admin/Vendors/Orders/:id',
    role: 'admin',
    showBackBtn: true
  },
  OrderDetailScreen: {
    component: OrderDetailScreen,
    path: '/vendor/orders/orderDetailScreen/:id',
    role: 'vendor',
    exact: true,
    showBackBtn: true
  },
  OrderCatalogue: {
    component: OrderCatalogue,
    path: '/vendor/orders',
    role: 'vendor',
    name: 'Orders'
  },
  AdminOrderDetailScreen: {
    component: OrderDetailScreen,
    path: '/admin/orders/orderDetailScreen/:id',
    role: 'admin',
    showBackBtn: true
  },
  AdminOrderCatalogue: {
    component: OrderCatalogue,
    path: '/admin/orders',
    role: 'admin',
    name: 'Orders'
  },
  BuyerDetailPage: {
    component: BuyerDetailPage,
    path: '/vendor/Buyers/buyerDetail/:id',
    role: 'vendor',
    name: '',
    showBackBtn: true
  },
  BuyerDetailPageAdmin: {
    component: BuyerDetailPage,
    path: '/admin/Buyers/buyerDetail/:id',
    role: 'admin',
    name: '',
    showBackBtn: true
  },
  BuyerPage:{
    component:UsersPageVendor,
    path:"/vendor/buyers/",
    role:"vendor",
    name:"Buyer"
  },
  UsersPageVendor:{
    component:VendorUsersPageAdmin,
    path:"/admin/Vendors",
    role:"admin",
    name:"Vendors"
  },
  UsersPageBuyers:{
    component:BuyerUsersPageAdmin,
    path:"/admin/Buyers",
    role:"admin",
    name:"Buyers"
  },
  FirmDetails:{
    component:FirmTabs,
    path:"/vendor/profile",
    role:"vendor",
    name:"My Profile"
  },
  AdminProfile:{
    component:BuyerProfile,
    path:"/admin/profile",
    role:"admin",
    name:"My Profile"
  },
  AdminChangePassword:{
    component:ChangePasswordForm,
    path:"/admin/password",
    role:"admin",
    name:"Change Password"
  },
  NoRoutePage:{
    component:NoRoutePage,
    path:"*",
    role:"blank",
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    return (
      <View>
        <ToastContainer />
        <Switch>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </Switch>
        <EmailAccountLoginBlock />
      </View>
    );
  }
}

export default App;
