import React,{useState,useEffect,Fragment} from "react";
import "./style.css";
import Container from "@mui/material/Container";
import {Grid, Button, Typography, Divider, Modal, Box, CircularProgress, Alert} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CartItems from "./CartItems";
import {withRouter} from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";
import {removeAllCartItems, finalOrder, emptyCart,sentEmail,updateUserProfile,setLoginModelTrue} from "./assets";
import {toast} from "react-toastify";
import CartItemsNA from "./CartItemsNA";
// @ts-ignore
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


//@ts-ignore
const Cart = ({history}) => {
  // @ts-ignore
  const dispatch = useDispatch()
  // @ts-ignore
  const cartList = useSelector(state => state?.cart?.cart)
  // @ts-ignore
  const cartId = useSelector(state => state?.cart?.cartId)
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [finalAmount, setFinalAmount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleFinalizeOrder = async () => {
    setLoader(true)
    dispatch(updateUserProfile())
    //@ts-ignore
    const profile = JSON.parse(localStorage.getItem("profile"))
    if(profile){
      if(profile.email_confirmed){
        const {data} = await finalOrder()
        history.push(`/OrderSummary/${data.data?.order.data.id}`)
      }else{
        toast.error("Your Email id is not verified Please verify your email")
        handleOpen1()
      }
    }else{
      handleOpen()
    }
    setLoader(false)
  }
  useEffect(() => {
    let amount = 0
    // @ts-ignore
    cartList?.forEach((i)=>{
      if(i?.attributes?.status){
        if(i?.attributes?.status === "active")
        {
          const a = parseInt(i?.attributes.total_price)
          amount = amount +a
        }else{
          setIsError(true)
        }
      }else{
        const a = parseInt(i?.attributes.total_price)
        amount = amount +a
      }

    })
    setFinalAmount(amount)
  }, [cartList]);

  const manageResentEmail = async () =>{
    setIsLoading(true)
    const res = await sentEmail()
    if(res.data.success){
      toast.success("Verification Email sent successfully ")
      handleClose1()
    }else{
      toast.error("Something went wrong Please try again")
      handleClose1()
    }
    setIsLoading(false)
  }

  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);

  const manageLogin = () => {
    dispatch(setLoginModelTrue())
    setOpen(false)
  }
  return (
    <Fragment>
    <Grid>
      <Grid sx={{ background: "#fff", display: "flex", padding: "10px 0",maxHeight:"90px" }}>
        <Container>
          <Grid container>
            <Grid item xs={12} >
                <Button
                  size="large"
                  onClick={()=>history.goBack()}
                  sx={{ color: "#472264", fontWeight: "bold" }}
                  startIcon={
                    <ArrowBackIcon
                      className="back-btn"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  Continue Shopping
                </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid sx={{ background: "#EAEAEA", display: "flex", padding: "35px 0",minHeight:"800px" }}>
        <Container>
          {cartList.length > 0 ?
              <Grid item xs={12} sx={{ marginBottom: "15px" }}>
                <Typography variant="h4">Cart</Typography>
              </Grid>
              :null
          }
          {
            cartList.length > 0 &&
            isError &&
              <Alert severity="error" sx={{marginBottom:"10px",borderRadius:"15px"}}>Some of your cart items {cartList.length > 1 ? "are" : "is"} currently out of stock, It will removed automatically in finalize order page</Alert>
          }
          {
            cartList.length > 0 ?
                <Grid container display="flex" alignItems="baseline">
                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Box
                        sx={{
                          marginRight: { xs: "0", md: "20px" },
                          marginBottom: "30px"
                        }}>
                      <Grid
                          container
                          className="cart-header"
                          sx={{ boxShadow: 2 }}
                          display="flex"
                          alignItems="center"
                      >
                        <Grid item xs={6} sm={4}>
                          Items ({cartList?.length || 0})
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          Price
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          Quantity
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          Total Amount
                        </Grid>
                        <Grid item xs={6} sm={2}>
                          <Button
                              onClick={(e)=> {
                                e.preventDefault()
                                dispatch(removeAllCartItems(cartId))
                              }}
                              variant="contained"
                              size="small"
                              className="add-cart-btn"
                              sx={{ width: "100%", padding: "8px ​!important" }}
                          >
                            Remove
                          </Button>
                        </Grid>
                      </Grid>
                      {
                        // @ts-ignore
                        cartList?.map((item,key)=>{
                          if(item.attributes.status){
                            if(item?.attributes?.status === "active"){
                              return(
                                  <CartItems item={item} key={key}/>
                              )
                            }else {
                              return(
                                  <CartItemsNA item={item} key={key}/>
                              )
                            }
                          }else{
                            return(
                                <CartItems item={item} key={key}/>
                            )
                          }
                        })
                      }
                    </Box>
                  </Grid>
                  <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      sx={{
                        background: "#fff",
                        borderRadius: "15px",
                        padding: "15px",
                        boxShadow: 3
                      }}
                  >
                    <Button
                        onClick={handleFinalizeOrder}
                        variant="contained"
                        className="bg-purple-light"
                        sx={{ width: "100%", padding: "15px" }}
                    >
                      {loader ? <CircularProgress color="inherit" size={30}/> : "Finalize ORDER"}
                    </Button>
                    <Grid
                        container
                        sx={{ display: "flex", padding: "25px 0 10px 0" }}
                    >
                      <Grid item xs={5}>
                        <Typography variant="body2" fontWeight="700">
                          {cartList.length} items
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography
                            variant="body2"
                            fontWeight="700"
                            style={{ textAlign: "right" }}
                        >
                          $ {numberWithCommas(finalAmount)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ paddingTop: "15px" }}>
                        <Divider sx={{ margin: "5px 0" }} />
                      </Grid>
                      <Grid item xs={5} sx={{ paddingTop: "15px" }}>
                        <Typography
                            variant="body1"
                            fontWeight="700"
                            style={{ color: "#472264" }}
                        >
                          Total Amount
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ paddingTop: "15px" }}>
                        <Typography
                            variant="body1"
                            fontWeight="700"
                            style={{ textAlign: "right", color: "#472264" }}
                        >
                          $ {numberWithCommas(finalAmount)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> :
                <Box sx={{width:"100%",height:"100%",display:"flex",justifyContent:'center',alignItems:"center"}}>
                  <img className="emptyCart" src={emptyCart} alt="Empty Cart"/>
                </Box>
          }
        </Container>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {
          //@ts-ignore
          <Box sx={modalStyle}>
            <Typography
                variant="h6"
                component="h1"
                color="#472264"
                sx={{
                  borderBottom: "1px solid #b6b8b894",
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: "15px",
                  fontWeight: "bold"
                }}
            >
              Checkout
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              You need an account in order to checkout.
            </Typography>
            <Grid
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width="100%"
                sx={{ paddingTop: "25px" }}
            >

              <Button
                  variant="contained"
                  onClick={handleClose}
                  className="cancel-btn"
              >
                Cancel
              </Button>
              <Button
                  onClick={manageLogin}
                  variant="contained"
                  className="checkout-btn bg-purple-light"
              >
                Login Page
              </Button>
            </Grid>
          </Box>
        }
      </Modal>
      <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        {
          //@ts-ignore
          <Box sx={modalStyle1}>
            <Typography
                variant="h6"
                component="h1"
                color="#472264"
                sx={{
                  borderBottom: "1px solid #b6b8b894",
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: "15px",
                  fontWeight: "bold"
                }}
            >
              Your Email ID is not verified
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              You have to verify the email in order to checkout.
            </Typography>
            <Grid
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width="100%"
                sx={{ paddingTop: "25px" }}
            >
              <Button
                  variant="contained"
                  onClick={handleClose1}
                  className="cancel-btn"
              >
                Cancel
              </Button>
              <Button
                  onClick={manageResentEmail}
                  sx={{minWidth:"250px"}}
                  variant="contained"
                  className="checkout-btn bg-purple-light"
              >
                {
                  isLoading ? <CircularProgress size={25} /> : "Resent Verification email"
                }
              </Button>
            </Grid>
          </Box>
        }
      </Modal>
    </Grid>
    </Fragment>
  );
};

export default withRouter(Cart);

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "10px"
};

const modalStyle1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs:"100%",
    md:"500px"
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "10px"
};