const initialState = {
    vendors:[],
    vendorStatusCounts:{
        all_count:0,
        approved_count:0,
        pending_count:0,
        rejected_count:0,
    },
    vendorDetails:{},
    vendorDashboard:{},
    pagination:{},
    isLoading:false,
    isButtonLoadingApprove:false,
    isButtonLoadingReject:false,
    selected:[],
    currentPage:1,
    showCount:10,
    productList:[],
    productDetails:[],
    orderList:[],
    orderDetails: {},
    currentStatus:"all",
    searchKey:"",
    searchText:"",
}

export const vendorReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case "SET_VENDOR_LIST":
            const updatedData = payload.map((item) => {
                return{
                    ...item,
                    isChecked:false
                }
            })
            return {
                ...state,
                vendors: updatedData,
            }
        case "SET_VENDOR_STATUS_COUNT":
            return {
                ...state,
                vendorStatusCounts:payload
            }
        case "SET_VENDOR_PAGINATION":
            return {
                ...state,
                pagination: payload,
            }
        case "SET_VENDOR_CURRENT_FILTER":
            return {
                ...state,
                currentStatus: payload,
            }
        case "SET_VENDOR_SEARCH_KEY":
            return {
                ...state,
                searchKey: payload,
            }
        case "SET_VENDOR_SEARCH_TEXT":
            return {
                ...state,
                searchText: payload,
            }
        case "SET_LOADING_VENDOR_FALSE":
                return {
                ...state,
                isLoading:false
            }
        case "SET_LOADING_VENDOR_TRUE":
            return {
                ...state,
                isLoading:true
            }
        case "SET_BUTTON_LOADING_VENDOR_APPROVE_FALSE":
            return {
                ...state,
                isButtonLoadingApprove:false
            }
        case "SET_BUTTON_LOADING_VENDOR_APPROVE_TRUE":
            return {
                ...state,
                isButtonLoadingApprove:true
            }
        case "SET_BUTTON_LOADING_VENDOR_REJECT_FALSE":
            return {
                ...state,
                isButtonLoadingReject:false
            }
        case "SET_BUTTON_LOADING_VENDOR_REJECT_TRUE":
            return {
                ...state,
                isButtonLoadingReject:true
            }
        case "SET_VENDOR_DETAILS_PAGE":
            return {
                ...state,
                vendorDetails:payload,
            }
        case "SET_VENDOR_DASHBOARD_PAGE":
            return {
                ...state,
                vendorDashboard:payload
            }
        case "SET_CURRENT_VENDOR_PAGE":
            return {
                ...state,
                currentPage:payload
            }
        case "SET_SHOW_VENDOR_COUNT":
            return {
                ...state,
                showCount:payload
            }
        case "HANDLE_SELECT_VENDORS":
            const flag = state.selected.indexOf(payload)
            let updateSelectedArray = []
            if(flag !== -1)
            {
                updateSelectedArray = state.selected.filter((item)=>{
                    return item !== payload
                })

            }else{
                updateSelectedArray = [
                    ...state.selected,
                    payload
                ]
            }
            const updateList = state.vendors.map((item) => {
                if(item.id === payload) {
                    return{
                        ...item,
                        isChecked: !item.isChecked
                    }
                }else {
                    return item
                }
            })
            return {
                ...state,
                selected:updateSelectedArray,
                vendors: updateList
            }
        case "SELECT_ALL_VENDORS":
            let selectAll = []
            let selectedList = []
            if(state.selected.length > 0){
                selectedList = []
                selectAll = state.vendors.map((item)=>{
                    return{
                        ...item,
                        isChecked: false
                    }
                })
            }else{
                selectedList = state.vendors.map((item)=>{
                    return item.id
                })
                selectAll = state.vendors.map((item)=>{
                    return{
                        ...item,
                        isChecked: true
                    }
                })
            }
            return {
                ...state,
                vendors:selectAll,
                selected:selectedList
            }
        case "EMPTY_SELECTED_VENDOR":
            return {
                ...state,
                selected: []
            }
        default:
            return state
    }
}