const initialState = {
    catalog:[],
    pagination:{},
    isLoading:true,
    currentPage:1,
    showCount:20,
}

export const catalogReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case "SET_CATALOG_LIST":
            return {
                ...state,
                catalog: payload,
            }
        case "SET_CATALOG_PAGINATION":
            return {
                ...state,
                pagination: payload,
            }
        case "SET_CATALOG_LOADING_FALSE":
            return {
                ...state,
                isLoading:false
            }
        case "SET_CATALOG_LOADING_TRUE":
            return {
                ...state,
                isLoading:true
            }
        case "SET_CATALOG_CURRENT_PAGE":
            return {
                ...state,
                currentPage:payload
            }
        case "SET_CATALOG_SHOW_COUNT":
            return {
                ...state,
                showCount:payload
            }
        default:
            return state
    }
}