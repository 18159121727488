import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import { withRouter, Link } from 'react-router-dom';

import {User} from './ViewUsers';

interface Data {
  catName: string;
  subCats: string;
  showInMenu: boolean;
}

const PurpleSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#693a8e'
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#925BBD'
  }
}))

type Order = 'asc' | 'desc';
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;

  return (
    <TableHead sx={{background: '#472264'}}>
      <TableRow>
        <TableCell
          align="center"
          sx={{color: '#fff'}}
        >
          Name
        </TableCell>
        <TableCell
          align="center"
          sx={{color: '#fff'}}
        >
          Email id
        </TableCell>
        <TableCell
          align="center"
          sx={{color: '#fff', borderRadius: '0 8px 0 0'}}
        >
          Roles and Permissions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

// @ts-ignore
export default function UsersTable({role, users}) {
  const [order, setOrder] = React.useState<Order>('asc');
  // @ts-ignore
  const [orderBy, setOrderBy] = React.useState<keyof Data>('calories');
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // @ts-ignore
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    console.log('selected row')
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: '100%', marginTop: 2}}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{width:{
            md:"100%",
            sm:"700px",
            xs:"350px"
          }}}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="Categorytable"
            size='medium'
          >
            {/* @ts-ignore */}
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              rowCount={users.length}
            />
            <TableBody>
              {users.map((user: User) => (
                <TableRow
                  hover
                  // onClick={(event) => handleClick(event, row.catName)}
                  role="checkbox"
                  // aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={user.id}
                  // selected={isItemSelected}
                >
                  <TableCell align="center" >{user.attributes.full_name}</TableCell>
                  <TableCell align="center" >{user.attributes.email}</TableCell>
                  <TableCell align="center" >
                    <Link to={{
                      pathname: '/Admin/RolesPermissions/ViewUsers/ChangePermissions',
                      state: {
                        id: user.id,
                        name: user.attributes.full_name,
                        role: role,
                      }
                    }}>
                      CHANGE ROLES AND PERMISSIONS
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
