import React,{useState} from 'react';
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import {ColorButton, registerStpes, registerStpesLine} from "./Style";
import Dropzone from "./DropZone";
import {registrationVendor} from './api.js'
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

const RegisterForm = ({setFormType,vendorRegistration}) => {
    const [file, setFile] = useState();
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const token = window.localStorage.getItem('authToken')
    const handleSubmit = async () => {
        setIsLoading(true)
        if(file){
            const formData = new FormData()
            formData.append('name',vendorRegistration.name)
            formData.append('address_attributes[address_line1]',vendorRegistration.address_line1)
            formData.append('address_attributes[address_line2]',vendorRegistration.address_line2)
            formData.append('address_attributes[country]',vendorRegistration.country)
            formData.append('address_attributes[state]',vendorRegistration.state)
            formData.append('address_attributes[zipcode]',vendorRegistration.zipcode)
            formData.append('registration_id',file.registration_id)
            const data = await registrationVendor(formData,token)
            if(data.errors){
                setError(data.errors)
            }else{
                setFormType('registerVendor3')
            }
        }else{
            setError([
                {
                    message:'Please Select file'
                }
            ])
        }
        setIsLoading(false)
    }

    return (
        <Grid item xs={12} className='registerMainDiv' sx={{ padding : '50px 0'}}>
            <Box className='registerFormFlex'>
                <Typography  variant="h6" component="div" sx={{fontWeight:"bold"}}>
                    Vendor Registration in only 3 Steps
                </Typography>
                <Typography variant="subtitle1" component="div">
                    Provide your documents
                </Typography>
                <Box sx={{display: 'flex',flexDirection:'row',alignItems:'center',margin:'40px 0px'}}>
                    <Box sx={registerStpes} style={{backgroundColor:'purple'}}>

                    </Box>
                    <Box sx={registerStpesLine} style={{backgroundColor:'purple'}}>

                    </Box>
                    <Box sx={registerStpes} style={{backgroundColor:'purple'}}>

                    </Box>
                    <Box sx={registerStpesLine} style={{backgroundColor:'#F0F0F0'}}>

                    </Box>
                    <Box sx={registerStpes} style={{backgroundColor:'#F0F0F0'}}>

                    </Box>
                </Box>
                <Grid item xs={12}>
                    {
                        error ?
                                error.map((e,index)=>{
                                    return(
                                        <Alert key={index} severity="error" sx={{width:'82%',alignSelf:'center'}}>
                                            {e.message}
                                        </Alert>
                                    )
                                })
                             : null

                    }
                    <Typography variant="subtitle2" component="div" sx={{fontWeight:"bold",marginBottom:'10px',marginTop:'20px'}}>
                        Upload ID
                    </Typography>
                    <Dropzone setFile={setFile}/>
                    <Box>
                        <ColorButton variant="contained" color="secondary" sx={{width:"90%",height:'50px'}} onClick={handleSubmit}>{isLoading ? <CircularProgress color="inherit" /> : "NEXT"}</ColorButton>
                    </Box>
                </Grid>
            </Box>
        </Grid>
    );
};

export default RegisterForm;
