import React, { useEffect, useState } from "react";
import { getSection3, manageLoading, middleBanner } from "./assets";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @ts-ignore
const Section3 = ({ isLoading }) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { section3 } = useSelector((state) => state.landingPage);

  const manageLoader = () => {
    dispatch(manageLoading());
  };

  return (
    <Grid
      container
      sx={
        isLoading
          ? { display: "none" }
          : {
              width: "100%",
              height: "475px",
              backgroundImage: `url(${section3?.background_image?.url})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              display: "flex",
            }
      }
    >
      <Grid item xs={0} md={5} />
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          height:"100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "200px 20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography
            variant="h2"
            component="div"
            color="#6a3a8e"
            sx={{
              fontWeight: "bold",
              marginBottom: "30px",
              fontSize: { xs: "2.5rem", sm: "3.75rem" },
            }}
          >
            {section3.heading_title}
          </Typography>
          <Typography
            variant="h6"
            component="div"
            color="#6a3a8e"
            sx={{
              fontWeight: "bold",
              marginBottom: "30px",
              textAlign: "right",
              width: "80%",
            }}
          >
            {section3.description}
          </Typography>
          <Link to={section3?.link} style={{ textDecoration: "none" }}>
            <Button
              style={{
                width: "150px",
                height: "50px",
                backgroundColor: "#d6b236",
                color: "black",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
            >
              Show More
            </Button>
          </Link>
        </Box>
      </Grid>
      <img
        src={section3?.background_image?.url}
        onLoad={manageLoader}
        style={{ display: "none" }}
      />
    </Grid>
  );
};

export default Section3;
