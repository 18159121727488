import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import styles from "./styles";

interface IProps {
  categoryControl: any;
  categoryErrors: any;
  addToMenu: boolean;
  setAddToMenu: any;
  handleCategoryFormSubmit: any;
  handleCategoryCreation: any;
  isSavingCategory: boolean;
}

export default function CreateCategory({
  categoryControl,
  categoryErrors,
  addToMenu,
  setAddToMenu,
  handleCategoryFormSubmit,
  handleCategoryCreation,
  isSavingCategory,
}: IProps) {
  return (
    <Box sx={styles.box}>
      <Typography sx={styles.boxHeader}>Add Category</Typography>
      <Typography sx={{ color: "#472264", fontWeight: "bold" }}>
        Category Name
      </Typography>
      <Controller
        name="name"
        control={categoryControl}
        render={({ field }) => (
          <TextField
            {...field}
            error={categoryErrors["name"] ? true : false}
            placeholder="Enter category name"
            size="small"
            sx={{ width: "100%", background: "#f1f1f1", marginY: 1 }}
          />
        )}
      />
      {categoryErrors["name"] ? (
        <Typography variant="subtitle2" sx={styles.errorText}>
          {categoryErrors["name"]?.message}
        </Typography>
      ) : null}
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={addToMenu}
              color="secondary"
              onChange={() => setAddToMenu((prev: any) => !prev)}
            />
          }
          label="Add to main menu"
        />
      </FormGroup>
      <Button
        variant="contained"
        // @ts-ignore
        sx={styles.btn}
        onClick={handleCategoryFormSubmit(handleCategoryCreation)}
      >
        {isSavingCategory ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          "Save"
        )}
      </Button>
    </Box>
  );
}
