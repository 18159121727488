const initialState = {
    usersList:[],
    currentPage:"1",
    showCount:"10",
    pagination:{},
    searchKey:"",
    searchText:"",
    isLoading:false,
}

export const rolesAndPermissionReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case "SET_USERS_LIST":
            return{
                ...state,
                usersList: payload,
            }
        case "SET_ROLES_AND_PERMISSION_CURRENT_PAGE":
            return{
                ...state,
                currentPage: payload,
            }
        case "SET_ROLES_AND_PERMISSION_SHOW_COUNT":
            return {
                ...state,
                showCount:payload
            }
        case "SET_ROLES_AND_PERMISSION_PAGINATION":
            return {
                ...state,
                pagination:payload
            }
        case "SET_ROLES_AND_PERMISSION_SET_SEARCH_KEY":
            return {
                ...state,
                searchKey:payload
            }
        case "SET_ROLES_AND_PERMISSION_SET_SEARCH_TEXT":
            return {
                ...state,
                searchText:payload
            }
        case "SET_ROLES_AND_PERMISSION_SET_LOADING_TRUE":
            return {
                ...state,
                isLoading:true
            }
        case "SET_ROLES_AND_PERMISSION_SET_LOADING_FALSE":
            return {
                ...state,
                isLoading:false
            }
        default:
            return state
    }
}