import { Box, FormControl, Grid, NativeSelect, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React,{useEffect,Fragment} from "react";
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import {
    getBuyerDetails,
    setCurrentPageBuyerDetails,
    setShowCountBuyerDetails,
} from "./assets";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import LoaderPage from "../../../components/src/LoaderPage";
import moment from "moment";
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor:"#472264",
      color: theme.palette.common.white,
      textAlign:"center"
    },
    body: {
      fontSize: 13,
      color:"#472264",
      fontWeight:"bold"
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: "#f5ebfc",
      },
    },
  }),
)(TableRow);
 

function createData( name: string, calories: string, fat: string, carbs: string, protein: number,status:string) {
  return { name, calories, fat, carbs, protein,status };
}

const rows = [
  createData('PR12372', "Leave Sofia -Euro" , "Preconfigured embryos", "Angus", 107076.00,"Complete"),
  createData('PR12372', "Leave Sofia -Euro" , "Preconfigured embryos", "Angus", 107076.00,"Cancelled"),
  createData('PR12372', "Leave Sofia -Euro" , "Preconfigured embryos", "Angus", 107076.00,"Payment Declined"),
  createData('PR12372', "Leave Sofia -Euro" , "Preconfigured embryos", "Angus", 107076.00,"Complete"),
];
// @ts-ignore
const BuyerDetailPage =({match})=>{
    // @ts-ignore
    const details = useSelector(state => state.buyers)
    const {buyerDetails,buyerOrders,pagination,isLoading,currentPage,showCount} = details
    const dispatch = useDispatch()
    const classes = useStyles();
    const {id} = match?.params

    useEffect(() => {
        dispatch(getBuyerDetails(id,showCount,currentPage))
    }, []);

    // @ts-ignore
    const managePagination = (event,value) =>{
        dispatch(setCurrentPageBuyerDetails(id,value,showCount))
    }

    return(
        <Fragment>
            {
                isLoading?
                    <LoaderPage/> :

        <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:0,padding:0}} component="div">
                        {buyerDetails.full_name}
                    </Typography>
                    <Typography variant="subtitle2" display="block" gutterBottom>
                        Buyer <span style={{color:"#824bad",fontWeight:"normal"}}>/ View Profile</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box style={{backgroundColor:"white", borderRadius:"10px",padding:"20px", minHeight:"308px"}}>
                        <Typography variant="h6" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:0,padding:0}} component="div">
                        Details
                        </Typography>
                        <Grid container >
                            <Grid item xs={9}>
                                    <div style={{display:"flex", alignItems:"start" ,marginTop:"20px"}}>
                                        <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:"0",padding:"0" }}>
                                                Total Product Purchase:
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{display:"flex", alignItems:"flex-end",justifyContent:"flex-end",marginTop:"20px"}}>
                                        <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#824bad",fontWeight:"bold", margin:"0",padding:"0" }}>
                                            {buyerDetails.total_product_purchase}
                                        </Typography>
                                    </div>
                                </Grid>
                            <Grid item xs={9}>
                                <div style={{display:"flex", alignItems:"start" ,marginTop:"20px"}}>
                                    <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:"0",padding:"0" }}>
                                        Pending orders:
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div style={{display:"flex", alignItems:"flex-end",justifyContent:"flex-end",marginTop:"20px"}}>
                                    <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#824bad",fontWeight:"bold", margin:"0",padding:"0" }}>
                                        {buyerDetails?.pending_order}
                                    </Typography>
                                </div>
                            </Grid>
                                <Grid item xs={9}>
                                    <div style={{display:"flex", alignItems:"start" ,marginTop:"20px"}}>
                                        <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:"0",padding:"0" }}>
                                                    Cancelled Order:
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{display:"flex", alignItems:"flex-end",justifyContent:"flex-end",marginTop:"20px"}}>
                                        <Typography variant="subtitle2" display="block" gutterBottom style={{color:"red",fontWeight:"bold", margin:"0",padding:"0" }}>
                                            {buyerDetails.cancelled_order}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={9}>
                                    <div style={{display:"flex", alignItems:"start" ,marginTop:"20px"}}>
                                        <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:"0",padding:"0" }}>
                                                    Complete Order:
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{display:"flex", alignItems:"flex-end",justifyContent:"flex-end",marginTop:"20px"}}>
                                        <Typography variant="subtitle2" display="block" gutterBottom style={{color:"green",fontWeight:"bold", margin:"0",padding:"0" }}>
                                            {buyerDetails.completed_order}
                                        </Typography>
                                    </div>
                                </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box style={{backgroundColor:"white", borderRadius:"10px",padding:"20px"}}>
                        <Typography variant="h6" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:0,padding:0}} component="div">
                        {buyerDetails.full_name}
                        </Typography>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"start",marginTop:"20px"}}>
                            <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:"0",padding:"0" }}>
                                Contact Number
                            </Typography>
                            <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#824bad",fontWeight:"bold", margin:"0",padding:"0"}}>
                               + {buyerDetails.phone_number}
                            </Typography>  
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"start",marginTop:"20px"}}>
                            <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:"0",padding:"0" }}>
                                Email Id
                            </Typography>
                            <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#824bad",fontWeight:"bold", margin:"0",padding:"0"}}>
                                {buyerDetails.email}
                            </Typography>  
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"start",marginTop:"20px"}}>
                            <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:"0",padding:"0" }}>
                                Shipping Address
                            </Typography>
                            <Typography variant="subtitle2" display="block" gutterBottom style={{color:"#824bad",fontWeight:"bold", margin:"0",padding:"0"}}>
                                {buyerDetails.full_name},
                                <br/>
                                {buyerDetails?.shipping_address?.data?.attributes?.address?.address_line1},
                                <br/>
                                {buyerDetails?.shipping_address?.data?.attributes?.address?.address_line2},
                                <br/>
                                {buyerDetails?.shipping_address?.data?.attributes?.address?.country}
                                {" "}
                                {buyerDetails?.shipping_address?.data?.attributes?.address?.state} -
                                {buyerDetails?.shipping_address?.data?.attributes?.address?.zipcode}
                            </Typography>
                        </div>
                    </Box>
                </Grid>
            {
                buyerOrders.length !== 0 ?
                <Fragment>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom style={{color:"#472264",fontWeight:"bold", margin:0,padding:0}} component="div">
                        Order History ({buyerOrders.length})
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer
                        sx={{
                            borderTopLeftRadius:15,
                            borderTopRightRadius:15,
                            width:{
                                md:"100%",
                                sm:"700px",
                                xs:"350px"
                            }
                        }}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead >
                                <TableRow >
                                    <StyledTableCell >Id</StyledTableCell>
                                    <StyledTableCell align="right">Products</StyledTableCell>
                                    <StyledTableCell align="right">Date & Time</StyledTableCell>
                                    <StyledTableCell align="right">Category</StyledTableCell>
                                    <StyledTableCell align="right">SubCategory</StyledTableCell>
                                    <StyledTableCell align="right">Amount</StyledTableCell>
                                    <StyledTableCell align="right">Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    // @ts-ignore
                                    buyerOrders.map((row, key) => (
                                        <StyledTableRow key={key}>
                                            <StyledTableCell component="th" scope="row">
                                                <div style={{display:"flex", alignItems:"center"}}>
                                                    <img src ={row?.attributes?.catalogue_image?.url} width="40px" height="40px" style={{marginRight:"30px",borderRadius:"10px"}}/>
                                                    {row.attributes.product_number}
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.attributes.product_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <div>
                                                        {moment(row.attributes.order_date).format(
                                                            " DD/MM/YY"
                                                        )}
                                                    </div>
                                                    <div style={{ color: "#824bad" }}>
                                                        {moment(row.attributes.order_date).format(
                                                            "h:mm a"
                                                        )}
                                                    </div>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{row.attributes.category}</StyledTableCell>
                                            <StyledTableCell align="center">{row.attributes.sub_category}</StyledTableCell>
                                            <StyledTableCell align="center">{"$"+row.attributes.total_price}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <div style={{  // @ts-ignore
                                                    backgroundColor:
                                                        (row?.attributes?.status === "Complete" &&
                                                            "#09B345") ||
                                                        (row?.attributes?.status === "Pending" &&
                                                            "#D6B336") ||
                                                        (row?.attributes?.status === "Placed" &&
                                                            "#D6B336") ||
                                                        (row?.attributes?.status ===
                                                            "Payment Proceed" &&
                                                            "#FF8502") ||
                                                        (row?.attributes?.status === "Processing" &&
                                                            "#925BBD") ||
                                                        (row?.attributes?.status === "Shipping" &&
                                                            "#693A8E") ||
                                                        (row?.attributes?.status === "Out Of Stock" &&
                                                            "#A4A4A4") ||
                                                        (row?.attributes?.status ===
                                                            "Payment Declined" &&
                                                            "#E13535") ||
                                                        (row?.attributes?.status ===
                                                            "Address Invalid" &&
                                                            "#606060"),
                                                    color:"white", padding:"7px 15px",borderRadius:"10px",}}>
                                                    {row.attributes.status === "Placed" ? "Pending" : row.attributes.status}
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid  item xs={12} sm={6}>
                    <div className={classes.pageselect}>
                        <p  className={classes.paragragh}>Show:</p>
                        <FormControl style={{marginLeft:"15px",marginRight:"5px"}} >
                            <NativeSelect
                                disableUnderline
                                name="Show"
                                onChange={(e) => dispatch(setShowCountBuyerDetails(id,currentPage,e.target.value))}
                            >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                            </NativeSelect>
                        </FormControl>
                    </div>
                </Grid>
                <Grid xs={12} sm={6}>
                    <Box sx={{display:"flex",alignItems: {xs:"flex-start",sm:"flex-end"},justifyContent: {xs:"flex-start",sm:"flex-end"}, marginTop:"20px",marginLeft:"20px" }}>
                        <Stack spacing={5}>
                            <Pagination
                                count={pagination.total_pages}
                                hidePrevButton
                                page={currentPage}
                                color="secondary"
                                variant="outlined"
                                shape="rounded"
                                onChange = {managePagination}
                            />
                        </Stack>
                    </Box>
                </Grid>
                </Fragment> : <Grid item xs={12} sx={{height:"45vh"}}></Grid>
            }
        </Grid>
            }
        </Fragment>
    )
}

export default withRouter(BuyerDetailPage);

const useStyles = makeStyles({
    table: {
      width: "100%",
    },
    paper : {
      color:"black",
      padding: 10,
      backgroundColor: "white",
      display:"flex",
      flexDirection:"column"
    },
    menuLink:{
      textDecoration:"none",
      color:"#472264",
      marginBottom:5,
      boxShadow:"none"
    },
    box:{
      backgroundColor:"white",
      borderRadius:"10px",
      display:"flex"
    },
    select:{
      width:"40%",
      backgroundColor:"transparent",
      margin:"5px 20px 5px 20px", 
      borderRight:"1px solid #737373", 
      paddingRight:"10px"
    },
    search:{
      width:"60%",
      margin:5,
      display:"flex",
      alignItems:"space-between"
    },
    showselect:{
      display:"flex" , 
      justifyContent:"space-between"
    },
    pageselect:{
      backgroundColor:"white", 
      borderRadius:"5px", 
      height:"33px", 
      width:"150px",
      display:"flex",
      justifyContent:"space-between"
    },
    paragragh:{
      padding:0,
      marginTop:4, 
      marginLeft:20,
      fontWeight:"bold",
      // width:"50%"
    }
   
  });