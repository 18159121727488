import React from "react"
import {
    adminSearch, buyersSearch,
    getAdminUsers,
    getBuyerUsers,
    getVendorUsers, vendorSearch,
} from "./reduxRolesAndPermissionApi";
import store from "../store";


export const getUsersList = (role,count,page) => async dispatch => {
    dispatch({type:'SET_ROLES_AND_PERMISSION_SET_LOADING_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        let users = {}
        if (role === 'Administrator') {
            const admin = await getAdminUsers(count,page)
            users = admin.data
        } else if (role === 'Buyer') {
            const buyer = await getBuyerUsers(count,page)
            users = buyer.data
        } else if (role === 'Vendor') {
            const vendor = await getVendorUsers(count,page)
            users = vendor.data
        }
        dispatch({type:'SET_USERS_LIST',payload:users.data})
        dispatch({type:'SET_ROLES_AND_PERMISSION_PAGINATION',payload:users.meta.pagination})
    }
    dispatch({type:'SET_ROLES_AND_PERMISSION_SET_LOADING_FALSE'})
}

export const setCurrentPage = (role,count,page) => async (dispatch,getState) => {
    const {searchKey,searchText} = store.getState().rolesAndPermission
    dispatch({type:'SET_ROLES_AND_PERMISSION_CURRENT_PAGE',payload:page})
    if(searchKey !== "" && searchText !== ""){
        dispatch(getUsersListBySearch(role,count,page,searchKey,searchText))
    }else{
        dispatch(getUsersList(role,count,page))
    }
}

export const setShowCount = (role,count,page) => async dispatch => {
    const {searchKey,searchText} = store.getState().rolesAndPermission
    dispatch({type:'SET_ROLES_AND_PERMISSION_SHOW_COUNT',payload:count})
    if(searchKey !== "" && searchText !== ""){
        dispatch(getUsersListBySearch(role,count,page,searchKey,searchText))
    }else{
        dispatch(getUsersList(role,count,page))
    }
}

export const setSearchKey = (key) => async dispatch => {
    dispatch({type:'SET_ROLES_AND_PERMISSION_SET_SEARCH_KEY',payload:key})
}

export const setSearchText = (text) => async dispatch => {
    dispatch({type:'SET_ROLES_AND_PERMISSION_SET_SEARCH_TEXT',payload:text})
}

export const getUsersListBySearch = (role,count,page,searchKey,searchText) => async dispatch => {
    dispatch({type:'SET_ROLES_AND_PERMISSION_SET_LOADING_TRUE'})
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        let users = {}
        if (role === 'Administrator') {
            const admin = await adminSearch(count,page,searchKey,searchText)
            users = admin.data
        } else if (role === 'Buyer') {
            const buyer = await buyersSearch(count,page,searchKey,searchText)
            users = buyer.data
        } else if (role === 'Vendor') {
            const vendor = await vendorSearch(count,page,searchKey,searchText)
            users = vendor.data
        }
        dispatch({type:'SET_USERS_LIST',payload:users.data})
        dispatch({type:'SET_ROLES_AND_PERMISSION_PAGINATION',payload:users.meta.pagination})
    }
    dispatch({type:'SET_ROLES_AND_PERMISSION_SET_LOADING_FALSE'})
}