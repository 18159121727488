import {
  Alert,
  Box,
  CircularProgress,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  UseFormHandleSubmit,
} from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import * as yup from "yup";
import { toast } from "react-toastify";
import { IData, capitalize, API } from "./BuyerProfile";
const { ColorButton } = require("./Styles");

interface IProps {
  handleSubmit: UseFormHandleSubmit<IData>;
  handleSubmitForm: (data: IData) => void;
  isSaving: boolean;
  control: Control<IData>;
  errors: DeepMap<IData, FieldError>;
}
// @ts-ignore
export default function ProfileForm({
  handleSubmit,
  handleSubmitForm,
  isSaving,
  control,
  errors,
}: IProps) {

  return (
    <Box>
      <Box sx={{ marginBottom: "15px" }}>
        <Typography variant="subtitle1" component="div" sx={styles.label}>
          Full Name
        </Typography>
        <Controller
          name="fullName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors["fullName"] ? true : false}
              placeholder="Enter your full name"
              size="small"
              sx={{ width: "100%" }}
            />
          )}
        />
        {errors["fullName"] ? (
          <Typography variant="subtitle2" sx={styles.errorText}>
            {capitalize(errors["fullName"]?.message)}
          </Typography>
        ) : null}
      </Box>
      <Box sx={{ marginBottom: "15px" }}>
        <Typography variant="subtitle1" component="div" sx={styles.label}>
          Email
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={errors["email"] ? true : false}
              placeholder="Enter your email"
              size="small"
              sx={{ width: "100%" }}
            />
          )}
        />
        {errors["email"] ? (
          <Typography variant="subtitle2" sx={styles.errorText}>
            {capitalize(errors["email"]?.message)}
          </Typography>
        ) : null}
      </Box>
      <Box sx={{ marginBottom: "15px" }}>
        <Typography variant="subtitle1" component="div" sx={styles.label}>
          Mobile
        </Typography>
        <Controller
          name="phoneNo"
          control={control}
          render={({ field }) => (
            <PhoneInput
              {...field}
              containerStyle={{ width: "100%" }}
              defaultErrorMessage="error errror"
              inputStyle={{
                border: errors["phoneNo"] && "1px solid red",
              }}
              buttonStyle={{
                border: errors["phoneNo"] && "1px solid red",
              }}
            />
          )}
        />
        {errors["phoneNo"] ? (
          <Typography variant="subtitle2" sx={styles.errorText}>
            {capitalize(errors["phoneNo"]?.message)}
          </Typography>
        ) : null}
      </Box>
      <Box>
        <ColorButton
          variant="contained"
          color="secondary"
          sx={styles.btn}
          onClick={handleSubmit(handleSubmitForm)}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            {isSaving ? <CircularProgress color="inherit" size={30} /> : "Save"}
          </Typography>
        </ColorButton>
      </Box>
    </Box>
  );
}

// @ts-ignore
const styles = {
  label: { fontWeight: "bold", marginBottom: "1" },
  btn: { width: "100%", marginTop: "10px" },
  errorText: { marginBottom: "1", color: "red" },
  phoneError: {
    border: "1px solid red",
  },
};
