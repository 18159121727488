import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  ClickAwayListener,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios, { AxiosRequestConfig } from "axios";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import {baseURL} from "./assets";

const { ColorButton } = require("./Styles");
const { HtmlTooltip } = require("../../email-account-registration/src/Style");

const changePwdSchema = yup.object().shape({
  oldPassword: yup.string().trim().required("Old password in required"),
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .min(8, "Too short, Minimum 8 characters required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
      "Password does not meet the requirements"
    ),
  passwordConfirmation: yup
    .string()
    .trim()
    .required("Password confirmation is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const initialValues = {
  oldPassword: "",
  password: "",
  passwordConfirmation: "",
};

const API = axios.create({
  baseURL: baseURL,
  timeout: 5000,
});

interface IData {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

export default function ChangePasswordForm() {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("authToken") || "";
  const {
    handleSubmit: validateSubmission,
    control,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(changePwdSchema),
    defaultValues: initialValues,
  });

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (pwdData: IData) => {
    setIsChanging(true);
    const data = {
      current_password: pwdData.oldPassword,
      new_password: pwdData.password,
      new_password_confirmation: pwdData.passwordConfirmation,
    };
    const headers: AxiosRequestConfig = {
      headers: {
        token: token,
      },
    };
    API.put("/account_block/accounts/password", data, headers)
      .then((response) => {
        // Reset the form
        reset();
        toast.success(response.data.meta.message);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          err.response.data.errors.forEach((e: any) => {
            toast.error(e.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsChanging(false);
      });
  };

  return (
    <Grid container sx={styles.gridContainer}>
      <Grid item xs={12}>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography variant="subtitle1" component="div" sx={styles.label}>
            Old Password
          </Typography>
          <FormControl size="small" sx={styles.formControl}>
            <Controller
              name="oldPassword"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="outlined-adornment-password"
                  type="password"
                  placeholder="Enter Old Password"
                />
              )}
            />
            {errors["oldPassword"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {errors["oldPassword"]?.message}
              </Typography>
            ) : null}
          </FormControl>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography variant="subtitle1" component="div" sx={styles.label}>
            New Password
          </Typography>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <HtmlTooltip
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement="bottom"
                title={
                  <>
                    <Typography variant="subtitle2" color="inherit">
                      Password must have
                    </Typography>
                    <Typography variant="subtitle2" color="inherit">
                      - Minimum 8 characters{" "}
                    </Typography>
                    <Typography variant="subtitle2" color="inherit">
                      - Minimum 1 capital letter
                    </Typography>
                    <Typography variant="subtitle2" color="inherit">
                      - Minimum 1 special character
                    </Typography>
                    <Typography variant="subtitle2" color="inherit">
                      - Minimum 1 number
                    </Typography>
                  </>
                }
              >
                <FormControl
                  size="small"
                  sx={styles.formControl}
                  onClick={handleTooltipOpen}
                >
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        id="outlined-adornment-new-password"
                        type={showNewPassword ? "text" : "password"}
                        placeholder="Enter New password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowNewPassword((prevStatus) => !prevStatus)
                              }
                              edge="end"
                            >
                              {showNewPassword ? (
                                <Visibility color="secondary" />
                              ) : (
                                <VisibilityOff color="secondary" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                  {errors["password"] ? (
                    <Typography variant="subtitle2" sx={styles.errorText}>
                      {errors["password"]?.message}
                    </Typography>
                  ) : null}
                </FormControl>
              </HtmlTooltip>
            </div>
          </ClickAwayListener>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography variant="subtitle1" component="div" sx={styles.label}>
            Confirm Password
          </Typography>
          <FormControl size="small" sx={styles.formControl}>
            <Controller
              name="passwordConfirmation"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="outlined-adornment-confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm New Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword((prevStatus) => !prevStatus)
                        }
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility color="secondary" />
                        ) : (
                          <VisibilityOff color="secondary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
            {errors["passwordConfirmation"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {errors["passwordConfirmation"]?.message}
              </Typography>
            ) : null}
          </FormControl>
        </Box>
        <Box>
          <ColorButton
            variant="contained"
            color="secondary"
            sx={styles.btn}
            onClick={validateSubmission(handleSubmit)}
          >
            {isChanging ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              "Save"
            )}
          </ColorButton>
        </Box>
      </Grid>
    </Grid>
  );
}

const styles = {
  gridContainer: {
    background: "#FFF",
    padding: {
      xs: "15px",
      md: "50px 60px",
    },
    borderRadius: "5px",
  },
  label: { fontWeight: "bold", marginBottom: "1" },
  formControl: { m: 0, width: "100%" },
  btn: { width: "100%", marginTop: "10px" },
  errorText: { marginBottom: "1", color: "red" },
};
