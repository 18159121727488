import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderPage from "../../../components/src/LoaderPage";
import { DropZone } from "./assets";
import { PreviewButton, SaveButton } from "./Buttons";
import {
  API,
  capitalize,
  IData,
  initialValues,
  sectionOneSchema,
  styles,
} from "./SectionOne";
import SectionThreePreview from "./SectionThreePreview";
import RouteLeavingGuard from "./RouteLeavingGuard";
interface IProps {
  history: any;
  location: any;
}

function SectionThree({ history, location: currentLocation }: IProps) {
  const token = localStorage.getItem("authToken") || "";
  // Image returned from API
  const [backgroundImg, setBackgroundImg] = useState("");
  const [backgroundImgName, setBackgroundImgName] = useState("");
  // Files users select
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState<string | ArrayBuffer | null>("");
  const [hasRemovedFile, setHasRemovedFile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    handleSubmit: validateSubmission,
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(sectionOneSchema),
    defaultValues: initialValues,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Submit section three
  const handleSubmit = (data: IData) => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append("heading_title", data.title);
    formData.append("description", data.description);
    formData.append("link", data.link);
    file && formData.append("background_image", file);
    API.post("/bx_block_contentmanagement/section_threes", formData, {
      headers: { token },
    })
      .then((response) => {
        const { background_image } = response.data.data.attributes;
        setBackgroundImg(background_image?.url);
        setBackgroundImgName(background_image?.file_name);
        setHasSaved(true);
        toast.success("Saved");
      })
      .catch((err) => {
        setBackgroundImg("");
        if (err.response.status === 422) {
          err.response.data.errors.forEach((e: any) => {
            toast.error(e.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsSaving(false);
        // Remove selected file
        if (file) {
          setFile("");
          setHasRemovedFile(true);
        }
      });
  };

  const shouldBlockNavigation = (nextLocation: any) => {
    return isDirty && currentLocation.pathname !== nextLocation.pathname
      ? true
      : false;
  };

  const navigate = (nextLocation: any) => {
    history.push(nextLocation.pathname);
  };

  // Get URL of selected file
  useEffect(() => {
    if (typeof file === "object") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileUrl(reader.result);
      };
    }
  }, [file]);

  // Remove file
  const removeFileHandler = () => {
    setFile("");
    setFileUrl("");
    setHasRemovedFile(true);
  };

  // Load data
  useEffect(() => {
    API.get("/bx_block_contentmanagement/section_threes", { headers: { token } })
      .then((response) => {
        const { background_image, description, heading_title, link } =
          response.data.data.attributes;
        // Update default values for react hook form
        setValue("title", heading_title);
        setValue("link", link);
        setValue("description", description);
        setBackgroundImg(background_image?.url);
        setBackgroundImgName(background_image?.file_name);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Could not load data");
      });
  }, []);

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <Box sx={{ padding: "20px 0" }}>
      {/* File upload section starts */}
      <Typography sx={styles.label}>Background Image</Typography>
      <DropZone
        setFile={setFile}
        hasRemovedFile={hasRemovedFile}
        setHasRemovedFile={setHasRemovedFile}
        backgroundImgName={backgroundImgName}
      />
      {file && (
        <Box sx={styles.removeBtnContainer}>
          <Button sx={styles.label} onClick={removeFileHandler}>
            Remove
          </Button>
        </Box>
      )}
      {/* File upload section ends */}
      {/* Content form starts */}
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Heading Title</Typography>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors["title"] ? true : false}
                placeholder="Enter heading"
                size="small"
                sx={styles.textField}
              />
            )}
          />
          {errors["title"] ? (
            <Typography variant="subtitle2" sx={styles.errorText}>
              {capitalize(errors["title"]?.message)}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Link</Typography>
          <Controller
            name="link"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors["link"] ? true : false}
                placeholder="Url://"
                size="small"
                sx={styles.textField}
              />
            )}
          />
          {errors["link"] ? (
            <Typography variant="subtitle2" sx={styles.errorText}>
              {capitalize(errors["link"]?.message)}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Typography sx={styles.label}>Description</Typography>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors["description"] ? true : false}
                multiline
                minRows="3"
                placeholder="Description"
                size="small"
                sx={styles.textField}
              />
            )}
          />
          {errors["description"] ? (
            <Typography variant="subtitle2" sx={styles.errorText}>
              {capitalize(errors["description"]?.message)}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      {/* Content form ends */}
      {/* Button container starts */}
      <Grid sx={styles.btnContainer}>
        <PreviewButton
          text="preview"
          handleOpen={validateSubmission(handleOpen)}
        />
        <SaveButton
          text="save"
          handleSubmit={validateSubmission(handleSubmit)}
          isSaving={isSaving}
        />
      </Grid>
      {/* Button container ends */}
      <SectionThreePreview
        open={open}
        handleClose={handleClose}
        fileUrl={fileUrl}
        backgroundImg={backgroundImg}
        title={watch("title")}
        description={watch("description")}
        link={watch("link")}
      />
      <RouteLeavingGuard
        when={isDirty && !hasSaved}
        shouldBlockNavigation={shouldBlockNavigation}
        navigate={navigate}
      />
    </Box>
  );
}

export default withRouter(SectionThree);
