import * as React from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import ChangePasswordForm from "./ChangePasswordForm";
import LogoutModal from "./LogoutModal";
import BuyerProfile from "./BuyerProfile";
import Logout from "@mui/icons-material/Logout";
import Order from "./Order";
import BankDetails from "./BankDetails";
import ChangeDetails from "./ChangeDetails";
import FirmDetailsTab from "./FirmDetailsTab";
import ContactSupport from "./ContactSupport";
import ProfileForm from "./ProfileForm";
import { useState } from "react";
import { Alert } from "@mui/material";
import {toast} from "react-toastify";
// @ts-ignore
import {sentEmail,sentUnconfirmedEmail} from "../../email-account-login/src/api";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FirmTabs() {
  //   const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [openModal, setOpenModal] = useState(false);
  // @ts-ignore
  const profile = JSON.parse(localStorage.getItem("profile"));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
    const [isDisabled, setIsDisabled] = useState(false);

    const manageMail = async () => {
      let res
        try {
            if(profile.unconfirmed_email){
                res = await sentUnconfirmedEmail()
            }else{
                res = await sentEmail()
            }
            if(res.data.success){
                toast.success("Verification email sent successfully")
                setIsDisabled(true)
            }else{
                res.data.errors.map((e:any)=>{
                    toast.error(e.message)
                })
            }
        }catch (e) {
            toast.error("Something went wrong.")
        }
    }

  const showAlert = () => {
       if(!profile.email_confirmed){
          return (
              <Alert severity="error" sx={{ marginBottom: "30px" }}>
                  <Box sx={{display:"flex",flexDirection:"row", alignItems:"center",marginTop:"-5px"}}>
                      <Box>
                          Your email confirmation is pending please confirm your email
                      </Box>
                      <Box>
                          <Button color="error" onClick={manageMail} disabled={isDisabled}>
                              Click here to Resent verification email
                          </Button>
                      </Box>
                  </Box>
              </Alert>
          );
      } else if (profile?.vendor_status === "pending") {
      return (
        <Alert severity="warning" sx={{ marginBottom: "30px" }}>
          Your profile is under review
        </Alert>
      );
    } else if (profile?.vendor_status === "rejected") {
      return (
        <Alert severity="error" sx={{ marginBottom: "30px" }}>
          Your profile has been rejected. You can submit your documents please
          try again
        </Alert>
      );
    } else if (profile?.vendor_status === "approved") {
      return (
        <Alert severity="success" sx={{ marginBottom: "30px" }}>
          Your profile has been accepted now you can upload products and publish
        </Alert>
      );
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static" color="inherit">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label="Profile"
            {...a11yProps(0)}
            color="secondary"
            style={{ fontWeight: "bold", fontSize: 14, textTransform: "none" }}
          />
          <Tab
            label="Firm Details"
            {...a11yProps(1)}
            color="secondary"
            style={{ fontWeight: "bold", fontSize: 14, textTransform: "none" }}
          />
          <Tab
            label="Change Password"
            {...a11yProps(2)}
            color="secondary"
            style={{ fontWeight: "bold", fontSize: 14, textTransform: "none" }}
          />
          <Tab
            label="Bank Details"
            {...a11yProps(3)}
            color="secondary"
            style={{ fontWeight: "bold", fontSize: 14, textTransform: "none" }}
          />
          <Tab
            label="Contact Support"
            {...a11yProps(4)}
            color="secondary"
            style={{ fontWeight: "bold", fontSize: 14, textTransform: "none" }}
          />
          <Tab
            label="Logout"
            {...a11yProps(5)}
            icon={<Logout />}
            iconPosition="start"
            color="secondary"
            style={{ fontWeight: "bold", fontSize: 14, textTransform: "none" }}
            onClick={() => setOpenModal(!openModal)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {showAlert()}
        <Box sx={{ height: "75vh" }}>
          <BuyerProfile />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {showAlert()}
        <Box sx={{ height: "100%" }}>
          <FirmDetailsTab />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        {showAlert()}
        <Box sx={{ height: "75vh" }}>
          <ChangePasswordForm />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        {showAlert()}
        <Box sx={{ height: "75vh" }}>
          <BankDetails />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={4}>
        {showAlert()}
        <Box sx={{ height: "75vh" }}>
          <ContactSupport />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Box sx={{ height: "100vh" }}></Box>
      </TabPanel>
      <LogoutModal openModal={openModal} setOpenModal={setOpenModal} />
    </Box>
  );
}
