import React from "react";
import { Grid, Typography, Button, Modal, Box } from "@mui/material";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { productDelete } from "./assets";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "47%",
  transform: "translate(-50%, -50%)",
  borderRadius: "12px",
  width: {
    xs: "80%",
    sm: 500,
  },
  backgroundColor: "#fff",
  boxShadow: 24,
} as const;

// @ts-ignore
function DeactivationModal(props) {
  const dispatch = useDispatch();
  const manageDeleteModal = () => {
    dispatch(productDelete(props.deleteId, props.id, props.count, props.page));
    props.setOpenModal(false);
    if(!props.count && !props.page){
        props.history.goBack()
    }
  };

  return (
    <Modal
      open={props.openModal}
      onClose={() => props.setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={modalStyle}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "700",
            color: "#472264",
            padding: "15px 0",
          }}
        >
          Delete Product
        </Typography>
        <Box sx={{ paddingY: 2, paddingX: 3.5 }}>
          <Typography
            id="modal-modal-description"
            sx={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "400",
              color: "#824bad",
            }}
          >
            Are you sure you want to delete this product?
          </Typography>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#824bad",
                borderColor: "#824bad !important",
                borderRadius: "8px",
                fontSize: "18px",
                fontWeight: "500",
                marginRight: 2,
                textTransform: "uppercase",
                width: "250px",
                p: 1,
                "&:hover": {
                  background: "#fff",
                },
              }}
              onClick={() => props.setOpenModal(false)}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "#693a8e",
                borderRadius: "8px",
                fontSize: "18px",
                fontWeight: "500",
                textTransform: "uppercase",
                width: "250px",
                p: 1,
                "&:hover": {
                  background: "#693a8e",
                },
              }}
              onClick={manageDeleteModal}
            >
              yes
            </Button>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
export default withRouter(DeactivationModal);

