export const topBanner = require("../assets/topBanner.png");
export const middleBanner = require("../assets/middleBanner.png");
export const middleBanner2 = require("../assets/middleBanner2.png");
export const halfSizeBanner = require("../assets/halfSizeBanner.png");
export const Image6 = require("../assets/Image6.png");
export const Image7 = require("../assets/Image7.png");

export const {
  getSection1,
  getSection2,
  getSection3,
  getSection4,
  getSection5,
  getSection6
} = require("./API");

export const {manageLoading} = require("../../../components/src/Redux/LandingPage/reduxLandingPageActions")
