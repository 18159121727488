import * as yup from "yup";

const addProductSchema = yup.object().shape({
  categoryName: yup.string().required("Category is required"),
  subCategoryName: yup.string().nullable(),
  productName: yup.string().required("Product name is required"),
  price: yup.string().required("Price is required"),
  scrotal: yup.string().nullable(),
  FanimalName: yup.string().required("Animal name is required"),
  FnickName: yup.string().required("Nick name is required"),
  FDateOfBirth: yup
    .date()
    .nullable()
    .typeError("Invalid date")
    .required("Date of birth is required"),
  Fbreed: yup.string().required("Breed is required"),
  FAnalysisCode: yup.string().nullable(),
  FNumAnalysis: yup.string().nullable(),
  ManimalName: yup.string().nullable(),
  MnickName: yup.string().nullable(),
  Mbreed: yup.string().nullable(),
  MAnalysisCode: yup.string().nullable(),
  MNumAnalysis: yup.string().nullable(),
  MDateOfBirth: yup
    .date()
    .nullable()
    .default(null),
  race: yup.string().required("Race is required"),
  birthWeight: yup.string().required("Birth weight is required"),
  weaningWeight: yup.string().required("Weaning weight is required"),
  weightAtTheEnd: yup.string().required("Weight at the end is required"),
  byCountry: yup.string().required("Country is required"),
  delivery: yup.string().required("Delivery is required"),
  description: yup
    .string()
    .trim()
    .required("Description is required"),
});

export default addProductSchema;
