import React, {useEffect, useState} from "react";
import { Grid, Typography, Stack, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ViewListIcon from "@mui/icons-material/ViewList";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PasswordIcon from "@mui/icons-material/Password";
import LogoutIcon from "@mui/icons-material/Logout";

// Style of sidebar buttons
const sidebarButtonStyles = {
  fontWeight: "bold",
  borderRadius: "8px",
  textTransform: "capitalize",
  justifyContent: "flex-start",
  paddingY: "10px",
  lineHeight: 2,
};

type props = {
  containButtons: {
    profile: boolean;
    my_orders: boolean;
    shipping_address: boolean;
    change_password: boolean;
    logout: boolean;
  };
  handleSidebarClick: (displayComponent: any, buttonName: any) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  orderCount: number;
};

export default function SideMenu({
  orderCount,
  containButtons,
  handleSidebarClick,
  setOpenModal,
}: props) {
  const [orderCountDisplay, setOrderCountDisplay] = useState("");

  useEffect(() => {
    if (orderCount > 0 && orderCount < 10) {
      setOrderCountDisplay(`0${orderCount}`);
    } else {
      setOrderCountDisplay(`${orderCount}`);
    }
  }, [orderCount])

  return (
    <Grid
      item
      xs={12}
      md={3}
      sx={{
        background: "#FFF",
        padding: {
          xs: "10px",
          lg: "30px 20px"
        },
        borderRadius: "8px",
        alignSelf: "flex-start",
      }}
    >
      <Stack spacing={2}>
        <Button
          variant={containButtons.profile ? "contained" : "text"}
          // @ts-ignore
          sx={{
            ...sidebarButtonStyles,
            background: containButtons.profile ? "#693A8E" : "",
            color: containButtons.profile ? "#FFF" : "#472264",
            "&:hover": {
              background: containButtons.profile ? "#693A8E" : "",
            },
          }}
          startIcon={
            <PersonIcon
              sx={{
                color: containButtons.profile ? "#d6b336" : "#472264",
              }}
            />
          }
          onClick={() => handleSidebarClick("profile", "profile")}
        >
          <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
            Profile
          </Typography>
        </Button>
        <Button
          variant={containButtons.my_orders ? "contained" : "text"}
          // @ts-ignore
          sx={{
            ...sidebarButtonStyles,
            background: containButtons.my_orders ? "#693A8E" : "",
            color: containButtons.my_orders ? "#FFF" : "#472264",
            "&:hover": {
              background: containButtons.my_orders ? "#693A8E" : "",
            },
          }}
          startIcon={
            <ViewListIcon
              sx={{
                color: containButtons.my_orders ? "#d6b336" : "#472264",
              }}
            />
          }
          endIcon={
            <span
              style={{
                background: "#d6b336",
                fontSize: "12px",
                padding: 0,
                width: "30px",
                borderRadius: "9px",
              }}
            >
              {orderCountDisplay}
            </span>
          }
          onClick={() => handleSidebarClick("myOrders", "my_orders")}
        >
          <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
            My Orders
          </Typography>
        </Button>
        <Button
          variant={containButtons.shipping_address ? "contained" : "text"}
          // @ts-ignore
          sx={{
            ...sidebarButtonStyles,
            background: containButtons.shipping_address ? "#693A8E" : "",
            color: containButtons.shipping_address ? "#FFF" : "#472264",
            "&:hover": {
              background: containButtons.shipping_address ? "#693A8E" : "",
            },
          }}
          startIcon={
            <LocationOnIcon
              sx={{
                color: containButtons.shipping_address ? "#d6b336" : "#472264",
              }}
            />
          }
          onClick={() =>
            handleSidebarClick("shippingAddress", "shipping_address")
          }
        >
          <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
            Shipping Address
          </Typography>
        </Button>
        <Button
          variant={containButtons.change_password ? "contained" : "text"}
          // @ts-ignore
          sx={{
            ...sidebarButtonStyles,
            background: containButtons.change_password ? "#693A8E" : "",
            color: containButtons.change_password ? "#FFF" : "#472264",
            "&:hover": {
              background: containButtons.change_password ? "#693A8E" : "",
            },
          }}
          startIcon={
            <PasswordIcon
              sx={{
                color: containButtons.change_password ? "#d6b336" : "#472264",
              }}
            />
          }
          onClick={() =>
            handleSidebarClick("changePassword", "change_password")
          }
        >
          <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
            Change Password
          </Typography>
        </Button>
        <Button
          variant={containButtons.logout ? "contained" : "text"}
          // @ts-ignore
          sx={{
            ...sidebarButtonStyles,
            background: containButtons.logout ? "#693A8E" : "",
            color: containButtons.logout ? "#FFF" : "#472264",
            "&:hover": {
              background: containButtons.logout ? "#693A8E" : "",
            },
          }}
          startIcon={
            <LogoutIcon
              sx={{
                color: containButtons.logout ? "#d6b336" : "#472264",
              }}
            />
          }
          onClick={() => setOpenModal(true)}
        >
          <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
            Logout
          </Typography>
        </Button>
      </Stack>
    </Grid>
  );
}
