import React, {useState} from 'react';
import Typography from "@mui/material/Typography";
import {Grid,MenuItem} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import {ColorButton, flexBox, HtmlTooltip} from "./Style";
import {withRouter} from 'react-router-dom'
import {registerUser} from "./api";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from 'yup'
import {useForm,Controller} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {setCart} from "../../email-account-login/src/assets";
import {addCart} from "../../email-account-login/src/api";
import {useDispatch} from "react-redux";
import {setLoginModelTrue} from "./assets"
const initialState = {
    full_name: "",
    email:"",
    full_phone_number: "",
    password: "",
    password_confirmation: "",
    user_role: ""
}

const RegisterForm = ({setFormType, setOpenSuccessModel,setOpenRegisterModel,history,match}) => {
    const registerSchema = yup.object().shape({
        "full_name":yup.string().trim().required("Name Is required"),
        "password":yup.string().trim().required("Please enter password").min(8,"Short Password, Minimum 8 characters required").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/, 'Password does not meet the requirements.'),
        "password_confirmation":yup.string().trim().oneOf([yup.ref("password"),null],"Confirm password and password does not match").required("Please confirm your password"),
        "email":yup.string().email("Please enter a valid Email").required("Email ID is required"),
    })
    const { handleSubmit, formState: { errors },control:regControl} = useForm({
        resolver: yupResolver(registerSchema),
        defaultValues: initialState
    });
    const dispatch = useDispatch()
    const type = match.params.type
    const [values, setValues] = React.useState(initialState)
    const [error, setError] = useState('')
    const [termsCheckBox, setTermsCheckBox] = React.useState(false);
    const [passwordVisiblity, setPasswordVisiblity] = React.useState({
        showPassword1: false,
        showPassword2: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit1 = async (formData) => {
        setIsLoading(true)
        if(!termsCheckBox){
            setError([
                {
                    message:'Please accept Terms & Conditions'
                }
            ])
            setIsLoading(false)
            return
        }
        const finalData = {
            data:{
                attributes:{
                    ...formData,
                    user_role:type,
                    full_phone_number: values.full_phone_number
                }
            }
        }
        try {
            const {data} = await registerUser(finalData)
            if(data.errors){
                setError(data.errors)
            }else{
                window.localStorage.setItem('authToken',data.meta.token)
                window.localStorage.setItem('profile',JSON.stringify(data.data.attributes))
                if (data.data.attributes.user_role === "buyer") {
                    const cart = JSON.parse(localStorage.getItem("cart"))
                    if(cart){
                        const updatedCart = cart.map((c)=>{
                            return{
                                catalogue_id:c.catalogue_id,
                                quantity:c.quantity
                            }
                        })
                        const cartAdd = await addCart({cart:updatedCart},data.meta.token)
                        if(cartAdd.status === 200){
                            dispatch(setCart())
                        }
                    }
                    setFormType('success')
                }
                setFormType('success')
            }
        }catch (e) {
            setError([
                {
                    message:'Something Went Wrong'
                }
            ])
        }
        setIsLoading(false)
    }
    const manageLogin = () => {
        dispatch(setLoginModelTrue())
        setOpenRegisterModel(false)
    }
    return (
        <Grid item xs={12} className='registerMainDiv' sx={{ padding : '35px 0'}}>
            <Box className='registerFormFlex'>
                <Typography variant="h6" component="div" sx={{fontWeight:"bold", color: '#412360'}}>
                    Signup as a {type} !
                </Typography>
                <Typography variant="subtitle2" component="div" color="purple">
                    Fields marked with an asterisk (*) are required.
                </Typography>
                <Grid item xs={12} sx={{marginTop:"20px"}}>
                    {
                        error ?
                            error.map((e,index)=>{
                                return(
                                    <Alert key={index} severity="error" sx={{width:'90%',alignSelf:'center',marginBottom:"10px"}}>
                                        {e.message}
                                    </Alert>
                                )
                            })
                            : null
                    }
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold", marginBottom:'1'}}>
                            Name
                            <Typography  variant="subtitle1" color='secondary' component="span" sx={{fontWeight:"bold"}}>
                                *
                            </Typography>
                        </Typography>
                        <Controller
                            name="full_name"
                            control={regControl}
                            render={({ field }) => (
                                <TextField block error={errors["full_name"]} {...field} placeholder="Enter your full Name"  size='small' sx={{width:"90%"}} />
                            )} />
                        {errors["full_name"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["full_name"]?.message}
                            </Typography> : null
                        }
                    </Box>
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold", marginBottom:'1'}}>
                            Email
                            <Typography variant="subtitle1" color='secondary' component="span" sx={{fontWeight:"bold"}}>
                                *
                            </Typography>
                        </Typography>
                        <Controller
                            name="email"
                            control={regControl}
                            render={({ field }) => (
                                <TextField block error={errors["email"]} {...field} placeholder="Enter your email address" size='small' sx={{width:"90%"}}/>
                            )} />
                        {errors["email"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["email"]?.message}
                            </Typography> : null
                        }
                    </Box>
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold", marginBottom:'1'}}>
                            Mobile
                        </Typography>
                        <PhoneInput
                            containerStyle={{width:"90%"}}
                            size="small"
                            country={'ar'}
                            value={values.full_phone_number}
                            onChange={(phone)=> setValues({ ...values, full_phone_number: phone })}
                        />
                        {errors["phone"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["phone"]?.message}
                            </Typography> : null
                        }
                    </Box>
                    <Box sx={{marginBottom:"15px"}}>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold",marginBottom:'1'}}>
                            Password
                            <Typography variant="subtitle1" color='secondary' component="span" sx={{fontWeight:"bold"}}>
                                *
                            </Typography>
                        </Typography>
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Typography variant="subtitle2" color="inherit">Password must have</Typography>
                                    <Typography variant="subtitle2" color="inherit">- Minimum 8 characters </Typography>
                                    <Typography variant="subtitle2" color="inherit">- Minimum 1 capital letter</Typography>
                                    <Typography variant="subtitle2" color="inherit">- Minimum 1 special character</Typography>
                                    <Typography variant="subtitle2" color="inherit">- Minimum 1 number</Typography>
                                </React.Fragment>
                            }
                        >
                            <FormControl sx={{ m: 0,width:"90%" }}>
                                <Controller
                                    name="password"
                                    control={regControl}
                                    render={({ field }) => (
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            error={errors["password"]}
                                            {...field}
                                            type={passwordVisiblity.showPassword1 ? 'text' : 'password'}
                                            placeholder="Enter your password"
                                            size="small"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={()=>{
                                                            setPasswordVisiblity({
                                                                ...passwordVisiblity,
                                                                showPassword1: !passwordVisiblity.showPassword1,
                                                            })
                                                        }}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {passwordVisiblity.showPassword1 ? <Visibility/> :  <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />)} />
                            </FormControl>
                        </HtmlTooltip>
                        {errors["password"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["password"]?.message}
                            </Typography> : null
                        }
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" component="div" sx={{fontWeight:"bold",marginBottom:'1'}}>
                            Confirm Password
                            <Typography variant="subtitle1" color='secondary' component="span" sx={{fontWeight:"bold"}}>
                                *
                            </Typography>
                        </Typography>
                        <FormControl sx={{ m: 0,width:"90%" }}>
                            <Controller
                                name="password_confirmation"
                                control={regControl}
                                render={({ field }) => (
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        error={errors["password_confirmation"]}
                                        {...field}
                                        type={passwordVisiblity.showPassword2 ? 'text' : 'password'}
                                        placeholder="Confirm your password"
                                        size="small"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={()=>{
                                                        setPasswordVisiblity({
                                                            ...passwordVisiblity,
                                                            showPassword2: !passwordVisiblity.showPassword2,
                                                        })
                                                    }}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {passwordVisiblity.showPassword2 ? <Visibility/> : <VisibilityOff/> }
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                )} />
                        </FormControl>
                        {errors["password_confirmation"] ?
                            <Typography
                                variant="subtitle2"
                                component="div"
                                sx={{ marginBottom: "1",color:"red" }}
                            >
                                {errors["password_confirmation"]?.message}
                            </Typography> : null
                        }
                    </Box>

                    <Box sx={flexBox}>
                        <Grid container sx={{display:'flex',flexDirection:'row', justifyContent:'center', alignContent:'center',width:"100%"}}>
                            <Grid item xs={12} sm={12} md={7} s sx={{display:"flex",flexDirection:"row"}}>
                                <Checkbox sx={{padding:"0px 5px 0px 0px"}} icon={<RadioButtonUncheckedIcon sx={{color:"#412360"}} />} checkedIcon={<CheckCircleIcon sx={{color:"#D6B336"}}/>} checked={termsCheckBox} onChange={() => setTermsCheckBox(!termsCheckBox)}/>
                                <Typography variant="body1" component="div" alignSelf='center' sx={{margin:'0px'}} onClick={()=>setTermsCheckBox(!termsCheckBox)} sx={{cursor:"pointer"}}>
                                    I accept
                                </Typography>
                                <Typography variant="body1" color="#D6B336" alignSelf='center' marginLeft={0.5} sx={{cursor:'pointer',fontWeight:"bold",textDecoration:"none"}}>
                                    Terms & Condition
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} sx={{display:"flex",flexDirection:"row",justifyContent: {xs:"flex-start"},marginLeft:"-6px"}}>
                                <Typography variant="body1" component="div" alignSelf='center' marginLeft={0.5} onClick={()=>setTermsCheckBox(!termsCheckBox)} sx={{cursor:"pointer"}}>
                                    and
                                </Typography>
                                <Typography variant="body1" color="#D6B336" marginLeft={0.5} sx={{cursor:'pointer',fontWeight:"bold",textDecoration:"none"}}>
                                    Privacy Policy
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <ColorButton variant="contained" color="secondary" sx={{width:"90%",height:"50px"}} onClick={handleSubmit(handleSubmit1)}>{isLoading ? <CircularProgress color="inherit" /> : "SIGNUP"}</ColorButton>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'row',alignContent:'space-between',justifyContent:'center',margin:'20px 0px'}}>
                        <Typography variant="body2" component="div" alignSelf='center' sx={{fontWeight:"bold"}}>
                            Already have an account?
                        </Typography>
                        <Typography variant="body2" component="div" color="#d8b434" alignSelf='center' marginLeft={1} sx={{fontWeight:"bold",cursor:'pointer'}} onClick={manageLogin}>
                            Login
                        </Typography>
                    </Box>
                </Grid>
            </Box>
        </Grid>
    );
};

export default withRouter(RegisterForm);7