import { Grid, Typography } from '@mui/material';
import React from 'react';
import {info, noimg} from './assets'

import moment from 'moment'
// @ts-ignore
const Notify = ({notification}) => {
    return (
        <div className="notify-grid-main-div" style={{ borderRadius : '15px'}}>
            <Grid className="notify-grid-main" container display="flex"  alignItems="center" sx={{ padding: '15px' , flexWrap:"nowrap" }} >
                <Grid className="notify-grid-img" item >
                    {
                        notification.attributes.notifiable_type === "BxBlockCatalogue::Catalogue" ?
                            <img src={info} width='60px'  style={{ padding : '0' }} alt='notification'/> :
                            <img src={notification?.attributes?.image?.url || noimg} width='60px'  style={{ padding : '0' }} alt='notification'/>
                    }
                </Grid>
                <Grid item xs={12} className="notify-grid-content" sx={{ position : 'relative'}}>
                    <Typography variant="subtitle2" color="#693A8E" sx={{ paddingBottom : '8px'}}>{notification.attributes.contents}</Typography>
                    <Typography variant="body1" sx={{ color : '#bdb9b9', fontWeight : 'bold'}} >{moment(notification.attributes.created_at).fromNow()}</Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default Notify;