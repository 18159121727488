import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
    baseURL: `${baseURL}/bx_block_dashboard/`
});

API.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

export const getAdminUsers = async (count,page) => {
    try {
        return await API.get(`roles_and_permissions/admin_users?per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

export const getBuyerUsers = async (count,page) => {
    try {
        return await API.get(`buyers?per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

export const getVendorUsers = async (count,page) => {
    try {
        return await API.get(`vendors?per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

export const adminSearch = async (count,page,type,search) => {
    try {
        return await API.get(`roles_and_permissions/admin_users?search_key=${type}&search=${search}&per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

export const buyersSearch = async (count,page,type,search) => {
    try {
        return await API.get(`buyers?search_key=${type}&search=${search}&per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

export const vendorSearch = async (count,page,type,search) => {
    try {
        return await API.get(`vendors?search_key=${type}&search=${search}&per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}