const initialState = {
    notifications:[],
    unReadNotification:[],
    unreadIds:[],
    unreadCount:0,
    isLoading:false,
}

export const notificationReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case "SET_NOTIFICATION_LIST":
            let id = []
            const a = payload instanceof Array && payload?.map((i)=>{
                i.notifications.map((j)=>{
                    if(!j.is_read){
                        id = [...id,j.id]
                    }
                })
            })
            return {
                ...state,
                notifications: payload,
                unreadCount:id.length,
                unreadIds:id
            }
        case "SET_UNREAD_NOTIFICATIONS":
            return{
                ...state,
                unReadNotification: payload,
            }
        case "SET_UNREAD_COUNT_ZERO":
            return{
                ...state,
                unreadCount: 0,
                unReadNotification: []
            }
        case "SET_LOADING_NOTIFICATION_FALSE":
            return {
                ...state,
                isLoading:false
            }
        case "SET_LOADING_NOTIFICATION_TRUE":
            return {
                ...state,
                isLoading:true
            }
        case "SET_NOTIFICATIONS_BLANK":
            return{
                ...state,
                notifications:[],
                unreadCount: 0,
                unReadNotification: []
            }
        default:
            return state
    }
}