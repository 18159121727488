import React, { useState } from "react";
import { Grid, IconButton, Typography, TextField, Button } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { withRouter } from "react-router-dom";

import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSix from "./SectionSix";

// @ts-ignore
function SectionContainer({ history, match }: any) {
  const curSection = parseInt(match.params.id);

  // Change sections
  const handleSection = (action: string) => {
    if (action === "increment" && curSection < 6) {
      history.push(`${curSection + 1}`);
    } else if (action === "decrement" && curSection > 1) {
      history.push(`${curSection - 1}`);
    }
  };

  return (
    <Grid sx={styles.container}>
      {/* Section controls */}
      <Grid container sx={styles.sectionContainer}>
        {/* Left sided controls */}
        <Grid item sx={styles.btnContainer}>
          <IconButton
            onClick={() => handleSection("decrement")}
            disabled={curSection === 1}
          >
            <KeyboardArrowLeftIcon fontSize="large" />
          </IconButton>
          <Typography
            // @ts-ignore
            sx={{
              ...styles.prevText,
              color: `${curSection > 1 ? "#aca8a8" : "#f1f1f1"}`,
            }}
          >
            {`section 0${curSection - 1}`}
          </Typography>
        </Grid>
        {/* Control center */}
        <Grid item>
          <Typography
            variant="h6"
            // @ts-ignore
            sx={styles.sectionText}
          >
            {`section 0${curSection}`}
          </Typography>
        </Grid>
        {/* Right sided controls */}
        <Grid item sx={styles.btnContainer}>
          <Typography
            // @ts-ignore
            sx={{
              ...styles.nextText,
              color: `${curSection < 6 ? "#aca8a8" : "#f1f1f1"}`,
            }}
          >
            {`section 0${curSection + 1}`}
          </Typography>
          <IconButton
            onClick={() => handleSection("increment")}
            disabled={curSection === 6}
          >
            <ChevronRightIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
      {/* show relevant sections */}
      {curSection === 1 && <SectionOne />}
      {curSection === 2 && <SectionTwo />}
      {curSection === 3 && <SectionThree />}
      {curSection === 4 && <SectionFour />}
      {curSection === 5 && <SectionFive />}
      {curSection === 6 && <SectionSix />}
    </Grid>
  );
}

export default withRouter(SectionContainer);

const styles = {
  container: {
    background: "#fff",
    borderRadius: "8px",
    padding: {
      xs: "10px",
      md: "20px 30px",
    },
  },
  sectionContainer: {
    background: "#f1f1f1",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px",
    padding: { xs: 0, md: "20px" },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  prevText: {
    textTransform: "uppercase",
    fontSize: "12px",
    marginLeft: "10px",
    display: {
      xs: "none",
      md: "block",
    },
  },
  sectionText: {
    textTransform: "uppercase",
    color: "#472264",
    fontWeight: "bold",
    fontSize: { xs: "16px", md: "1.25rem" },
  },
  nextText: {
    textTransform: "uppercase",
    fontSize: "12px",
    marginRight: "10px",
    display: {
      xs: "none",
      md: "block",
    },
  },
};
