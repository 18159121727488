import { makeStyles } from "@material-ui/core/styles";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import LoaderPage from "../../../components/src/LoaderPage";
import { DropZone } from "../../ContentManagement/src/assets";
import {
  cancelImage,
  getCountries,
  getStates,
  getVendorFirmDetails,
  removeImage,
  saveFirmDetails,
  updateFirmDetails,
} from "./assets";
const { ColorButton } = require("./Styles");
import {initialValues, IData} from "../../../components/src/hooks/useShippingAddress";

interface IState {
  code: string;
  name: string;
}

interface ICountry {
  code: string;
  name: string;
}

interface IError {
  message: string;
}

const firmDetailSchema = yup.object().shape({
  name: yup.string().required("Store name is required"),
  address1: yup.string().required("Address line 1 is required"),
  address2: yup.string().required("Address line 2 is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  zipcode: yup
    .string()
    .required("Zipcode is required")
    .min(4),
});

const initialState = {
  addressId: "",
  endpointId: "",
  image: "",
  isApproved: "",
  imageId: "",
};

const FirmDetailsTab = () => {
  const classes = useStyles();
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [state, setState] = useState("");
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState<string | ArrayBuffer | null>("");
  const [isLoading, setIsLoading] = useState(true);
  const [countryValue, setCountryValue] = useState("");
  const [states, setStates] = useState([]);
  const [detailsAlreadyExist, setDetailsAlreadyExist] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState<{ message: string }[]>([]);
  const [message, setMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [hasRemovedFile, setHasRemovedFile] = useState(false);
  const {
    handleSubmit: validateSubmission,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(firmDetailSchema),
    defaultValues: initialValues,
  });

  const countryList = async () => {
    try {
      const { data } = await getCountries();
      setCountries(data);
    }catch (e) {
      toast.error("Something went wrong")
    }
  };

  // @ts-ignore
  const getStateData = async (code) => {
    try {
      const { data } = await getStates(code);
      setStates(data);
    }catch (e) {
      toast.error("Something went wrong")
    }
    setIsLoading(false);
  };

  const getDetails = async () => {
    const { data } = await getVendorFirmDetails();
    setIsLoading(false);
    if (data.data) {
      setDetailsAlreadyExist(true);
      // @ts-ignore
      setFormData({
        ...formData,
        endpointId: data.data?.attributes.address.addressble_id,
        addressId: data.data?.attributes.address.id,
        isApproved: data.data?.attributes.approval_status,
        imageId: data.data?.attributes.registration_id?.id,
        image: data.data?.attributes.registration_id?.url,
      });
      // Set data to form fields
      setValue("name", data.data?.attributes.name);
      setValue("address1", data.data?.attributes.address.address_line1);
      setValue("address2", data.data?.attributes.address.address_line2);
      setValue("country", data.data?.attributes.address.country);
      setValue("state", data.data?.attributes.address.state);
      setValue("zipcode", data.data?.attributes.address.zipcode);
      setFileUrl(data.data?.attributes.registration_id?.url);
      setCountryValue(data.data?.attributes.address.country);
      setState(data.data?.attributes.address.state);
    } else {
      setDetailsAlreadyExist(false);
    }
  };

  // Change inputs
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string
  ) => {
    e.persist();
    setFormData((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const handleCountryChange = (e: any) => {
    setFormData((prev) => ({
      ...prev,
      country: e.target.value,
      state: "",
    }));
    setIsLoading(true);
  }

  // Remove imgages
  const handleRemoveImg = async () => {
    // @ts-ignore
    if (formData.image?.size) {
      // Remove locally selected file
      setFormData((prev) => ({
        ...prev,
        image: "",
      }));
      setFile("");
      setHasRemovedFile(true);
    } else {
      // Remove from server
      try {
        const response = await removeImage({
          id: formData.imageId,
        });
        if (response.status === 200) {
          setFormData((prev) => ({
            ...prev,
            image: "",
            imageId: "",
          }));
          setFileUrl("");
          setHasRemovedFile(true);
          toast.success("Id removed successfully");
        } else {
          toast.error("Could not remove id");
        }
      } catch {
        toast.error("Could not remove id");
      }
    }
  };

  // Submit
  const handleSubmit = async (firmDetails: IData) => {
    setIsSaving(true);
    const data = new FormData();
    data.append("name", firmDetails.name);
    data.append("address_attributes[address_line1]", firmDetails.address1);
    data.append("address_attributes[address_line2]", firmDetails.address2);
    data.append("address_attributes[country]", firmDetails.country);
    data.append("address_attributes[state]", firmDetails.state);
    data.append("address_attributes[zipcode]", firmDetails.zipcode);
    file && data.append("registration_id", file);
    formData.addressId &&
      data.append("address_attributes[id]", formData.addressId);
    if (detailsAlreadyExist) {
      // Update firm details
      try {
        const response = await updateFirmDetails(formData.endpointId, data);
        setIsSaving(false);
        setFile("");
        // setFileUrl(response.data.data?.attributes.registration_id?.url);
        setFormData({
          ...formData,
          imageId: response.data.data?.attributes.registration_id?.id,
          image: response.data.data?.attributes.registration_id?.url,
        });
        setHasRemovedFile(true);
        if (response.status === 200) {
          toast.success("Updated");
        } else if (response.status === 422) {
          response.data.errors.forEach((e: IError) => {
            toast.error(e.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch {
        toast.error("Something went wrong");
      }
    } else {
      // Save firm details
      try {
        const response = await saveFirmDetails(data);
        setIsSaving(false);
        setFile("");
        setHasRemovedFile(true);
        if (response.status === 201) {
          toast.success("Saved");
        } else if (response.status === 422) {
          response.data.errors.forEach((e: IError) => {
            toast.error(e.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch {
        toast.error("Something went wrong");
      }
    }
  };

  // Load states after country change
  useEffect(() => {
    if (country && countries.length > 0) {
      // @ts-ignore
      const curCountry: ICountry = countries.find((c: ICountry) => c.name === country);
      if (curCountry) {
        const code = curCountry["code"].toLowerCase();
        getStateData(code);
        setIsLoading(false);
      } 
    }
  }, [country, countries]);

  // Load data
  useEffect(() => {
    countryList();
    getDetails();
  }, []);

  // Preview and add file to form data
  useEffect(() => {
    if (file) {
      setFormData((prev) => ({
        ...prev,
        image: file,
      }));
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        // ts-ignore
        setFileUrl(reader.result);
      };
    } else {
      setFileUrl("");
    }
  }, [file]);

  // Detect country change
  useEffect(() => {
    setCountry(watch("country"));
  }, [watch("country")]);

  return (
    <Fragment>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <div className={classes.textDiv}>
                      <Typography variant="subtitle1" sx={styles.label}>
                        Firm Name
                      </Typography>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors["name"] ? true : false}
                            placeholder="Firm Name"
                            size="small"
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                          />
                        )}
                      />
                      {errors["name"] ? (
                        <Typography variant="subtitle2" sx={styles.errorText}>
                          {errors["name"]?.message}
                        </Typography>
                      ) : null}
                    </div>
                    <div className={classes.textDiv}>
                      <Typography variant="subtitle1" sx={styles.label}>
                        Address Line 2
                      </Typography>
                      <Controller
                        name="address2"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors["address2"] ? true : false}
                            placeholder="Address Line 2"
                            size="small"
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                          />
                        )}
                      />
                      {errors["address2"] ? (
                        <Typography variant="subtitle2" sx={styles.errorText}>
                          {errors["address2"]?.message}
                        </Typography>
                      ) : null}
                    </div>
                    <div className={classes.textDiv}>
                      <Typography variant="subtitle1" sx={styles.label}>
                        State
                      </Typography>
                      <FormControl
                        size="small"
                        fullWidth
                        error={errors["state"] ? true : false}
                      >
                        <InputLabel id="bulk-actions">Select state</InputLabel>
                        <Controller
                          name="state"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={states.map((s: IState) => s.name).includes(field.value) ? field.value : ""}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Select state"
                            >
                              {states.map((state: IState) => (
                                <MenuItem key={state.code} value={state.name}>
                                  {state.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )
                        }
                        />
                        {errors["state"] ? (
                          <Typography variant="subtitle2" sx={styles.errorText}>
                            {errors["state"]?.message}
                          </Typography>
                        ) : null}
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.textDiv}>
                      <Typography variant="subtitle1" sx={styles.label}>
                        Address Line 1
                      </Typography>
                      <Controller
                        name="address1"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors["address1"] ? true : false}
                            placeholder="Address Line 1"
                            size="small"
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                          />
                        )}
                      />
                      {errors["address1"] ? (
                        <Typography variant="subtitle2" sx={styles.errorText}>
                          {errors["address1"]?.message}
                        </Typography>
                      ) : null}
                    </div>
                    <div className={classes.textDiv}>
                      <Typography variant="subtitle1" sx={styles.label}>
                        Country
                      </Typography>
                      <FormControl
                        size="small"
                        fullWidth
                        error={errors["country"] ? true : false}
                      >
                        <InputLabel id="bulk-actions">Select country</InputLabel>
                        <Controller
                          name="country"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Select country"
                            >
                              {countries.map((name: any) => (
                                <MenuItem key={name.name} value={name.name}>
                                  {name.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors["country"] ? (
                          <Typography variant="subtitle2" sx={styles.errorText}>
                            {errors["country"]?.message}
                          </Typography>
                        ) : null}
                      </FormControl>
                    </div>
                    <div className={classes.textDiv}>
                      <Typography
                        variant="subtitle1"
                        sx={styles.label}
                      >
                        ZipCode
                      </Typography>
                      <Controller
                        name="zipcode"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={errors["zipcode"] ? true : false}
                            placeholder="ZipCode"
                            size="small"
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                          />
                        )}
                      />
                      {errors["zipcode"] ? (
                        <Typography variant="subtitle2" sx={styles.errorText}>
                          {errors["zipcode"]?.message}
                        </Typography>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      sx={styles.uploadTxt}
                    >
                      Upload ID
                    </Typography>
                    <div style={{ position: "relative" }}>
                      {(fileUrl ||  formData.image) ? (
                        <img
                          // @ts-ignore
                          src={fileUrl ? fileUrl : formData.image}
                          alt="id"
                          style={styles.img}
                        />
                      ) : (
                        <DropZone
                          setFile={setFile}
                          hasRemovedFile={hasRemovedFile}
                          setHasRemovedFile={setHasRemovedFile}
                        />
                      )}
                      {formData.isApproved !== "approved" && (
                        <IconButton
                          sx={{
                            ...styles.iconBtn,
                            display: (fileUrl ||  formData.image) ? "block" : "none",
                          }}
                          onClick={handleRemoveImg}
                        >
                          <img src={cancelImage} width="20px" height="20px" />
                        </IconButton>
                      )}
                    </div>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        ...styles.label,
                        color: "grey",
                        textAlign: "right",
                      }}
                    >
                      {formData.isApproved === "approved"
                        ? "Your ID is Approved"
                        : null}
                      {formData.isApproved === "pending"
                        ? "Your ID is Under Review"
                        : null}
                      {formData.isApproved === "rejected"
                        ? "Your ID is Rejected. Please Re-Upload"
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ColorButton
                      variant="contained"
                      color="secondary"
                      sx={styles.colorBtn}
                      onClick={validateSubmission(handleSubmit)}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {isSaving ? (
                          <CircularProgress color="inherit" size={30} />
                        ) : (
                          "Save"
                        )}
                      </Typography>
                    </ColorButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
};
export default FirmDetailsTab;

const useStyles = makeStyles({
  menuLink: {
    textDecoration: "none",
    color: "#472264",
    marginBottom: 5,
    boxShadow: "none",
  },
  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    marginTop: "20px",
    padding: "30px",
  },

  textDiv: {
    marginBottom: "30px",
  }
});

const styles = {
  label: {
    fontWeight: "bold",
    marginBottom: 1,
  },
  iconBtn: {
    borderRadius: "10px",
    position: "absolute",
    top: -15,
    right: -15,
  } as const,
  colorBtn: { width: "100%", marginTop: "10px" },
  img: { width: "100%", height: "auto" },
  uploadTxt: {
    fontWeight: "bold",
    marginBottom: "2",
  },
  errorText: { marginBottom: "1", color: "red" },
};
