import React, { useEffect } from "react";
import "./style.css";
import Container from "@mui/material/Container";
import {
  Grid,
  Button,
  Typography,
  Divider,
  Box,
  TextField,
  Autocomplete,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, withRouter } from "react-router-dom";
import FinalOrder from "./FinalOrder";
import ShippingAddress from "./ShippingAddress.tsx";
import { getFinalCart } from "../../../components/src/Redux/Cart/reduxCartActions";
import { useDispatch, useSelector } from "react-redux";
import {noimg} from "../../Notifications/src/assets"
// @ts-ignore
const FinaliseOrder = ({ match,history }) => {
  const dispatch = useDispatch();
  const { id } = match.params;
  
  useEffect(() => {
    dispatch(getFinalCart(id,history));
  }, []);

  return (
    <Grid>
      <Grid sx={{ background: "#fff", display: "flex", padding: "10px 0" }}>
        <Container>
          <Grid container>
            <Grid item xs="12">
              <Link to="/shoppingCart" style={{ textDecoration: "none" }}>
                <Button
                  size="large"
                  sx={{ color: "#472264", fontWeight: "bold" }}
                  startIcon={
                    <ArrowBackIcon
                      className="back-btn"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                    Finalize Order
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Box
        sx={{
          background: "#EAEAEA",
          display: "flex",
          padding: "35px 0 55px 0",
        }}
      >
        <Container>
          <Grid container sx={{gap: 5}}>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              sx={{
                background: "#fff",
                borderRadius: "5px",
                padding: "25px 25px",
                boxShadow: 2,
              }}
            >
              <ShippingAddress />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              sx={{
                background: "#fff",
                borderRadius: "5px",
                padding: "15px",
                boxShadow: 2,
              }}
            >
              <FinalOrder id={id} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Grid>
  );
};

export default withRouter(FinaliseOrder);
