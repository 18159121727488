import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Category,
  Subcategory,
} from "../../../categoriessubcategories/src/Categoriessubcategories.web";
import { baseURL, getProductDetails } from "../assets";
import addProductSchema from "./productSchema";
import initialState from "./state";
const addFormData = require("../apiCalls");

const {
  addProduct,
  editProduct: editProductFromVendor,
  deleteImage,
  addProductFromAdmin,
  editProductFromAdmin,
} = addFormData;

const races = [
  "Angus", 
  "Limangus", 
  "Shorthorn", 
  "Bonsmara", 
  "Braford", 
  "Brangus", 
  "Hereford", 
  "Limousin", 
  "Charolais", 
  "Wagyu"
]

export interface Profile {
  user_role: string;
}

interface IProps {
  location: any;
  history: any;
}

const API = axios.create({
  baseURL: baseURL,
  timeout: 5000,
});

export default function useAddEditProduct(props: IProps) {
  const [values, setValues] = useState(initialState);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [files, setFiles] = useState("");
  const [fileUrls, setFileUrls] = useState<(string | ArrayBuffer | null)[]>([]);
  const [isSaving, setIsSaving] = useState(true);
  const [hasSavedValues, setHasSavedValues] = useState(false);
  const [fitFormData, setFitFormData] = useState(false);
  const [changedCategory, setChangedCategory] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const [productId, setProductId] = useState("");
  const [user, setUser] = useState("");
  const [formType, setFormType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const token = localStorage.getItem("authToken") || "";
  const profile: Profile = JSON.parse(localStorage.getItem("profile") || "{}");
  // @ts-ignore
  const details = useSelector((state) => state.products.productDetails);
  const isLoading = useSelector((state: any) => state.products.isLoading);
  const { addRemoveProduct, editProduct } = useSelector(
    // @ts-ignore
    (state) => state.landingPage
  );
  const dispatch = useDispatch();
  const {
    handleSubmit: validateSubmission,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(addProductSchema),
    defaultValues: initialState,
  });

  // Move users to previous page if s/he doesn't have permission
  // to view this page
  useEffect(() => {
    const path = props.location.pathname.split("/")[3];
    if (path === "editProduct") {
      if (!editProduct) {
        props.history.goBack();
      }
    } else {
      if (!addRemoveProduct) {
        props.history.goBack();
      }
    }
  }, [addRemoveProduct, editProduct]);

  // Delete images
  const handleImageDeletion = async (index: number) => {
    // Delete file from server if it is already uploaded
    // @ts-ignore
    if (values.images[index].id) {
      // @ts-ignore
      const response = await deleteImage(values.images[index].id, token);
      if (response.status === 200) {
        setValues((prev) => {
          return {
            ...prev,
            images: [...prev.images].filter((item, i) => index !== i),
          };
        });
      } else {
        toast.error("Something went wrong!");
      }
    } else {
      // Remove selected file from state
      setValues((prev) => {
        return {
          ...prev,
          images: [...prev.images].filter((item, i) => index !== i),
        };
      });
    }
  };

  // Delete product
  const handleDeleteClick = () => {
    setOpenModal((prev) => !prev);
  };

  // Submit add/edit form
  const handleSubmit = async (formData: any, isActive: boolean) => {
    if (values.images.length === 0) {
      toast.error("Please upload at least one image");
      return;
    }
    setIsSaving(true);
    const data = new FormData();
    vendorId && data.append("account_id", vendorId);
    data.append("category_id", values.categoryId);
    data.append("sub_category_id", values.subCategoryId);
    // @ts-ignore
    data.append("stock_qty", values.stockQty);
    data.append("name", formData.productName);
    data.append("price", formData.price);
    data.append("race", formData.race);
    data.append("birth_weight", formData.birthWeight);
    data.append("weaning_weight", formData.weaningWeight);
    data.append("weight_at_the_end", formData.weightAtTheEnd);
    formData.scrotal && data.append("scrotal_circumference", formData.scrotal);
    data.append("by_country", formData.byCountry);
    data.append("delivery", formData.delivery);
    // @ts-ignore
    data.append("active", isActive ? true : false);
    data.append("product_details", formData.description);
    data.append("father_data_attributes[animal_name]", formData.FanimalName);
    data.append("father_data_attributes[nick_name]", formData.FnickName);
    data.append("father_data_attributes[date_of_birth]", formData.FDateOfBirth);
    data.append("father_data_attributes[breed]", formData.Fbreed);
    formData.FAnalysisCode && data.append(
      "father_data_attributes[dna_analysis_code]",
      formData.FAnalysisCode
    );
    formData.FNumAnalysis && data.append(
      "father_data_attributes[number_of_dna_analysis]",
      formData.FNumAnalysis
    );
    formData.ManimalName && data.append("mother_data_attributes[animal_name]", formData.ManimalName);
    formData.MnickName && data.append("mother_data_attributes[nick_name]", formData.MnickName);
    formData.MDateOfBirth && data.append("mother_data_attributes[date_of_birth]", formData.MDateOfBirth);
    formData.Mbreed && data.append("mother_data_attributes[breed]", formData.Mbreed);
    formData.MAnalysisCode && data.append(
      "mother_data_attributes[dna_analysis_code]",
      formData.MAnalysisCode
    );
    formData.MNumAnalysis && data.append(
      "mother_data_attributes[number_of_dna_analysis]",
      formData.MNumAnalysis
    );
    [...values.images].forEach((image) => {
      // @ts-ignore
      if (!image.url) {
        data.append("images[]", image);
      }
    });

    try {
      let response;
      if (formType === "add") {
        response = vendorId
          ? await addProductFromAdmin(data, token)
          : await addProduct(data, token);
      } else {
        response = user
          ? await editProductFromAdmin(data, productId, token)
          : await editProductFromVendor(data, productId, token);
      }
      if (response.status === 200) {
        toast.success(isActive ? "Published" : "Saved");
        props.history.goBack();
      } else if (response.status === 201) {
        setValues(initialState);
        toast.success(isActive ? "Published" : "Saved");
        props.history.goBack();
      } else if (response.status === 422) {
        // @ts-ignore
        response.data.errors.forEach((err) => {
          toast.error(err.message);
        });
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      return err;
    } finally {
      setIsSaving(false);
    }
  };

  // Get product details from redux
  useEffect(() => {
    if (formType === "edit") {
      dispatch(getProductDetails(productId));
    } else {
      setIsSaving(false);
    }
  }, [formType, productId]);

  // Update state when details from redux is loaded
  useEffect(() => {
    if (
      formType === "edit" &&
      Object.getPrototypeOf(details) === Object.prototype &&
      Object.keys(details).length !== 0
    ) {
      setValues({
        productName: details.name,
        price: details.price,
        categoryId: details.category?.id,
        categoryName: details.category?.name,
        subCategoryId: details.sub_category ? details.sub_category.id : "",
        subCategoryName: details.sub_category ? details.sub_category.name : "",
        race: details.race,
        birthWeight: details.birth_weight,
        weaningWeight: details.weaning_weight,
        weightAtTheEnd: details.weight_at_the_end,
        scrotal: details.scrotal_circumference,
        byCountry: details.by_country,
        delivery: details.delivery,
        FanimalName: details.father_data?.attributes.animal_name,
        FnickName: details.father_data?.attributes.nick_name,
        FDateOfBirth: details.father_data?.attributes.date_of_birth,
        Fbreed: details.father_data?.attributes.breed,
        FAnalysisCode: details.father_data?.attributes.dna_analysis_code,
        FNumAnalysis: details.father_data?.attributes.number_of_dna_analysis,
        ManimalName: details.mother_data?.attributes.animal_name,
        MnickName: details.mother_data?.attributes.nick_name,
        MDateOfBirth: details.mother_data?.attributes.date_of_birth,
        Mbreed: details.mother_data?.attributes.breed,
        MAnalysisCode: details.mother_data?.attributes.dna_analysis_code,
        MNumAnalysis: details.mother_data?.attributes.number_of_dna_analysis,
        description: details.product_details,
        stockQty: details.stock_qty,
        images: details.images ? details.images : [],
      });

      // Set form data
      setValue("categoryName", details.category?.name);
      setValue(
        "subCategoryName",
        details.sub_category ? details.sub_category.name : ""
      );
      setValue("productName", details.name);
      setValue("price", details.price);
      setValue("race", details.race);
      setValue("birthWeight", details.birth_weight);
      setValue("weaningWeight", details.weaning_weight);
      setValue("weightAtTheEnd", details.weight_at_the_end);
      setValue("scrotal", details.scrotal_circumference);
      setValue("byCountry", details.by_country);
      setValue("delivery", details.delivery);
      setValue("description", details.product_details);
      setValue("FanimalName", details.father_data?.attributes.animal_name);
      setValue("FnickName", details.father_data?.attributes.nick_name);
      setValue("FDateOfBirth", details.father_data?.attributes.date_of_birth);
      setValue("Fbreed", details.father_data?.attributes.breed);
      setValue(
        "FAnalysisCode",
        details.father_data?.attributes.dna_analysis_code
      );
      setValue(
        "FNumAnalysis",
        details.father_data?.attributes.number_of_dna_analysis
      );
      setValue("ManimalName", details.mother_data?.attributes.animal_name);
      setValue("MnickName", details.mother_data?.attributes.nick_name);
      setValue("MDateOfBirth", details.mother_data?.attributes.date_of_birth);
      setValue("Mbreed", details.mother_data?.attributes.breed);
      setValue(
        "MAnalysisCode",
        details.mother_data?.attributes.dna_analysis_code
      );
      setValue(
        "MNumAnalysis",
        details.mother_data?.attributes.number_of_dna_analysis
      );
    }
  }, [details, formType]);

  // Load categories & countries
  useEffect(() => {
    const categoryApi = API.get(
      "/bx_block_categories/categories/category_list",
      { headers: { token } }
    );
    const countryApi = API.get("/account_block/accounts/country");
    Promise.all([categoryApi, countryApi])
      .then(([categoryResp, countryResp]) => {
        // Set categories
        setCategories(categoryResp.data.data);
        // Set countries
        setCountries(countryResp.data);
      })
      .catch(() =>
        toast.error("Something is not right. Please try reloading this page.")
      );
  }, []);

  // Generate sub categories
  useEffect(() => {
    if (values.categoryId) {
      const category = categories.find(
        (cat: Category) => cat.attributes.id === parseInt(values.categoryId)
      );
      category?.attributes.sub_categories &&
        // @ts-ignore
        setSubCategories(category?.attributes.sub_categories);
    }
  }, [values.categoryId, categories]);

  // Handle category change
  useEffect(() => {
    const catName = watch("categoryName");
    if (changedCategory) {
      const catIndex = categories.findIndex(
        (cat) => cat.attributes.name === catName
      );
      if (catIndex > -1) {
        const catId = categories[catIndex].attributes.id;
        setValue("subCategoryName", "");
        // @ts-ignore
        setValues((prev) => ({
          ...prev,
          categoryId: catId,
          subCategoryId: "",
        }));
      }
    }
  }, [watch("categoryName"), changedCategory]);

  // Detect category change
  useEffect(() => {
    const catName = watch("categoryName");
    if (catName !== values.categoryName) {
      setChangedCategory(true);
    }
  }, [watch("categoryName")]);

  // Change subcategory
  useEffect(() => {
    const subCatName = watch("subCategoryName");
    if (subCatName) {
      const subCatIndex = subCategories.findIndex(
        (subCat: Subcategory) => subCat.name === subCatName
      );
      if (subCatIndex > -1) {
        const subCatId = subCategories[subCatIndex]["id"];
        setValues((prev) => ({
          ...prev,
          subCategoryId: subCatId,
        }));
      }
    }
  }, [watch("subCategoryName")]);

  // Add files
  useEffect(() => {
    if (values.images.length + files.length > 5) {
      toast.error("Sorry! You can't upload more than 5 images.");
    } else {
      // @ts-ignore
      setValues((prev) => ({
        ...prev,
        // @ts-ignore
        images: [...prev.images, ...files],
      }));
    }
  }, [files]);

  // Generate file urls to display preview
  useEffect(() => {
    setFileUrls([]);
    [...values.images].forEach((img) => {
      // @ts-ignore
      if (img?.url) {
        // ts-ignore
        setFileUrls((prev) => {
          // @ts-ignore
          return [...prev, img.url];
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onloadend = () => {
          // ts-ignore
          setFileUrls((prev) => {
            return [...prev, reader.result];
          });
        };
      }
    });
  }, [values.images]);

  // Get data from props
  useEffect(() => {
    setVendorId(props.location.state?.vendorId);
    setProductId(props.location.state?.productId);
    setUser(props.location.state?.user);
    setFormType(props.location.state.formType);
  }, [props]);

  return {
    isLoading,
    isSaving,
    formType,
    profile,
    errors,
    categories,
    subCategories,
    control,
    values,
    setValues,
    setFiles,
    fileUrls,
    handleImageDeletion,
    races,
    countries,
    handleDeleteClick,
    validateSubmission,
    handleSubmit,
    openModal,
    setOpenModal,
    productId,
  };
}
