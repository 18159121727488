import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  formType: {
    color: "#472264",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
  },
  topContainer: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
  },
  sectionContainer: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
    marginTop: "20px",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "1",
    color: "#472264",
    margin: "10px 10px 0px 20px",
  },
  inputBase: {
    fontSize: 14,
    textAlign: "start",
  },
  select: {
    width: "95%",
    margin: "10px 10px",
    borderRadius: "10px",
    backgroundColor: "#edebeb",
    height: "45px",
    textAlign: "center",
    justifyContent: "center",
    padding: "0 20px",
  },
  cart: {
    width: "15%",
    margin: "10px 10px",
    borderRadius: "10px",
    backgroundColor: "#edebeb",
    // height:"50px",
    textAlign: "center",
    justifyContent: "center",
    padding: "2px 20px",
  },
  stockContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  stockQty: {
    padding: "10px",
    color: "#824bad",
  },
  heading: {
    fontWeight: "bold",
    marginBottom: "1",
    color: "#472264",
    fontSize: 20,
  },
  fileCount: {
    fontWeight: "normal",
    marginBottom: "1",
    color: "#472264",
    fontSize: 20,
  },
  uploadBtnContainer: {
    border: "1px dashed brown ",
    borderRadius: "10px",
    padding: "20px",
    width: "100%",
    height: "160px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadBtn: {
    color: "#824bad",
    fontWeight: "bold",
    textTransform: "none",
  },
  cancelIcon: {
    borderRadius: "10px",
    position: "absolute",
    top: -15,
    right: -14,
  },
  description: {
    fontSize: 14,
    width: "95%",
    margin: "10px 10px",
    borderRadius: "10px",
    textAlign: "start",
    backgroundColor: "#edebeb",
    padding: "20px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "wrap"
  },
});

export const styles = {
  previewContaier: {
    backgroundColor: "#edebeb",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    width: "100%",
    height: {
      md: "150px",
      xs: "200px",
    },
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  } as const,
  errorText: {
    marginTop: "3px",
    color: "red",
    lineHeight: 0,
    textAlign: "start",
  } as const,
  btn: { color: "#472264", margin: "20px" },
  btnPublish: { 
    backgroundColor: "#472264", 
    color: "white",
    "&:hover": {
      backgroundColor: "#472264"
    } 
  }
};
