import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

const { ColorButton } = require("./Styles");
import { getCountries } from "./assets";
import { BankDetails } from "./BankDetails";

const bankDetailSchema = yup.object().shape({
  full_name: yup.string().trim().required("Full name is required"),
  bank_name: yup.string().trim().required("Bank name is required"),
  bank_country: yup.string().trim().required("Country is required"),
  account_number: yup.string().trim().required("Account number is required"),
  account_number_confirmation: yup
    .string()
    .trim()
    .oneOf([yup.ref("account_number"), null], "Account numbers must match")
    .required("Account number confirmation is required"),
  routing_number: yup.number().required("Routing number is required").typeError("Routing number must be a number"),
  swift_key: yup.string().trim().required("Swift key is required"),
});

interface IProps {
  initialBankDetails: any;
  bankDetails: any;
  setBankDetails: any;
  bankDetailsExist: boolean;
  isSaving: any;
  error: any;
  message: any;
  handleSubmit: any;
  setBankEditPage: any;
  setError: any;
  setMessage: any;
}

// @ts-ignore
const ChangeDetails = ({
  initialBankDetails,
  bankDetails,
  setBankDetails,
  bankDetailsExist,
  isSaving,
  error,
  message,
  handleSubmit,
  setBankEditPage,
  setError,
  setMessage,
}: IProps) => {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);
  const {
    handleSubmit: validateSubmission,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(bankDetailSchema),
    defaultValues: initialBankDetails,
  });

  const getCountryList = async () => {
    const { data } = await getCountries();
    setCountries(data);
  };

  // Display saved data inside form
  useEffect(() => {
    setValue("full_name", bankDetails.full_name);
    setValue("bank_name", bankDetails.bank_name);
    setValue("bank_country", bankDetails.bank_country);
    setValue("account_number", bankDetails.account_number);
    setValue("routing_number", bankDetails.routing_number);
    setValue("swift_key", bankDetails.swift_key);
  }, [bankDetails]);

  // Load data
  useEffect(() => {
    getCountryList();
    setError([]);
    setMessage("");
  }, []);

  return (
    <Box>
      <Box style={styles.boxContainer}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Box className={classes.box}>
              <div style={{ display: "flex" }}>
                <Button
                  size="large"
                  sx={styles.backBtn}
                  startIcon={<ArrowBackIcon className="back-btn" />}
                  disabled={!bankDetailsExist}
                  onClick={() => setBankEditPage(false)}
                />

                <Typography variant="h5" gutterBottom sx={styles.backBtnText}>
                  Change Bank Account
                </Typography>
              </div>

              <Typography variant="subtitle1" sx={styles.label}>
                Full Name
              </Typography>
              <Controller
                name="full_name"
                control={control}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    placeholder="Full Name"
                    sx={{
                      ...styles.inputBase,
                      border: errors["full_name"]
                        ? "1px solid red"
                        : "1px solid #edebeb",
                    }}
                  />
                )}
              />
              {errors["full_name"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["full_name"]?.message}
                </Typography>
              ) : null}

              <Typography variant="subtitle1" sx={styles.label}>
                Bank Name
              </Typography>
              <Controller
                name="bank_name"
                control={control}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    placeholder="Bank Name"
                    sx={{
                      ...styles.inputBase,
                      border: errors["bank_name"]
                        ? "1px solid red"
                        : "1px solid #edebeb",
                    }}
                  />
                )}
              />
              {errors["bank_name"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["bank_name"]?.message}
                </Typography>
              ) : null}

              <Typography variant="subtitle1" sx={styles.label}>
                Bank Country
              </Typography>
              <FormControl
                sx={{ 
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#edebeb"
                  }
                }}
                error={errors["bank_country"] ? true : false}
              >
                <InputLabel id="bulk-actions">Select Country</InputLabel>
                <Controller
                  name="bank_country"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={countries.length > 0 ? field.value : ""}
                      labelId="country-select"
                      id="demo-simple-select"
                      sx={{ width: "100%" }}
                      label="Select Country"
                      displayEmpty
                    >
                      {countries.map((country: any) => (
                        <MenuItem key={country.code} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors["bank_country"] ? (
                  <Typography variant="subtitle2" sx={styles.errorText}>
                    {errors["bank_country"]?.message}
                  </Typography>
                ) : null}
              </FormControl>

              <Typography variant="subtitle1" component="div" sx={styles.label}>
                Account Number
              </Typography>
              <Controller
                name="account_number"
                control={control}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    placeholder="Account Number"
                    sx={{
                      ...styles.inputBase,
                      border: errors["account_number"]
                        ? "1px solid red"
                        : "1px solid #edebeb",
                    }}
                  />
                )}
              />
              {errors["account_number"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["account_number"]?.message}
                </Typography>
              ) : null}

              <Typography variant="subtitle1" sx={styles.label}>
                Confirm Account Number
              </Typography>
              <Controller
                name="account_number_confirmation"
                control={control}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    placeholder="Confirm Account Number"
                    sx={{
                      ...styles.inputBase,
                      border: errors["account_number_confirmation"]
                        ? "1px solid red"
                        : "1px solid #edebeb",
                    }}
                  />
                )}
              />
              {errors["account_number_confirmation"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["account_number_confirmation"]?.message}
                </Typography>
              ) : null}

              <Typography variant="subtitle1" sx={styles.label}>
                Routing Number
              </Typography>
              <Controller
                name="routing_number"
                control={control}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    placeholder="Routing Number"
                    sx={{
                      ...styles.inputBase,
                      border: errors["routing_number"]
                        ? "1px solid red"
                        : "1px solid #edebeb",
                    }}
                  />
                )}
              />
              {errors["routing_number"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["routing_number"]?.message}
                </Typography>
              ) : null}

              <Typography variant="subtitle1" sx={styles.label}>
                SWIFT Key
              </Typography>
              <Controller
                name="swift_key"
                control={control}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    placeholder="SWIFT Key"
                    sx={{
                      ...styles.inputBase,
                      border: errors["swift_key"]
                        ? "1px solid red"
                        : "1px solid #edebeb",
                    }}
                  />
                )}
              />
              {errors["swift_key"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["swift_key"]?.message}
                </Typography>
              ) : null}

              <ColorButton
                variant="contained"
                color="secondary"
                sx={styles.colorBtn}
                onClick={validateSubmission(handleSubmit)}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {isSaving ? (
                    <CircularProgress color="inherit" size={30} />
                  ) : (
                    "Submit"
                  )}
                </Typography>
              </ColorButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChangeDetails;

const useStyles = makeStyles({
  menuLink: {
    textDecoration: "none",
    color: "#472264",
    marginBottom: 5,
    boxShadow: "none",
  },
  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "50px 30px",
  },
  title: {
    fontWeight: "normal",
    fontSize: 14,
  },
  detail: {
    margin: "30px",
  },

  boxproduct: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
  },
});

const styles = {
  boxContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "20px",
  },
  backBtn: { color: "#472264", fontWeight: "bold" },
  backBtnText: { fontWeight: "bold", marginTop: "10px" },
  inputBase: {
    color: "black",
    fontSize: 14,
    border: "1px solid #edebeb",
    borderRadius: "5px",
    width: "100%",
    padding: "10px",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "1",
    margin: "10px 0 5px 10px",
  },
  colorBtn: { width: "100%", marginTop: "20px" },
  errorText: { color: "red", margin: "0 0 0 10px", },
};
