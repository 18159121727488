import React from 'react';
import { Typography, Grid, Box, Button, Modal } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';

import { style, propsInterface } from './SectionOnePreview';

export default function SectionSixPreview({
  open,
  handleClose,
  backgroundImg,
  fileUrl,
  title,
  link,
}: propsInterface) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{...style, width: "90vw"}}>
        {/* Cancel icon */}
        <Box sx={{ position: 'relative' }}>
          <CancelIcon
            fontSize='large'
            sx={styles.cancelIcon}
            onClick={handleClose}
          />
        </Box>
        <Grid
          container
          sx={{
            width: "90%",
            height: "450px",
            margin: {
              xs: "15px auto",
              sm: "30px auto",
            },
            borderRadius: "20px",
            backgroundColor: "#6a3a8e",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              ...styles.left,
              backgroundImage: `url(${fileUrl ? fileUrl : backgroundImg})`,
            }}
          />
          <Grid
            item
            xs={12}
            sm={6}
            sx={styles.right}
          >
            <Box
              sx={styles.titleContainer}
            >
              <Typography
                variant='h2'
                color='white'
                sx={styles.title}
              >
                {title}
              </Typography>
              <Link to={`/${link}`} target="_blank" style={{ textDecoration: 'none' }}>
                <Button
                  sx={styles.btn}
                >
                  Show More
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
        {/* <Box
          sx={styles.container}
        >
        </Box> */}
      </Box>
    </Modal>
  );
}

const styles = {
  cancelIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
    color: '#d6b336',
    cursor: 'pointer',
  } as const,
  container: {
    width: '100%',
    height: '450px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '60px',
  },
  left: {
    height: {
      xs: "220px",
      sm: "auto"
    },
    backgroundSize: 'cover',
    borderRadius: {
      xs: "20px 20px 0px 0px;",
      sm: "20px 0px 0px 20px;",
    },
    borderRight: {
      xs: 0,
      sm: "10px solid #d6b236",
    },
    borderBottom: {
      xs: "10px solid #d6b236",
      sm: 0,
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: {
      xs: "2.75rem",
      sm: "3.75rem",
    },
    textAlign: "center",
    marginBottom: "30px",
  } as const,
  btn: {
    width: '150px',
    height: '50px',
    backgroundColor: '#d6b236',
    color: 'black',
    borderRadius: '5px',
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as const,
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  } as const
}
