import React, {useState, useEffect} from "react";
import { withRouter, Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Divider, IconButton, Grid } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Logo,
  cartIcon,
  bell,
  search,
  getNotifications,
  getUnreadNotifications,
  getLandingCategoryList,
  updateUserProfile,
  manageCartActiveProducts,
} from "./importAssets";
import ProfileButton from "./ProfileButton";
import Notify from "../../blocks/Notifications/src/NotifyNav";
import StyledMenu from "./StyledMenu";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "./Redux/Cart/reduxCartActions";
import CatalogSearch from "./CatalogSearch";
import BuyerMenu from "./BuyerMenu";
import BuyerMenuDrawer from "./BuyerMenuDrawer";

// @ts-ignore
const TopNav = ({ history, match }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const dispatch = useDispatch();
  const cartQty = useSelector((state) => state.cart.cartItems);
  const { user_role } = useSelector((state) => state.landingPage.profile);
  const { unReadNotification, unreadCount } = useSelector(
      (state) => state.notifications
  );
  const { categories } = useSelector((state) => state.landingPage);
  const [isLogin, setIsLogin] = useState(false);
  const [searchVisibility, setSearchVisibility] = useState(false);

  const toggleDrawer = (event) => {
    if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer((prevStatus) => !prevStatus);
  };

  useEffect(() => {
    if (cartQty === 0) {
      dispatch(setCart());
    }
    if (isLogin) {
      dispatch(getNotifications());
      dispatch(getUnreadNotifications());
      dispatch(updateUserProfile(history));
    }
  }, [isLogin]);

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getLandingCategoryList(match));
    }
    const cart = localStorage.getItem("cart")
    if(cart){
      dispatch(manageCartActiveProducts())
    }
    if(cart === 'undefined')
    {
      localStorage.removeItem("cart")
    }
  }, []);

  const manageCartNavigation = () => {
    history.push("/shoppingCart");
  };
  // POPUP
  const [anchorE3, setAnchorE3] = React.useState(null);
  const openPopup = Boolean(anchorE3);
  // POPUP
  const handlePopup = (event) => {
    if (unReadNotification.length > 0) {
      setAnchorE3(event.currentTarget);
    } else {
      if (isLogin) {
        console.log(location.pathname)
        if(location.pathname !== "/notifications"){
          history.push("/notifications");
        }
      }
    }
  };
  const handleClose = () => {
    setAnchorE3(null);
  };

  const handleSearch = () => {
    setSearchVisibility(!searchVisibility);
  };

  return (
      <Box sx={{ flexGrow: 1, zIndex: "0" }}>
        <AppBar
            position="static"
            sx={{ backgroundColor: "#6a3a8e", padding: { xs: "10px 0", md: 0 } }}
        >
          <Toolbar sx={{ flexWrap: "wrap" }}>
            <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexGrow: 1,
                }}
            >
              <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={toggleDrawer}
                  sx={styles.toggleBtn}
              >
                <MenuIcon sx={styles.menuIcon} />
              </IconButton>
              <Link to="/" style={{ textDecoration: "none" }}>
                <img
                    src={Logo}
                    width="100px"
                    alt="Brand Logo"
                    style={{ padding: "10px" }}
                />
              </Link>
            </Box>
            {searchVisibility ? (
                <Box
                    sx={{
                      marginRight: "15px",
                      marginBottom: {
                        xs: "8px",
                        sm: 0,
                      },
                      display: "flex",
                    }}
                >
                  <CatalogSearch />
                </Box>
            ) : null}
            <Box sx={{ marginRight: "35px", marginY: "5px" }}>
              <Badge color="secondary" badgeContent={0}>
                <IconButton onClick={handleSearch}>
                  <img
                      src={search}
                      width="23px"
                      alt="Brand Logo"
                      style={{ cursor: "pointer" }}
                  />
                </IconButton>
              </Badge>
            </Box>
            {
              user_role === "buyer"  &&
                <>
                  <Box sx={{ marginRight: "25px", marginY: "5px" }}>
                    <IconButton onClick={manageCartNavigation}>
                      <Badge color="secondary" badgeContent={cartQty}>
                        <img
                            src={cartIcon}
                            width="33px"
                            alt="Brand Logo"
                            style={{ cursor: "pointer", paddingRight: "10px" }}
                        />
                      </Badge>
                    </IconButton>
                  </Box>
                  <Box style={{ marginRight: "25px", marginTop: "5px" }}>
                    <Badge badgeContent={unreadCount} color="success">
                      <Box
                          aria-controls="demo-customized-popup"
                          onClick={handlePopup}
                          id="demo-customized-popup-open"
                      >
                        <img
                            src={bell}
                            width="21px"
                            alt="Notifications"
                            style={{ cursor: "pointer" }}
                        />
                      </Box>
                    </Badge>
                    {isLogin ? (
                        <StyledMenu
                            anchorEl={anchorE3}
                            open={openPopup}
                            onClose={handleClose}
                        >
                          {unReadNotification?.map((n,key) => {
                            return (
                                <div key={key}>
                                  <Notify notification={n} />
                                  <Divider />
                                </div>
                            );
                          })}
                          <Grid sx={{ padding: "7px", textAlign: "center" }}>
                            <Link to="/notifications" style={{ textDecoration: "none" }}>
                              <Typography variant="subtitle1">SEE MORE</Typography>
                            </Link>
                          </Grid>
                        </StyledMenu>
                    ) : null}
                  </Box>
                </>
            }
            {
                !user_role  &&
                <>
                  <Box sx={{ marginRight: "25px", marginY: "5px" }}>
                    <IconButton onClick={manageCartNavigation}>
                      <Badge color="secondary" badgeContent={cartQty}>
                        <img
                            src={cartIcon}
                            width="33px"
                            alt="Brand Logo"
                            style={{ cursor: "pointer", paddingRight: "10px" }}
                        />
                      </Badge>
                    </IconButton>
                  </Box>
                  <Box style={{ marginRight: "25px", marginTop: "5px" }}>
                    <Badge badgeContent={unreadCount} color="success">
                      <Box
                          aria-controls="demo-customized-popup"
                          onClick={handlePopup}
                          id="demo-customized-popup-open"
                      >
                        <img
                            src={bell}
                            width="21px"
                            alt="Notifications"
                            style={{ cursor: "pointer" }}
                        />
                      </Box>
                    </Badge>
                    {isLogin ? (
                        <StyledMenu
                            anchorEl={anchorE3}
                            open={openPopup}
                            onClose={handleClose}
                        >
                          {unReadNotification?.map((n,key) => {
                            return (
                                <div key={key}>
                                  <Notify notification={n} />
                                  <Divider />
                                </div>
                            );
                          })}
                          <Grid sx={{ padding: "7px", textAlign: "center" }}>
                            <Link to="/notifications" style={{ textDecoration: "none" }}>
                              <Typography variant="subtitle1">SEE MORE</Typography>
                            </Link>
                          </Grid>
                        </StyledMenu>
                    ) : null}
                  </Box>
                </>
            }
            <ProfileButton isLogin={isLogin} setIsLogin={setIsLogin} />
          </Toolbar>
        </AppBar>
        <AppBar position="static" style={{ backgroundColor: "#d6b236" }}>
          <BuyerMenu />
          <BuyerMenuDrawer
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              toggleDrawer={toggleDrawer}
          />
        </AppBar>
      </Box>
  );
};

export default withRouter(TopNav);

const styles = {
  toggleBtn: {display: { xs: "block", lg: "none" } },
  menuIcon: {
    backgroundColor: "#6a3a8e",
    borderRadius: "3px",
    padding: "2px",
    fontSize: "2rem"
  },
};

