export const emptyMyOrdersIcon = require("../assets/emptyMyOrdersIcon.png");
export const {baseURL} = require("framework/src/config");
export const whiteArrowImage = require("../assets/arrow.png");
export const cowImage = require("../assets/cow.png");
export const cow2Image = require("../assets/cow2.png");
export const eyeImage = require("../assets/eye.png");
export const searchImage = require("../assets/search.png");
export const {
    getOrderList,
    updateOrderStatus,
    getOrderDetails,
    setCurrentPageOrders,
    setShowCountOrders,
    setSearchKeyOrders,
    setSearchTextOrders,
    getOrderListBySearch,
    setOrderFilter,
    updateOrderStatusDetails
} = require("../../../components/src/Redux/Orders/reduxOrdersActions");