import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { ColorButton, registerStpes, registerStpesLine } from "./Style";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import { getCountries,getStates } from "./api";
import * as yup from "yup";
import {useForm,Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import CircularProgress from "@mui/material/CircularProgress";

const initialState = {
  name: "",
  address_line1: "",
  address_line2: "",
  country: "Argentina",
  state: "",
  zipcode: ""
};

const RegisterForm = ({ setFormType, setVendorRegistration }) => {
  const firmDetails = yup.object().shape({
    "name":yup.string().required("Please enter firm name"),
    "address_line1":yup.string().required("Please enter firm address 1"),
    "address_line2":yup.string().required("Please enter firm address 2"),
    "state":yup.string().required("Please select state"),
    "zipcode":yup.string().required("Please enter zip code").min(4,"Please enter valid Zipcode"),
  })
  const { register, handleSubmit, watch, formState: { errors },control:firmController } = useForm({
    resolver: yupResolver(firmDetails),
    defaultValues:initialState
  });

  const [values, setValues] = React.useState(initialState);
  const [error, setError] = useState("");
  const [country, setCountry] = useState([]);
  const [countryValue, setCountryValue] = useState("AR");
  const [states, setStates] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    countryList();
    getStateData("AR");
  }, []);

  const countryList = async () => {
    const ctry = await getCountries();
    setCountry(ctry?.data);
  };

  const handleSubmit1 = (formData) => {
    if (
        formData.name &&
        formData.address_line1 &&
        formData.address_line2 &&
        values.country &&
        formData.state &&
        formData.zipcode
    ) {
      setVendorRegistration({
        // @ts-ignore
        ...formData,
        country:values.country
      });
      setFormType("registerVendor2");
    } else {
      setError([
        {
          message: "Please Fill Proper details"
        }
      ]);
    }
  };

  const handleCountry = e => {
    setCountryValue(e.target.value);
    setStates([]);
    let { data } = "";
    country.filter(x => {
      if (x.code == e.target.value) {
        data = x;
        getStateData(data.code);
      }
    });
    setValues({ ...values, country: data.name });
  };
  const getStateData = async code => {
    setStateLoading(true)
    const { data } = await getStates(code);
    setStates(data);
    setStateLoading(false)
  };
  const handleStateUpdate = e => {
    setValues({ ...values, state: e.target.value });
  };
  return (
    <Grid item xs={12} className="registerMainDiv" style={{padding:'40px 0px'}}>
      <Box className="registerFormFlex">
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Vendor registration in only 3 steps
        </Typography>
        <Typography variant="subtitle1" component="div">
          Provide your firm details
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "20px 0px"
          }}
        >
          <Box sx={registerStpes} style={{ backgroundColor: "purple" }} />
          <Box sx={registerStpesLine} style={{ backgroundColor: "#F0F0F0" }} />
          <Box sx={registerStpes} style={{ backgroundColor: "#F0F0F0" }} />
          <Box sx={registerStpesLine} style={{ backgroundColor: "#F0F0F0" }} />
          <Box sx={registerStpes} style={{ backgroundColor: "#F0F0F0" }} />
        </Box>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          {error
            ? error.map((e, index) => {
                return (
                  <Alert
                    key={index}
                    severity="error"
                    sx={{ width: "82%", alignSelf: "center" }}
                  >
                    {e.message}
                  </Alert>
                );
              })
            : null}
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Firm Name
            </Typography>
            <Controller
                name="name"
                control={firmController}
                render={({ field }) => (
                  <TextField
                    error={errors["name"]}
                    {...field}
                    block
                    placeholder="Enter your firm name"
                    size="small"
                    sx={{ width: "90%" }}
                  />
                )} />
            {errors["name"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["name"]?.message}
                </Typography> : null
            }
          </Box>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Address Line 1
            </Typography>
            <Controller
                name="address_line1"
                control={firmController}
                render={({ field }) => (
                <TextField
                  error={errors["address_line1"]}
                  {...field}
                  block
                  placeholder="Enter your firm address 1"
                  size="small"
                  sx={{ width: "90%" }}
                />
              )} />
            {errors["address_line1"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["address_line1"]?.message}
                </Typography> : null
            }
          </Box>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Address Line 2
            </Typography>
            <Controller
              name="address_line2"
              control={firmController}
              render={({ field }) => (
                <TextField
                  error={errors["address_line2"]}
                  {...field}
                  block
                  placeholder="Enter your firm address 2"
                  size="small"
                  sx={{ width: "90%" }}
                />
            )} />
            {errors["address_line2"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["address_line2"]?.message}
                </Typography> : null
            }
          </Box>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Country
            </Typography>
            <FormControl sx={{ width: "90%" }}>
              <Select
                error={errors["country"]}
                {...register("country")}
                size="small"
                value={countryValue}
                onChange={e => handleCountry(e)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {country?.map((c, index) => {
                    return <MenuItem value={c.code}>{c.name}</MenuItem>;
                })}
              </Select>
              {errors["country"] ?
                  <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{ marginBottom: "1",color:"red" }}
                  >
                    {errors["country"]?.message}
                  </Typography> : null
              }
            </FormControl>
          </Box>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              State
            </Typography>
            {/* <TextField block placeholder="Enter your State" size='small' sx={{width:"90%"}}  onChange={handleChange('state')}/> */}
            <FormControl sx={{ width: "90%",display:"flex",alignItems:"center" }}>
              {
                stateLoading ? <CircularProgress/>:
                    <Controller
                        name="state"
                        control={firmController}
                        render={({ field }) => (
                        <Select
                            error={errors["state"]}
                            {...field}
                            sx={{width:"100%"}}
                            size="small"
                            placeholder="Select State"
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">Select State</MenuItem>
                          {states.map((s, index) => {
                            return <MenuItem value={`${s.name}`}>{s.name}</MenuItem>
                          })}
                        </Select>
                        )} />
                    }
              {errors["state"] ?
                  <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{ marginBottom: "1",color:"red",alignSelf:"flex-start" }}
                  >
                    {errors["state"]?.message}
                  </Typography> : null
              }
            </FormControl>
          </Box>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Zipcode
            </Typography>
            <Controller
                name="zipcode"
                control={firmController}
                render={({ field }) => (
              <TextField
                error={errors["zipcode"]}
                {...field}
                block
                type="text"
                placeholder="Enter zipcode"
                size="small"
                sx={{ width: "90%" }}
              /> )} />
            {errors["zipcode"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red"}}
                >
                  {errors["zipcode"]?.message}
                </Typography> : null
            }
          </Box>
          <Box>
            <ColorButton
              variant="contained"
              color="secondary"
              sx={{ width: "90%" }}
              onClick={handleSubmit(handleSubmit1)}
            >
              NEXT
            </ColorButton>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default RegisterForm;
