// @ts-ignore
import React from 'react';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const TermsAndConditions = () => {
    return (
        <Container sx={{minHeight:"100vh",width:"100%"}}>
            <Box sx={{marginTop:"30px"}}>
                <Typography variant="h2" sx={{textAlign:"center",fontWeight:"bold",color:"#693a8e"}}>Terms & Conditions</Typography>
                <Box sx={{marginTop:"30px"}}>
                    <ol>
                        <li style={{marginTop:"30px"}}>
                            <Typography variant="subtitle1" sx={{textAlign:"left",fontWeight:"bold",color:"#693a8e"}}>
                                YOUR AGREEMENT
                            </Typography>
                            <Typography variant="subtitle2" sx={{textAlign:"left",color:"#693a8e"}}>
                                By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.
                                PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.
                            </Typography>
                        </li>
                        <li style={{marginTop:"30px"}}>
                            <Typography variant="subtitle2" sx={{textAlign:"left",fontWeight:"bold",color:"#693a8e"}}>
                                YOUR AGREEMENT
                            </Typography>
                            <Typography variant="subtitle2" sx={{textAlign:"left",color:"#693a8e"}}>
                                By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.
                                PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.
                            </Typography>
                        </li>
                        <li style={{marginTop:"30px"}}>
                            <Typography variant="subtitle2" sx={{textAlign:"left",fontWeight:"bold",color:"#693a8e"}}>
                                YOUR AGREEMENT
                            </Typography>
                            <Typography variant="subtitle2" sx={{textAlign:"left",color:"#693a8e"}}>
                                By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.
                                PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.
                            </Typography>
                        </li>
                        <li style={{marginTop:"30px"}}>
                            <Typography variant="subtitle2" sx={{textAlign:"left",fontWeight:"bold",color:"#693a8e"}}>
                                YOUR AGREEMENT
                            </Typography>
                            <Typography variant="subtitle2" sx={{textAlign:"left",color:"#693a8e"}}>
                                By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.
                                PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.
                            </Typography>
                        </li>
                        <li style={{marginTop:"30px"}}>
                            <Typography variant="subtitle2" sx={{textAlign:"left",fontWeight:"bold",color:"#693a8e"}}>
                                YOUR AGREEMENT
                            </Typography>
                            <Typography variant="subtitle2" sx={{textAlign:"left",color:"#693a8e"}}>
                                By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.
                                PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.
                            </Typography>
                        </li>
                        <li style={{marginTop:"30px"}}>
                            <Typography variant="subtitle2" sx={{textAlign:"left",fontWeight:"bold",color:"#693a8e"}}>
                                YOUR AGREEMENT
                            </Typography>
                            <Typography variant="subtitle2" sx={{textAlign:"left",color:"#693a8e"}}>
                                By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.
                                PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.
                            </Typography>
                        </li>
                    </ol>
                </Box>
            </Box>
        </Container>
    );
};

export default TermsAndConditions;