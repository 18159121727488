import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@mui/material/Typography";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Box, Stack} from "@mui/material";

export default function MyDropzone({ setFiles }) {
  
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.length > 0 && setFiles(acceptedFiles);
    }, []);
    
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  return (
    <Box {...getRootProps()} sx={styles.container}>
      <input {...getInputProps()} accept="image/*" />
      <Stack alignItems="center">
          <UploadFileIcon fontSize="large" color="secondary" />
          <Typography variant="subtitle1" component="div" color="purple">
            {isDragActive ? "Drop here" : "Upload"}
          </Typography>
        </Stack>
    </Box>
  );
}

const styles = {
  container: {
    height: {
      md: "150px",
      xs: "200px"
    },
    width: "100%",
    border: "2px dashed orange",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
    cursor: "pointer"
  }
}
