import * as React from "react";
import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import styles from "./styles";

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

export default function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead sx={{ background: "#472264" }}>
      <TableRow>
        <TableCell padding="checkbox" sx={styles.tableCellOne}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            sx={{ color: "#fff" }}
            color="secondary"
          />
        </TableCell>
        <TableCell align="left" sx={styles.tableCell}>
          Category
        </TableCell>
        <TableCell align="left" sx={styles.tableCell}>
          Sub-category
        </TableCell>
        <TableCell align="center" sx={styles.tableCell}>
          Activate/Deactivate
        </TableCell>
        <TableCell
          align="left"
          sx={{
            ...styles.tableCellOne,
            borderRadius: "0 8px 0 0",
          }}
        >
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
