const initialState = {
    cart:[],
    finalCart:[],
    cartItems:0,
    cartId:'',
    finalCartLoading:false,
    currentVendor:0,
}

export const cartReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case "SET_CART":
            return {
                ...state,
                cart: payload || [],
                cartItems: payload?.length || 0
            }
        case "ADD_TO_CART":
            let cartArray = {}
            if(state.cart){
                cartArray = {
                    ...state,
                    cart: [
                        ...state.cart,
                        payload
                    ]
                }
            }else{
                cartArray = {
                    ...state,
                    cart: [
                        payload
                    ]
                }
            }
            const currentLocalCart = JSON.parse(localStorage.getItem("cart"))
            if(currentLocalCart){
                localStorage.setItem("cart",JSON.stringify([
                    ...currentLocalCart,
                    payload
                ]))
            }else {
                localStorage.setItem("cart",JSON.stringify([
                    payload
                ]))
            }
            return cartArray
        case "ADD_COUNT":
            return {
                ...state,
                cartItems: state.cartItems + 1
            }
        case "SET_FINAL_CART":
            return {
                ...state,
                finalCart:payload
            }
        case "SET_FINAL_LOADING_FALSE":
            return {
                ...state,
                finalCartLoading:false
            }
        case "SET_FINAL_LOADING_TRUE":
            return {
                ...state,
                finalCartLoading:true
            }
        case "SET_CART_ID":
            return {
                ...state,
                cartId:payload
            }
        case "UPDATE_CART_ITEM_COUNT_PLUS":
            const updatedCart = state.cart.map((item)=>{
                if(item.id === payload.id){
                    return{
                        ...item,
                        quantity:payload.count + 1,
                        attributes:{
                            ...item.attributes,
                            quantity:payload.count + 1,
                            total_price: (payload.count + 1) * item.attributes.unit_price
                        }
                    }
                }else{
                    return item
                }
            })
            localStorage.setItem("cart",JSON.stringify(updatedCart))
            return {
                ...state,
                cart: updatedCart
            }
        case "UPDATE_CART_ITEM_COUNT_MINUS":
            const updatedMinusCart = state.cart.map((item)=>{
                if(item.id === payload.id){
                    return{
                        ...item,
                        quantity:payload.count - 1,
                        attributes:{
                            ...item.attributes,
                            quantity:payload.count - 1,
                            total_price: (payload.count - 1) * item.attributes.unit_price
                        }
                    }
                }else{
                    return item
                }
            })
            localStorage.setItem("cart",JSON.stringify(updatedMinusCart))
            return {
                ...state,
                cart: updatedMinusCart
            }
        case "REMOVE_ALL_ITEMS_CART":
            localStorage.removeItem("cart")
            return {
                ...state,
                cart:[],
                cartItems:0,
                currentVendor: ''
            }
        case "REMOVE_ITEM_CART":
            const newArray = state.cart.filter((value,index)=>{
                return value.id !== payload.id
            })
            localStorage.setItem("cart",JSON.stringify(newArray))
            if(newArray.length > 0){
                return {
                    ...state,
                    cart:newArray,
                    cartItems: state.cartItems - 1
                }
            }else{
                return {
                    ...state,
                    cart:newArray,
                    cartItems: 0,
                    currentVendor: ''
                }
            }
        case "UPDATE_FINAL_CART_ITEM_COUNT_PLUS":
            const updatedfinalCart = state.finalCart.map((item)=>{
                if(item.id === payload.id){
                    return{
                        ...item,
                        attributes:{
                            ...item.attributes,
                            quantity:payload.count + 1,
                            total_price: (payload.count + 1) * item.attributes.unit_price
                        }
                    }
                }else{
                    return item
                }
            })
            return {
                ...state,
                finalCart: updatedfinalCart
            }
        case "UPDATE_FINAL_CART_ITEM_COUNT_MINUS":
            const updatedMinusfinalCart = state.finalCart.map((item)=>{
                if(item.id === payload.id){
                    return{
                        ...item,
                        attributes:{
                            ...item.attributes,
                            quantity:payload.count - 1,
                            total_price: (payload.count - 1) * item.attributes.unit_price
                        }
                    }
                }else{
                    return item
                }
            })
            return {
                ...state,
                finalCart: updatedMinusfinalCart
            }
        case "REMOVE_ITEM_FINAL_CART":
            const newFinalArray = state.finalCart.filter((value,index)=>{
                return value.id !== payload.id
            })
            return {
                ...state,
                finalCart:newFinalArray,
            }
        case "UPDATE_CART_ITEM_COUNT_QTY":
            const updatedCartQty = state.cart.map((item)=>{
                if(item.id === payload.id){
                    return{
                        ...item,
                        quantity:payload.count,
                        attributes:{
                            ...item.attributes,
                            quantity:payload.count,
                            total_price: (payload.count) * item.attributes.unit_price
                        }
                    }
                }else{
                    return item
                }
            })
            localStorage.setItem("cart",JSON.stringify(updatedCartQty))
            return {
                ...state,
                cart: updatedCartQty
            }
        case "SET_VENDOR_ID_FOR_CART":
            return {
                ...state,
                currentVendor:payload,
            }
        case "VALIDATE_CART_ITEMS":
            const validateCart = state.cart.map((item) => {
                let flag = false
                payload.forEach((p)=>{
                    if(item.catalogue_id === p.toString()){
                        console.log(item.catalogue_id === p.toString())
                        console.log(item.catalogue_id,p)
                        flag = true
                    }else{
                        if(!flag){
                            flag = false
                        }
                    }
                })
                if(flag){
                    return{
                        ...item,
                        attributes:{
                            ...item.attributes,
                            status:"inactive"
                        }
                    }
                }else {
                    return item
                }
            })
            localStorage.setItem("cart",JSON.stringify(validateCart))
            return {
                ...state,
                cart: validateCart
            }
        case "CHANGE_STATUS_IF_VALIDATION_RESPONSE_GIVE_ZERO":
            const changeStatus = state.cart.map((item) => {
                return{
                    ...item,
                    attributes:{
                        ...item.attributes,
                        status:"active"
                    }
                }
            })
            localStorage.setItem("cart",JSON.stringify(changeStatus))
            return {
                ...state,
                currentVendor:changeStatus,
            }
        default:
            return state
    }
}