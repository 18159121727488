import React, {useState,useEffect} from 'react';
import { Grid, Button, Typography, Modal, Box  } from '@mui/material';
import {deleteIcon,product} from './assets'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';
import {useDispatch} from "react-redux";
import {updateAddCartItemQty,updateMinusCartItemQty,removeCartItem} from './assets';
import {noimg} from "../../Notifications/src/assets"

// @ts-ignore
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// @ts-ignore
const CartItems = ({item}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        setCartNum(item.attributes.quantity)
    }, [item]);

    const [cartNum, setCartNum] = useState(1)

    // useEffect(() => {
    //     if(item.attributes.quantity !== cartNum ){
    //         dispatch(updateCartItemQty(item.id,cartNum))
    //     }
    // }, [cartNum]);

    const increaseCart = () => {
        if(cartNum < 10){
            dispatch(updateAddCartItemQty(item.id,cartNum))
        }
    }

    const decreaseCart = () => {
        if(cartNum > 1){
            dispatch(updateMinusCartItemQty(item.id,cartNum))
        }
    }

    // @ts-ignore
    return (
        <Grid sx={{marginBottom:'20px'}}>
            {/* ITEMS */}
            <Grid container className="cart-content" sx={{ boxShadow: 2 }} xs={12} display="flex" alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={4} md={4} lg={4} display="flex" alignItems="center" sx={{ paddingBottom : { xs : '15px' }}}>
                    <img src={item?.attributes?.catalogue_image?.url || noimg} height='50px' alt="product"/>
                    <Typography variant="subtitle1" fontWeight="700" sx={{ padding : '5px 10px'}}>
                        {
                            //@ts-ignore
                            <strike>{item.attributes.catalogue_name} - {item.attributes.vendor_name} </strike>
                        }
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={2} md={2} lg={2} sx={{ paddingBottom : { xs : '15px' }}}>
                    <Typography variant="subtitle2" sx={{ display : { xs : 'flex', sm : 'none'}}}>Price</Typography>
                    <Typography variant="subtitle1" fontWeight="700" sx={{ padding : '5px 0'}}>
                        {
                            //@ts-ignore
                            <strike>$ {numberWithCommas(item.attributes.unit_price)}</strike>
                        }
                    </Typography>
                </Grid>
                <Grid item className="cart-box" xs={6} sm={2} md={2} lg={2}  display="flex" sx={{ paddingBottom : { xs : '15px' }, justifyContent : { xs : 'right', sm : 'left'}}}>
                    <Grid className="cart-items-box" xs={6}>
                        <Typography sx={{ padding : '0 5px'}} variant="h6">{cartNum}</Typography>
                        <Grid className="button-grp">
                            <IconButton className="btns-inc-dec"><ExpandLessIcon /></IconButton>
                            <IconButton className="btns-inc-dec"><KeyboardArrowDownIcon  /></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} sm={2} md={2} lg={2} sx={{ paddingBottom : { xs : '15px' }}}>
                    <Typography variant="subtitle2" sx={{ display : { xs : 'flex', sm : 'none'}}}>Total Amount</Typography>
                    <Typography variant="subtitle1" fontWeight="700" sx={{ padding : '5px 0'}}>
                        {
                            // @ts-ignore
                            <strike>$ {numberWithCommas(item.attributes.total_price)} </strike>
                        }
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={2} md={2} lg={2} display="flex" sx={{ justifyContent : { xs : 'right', sm : 'center'}, paddingBottom : { xs : '15px' }}}>
                    <Button variant="contained" size="small" className="add-cart-btn" onClick={()=> {dispatch(removeCartItem(item.id))}} sx={{ minWidth : '50px',  borderRadius : '50% !important'}} ><img src={deleteIcon} width='auto' alt="landing page"/></Button></Grid>
            </Grid>
        </Grid>
    );
};

export default CartItems;