import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ColorButton } from "./Style";
import { forgotPassword } from "./api";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

const LoginForm = ({ handleClose }) => {
  const [values, setValues] = useState({ email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setError("")
    setIsLoading(true);
    if (values.email) {
      const finalData = {
        data: {
          attributes: values
        }
      };
      try {
        const { data } = await forgotPassword(finalData);
        if (data.errors) {
          setError(data.errors);
        } else {
          setSuccess(true);
        }
      } catch (e) {
        setError([
          {
            message: "Something went wrong"
          }
        ]);
      }
    } else {
      setError([
        {
          message: "Please enter Email"
        }
      ]);
    }
    setIsLoading(false);
  };

  return (
    <Grid item xs={12} className="loginMainDiv">
      <Box className="loginFormFlex">
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          color="#472264"
          sx={{ fontWeight: "bold" }}
        >
          Forgot password?
        </Typography>
        <Typography
          variant="body1"
          component="div"
          gutterBottom
          color="#925BBD"
          sx={{ width: "90%",fontWeight:"bold" }}
        >
          Enter your registered email with us, We will send you a password reset link on your email address.
        </Typography>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          {error ? (
            <Alert severity="error" sx={{ width: "90%", alignSelf: "center" }}>
              {error.map(e => {
                return e.message;
              })}
            </Alert>
          ) : null}
          {success ? (
            <Alert
              severity="success"
              sx={{ width: "90%", alignSelf: "center" }}
            >
              Reset password link sent to your email id.
            </Alert>
          ) : null}
          <Box sx={{ marginBottom: "15px", marginTop: "15px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Email
            </Typography>
            <TextField
              block
              disabled={success}
              placeholder="Email"
              sx={{ width: "90%" }}
              value={values.email}
              onChange={handleChange("email")}
            />
          </Box>
          {
            !success ?
                <Box>
                  <ColorButton
                      variant="contained"
                      color="secondary"
                      sx={{
                        width: "90%",
                        marginBottom: "10px",
                        background: "#633c89",
                        padding: "10px 0 10px 0"
                      }}
                      onClick={handleSubmit}
                  >
                    {isLoading ? <CircularProgress /> : "Verify"}
                  </ColorButton>
                </Box> : null
          }
          <Box>
            <Button
              disableRipple
              sx={{ width: "90%", fontWeight: "bold", color: "#000" }}
              onClick={() => handleClose()}>
              {
                success ? "close" : "cancel"
              }
            </Button>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default LoginForm;
