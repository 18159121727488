import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
    baseURL: `${baseURL}/bx_block_dashboard/`
});

API.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

export const vendorsList = async (count,page) => {
    try {
        return await API.get(`/vendors?per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

export const activeVendor = async (id) => {
    try {
        return await API.post(`/vendors/activate_vendor`,{id});
    } catch (e) {
        return e.response;
    }
}

export const deactivateVendor = async (id) => {
    try {
        return await API.post(`/vendors/deactivate_vendor`,{id});
    } catch (e) {
        return e.response;
    }
}

export const bulkDeactivateVendor = async (id) => {
    try {
        return await API.post(`/vendors/bulk_deactivate_vendor_users`,{id});
    } catch (e) {
        return e.response;
    }
}

export const bulkActiveVendor = async (id) => {
    try {
        return await API.post(`/vendors/bulk_activate_vendor_users`,{id});
    } catch (e) {
        return e.response;
    }
}

export const approveVendor = async (id) => {
    try {
        return await API.post(`/vendors/approve_request`,{account_id:id});
    } catch (e) {
        return e.response;
    }
}

export const rejectVendor = async (id) => {
    try {
        return await API.post(`/vendors/reject_request`,{account_id:id});
    } catch (e) {
        return e.response;
    }
}


export const vendorSearch = async (count,page,type,search) => {
    try {
        return await API.get(`/vendors?search_key=${type}&search=${search}&per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

export const vendorListByFilter = async (count,page,filter) => {
    try {
        return await API.get(`/vendors?page=${page}&per=${count}&filter=${filter}`);
    } catch (e) {
        return e.response;
    }
}

export const vendorListSearchByFilter = async (count,page,filter,type,search) => {
    try {
        return await API.get(`/vendors?page=${page}&per=${count}&filter=pending${filter}&search_key=${type}&search=${search}`);
    } catch (e) {
        return e.response;
    }
}

export const vendorDetails = async (id) => {
    try {
        return await API.get(`/vendors/${id}`);
    } catch (e) {
        return e.response;
    }
}

export const vendorDetailsCharts = async (id,interval) => {
    try {
        return await API.get(`/dashboards/dashboard_order?interval=${interval}&account_id=${id}`);
    } catch (e) {
        return e.response;
    }
}

export const adminDashboard = async (interval) => {
    try {
        return await API.get(`/dashboards/dashboard_order?interval=${interval}`);
    } catch (e) {
        return e.response;
    }
}

