export const bell = require("../../blocks/landingpage/assets/bell.png");
export const cartIcon = require("../../blocks/landingpage/assets/cart.png");
export const search = require("../../blocks/landingpage/assets/search.png");
export const Logo = require("../../blocks/landingpage/assets/logo.png");
export const profilePic = require("../../blocks/landingpage/assets/profile.png");

export const Contact = require("../../blocks/landingpage/assets/Contact.png");
export const locationPin = require("../../blocks/landingpage/assets/locationPin.png");
export const mail = require("../../blocks/landingpage/assets/mail.png");
export const logoDark = require("../../blocks/landingpage/assets/logoDark.png");
export const arrowDown = require("../../blocks/landingpage/assets/arrowDown.png");
export const noRecords = require("../../blocks/landingpage/assets/norecord.png");
export const {getFooter,getFooterLinks} = require("./footerAPI")
export const {getNotifications,getUnreadNotifications,setUnreadCountZero,setNotificationBlank} = require("./Redux/Notifications/reduxNotificationActions")
export const {getLandingCategoryList,updateUserProfile} = require("./Redux/LandingPage/reduxLandingPageActions")
export const {cartZero,manageCartActiveProducts} = require("./Redux/Cart/reduxCartActions")
export const {setLoginModelTrue,setProfileEmpty} = require("./Redux/LandingPage/reduxLandingPageActions")