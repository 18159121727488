import React, { useState, useEffect } from "react";
import { Typography, Grid, TextField, Button, Box, Alert } from "@mui/material";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { withRouter } from "react-router-dom";

import { DropZone } from "./assets";
import { PreviewButton, SaveButton } from "./Buttons";
import { API, IData, capitalize, initialValues } from "./SectionOne";
import SectionFivePreview from "./SectionFivePreview";
import LoaderPage from "../../../components/src/LoaderPage";
import RouteLeavingGuard from "./RouteLeavingGuard";

export const sectionFiveSchema = yup.object().shape({
  title: yup.string().trim().max(25).required("title is required"),
  link: yup
    .string()
    .trim()
    .required("link is required")
    .test({
      name: "slash-check",
      message: "${path} can't start with /",
      test: (value: any) => value === null || value[0] !== "/",
    }),
});

interface IProps {
  history: any;
  location: any;
}

function SectionFive({ history, location: currentLocation }: IProps) {
  const token = localStorage.getItem("authToken") || "";
  // Image returned from API
  const [backgroundImg, setBackgroundImg] = useState("");
  const [backgroundImgName, setBackgroundImgName] = useState("");
  // Files users select
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState<string | ArrayBuffer | null>("");
  const [hasRemovedFile, setHasRemovedFile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    handleSubmit: validateSubmission,
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(sectionFiveSchema),
    defaultValues: initialValues,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Submit section five
  const handleSubmit = (data: IData) => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append("heading_title", data.title);
    formData.append("link", data.link);
    file && formData.append("background_image", file);
    API.post("/bx_block_contentmanagement/section_fives", formData, {
      headers: { token },
    })
      .then((response) => {
        const { background_image } = response.data.data.attributes;
        setBackgroundImg(background_image?.url);
        setBackgroundImgName(background_image?.file_name);
        setHasSaved(true);
        toast.success("Saved");
      })
      .catch((err) => {
        setBackgroundImg("");
        if (err.response.status === 422) {
          err.response.data.errors.forEach((e: any) => {
            toast.error(e.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsSaving(false);
        // Remove selected file
        if (file) {
          setFile("");
          setHasRemovedFile(true);
        }
      });
  };

  // Read URL of selected file
  useEffect(() => {
    if (typeof file === "object") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileUrl(reader.result);
      };
    }
  }, [file]);

  // Remove file
  const removeFileHandler = () => {
    setFile("");
    setFileUrl("");
    setHasRemovedFile(true);
  };

  const shouldBlockNavigation = (nextLocation: any) => {
    return isDirty && currentLocation.pathname !== nextLocation.pathname
      ? true
      : false;
  };

  const navigate = (nextLocation: any) => {
    history.push(nextLocation.pathname);
  };

  // Load data
  useEffect(() => {
    API.get("/bx_block_contentmanagement/section_fives", { headers: { token } })
      .then((response) => {
        const { background_image, heading_title, link } =
          response.data.data.attributes;
        // Update default values for react hook form
        setValue("title", heading_title);
        setValue("link", link);
        setBackgroundImg(background_image?.url);
        setBackgroundImgName(background_image?.file_name);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Could not load data");
      });
  }, []);

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <Box sx={{ padding: "20px 0" }}>
      {/* File upload section starts */}
      <Typography sx={styles.label}>Background Image</Typography>
      <DropZone
        setFile={setFile}
        hasRemovedFile={hasRemovedFile}
        setHasRemovedFile={setHasRemovedFile}
        backgroundImgName={backgroundImgName}
      />
      {file && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button sx={styles.label} onClick={removeFileHandler}>
            Remove
          </Button>
        </Box>
      )}
      {/* File upload section ends */}
      {/* Content form starts */}
      <Grid container spacing={2} sx={styles.container}>
        <Grid item xs={12} sm={6} sx={styles.item}>
          <Typography sx={styles.label}>Heading Title</Typography>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors["title"] ? true : false}
                placeholder="Enter heading"
                size="small"
                sx={styles.textField}
              />
            )}
          />
          {errors["title"] ? (
            <Typography variant="subtitle2" sx={styles.errorText}>
              {capitalize(errors["title"]?.message)}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} sx={styles.item}>
          <Typography sx={styles.label}>Link</Typography>
          <Controller
            name="link"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors["link"] ? true : false}
                placeholder="Url://"
                size="small"
                sx={styles.textField}
              />
            )}
          />
          {errors["link"] ? (
            <Typography variant="subtitle2" sx={styles.errorText}>
              {capitalize(errors["link"]?.message)}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      {/* Content form ends */}
      {/* Button container starts */}
      <Grid sx={styles.btnContainer}>
        <PreviewButton
          text="preview"
          handleOpen={validateSubmission(handleOpen)}
        />
        <SaveButton
          text="save"
          handleSubmit={validateSubmission(handleSubmit)}
          isSaving={isSaving}
        />
      </Grid>
      {/* Button container ends */}
      <SectionFivePreview
        open={open}
        handleClose={handleClose}
        fileUrl={fileUrl}
        backgroundImg={backgroundImg}
        title={watch("title")}
        link={watch("link")}
      />
      <RouteLeavingGuard
        when={isDirty && !hasSaved}
        shouldBlockNavigation={shouldBlockNavigation}
        navigate={navigate}
      />
    </Box>
  );
}

export default withRouter(SectionFive);

const styles = {
  container: { margin: "20px 0", width: "100%" },
  item: {
    paddingLeft: "0 !important",
    paddingRight: {
      sm: "16px",
      xs: 0,
    },
  },
  label: { color: "#472264", fontWeight: "bold" },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
  },
  textField: { width: "100%", background: "#f1f1f1" },
  errorText: { marginBottom: "1", color: "red" },
};
