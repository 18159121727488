import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#472264"),
  borderRadius: "10px",
  height: "50px",
  width: '100%',
  backgroundColor: "#472264",
  "&:hover": {
    backgroundColor: "#693A8E",
  },
}));
