import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
    baseURL:`${baseURL}/bx_block_contentmanagement`,
})

export const getFooter = async () => {
    try{
        return await API.get(`/footers`)
    } catch (e) {
        return  e.response
    }
}

export const getFooterLinks = async () => {
    try{
        return await API.get(`/footer_sections`)
    } catch (e) {
        return  e.response
    }
}