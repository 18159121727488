import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import {
  logoDark,
  profilePic,
  arrowDown,
  bell,
  getNotifications,
  getUnreadNotifications,
  updateUserProfile,
  setNotificationBlank,
  cartZero,
  setProfileEmpty,
} from "./importAssets";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import WorkIcon from "@mui/icons-material/Work";
import { Link, withRouter } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import Notify from "../../blocks/Notifications/src/NotifyNav";
import { Divider, Grid } from "@mui/material";
import StyledMenu from "./StyledMenu";
import {toast} from "react-toastify";
const drawerWidth = 250;

const active = {
  color: "#fff !important",
  backgroundColor: "#6a3a8e",
};
function ResponsiveDrawer(props) {
  const dispatch = useDispatch();
  const localProfile = JSON.parse(localStorage.getItem("profile"));
  const { unReadNotification, unreadCount } = useSelector(
    (state) => state.notifications
  );
  const { profile } = useSelector((state) => state.landingPage);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE3, setAnchorE3] = React.useState(null);
  const open = Boolean(anchorEl);
  const { window, Component, name, history, showBackBtn } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [profileStatus, setProfileStatus] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const openPopup = Boolean(anchorE3);

  // Manage User Role wise access
  useEffect(() => {
    if (localProfile === null) {
      history.push("/");
    } else {
      if (localProfile.user_role !== "vendor") {
        if (
          localProfile.user_role === "super_administrator" ||
          localProfile.user_role === "admin"
        ) {
          history.push("/admin/dashboard");
        } else {
          props.history.push("/");
        }
      } else {
        dispatch(updateUserProfile(history));
        dispatch(getNotifications());
        dispatch(getUnreadNotifications());
      }
    }
  }, []);

  // Manage the logout button
  const handleNavigation = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("profile");
    localStorage.removeItem("cart");
    dispatch(setNotificationBlank());
    dispatch(cartZero());
    dispatch(setProfileEmpty());
    history.push("/");
  };

  // Check if the user verified the email id or not
  useEffect(() => {
    if(profile.email_confirmed){
      if(profile.vendor_status === "approved"){
        setIsDisabled(false)
      }
    }else{
      if(profile.email){
        history.push("/vendor/profile")
      }
    }
  }, [profile]);

  const handlePopup = (event) => {
    if (unReadNotification.length > 0) {
      setAnchorE3(event.currentTarget);
    } else {
      if (location.pathname !== "/vendor/notifications") {
        history.push("/vendor/notifications");
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseNotification = () => {
    setAnchorE3(null);
  };

  // check current path and set the active class
  useEffect(() => {
    const currentpath = props.match.path.split("/");
    setCurrentPath(currentpath[2]);
    dispatch(updateUserProfile(props.history));
  }, [currentPath, props.match]);

  const drawer = (
    <Box sx={styles.drawer}>
      <Box>
        <Toolbar>
          <img width="60%" src={logoDark} alt={"logo"} />
        </Toolbar>
        <List sx={styles.list}>
          <ListItem
            button
            disabled={isDisabled}
            className={currentPath === "Dashboard" ? "active" : null}
            onClick={() => props.history.push("/vendor/dashboard")}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "#6a3a8e" }} />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} sx={{ color: "#6a3a8e" }} />
          </ListItem>
          <ListItem
            disabled={isDisabled}
            className={currentPath === "orders" ? "active" : null}
            onClick={() => props.history.push("/vendor/orders/")}
            button
          >
            <ListItemIcon>
              <ShoppingCartIcon sx={{ color: "#6a3a8e" }} />
            </ListItemIcon>
            <ListItemText primary={"Orders"} sx={{ color: "#6a3a8e" }} />
          </ListItem>
          <ListItem
            button
            disabled={isDisabled}
            className={currentPath === "myProducts" ? "active" : null}
            onClick={() => props.history.push("/vendor/myProducts")}
          >
            <ListItemIcon>
              <WorkIcon sx={{ color: "#6a3a8e" }} />
            </ListItemIcon>
            <ListItemText primary={"My Products"} sx={{ color: "#6a3a8e" }} />
          </ListItem>
          <ListItem
            button
            disabled={isDisabled}
            className={currentPath === "buyers" ? "active" : null}
            onClick={() => props.history.push("/vendor/buyers")}
          >
            <ListItemIcon>
              <PersonIcon sx={{ color: "#6a3a8e" }} />
            </ListItemIcon>
            <ListItemText primary={"Buyers"} sx={{ color: "#6a3a8e" }} />
          </ListItem>
          <ListItem
            button
            className={currentPath === "profile" ? "active" : null}
            onClick={() => props.history.push("/vendor/profile")}
          >
            <ListItemIcon>
              <SettingsIcon sx={{ color: "#6a3a8e" }} />
            </ListItemIcon>
            <ListItemText primary={"My Profile"} sx={{ color: "#6a3a8e" }} />
          </ListItem>
        </List>
      </Box>
      <Box sx={{ padding: "0 24px" }}>
        <Typography sx={styles.copyright}>GenBov @ 2021</Typography>
        <Typography sx={styles.copyright}>All Rights Reserved</Typography>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          ...styles.appbar,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          
        }}
      >
        <Toolbar>
          <Grid
            container
            sx={styles.gridContainer}
          >
            <Grid item xs={6}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ display: { md: "none" } }}
                >
                  <MenuIcon
                    sx={styles.menuIcon}
                  />
                </IconButton>
                {name && (
                  <Typography
                    variant="h4"
                    noWrap
                    component="div"
                    color="#6a3a8e"
                    sx={styles.pageTitle}
                  >
                    {name}
                  </Typography>
                )}
                {showBackBtn && (
                  <IconButton
                    sx={styles.iconBtn}
                    onClick={() => props.history.goBack()}
                  >
                    <ArrowBackIcon sx={styles.backIcon} />
                  </IconButton>
                )}
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              sx={styles.gridItemRight}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Badge badgeContent={unreadCount} color="secondary">
                    <Box
                      aria-controls="demo-customized-popup"
                      onClick={handlePopup}
                      id="demo-customized-popup-open"
                      sx={styles.bellContainer}
                    >
                      <img
                        src={bell}
                        width="21px"
                        alt="Notifications"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  </Badge>
                  <StyledMenu
                    anchorEl={anchorE3}
                    open={openPopup}
                    onClose={handleCloseNotification}
                  >
                    {unReadNotification?.map((n, key) => {
                      return (
                        <div key={key}>
                          <Notify notification={n} />
                          <Divider />
                        </div>
                      );
                    })}
                    <Grid sx={styles.seeMore}>
                      <Link
                        to="/vendor/notifications"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="subtitle1">SEE MORE</Typography>
                      </Link>
                    </Grid>
                  </StyledMenu>
                  <Avatar
                    alt="Profile picture"
                    src={profile?.avatar?.url || profilePic}
                    sx={{ marginLeft: 2 }}
                    onClick={handleClick}
                  />
                  <Box
                    id="basic-button"
                    onClick={handleClick}
                    sx={styles.usernameContainer}
                  >
                    <Typography
                      variant="h6"
                      noWrap
                      component="span"
                      color="#6a3a8e"
                      sx={{ fontWeight: "bold" }}
                    >
                      {profile?.full_name || "loading.."}
                    </Typography>
                    <Typography
                      variant="caption"
                      noWrap
                      component="span"
                      color="#6a3a8e"
                      marginTop="-7px"
                    >
                      {profile?.role_name || "loading.."}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleNavigation}>Logout</MenuItem>
        </Menu>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { xs: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          pt: {
            xs: 8,
            sm: 3,
          },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#f3f3f3",
        }}
      >
        <Toolbar />
        <Component />
      </Box>
    </Box>
  );
}

const styles = {
  drawer: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  list: { paddingLeft: "20px", paddingRight: "20px" },
  appbar: {
    backgroundColor: "#f3f3f3",
    boxShadow: "none",
    border: "none",
  },
  gridContainer: {
    width: "100%",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuIcon: {
    backgroundColor: "#6a3a8e",
    borderRadius: "3px",
    padding: "2px",
    fontSize: "2rem"
  },
  backIcon: {
    backgroundColor: "#6a3a8e",
    color: "#fff",
    borderRadius: "3px",
    padding: "2px",
    fontSize: "2rem"
  },
  pageTitle: {
    fontWeight: "bold",
    fontSize: {
      xs: "1.5rem",
      md: "2.125rem",
    },
  },
  iconBtn: { color: "#472264", fontWeight: "bold" },
  gridItemRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: { xs: "10px", sm: "0px" },
    marginBottom: { xs: "10px", sm: "0px" },
  },
  bellContainer: {
    backgroundColor: "#6a3a8e",
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  seeMore: { padding: "7px", textAlign: "center" },
  usernameContainer: {
    margin: "0px 10px",
    display: {
      xs: "none",
      sm: "flex",
    },
    flexDirection: "column",
    cursor: "pointer",
  },
  copyright: { fontSize: "18px !important", color: "#d8b434" },
};

export default withRouter(ResponsiveDrawer);
