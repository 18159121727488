import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoaderPage from "../../../components/src/LoaderPage";
import { DropZone } from "./assets";
import { PreviewButton, SaveButton } from "./Buttons";
import { API } from "./SectionOne";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RouteLeavingGuard from "./RouteLeavingGuard";
import { withRouter } from "react-router-dom";

import SectionTwoPreview from "./SectionTwoPreview";
import { capitalize } from "./SectionOne";

// Validation method to check if links has leading slash(/)
export function validateLeadingSlash(message: any) {
  // @ts-ignore
  return this.test("validateLeadingSlash", message, function (value: string) {
    // @ts-ignore
    const { path, createError } = this;
    if (value[0] === "/") {
      return createError({
        path,
        message: message ?? "Link can't start with /",
      });
    }
    return true;
  });
}

// Add validateLeadingSlash to yup
yup.addMethod(yup.string, "validateLeadingSlash", validateLeadingSlash);

const sectionTwoSchema = yup.object().shape({
  title1: yup
    .string()
    .trim()
    .max(25, "title must be less than 25 chars")
    .required("title is required"),
  link1: yup
    .string()
    .trim()
    // @ts-ignore
    .validateLeadingSlash()
    .required("link is required"),
  title2: yup
    .string()
    .trim()
    .max(25, "title must be less than 25 chars")
    .required("title is required"),
  link2: yup
    .string()
    .trim()
    // @ts-ignore
    .validateLeadingSlash()
    .required("link is required"),
  title3: yup
    .string()
    .trim()
    .max(25, "title must be less than 25 chars")
    .required("title is required"),
  link3: yup
    .string()
    .trim()
    // @ts-ignore
    .validateLeadingSlash()
    .required("link is required"),
  title4: yup
    .string()
    .trim()
    .max(25, "title must be less than 25 chars")
    .required("title is required"),
  link4: yup
    .string()
    .trim()
    // @ts-ignored
    .validateLeadingSlash()
    .required("link is required"),
  title5: yup
    .string()
    .trim()
    .max(25, "title must be less than 25 chars")
    .required("title is required"),
  link5: yup
    .string()
    .trim()
    // @ts-ignored
    .validateLeadingSlash()
    .required("link is required"),
});

const initialValues = {
  title1: "",
  link1: "",
  title2: "",
  link2: "",
  title3: "",
  link3: "",
  title4: "",
  link4: "",
  title5: "",
  link5: "",
};

interface IData {
  title1: string;
  link1: string;
  title2: string;
  link2: string;
  title3: string;
  link3: string;
  title4: string;
  link4: string;
  title5: string;
  link5: string;
}

interface IProps {
  history: any;
  location: any;
}

function SectionTwo({ history, location: currentLocation }: IProps) {
  // Col 1
  const [file1, setFile1] = useState("");
  const [fileUrl1, setFileUrl1] = useState<string | ArrayBuffer | null>("");
  const [hasRemovedFile1, setHasRemovedFile1] = useState(false);
  const [img1, setImg1] = useState("");
  const [img1Name, setImg1Name] = useState("");
  // Col 2
  const [file2, setFile2] = useState("");
  const [fileUrl2, setFileUrl2] = useState<string | ArrayBuffer | null>("");
  const [hasRemovedFile2, setHasRemovedFile2] = useState(false);
  const [img2, setImg2] = useState("");
  const [img2Name, setImg2Name] = useState("");
  // Col 3
  const [file3, setFile3] = useState("");
  const [fileUrl3, setFileUrl3] = useState<string | ArrayBuffer | null>("");
  const [hasRemovedFile3, setHasRemovedFile3] = useState(false);
  const [img3, setImg3] = useState("");
  const [img3Name, setImg3Name] = useState("");
  // Col 4
  const [file4, setFile4] = useState("");
  const [fileUrl4, setFileUrl4] = useState<string | ArrayBuffer | null>("");
  const [hasRemovedFile4, setHasRemovedFile4] = useState(false);
  const [img4, setImg4] = useState("");
  const [img4Name, setImg4Name] = useState("");
  // Col 5
  const [file5, setFile5] = useState("");
  const [fileUrl5, setFileUrl5] = useState<string | ArrayBuffer | null>("");
  const [hasRemovedFile5, setHasRemovedFile5] = useState(false);
  const [img5, setImg5] = useState("");
  const [img5Name, setImg5Name] = useState("");
  // Others
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("authToken") || "";
  const {
    handleSubmit: validateSubmission,
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(sectionTwoSchema),
    defaultValues: initialValues,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Submit section two
  const handleSubmit = (data: IData) => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append("section_twos_attributes[0][id]", "1");
    formData.append("section_twos_attributes[0][title]", data.title1);
    formData.append("section_twos_attributes[0][link]", data.link1);
    file1 && formData.append("section_twos_attributes[0][image]", file1);
    formData.append("section_twos_attributes[1][id]", "2");
    formData.append("section_twos_attributes[1][title]", data.title2);
    formData.append("section_twos_attributes[1][link]", data.link2);
    file2 && formData.append("section_twos_attributes[1][image]", file2);
    formData.append("section_twos_attributes[2][id]", "3");
    formData.append("section_twos_attributes[2][title]", data.title3);
    formData.append("section_twos_attributes[2][link]", data.link3);
    file3 && formData.append("section_twos_attributes[2][image]", file3);
    formData.append("section_twos_attributes[3][id]", "4");
    formData.append("section_twos_attributes[3][title]", data.title4);
    formData.append("section_twos_attributes[3][link]", data.link4);
    file4 && formData.append("section_twos_attributes[3][image]", file4);
    formData.append("section_twos_attributes[4][id]", "5");
    formData.append("section_twos_attributes[4][title]", data.title5);
    formData.append("section_twos_attributes[4][link]", data.link5);
    file5 && formData.append("section_twos_attributes[4][image]", file5);

    API.post("/bx_block_contentmanagement/section_twos", formData, {
      headers: { token },
    })
      .then((response) => {
        const { data } = response.data;
        setImg1(data["1"].image);
        setImg2(data["2"].image);
        setImg3(data["3"].image);
        setImg4(data["4"].image);
        setImg5(data["5"].image);
        setImg1Name(data["1"].file_name);
        setImg2Name(data["2"].file_name);
        setImg3Name(data["3"].file_name);
        setImg4Name(data["4"].file_name);
        setImg5Name(data["5"].file_name);
        setHasSaved(true);
        toast.success("Saved");
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 422) {
          err.response.data.errors.forEach((e: any) => {
            toast.error(e.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsSaving(false);
        // Remove selected files
        if (file1) {
          setFile1("");
          setHasRemovedFile1(true);
        }
        if (file2) {
          setFile2("");
          setHasRemovedFile2(true);
        }
        if (file3) {
          setFile3("");
          setHasRemovedFile3(true);
        }
        if (file4) {
          setFile4("");
          setHasRemovedFile4(true);
        }
        if (file5) {
          setFile5("");
          setHasRemovedFile5(true);
        }
      });
  };

  // Remove messages when file changes
  useEffect(() => {
    if (file1) {
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(file1);
      reader.onloadend = () => {
        setFileUrl1(reader.result);
      };
    } else {
      setFileUrl1("");
    }

    if (file2) {
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(file2);
      reader.onloadend = () => {
        setFileUrl2(reader.result);
      };
    } else {
      setFileUrl2("");
    }

    if (file3) {
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(file3);
      reader.onloadend = () => {
        setFileUrl3(reader.result);
      };
    } else {
      setFileUrl3("");
    }

    if (file4) {
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(file4);
      reader.onloadend = () => {
        setFileUrl4(reader.result);
      };
    } else {
      setFileUrl4("");
    }

    if (file5) {
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(file5);
      reader.onloadend = () => {
        setFileUrl5(reader.result);
      };
    } else {
      setFileUrl5("");
    }
  }, [file1, file2, file3, file4, file5]);

  // Remove file 1
  const removeFile1Handler = () => {
    setFile1("");
    setHasRemovedFile1(true);
  };

  // Remove file 2
  const removeFile2Handler = () => {
    setFile2("");
    setHasRemovedFile2(true);
  };

  // Remove file 3
  const removeFile3Handler = () => {
    setFile3("");
    setHasRemovedFile3(true);
  };

  // Remove file 4
  const removeFile4Handler = () => {
    setFile4("");
    setHasRemovedFile4(true);
  };

  // Remove file 5
  const removeFile5Handler = () => {
    setFile5("");
    setHasRemovedFile5(true);
  };

  const shouldBlockNavigation = (nextLocation: any) => {
    return isDirty && currentLocation.pathname !== nextLocation.pathname
      ? true
      : false;
  };

  const navigate = (nextLocation: any) => {
    history.push(nextLocation.pathname);
  };

  // Load data
  useEffect(() => {
    API.get("/bx_block_contentmanagement/section_twos", { headers: { token } })
      .then((response) => {
        const { data } = response.data;
        // col 1
        setImg1(data["1"].image ? data["1"].image : "");
        setValue("title1", data["1"].title);
        setValue("link1", data["1"].link);
        setImg1Name(data["1"].file_name);
        // col 2
        setImg2(data["2"].image ? data["2"].image : "");
        setValue("title2", data["2"].title);
        setValue("link2", data["2"].link);
        setImg2Name(data["2"].file_name);
        // col 3
        setImg3(data["3"].image ? data["3"].image : "");
        setValue("title3", data["3"].title);
        setValue("link3", data["3"].link);
        setImg3Name(data["3"].file_name);
        // col 4
        setImg4(data["4"].image ? data["4"].image : "");
        setValue("title4", data["4"].title);
        setValue("link4", data["4"].link);
        setImg4Name(data["4"].file_name);
        // col 5
        setImg5(data["5"].image ? data["5"].image : "");
        setValue("title5", data["5"].title);
        setValue("link5", data["5"].link);
        setImg5Name(data["5"].file_name);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Could not load data");
      });
  }, []);

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <Box sx={{ padding: "20px 0" }}>
      <Grid container spacing={10}>
        <Grid item xs={12} lg={5}>
          <Typography sx={styles.colHeading}>Col 01</Typography>
          <DropZone
            setFile={setFile1}
            hasRemovedFile={hasRemovedFile1}
            setHasRemovedFile={setHasRemovedFile1}
            backgroundImgName={img1Name}
          />
          {file1 && (
            <Box sx={styles.removeBtnContainer}>
              <Button sx={styles.colHeading} onClick={removeFile1Handler}>
                Remove
              </Button>
            </Box>
          )}
          {/* form */}
          <Grid>
            <Typography sx={styles.colHeading}>Title</Typography>
            <Controller
              name="title1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["title1"] ? true : false}
                  placeholder="Enter Title"
                  size="small"
                  sx={styles.textField}
                />
              )}
            />
            {errors["title1"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {capitalize(errors["title1"]?.message)}
              </Typography>
            ) : null}
          </Grid>
          <Grid>
            <Typography sx={styles.colHeading}>Link</Typography>
            <Controller
              name="link1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["link1"] ? true : false}
                  placeholder="Url://"
                  size="small"
                  sx={styles.textField}
                />
              )}
            />
            {errors["link1"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {capitalize(errors["link1"]?.message)}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        {/* Right side */}
        <Grid
          container
          item
          xs={12}
          lg={7}
          spacing={5}
          sx={styles.rightGridContainer}
        >
          {/* col 2 */}
          <Grid item xs={12} sm={6}>
            <Typography sx={styles.colHeading}>Col 02</Typography>
            <DropZone
              setFile={setFile2}
              hasRemovedFile={hasRemovedFile2}
              setHasRemovedFile={setHasRemovedFile2}
              backgroundImgName={img2Name}
            />
            {file2 && (
              <Box sx={styles.removeBtnContainer}>
                <Button sx={styles.colHeading} onClick={removeFile2Handler}>
                  Remove
                </Button>
              </Box>
            )}
            <Grid>
              <Typography sx={styles.colHeading}>Title</Typography>
              <Controller
                name="title2"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["title2"] ? true : false}
                    placeholder="Enter Title"
                    size="small"
                    sx={styles.textField}
                  />
                )}
              />
              {errors["title2"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {capitalize(errors["title2"]?.message)}
                </Typography>
              ) : null}
            </Grid>
            <Grid>
              <Typography sx={styles.colHeading}>Link</Typography>
              <Controller
                name="link2"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["link2"] ? true : false}
                    placeholder="Url://"
                    size="small"
                    sx={styles.textField}
                  />
                )}
              />
              {errors["link2"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {capitalize(errors["link2"]?.message)}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          {/* col 3 */}
          <Grid item xs={12} sm={6}>
            <Typography sx={styles.colHeading}>Col 03</Typography>
            <DropZone
              setFile={setFile3}
              hasRemovedFile={hasRemovedFile3}
              setHasRemovedFile={setHasRemovedFile3}
              backgroundImgName={img3Name}
            />
            {file3 && (
              <Box sx={styles.removeBtnContainer}>
                <Button sx={styles.colHeading} onClick={removeFile3Handler}>
                  Remove
                </Button>
              </Box>
            )}
            <Grid>
              <Typography sx={styles.colHeading}>Title</Typography>
              <Controller
                name="title3"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["title3"] ? true : false}
                    placeholder="Enter Title"
                    size="small"
                    sx={styles.textField}
                  />
                )}
              />
              {errors["title3"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {capitalize(errors["title3"]?.message)}
                </Typography>
              ) : null}
            </Grid>
            <Grid>
              <Typography sx={styles.colHeading}>Link</Typography>
              <Controller
                name="link3"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["link3"] ? true : false}
                    placeholder="Url://"
                    size="small"
                    sx={styles.textField}
                  />
                )}
              />
              {errors["link3"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {capitalize(errors["link3"]?.message)}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          {/* col 4 */}
          <Grid item xs={12} sm={6}>
            <Typography sx={styles.colHeading}>Col 04</Typography>
            <DropZone
              setFile={setFile4}
              hasRemovedFile={hasRemovedFile4}
              setHasRemovedFile={setHasRemovedFile4}
              backgroundImgName={img4Name}
            />
            {file4 && (
              <Box sx={styles.removeBtnContainer}>
                <Button sx={styles.colHeading} onClick={removeFile4Handler}>
                  Remove
                </Button>
              </Box>
            )}
            <Grid>
              <Typography sx={styles.colHeading}>Title</Typography>
              <Controller
                name="title4"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["title4"] ? true : false}
                    placeholder="Enter Title"
                    size="small"
                    sx={styles.textField}
                  />
                )}
              />
              {errors["title4"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {capitalize(errors["title4"]?.message)}
                </Typography>
              ) : null}
            </Grid>
            <Grid>
              <Typography sx={styles.colHeading}>Link</Typography>
              <Controller
                name="link4"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["link4"] ? true : false}
                    placeholder="Url://"
                    size="small"
                    sx={styles.textField}
                  />
                )}
              />
              {errors["link4"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {capitalize(errors["link4"]?.message)}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          {/* col 5 */}
          <Grid item xs={12} sm={6}>
            <Typography sx={styles.colHeading}>Col 05</Typography>
            <DropZone
              setFile={setFile5}
              hasRemovedFile={hasRemovedFile5}
              setHasRemovedFile={setHasRemovedFile5}
              backgroundImgName={img5Name}
            />
            {file5 && (
              <Box sx={styles.removeBtnContainer}>
                <Button sx={styles.colHeading} onClick={removeFile5Handler}>
                  Remove
                </Button>
              </Box>
            )}
            <Grid>
              <Typography sx={styles.colHeading}>Title</Typography>
              <Controller
                name="title5"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["title5"] ? true : false}
                    placeholder="Enter Title"
                    size="small"
                    sx={styles.textField}
                  />
                )}
              />
              {errors["title5"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {capitalize(errors["title5"]?.message)}
                </Typography>
              ) : null}
            </Grid>
            <Grid>
              <Typography sx={styles.colHeading}>Link</Typography>
              <Controller
                name="link5"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["link5"] ? true : false}
                    placeholder="Url://"
                    size="small"
                    sx={styles.textField}
                  />
                )}
              />
              {errors["link5"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {capitalize(errors["link5"]?.message)}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Button container starts */}
      <Grid sx={styles.btnContainer}>
        <PreviewButton
          text="preview"
          handleOpen={validateSubmission(handleOpen)}
        />
        <SaveButton
          text="save"
          handleSubmit={validateSubmission(handleSubmit)}
          isSaving={isSaving}
        />
      </Grid>
      {/* Button container ends */}
      <SectionTwoPreview
        open={open}
        handleClose={handleClose}
        fileUrl1={fileUrl1}
        fileUrl2={fileUrl2}
        fileUrl3={fileUrl3}
        fileUrl4={fileUrl4}
        fileUrl5={fileUrl5}
        title1={watch("title1")}
        title2={watch("title2")}
        title3={watch("title3")}
        title4={watch("title4")}
        title5={watch("title5")}
        link1={watch("link1")}
        link2={watch("link2")}
        link3={watch("link3")}
        link4={watch("link4")}
        link5={watch("link5")}
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
      />
      <RouteLeavingGuard
        when={isDirty && !hasSaved}
        shouldBlockNavigation={shouldBlockNavigation}
        navigate={navigate}
      />
    </Box>
  );
}

export default withRouter(SectionTwo);

const styles = {
  colHeading: { color: "#472264", fontWeight: "bold" },
  removeBtnContainer: { display: "flex", justifyContent: "flex-end" },
  textField: { display: "flex", justifyContent: "flex-end" },
  rightGridContainer: {
    paddingTop: {
      lg: "80px !important",
      xs: "40px !important",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
  },
  errorText: { marginBottom: "1", color: "red" },
};
