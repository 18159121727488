import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { withRouter } from "react-router-dom";

import BuyerProfile from "./BuyerProfile";
import Order from "./Order";
import OrderDetails from "./OrderDetails";
import ShippingAddressForm from "./ShippingAddressForm";
import ChangePasswordForm from "./ChangePasswordForm";
import LogoutModal from "./LogoutModal";
import SideMenu from "./SideMenu";

// Keep track of buttons in the sidebar if they are contained or not
const buttonContainStatus = {
  profile: true,
  my_orders: false,
  shipping_address: false,
  change_password: false,
  logout: false,
};

// @ts-ignore
function Profile({ history }) {
  const [containButtons, setContainButtons] = useState(buttonContainStatus);
  const [componentToDisplay, setComponentToDisplay] = useState("profile");
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderId, setOrderId] = useState<number | undefined>(0);
  const [orderCount, setOrderCount] = useState(0);
  const token = localStorage.getItem("authToken");

  // Handle component change based on button click
  const handleSidebarClick = (
    displayComponent: string,
    buttonName: string,
    orderId?: number
  ) => {
    if (displayComponent === "orderDetails") {
      setOrderId(orderId);
    }
    setComponentToDisplay(displayComponent);
    setContainButtons((prevState) => {
      const updatedContainButtons = { ...prevState };
      updatedContainButtons.profile = false;
      updatedContainButtons.my_orders = false;
      updatedContainButtons.shipping_address = false;
      updatedContainButtons.change_password = false;
      updatedContainButtons.logout = false;
      // @ts-ignore
      updatedContainButtons[buttonName] = true;
      return updatedContainButtons;
    });
  };

  // // Redirect users to landing page if now logged in
  useEffect(() => {
    if (token) {
      setIsLoading(false);
    } else {
      history.push("/");
    }
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", p: 15 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid
        sx={{
          padding: componentToDisplay === "orderDetails" ? "5px 0" : "10px 0",
        }}
      >
        {/* Page heading */}
        <Container>
          <Typography
            variant="h5"
            sx={{ color: "#472264", fontWeight: "bold", padding: "10px 0" }}
          >
            {componentToDisplay === "profile" ? "Profile" : ""}
            {componentToDisplay === "myOrders" ? "My Orders" : ""}
            {componentToDisplay === "shippingAddress" ? "Shipping Address" : ""}
            {componentToDisplay === "changePassword" ? "Change Password" : ""}
            {componentToDisplay === "orderDetails" && (
              <Button
                size="large"
                sx={{ color: "#472264", fontWeight: "bold" }}
                startIcon={<ArrowBackIcon className="back-btn" />}
                onClick={() => handleSidebarClick("myOrders", "my_orders")}
              >
                Orders Details
              </Button>
            )}
          </Typography>
        </Container>
      </Grid>
      <Grid
        sx={{ background: "#EAEAEA", padding: { xs: "30px 0", sm: "40px" } }}
      >
        <Container>
          <Grid container>
            {/* Sidemenu */}
            <SideMenu
              orderCount={orderCount}
              containButtons={containButtons}
              handleSidebarClick={handleSidebarClick}
              setOpenModal={setOpenModal}
            />
            {/* Right side component container */}
            <Grid 
              item 
              xs={12} 
              md={9}
              sx={{
                paddingLeft: {xs: 0, md: "40px"}, 
                paddingTop: {xs: "40px", md: 0}
              }}>
              {/* Buyer profile */}
              {componentToDisplay === "profile" && (
                <BuyerProfile setOrderCount={setOrderCount} />
              )}
              {/* Orders */}
              {componentToDisplay === "myOrders" && (
                <Order handleSidebarClick={handleSidebarClick} />
              )}
              {/* Order details */}
              {componentToDisplay === "orderDetails" && (
                <OrderDetails orderId={orderId} />
              )}
              {/* Shipping address */}
              {componentToDisplay === "shippingAddress" && (
                <ShippingAddressForm />
              )}
              {/* Change password */}
              {componentToDisplay === "changePassword" && (
                <ChangePasswordForm />
              )}
              {/* Logout */}
              <LogoutModal openModal={openModal} setOpenModal={setOpenModal} />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
}

// @ts-ignore
export default withRouter(Profile);
