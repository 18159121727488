import React from "react";
import { Typography, Grid, Box, Button, Modal } from "@mui/material";
import { Link } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export interface propsInterface {
  open: boolean;
  handleClose: () => void;
  fileUrl: string | ArrayBuffer | null;
  backgroundImg: string;
  title: string;
  description?: string;
  link: string;
}

export default function SectionOnePreview({
  open,
  handleClose,
  backgroundImg,
  fileUrl,
  title,
  description,
  link,
}: propsInterface) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* Cancel icon */}
        <Box sx={{ position: "relative" }}>
          <CancelIcon
            fontSize="large"
            sx={{
              position: "absolute",
              top: "0",
              right: "0",
              color: "#d6b336",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </Box>
        <Grid
          container
          sx={{
            display: "flex",
            width: "100%",
            // height: '475px',
            // Bg image
            backgroundImage: `url(${fileUrl ? fileUrl : backgroundImg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: {
                xs: "50px 0",
                md: "130px 0",
              },
            }}
          >
            <Box
              sx={{
                padding: {
                  xs: "0 30px",
                  md: "0 50px",
                },
              }}
            >
              <Typography
                variant="h2"
                component="div"
                color="white"
                sx={{ fontWeight: "bold", marginBottom: "30px" }}
              >
                {/* title */}
                {title}
              </Typography>
              <Typography
                variant="h6"
                component="div"
                color="white"
                sx={{ fontWeight: "bold", marginBottom: "30px" }}
              >
                {/* description */}
                {description}
              </Typography>
              {/* link */}
              <Link to={`/${link}`} target="_blank" style={{ textDecoration: "none" }}>
                <Button
                  style={{
                    width: "150px",
                    height: "50px",
                    backgroundColor: "#d6b236",
                    color: "black",
                    borderRadius: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Show More
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={0} sm={4} />
        </Grid>
      </Box>
    </Modal>
  );
}
