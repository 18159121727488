import { Box, Grid, styled, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {baseURL} from "./assets";
import LoaderPage from "../../../components/src/LoaderPage";

const API = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: { color: "#693a8e", marginBottom: 2 },
  editHeader: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#693a8e",
    marginBottom: 4,
  },
  btnContainer: { display: "flex", justifyContent: "flex-end", marginTop: 2 },
};

const PurpleSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#693a8e",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#925BBD",
  },
}));

export default function PaymentOptions() {
  const token = localStorage.getItem("authToken") || "";
  const [isLoading, setIsLoading] = useState(true);
  const [paymentOnDelivery, setPaymentOnDelivery] = useState(false);
  const [onlinePaymentOption, setOnlinePaymentOption] = useState(false);

  // Change status
  const handleChange = (key: string) => {
    setIsLoading(true);
    const data = {
      payment_on_delivery:
        key === "payment_on_delivery" ? !paymentOnDelivery : paymentOnDelivery,
      online_payment_option:
        key === "online_payment_option"
          ? !onlinePaymentOption
          : onlinePaymentOption,
    };

    API.post("/bx_block_dashboard/payment_options", data, {
      headers: { token },
    })
      .then((response) => {
        if (response.status === 201) {
          setIsLoading(false);
          setPaymentOnDelivery(
            response.data.data.attributes.payment_on_delivery
          );
          setOnlinePaymentOption(
            response.data.data.attributes.online_payment_option
          );
          if(key === "payment_on_delivery"){
            if(paymentOnDelivery){
              toast.info("Payment on delivery disabled");
            }else{
              toast.success("Payment on delivery enabled");
            }
          }else if(key === "online_payment_option"){
            if(onlinePaymentOption){
              toast.info("Online payment disabled");
            }else{
              toast.success("Online payment enabled");
            }
          }
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Could not change the status!");
      });
  };

  // Load data
  useEffect(() => {
    API.get("/bx_block_dashboard/payment_options", { headers: { token } })
      .then(({ data }) => {
        setIsLoading(false);
        setPaymentOnDelivery(data.data.attributes.payment_on_delivery);
        setOnlinePaymentOption(data.data.attributes.online_payment_option);
      })
      .catch((err) => {
        toast.error("Could not load data");
      });
  }, []);

  if (isLoading) {
    return <LoaderPage />
  }

  return (
    <>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "8px",
              padding: "20px 30px",
              marginTop: 3,
            }}
          >
            <Typography sx={styles.label}>Enabled Payment option</Typography>
            <Box sx={styles.row}>
              <Typography sx={styles.editHeader}>
                Payment on Delivery
              </Typography>
              <PurpleSwitch
                checked={paymentOnDelivery}
                onChange={() => handleChange("payment_on_delivery")}
              />
            </Box>
            <Box sx={styles.row}>
              <Typography sx={styles.editHeader}>
                Online Payment Option
              </Typography>
              <PurpleSwitch
                checked={onlinePaymentOption}
                onChange={() => handleChange("online_payment_option")}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
