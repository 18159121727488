import {combineReducers} from "redux";
// Importing the all Reducers
import {cartReducer} from "./Cart/reduxCartReducer";
import {orderReducer} from "./Orders/reduxOrdersReducer";
import {buyerReducer} from "./BuyerUsers/reduxBuyerReducer";
import {vendorReducer} from "./VendorUsers/reduxVendorReducer";
import {productsReducer} from "./Products/reduxProductsReducer";
import {notificationReducer} from "./Notifications/reduxNotificationReducer";
import {catalogReducer} from "./Cataloge/reduxCatalogeReducer";
import {landingPageReducer} from "./LandingPage/reduxLandingPageReducer";
import {rolesAndPermissionReducer} from "./RolesAndPermissions/reduxRolesAndPermissionReducer";

// Initialize the reducer
export const reducers = combineReducers({
    cart : cartReducer,
    orders: orderReducer,
    buyers: buyerReducer,
    vendors:vendorReducer,
    products:productsReducer,
    notifications:notificationReducer,
    catalog:catalogReducer,
    landingPage:landingPageReducer,
    rolesAndPermission:rolesAndPermissionReducer,
})