import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ColorButton } from "./Style";
import {getCountries, getStates, updateShippingAdderess} from "./api";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { withRouter } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as yup from "yup";
import {useForm,Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const initialState = {
  address_line1: "",
  address_line2: "",
  country: "Argentina",
  state: "",
  zipcode: ""
};

const RegisterForm = ({setOpenSuccessModel,setOpenRegisterModel,history}) => {
  const addressSchema = yup.object().shape({
    "address_line1":yup.string().required("Please enter address 1"),
    "address_line2":yup.string().required("Please enter address 2"),
    "state":yup.string().required("Please select state"),
    "zipcode":yup.string().required("Please enter Zipcode").min(4,"please enter valid Zipcode"),
  })
  const { register, handleSubmit, watch, formState: { errors },control:shippingController } = useForm({
    resolver: yupResolver(addressSchema),
    defaultValues:initialState
  });
  const [values, setValues] = React.useState(initialState);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [countryValue, setCountryValue] = useState("AR");
  const [states, setStates] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  useEffect(() => {
    countryList();
    getStateData("AR");
  }, []);

  const countryList = async () => {
    const ctry = await getCountries();
    setCountry(ctry?.data);
  };


  const handleCountry = e => {
    setCountryValue(e.target.value);
    setStates([]);
    let { data } = "";
    country.filter(x => {
      if (x.code == e.target.value) {
        data = x;
        getStateData(data.code);
      }
    });
    setValues({ ...values, country: data.name });
  };
  const getStateData = async code => {
    setStateLoading(true)
    const { data } = await getStates(code);
    setStates(data);
    setStateLoading(false)
  };

  const handleSubmit1 = async (formData) => {
    setIsLoading(true);
    const profile = JSON.parse(localStorage.getItem("profile"));
    const token = localStorage.getItem("authToken");
    try {
      if (
          formData.address_line1 &&
          formData.address_line2 &&
          formData.country &&
          formData.state &&
          formData.zipcode
      ) {
        const finalData = {
          name: profile.full_name,
          address_attributes: {
            address_line1:formData.address_line1,
            address_line2:formData.address_line2,
            country:values.country,
            state:formData.state,
            zipcode:formData.zipcode,

          }
        };
        const { data } = await updateShippingAdderess(finalData, token);
        if (data.errors) {
          setError(data.errors);
        } else {
          setOpenRegisterModel(false);
          history.push("/");
        }
      } else {
        setError([
          {
            message: "Please Enter All Fields"
          }
        ]);
      }
    } catch (e) {
      setError([
        {
          message: "Something Went Wrong"
        }
      ]);
    }
    setIsLoading(false);
  };

  return (
    <Grid item xs={12} className="registerMainDiv" sx={{ padding: "35px 0" }}>
      <Box className="registerFormFlex">
        <Typography
          variant="h6"
          component="div"
          color="purple"
          sx={{ fontWeight: "bold" }}
        >
          Shipping Address
        </Typography>
        <Typography variant="subtitle1" component="div" color="purple">
          Fields marked with an asterisk (*) are required.
        </Typography>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          {error
            ? error.map((e, index) => {
                return (
                  <Alert
                    key={index}
                    severity="error"
                    sx={{ width: "82%", alignSelf: "center" }}
                  >
                    {e.message}
                  </Alert>
                );
              })
            : null}
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Address Line 1
              <Typography
                variant="subtitle1"
                color="secondary"
                component="span"
                sx={{ fontWeight: "bold" }}
              >
                *
              </Typography>
            </Typography>
            <Controller
                name="address_line1"
                control={shippingController}
                render={({ field }) => (
                  <TextField
                    error={errors["address_line1"]}
                    {...field}
                    block
                    placeholder="Enter your address 1"
                    size="small"
                    sx={{ width: "90%" }}
                  />
                )} />
            {errors["address_line1"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["address_line1"]?.message}
                </Typography> : null
            }
          </Box>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Address Line 2
              <Typography
                variant="subtitle1"
                color="secondary"
                component="span"
                sx={{ fontWeight: "bold" }}
              >
                *
              </Typography>
            </Typography>
            <Controller
                name="address_line2"
                control={shippingController}
                render={({ field }) => (
                  <TextField
                    error={errors["address_line2"]}
                    {...field}
                    block
                    placeholder="Enter your address 2"
                    size="small"
                    sx={{ width: "90%" }}
                  />
                )} />
            {errors["address_line2"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["address_line2"]?.message}
                </Typography> : null
            }
          </Box>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
                variant="subtitle1"
                component="div"
                sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Country
              <Typography
                  variant="subtitle1"
                  color="secondary"
                  component="span"
                  sx={{ fontWeight: "bold" }}
              >
                *
              </Typography>
            </Typography>
            <FormControl sx={{ width: "90%" }}>
              <Select
                  error={errors["country"]}
                  {...register("country")}
                  size="small"
                  value={countryValue}
                  onChange={e => handleCountry(e)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {country?.map((c, index) => {
                    return <MenuItem value={c.code}>{c.name}</MenuItem>
                })}
              </Select>
              {errors["country"] ?
                  <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{ marginBottom: "1",color:"red" }}
                  >
                    {errors["country"]?.message}
                  </Typography> : null
              }
            </FormControl>
          </Box>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
                variant="subtitle1"
                component="div"
                sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              State
              <Typography
                  variant="subtitle1"
                  color="secondary"
                  component="span"
                  sx={{ fontWeight: "bold" }}
              >
                *
              </Typography>
            </Typography>
            <FormControl sx={{ width: "90%",display:"flex",alignItems:"center" }}>
              {
                stateLoading ? <CircularProgress/>:
              <Controller
                  name="state"
                  control={shippingController}
                  render={({ field }) => (
                    <Select
                        error={errors["state"]}
                        {...field}
                        sx={{width:"100%"}}
                        size="small"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">Select State</MenuItem>
                      {states.map((s, index) => {
                          return <MenuItem value={`${s.name}`}>{s.name}</MenuItem>
                      })}
                    </Select>
                  )} />
              }
              {errors["state"] ?
                  <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{ marginBottom: "1",color:"red",alignSelf:"flex-start" }}
                  >
                    {errors["state"]?.message}
                  </Typography> : null
              }
            </FormControl>
          </Box>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Zipcode
              <Typography
                  variant="subtitle1"
                  color="secondary"
                  component="span"
                  sx={{ fontWeight: "bold" }}
              >
                *
              </Typography>
            </Typography>
            <Controller
                name="zipcode"
                control={shippingController}
                render={({ field }) => (
                  <TextField
                    error={errors["zipcode"]}
                    {...field}
                    block
                    placeholder="Enter Zipcode"
                    size="small"
                    sx={{ width: "90%" }}
                  />
                )} />
            {errors["zipcode"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red"}}
                >
                  {errors["zipcode"]?.message}
                </Typography> : null
            }
          </Box>
          <Box>
            <ColorButton
              variant="contained"
              color="secondary"
              sx={{ width: "90%", height: "50px",marginTop:"15px" }}
              onClick={handleSubmit(handleSubmit1)}
            >
              {isLoading ? <CircularProgress /> : "Save"}
            </ColorButton>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default withRouter(RegisterForm);
