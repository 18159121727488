import axios from "axios";
import {baseURL} from "framework/src/config";

// API for redux notification
const API = axios.create({
    baseURL: `${baseURL}/bx_block_notifications/`
});

// set header token config
API.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

// get all notifications API
export const notification = async () => {
    try {
        return await API.get(`/notifications`);
    } catch (e) {
        return e.response;
    }
}

// get Unread notifications API
export const unreadNotification = async () => {
    try {
        return await API.get(`/notifications/unread_notification`);
    } catch (e) {
        return e.response;
    }
}

// get Unread notifications API
export const updateUnreadNotification = async (notifications) => {
    try {
        return await API.post(`/notifications/update_notifications`, {id:notifications});
    } catch (e) {
        return e.response;
    }
}