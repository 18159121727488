const initialState = {
    products:[],
    category_deactivated:false,
    productDetails:{},
    pagination:{},
    isLoading:false,
    currentPage:1,
    showCount:10,
    firmName:"",
}

export const productsReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case "SET_PRODUCT_LIST":
            let catFlag = false
            payload.map((i) => {
                if(!i?.attributes?.category_status){
                    catFlag = true
                }
            })
            return {
                ...state,
                products: payload,
                category_deactivated:catFlag
            }
        case "SET_PRODUCT_PAGINATION":
            return {
                ...state,
                pagination: payload,
            }
        case "SET_LOADING_FALSE":
            return {
                ...state,
                isLoading:false
            }
        case "SET_LOADING_TRUE":
            return {
                ...state,
                isLoading:true
            }
        case "SET_CURRENT_PRODUCT_PAGE":
            return {
                ...state,
                currentPage:payload
            }
        case "SET_SHOW_PRODUCT_COUNT":
            return {
                ...state,
                showCount:payload
            }
        case "SET_PRODUCT_PAGE_DETAILS":
            return {
                ...state,
                productDetails:payload,
            }
        case "SET_PRODUCT_FIRM_NAME":
            return {
                ...state,
                firmName:payload,
            }
        default:
            return state
    }
}