import React, { useEffect, useState, Fragment } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell from '@mui/material/TableCell';
import { Box } from "@mui/system";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
import {
  searchImage,
  eyeImage,
  getVendorBuyerList,
  setCurrentPageFromVendor,
  setShowCountFromVendor,
  setShowCount,
  getBuyerList,
  setCurrentPage,
  setSearchKeyBuyer,
  setSearchTextBuyer,
  getBuyerListSearchVendor,
} from "./assets";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  InputAdornment,
  InputLabel,
  ListItemText,
  Select,
  TextField,
  Typography,
  Stack,
  FormControl,
  Pagination,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LoaderPage from "../../../components/src/LoaderPage";
import moment from "moment";
import NoRecordPage from "../../../components/src/NoRecordPage";
import CloseIcon from "@mui/icons-material/Close";

// @ts-ignore
const UsersPageVendor = ({ history }) => {
  const [searchBy, setSearchBy] = useState("");
  const [searchPhrase, setSearchPhrase] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles();
  // @ts-ignore
  const {
    buyers,
    pagination,
    isLoading,
    currentPage,
    showCount,
    searchKey,
    searchText,
  } = useSelector((state: any) => state.buyers);

  const handleClickMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // @ts-ignore
  const managePagination = (event, value) => {
    dispatch(setCurrentPageFromVendor(value, showCount));
  };

  // Search buyers
  const handleSearch = () => {
    dispatch(getBuyerListSearchVendor(showCount, 1, searchKey, searchText));
  };

  // Clear search
  const clearSearch = () => {
    setSearchBy("");
    setSearchPhrase("");
    dispatch(getVendorBuyerList(showCount, 1));
  };

  // Set search by
  useEffect(() => {
    dispatch(setSearchKeyBuyer(searchBy));
  }, [searchBy]);

  // Set search phrase
  useEffect(() => {
    dispatch(setSearchTextBuyer(searchPhrase));
  }, [searchPhrase]);

  // Clear search when search phrase is empty
  useEffect(() => {
    if (searchPhrase === "") {
      clearSearch();
    }
  }, [searchPhrase]);

  // Load data
  useEffect(() => {
    dispatch(getVendorBuyerList(showCount, 1));
  }, []);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} lg={5}>
          <Stack direction="row">
            <FormControl size="small" sx={styles.formControl}>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="select-label-id"
                id="select-id"
                value={searchBy}
                label="Select"
                onChange={(e) => {
                  setSearchBy(e.target.value);
                }}
                sx={styles.select}
              >
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="email">Email Id</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              size="small"
              value={searchPhrase}
              onChange={(e) => {
                setSearchPhrase(e.target.value);
              }}
              sx={styles.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchPhrase && (
                      <IconButton onClick={clearSearch}>
                        <CloseIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={handleSearch}>
                      <img src={searchImage} alt="search" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
        {isLoading ? (
          <LoaderPage />
        ) : buyers.length !== 0 ? (
          <Grid item xs={12}>
            <TableContainer
              sx={{ borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                width:{
                  md:"100%",
                  sm:"750px",
                  xs:"350px"
                } }}
            >
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="right">Registered</StyledTableCell>
                    <StyledTableCell align="right">Email Id</StyledTableCell>
                    <StyledTableCell align="right">Phone</StyledTableCell>
                    <StyledTableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {// @ts-ignore
                  buyers.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "start",
                          }}
                        >
                          {row.attributes.full_name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {moment(row.attributes.created_at).format("Do MMM YY")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.attributes.email}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {
                          row.attributes.full_phone_number ? `+${row.attributes.full_phone_number}` : "-"
                        }
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <IconButton
                          onClick={() =>
                            history.push(`/vendor/buyers/buyerDetail/${row.id}`)
                          }
                        >
                          <img src={eyeImage} width="20px" height="15px" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <NoRecordPage text={"Sorry, no buyer users found!"}/>
        )}
      </Grid>
      <Grid container sx={{marginTop: 4}}>
        <Grid item xs={12} sm={6}>
          <FormControl size="small" sx={{ width: "150px",marginBottom:"10px" }}>
            <InputLabel id="bulk-actions">Show</InputLabel>
            <Select
              labelId="show-count"
              id="demo-simple-select"
              value={showCount}
              label="Show:"
              onChange={(e) =>
                dispatch(setShowCountFromVendor(currentPage, e.target.value))
              }
              sx={{ background: "#fff" }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} sx={{display:'flex',justifyContent: {xs:"center",sm:"flex-end"}}}>
          <Stack spacing={5} sx={{alignItems: "flex-end"}}>
            <Pagination
              count={pagination.total_pages}
              hidePrevButton
              page={currentPage}
              color="secondary"
              variant="outlined"
              shape="rounded"
              onChange={managePagination}
              sx={styles.pagination}
            />
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withRouter(UsersPageVendor);
const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  paper: {
    color: "black",
    padding: 10,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  menuLink: {
    textDecoration: "none",
    color: "#472264",
    marginBottom: 5,
    boxShadow: "none",
  },
  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    display: "flex",
  },
  select: {
    width: "40%",
    backgroundColor: "transparent",
    margin: "5px 20px 5px 20px",
    borderRight: "1px solid #737373",
    paddingRight: "10px",
  },
  search: {
    width: "60%",
    margin: 5,
    display: "flex",
    alignItems: "space-between",
  },
  showselect: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageselect: {
    backgroundColor: "white",
    borderRadius: "5px",
    height: "33px",
    width: "150px",
    display: "flex",
    justifyContent: "space-between",
  },
  paragragh: {
    padding: 0,
    marginTop: 4,
    marginLeft: 20,
    fontWeight: "bold",
    // width:"50%"
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#472264",
      color: theme.palette.common.white,
      textAlign: "start",
    },
    body: {
      fontSize: 13,
      color: "#472264",
      fontWeight: "bold",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f5ebfc",
      },
    },
  })
)(TableRow);

const styles = {
  firmName: {
    color: "#472264",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
  },
  formControl: {
    width: {
      xs: 150,
      md: 200,
    },
    ".MuiInputBase-root": {
      lineHeight: "1.7em",
    },
    ".MuiOutlinedInput-root": {
      padding: "0 10px",
    },
  },
  select: {
    background: "#fff",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  textField: {
    background: "#fff",
    width: {
      xs: 150,
      md: 200,
    },
    "& fieldset": {
      borderLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    ".MuiOutlinedInput-input": {
      height: "1.657em",
      paddingLeft: "10px",
    },
  },
  btn: {
    background: "#693a8e",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "100%",
    padding: "2px 10px",
    "&:hover": { background: "#693a8e" },
  },
  tabBtn: { borderRadius: 0, color: "#693a8e" },
  status: {
    width: "75%",
    margin: "0px 0px",
    borderRadius: "10px",
    height: "35px",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    padding: "0 15px",
  },
  pagination: {
    alignItems: "flex-end"
  },
};
