import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { registerArrow, registerTypes } from "./Style";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Link,withRouter } from "react-router-dom";
import { vendor, buyer, arrowBuyer,setLoginModelFalse} from "./assets";
import {useDispatch} from "react-redux";

const LoginForm = ({history,setFormType}) => {
  const dispatch = useDispatch()
  const manageRedirect = (type) => {
    if(type === "vendor"){
      history.push("/EmailAccountRegistration/vendor")
    }else if(type === "buyer"){
      history.push("/EmailAccountRegistration/buyer")
    }
    dispatch(setLoginModelFalse())
    setFormType("login")
  }

  return (
    <Grid item xs={12} className="loginMainDiv">
      <Box className="loginFormFlex" style={{ padding: "45px 0 25px 0" }}>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#412360" }}
        >
          Sign up as a
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          gutterBottom
          color="purple"
          sx={{ width: "90%" }}
        >
          Select your interest
        </Typography>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Paper elevation={0} sx={registerTypes} onClick={() => manageRedirect("vendor")}>
              <img
                src={vendor}
                style={{ width: "auto", height: "100px", marginLeft: "40px" }}
                alt="Brand Logo"
              />
              <Box sx={{ marginLeft: "35px", width: "50%" }}>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Vendor
                </Typography>
                <Typography variant="subtitle2" component="div" gutterBottom>
                  Create your account to offer your products into the platform
                </Typography>
              </Box>
              <Box sx={registerArrow}>
                <img
                  src={arrowBuyer}
                  width="30px"
                  alt="close Button"
                  sx={{ padding: "10px" }}
                />
              </Box>
            </Paper>
            <Paper elevation={0} sx={registerTypes} onClick={() => manageRedirect("buyer")}>
              <img
                src={buyer}
                alt="Brand Logo"
                style={{ width: "auto", height: "90px", marginLeft: "20px" }}
              />
              <Box sx={{ marginLeft: "20px", width: "50%" }}>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontWeight: "bold", textDecoration: "none" }}
                >
                  Buyer
                </Typography>
                <Typography variant="subtitle2" component="div" gutterBottom>
                  Create your account to start buying
                </Typography>
              </Box>
              <Box sx={registerArrow}>
                <img
                  src={arrowBuyer}
                  width="30px"
                  alt="close Button"
                  sx={{ padding: "10px" }}
                />
              </Box>
            </Paper>
        </Grid>
      </Box>
    </Grid>
  );
};

export default withRouter(LoginForm);
