import React from 'react';
import { Typography, Grid, Box, Button, Modal } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';

import { style, propsInterface } from './SectionOnePreview';

export default function SectionThreePreview({
  open,
  handleClose,
  backgroundImg,
  fileUrl,
  title,
  description,
  link,
}: propsInterface) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        {/* Cancel icon */}
        <Box sx={{ position: 'relative' }}>
          <CancelIcon
            fontSize='large'
            sx={styles.cancelIcon}
            onClick={handleClose}
          />
        </Box>
        <Grid
          container
          sx={{
            ...styles.container,
            backgroundImage: `url(${fileUrl ? fileUrl : backgroundImg})`,
          }}
        >
          <Grid item xs={0} md={5} />
          <Grid
            item
            xs={12}
            md={7}
            sx={styles.gridItem}
          >
            <Box
              sx={styles.textContainer}
            >
              <Typography
                variant='h2'
                color='#6a3a8e'
                sx={styles.title}
              >
                {title}
              </Typography>
              <Typography
                variant='h6'
                component='div'
                color='#6a3a8e'
                sx={styles.description}
              >
                {description}
              </Typography>
              <Link to={`/${link}`} target="_blank" style={{ textDecoration: 'none' }}>
                <Button
                  sx={styles.showMore}
                >
                  Show More
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

const styles = {
  cancelIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
    color: '#d6b336',
    cursor: 'pointer',
  } as const,
  container: {
    width: '100%',
    height: '475px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
  },
  gridItem: {
    height:"100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "200px 20px",
  } as const,
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  } as const,
  title: {
    fontWeight: "bold",
    marginBottom: "30px",
    fontSize: { xs: "2.5rem", sm: "3.75rem" },
  },
  description: {
    fontWeight: "bold",
    marginBottom: "30px",
    textAlign: "right",
    width: "80%",
  } as const,
  showMore: {
    width: '150px',
    height: '50px',
    backgroundColor: '#d6b236',
    color: 'black',
    borderRadius: '5px',
    fontWeight: 'bold',
  }
}