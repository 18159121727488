import {Grid, Button, Typography, Modal, Divider, CircularProgress} from "@mui/material";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CartItem from "./CartItem";
import { useSelector } from "react-redux";
import { placeOrder } from "./api";
import {toast} from "react-toastify";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
// @ts-ignore
const FinalOrder = ({ id, history }) => {
  const cartItems = useSelector((state) => state.cart.finalCart);
  // @ts-ignore
  const {paymentOnline,paymentOffline} = useSelector(state => state.landingPage)
  const [finalAmount, setFinalAmount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = React.useState({
    cod: true,
    online: false,
  });
  const { permission } = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    let amount = 0;
    cartItems.forEach((i) => {
      const a = parseInt(i?.attributes.total_price);
      amount = amount + a;
    });
    setFinalAmount(amount);
    if(cartItems.length === 0){
      // history.push("/Catalogue")
    }
    if(paymentOnline && !paymentOffline){
      setState({
        cod: false,
        online: true,
      })
    }
  }, [cartItems]);



  const handleChange = (event) => {
    if (event.target.name == "cod") {
      setState({
        ...state,
        cod: event.target.checked,
        online: false,
      });
    } else if (event.target.name == "online") {
      setState({
        ...state,
        online: event.target.checked,
        cod: false,
      });
    }
  };

  const handleClick = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    let orderFinalize = {};
    setIsDisabled(true)
    if (state.online) {
      try {
        orderFinalize = await placeOrder(id, "pay_on_online");
        if (orderFinalize.status === 200) {
          location.href = orderFinalize.data.data.init_point;
        }else {
          if (orderFinalize.data.errors) {
            orderFinalize.data.errors.map((e) => {
              toast.error(e.message)
              setIsDisabled(false)
            })
            setIsDisabled(false)
          }
        }
      }catch (e) {
        toast.error("Something went wrong..!")
      }
    } else {
      try{
        orderFinalize = await placeOrder(id, "pay_on_delivery");
        console.log("order",orderFinalize.status)
        if (orderFinalize.status === 200) {
          history.push(`/OrderStatus/${id}`);
        }else{
          if(orderFinalize.data.errors){
            orderFinalize.data.errors.map((e)=>{
              toast.error(e.message)
            })
          }
        }
      }catch (e) {
        toast.error("Something went wrong..!")
      }
    }
    setIsLoading(false)
  };

  const { cod, online } = state;
  const error = [cod, online].filter((v) => v).length !== 1;
  return (
    <Grid 
      sx={{ marginRight: { xs: "0", md: "20px" } }}
    >
      {/* HEADER */}
      <Typography
        variant="h5"
        fontWeight="700"
        color="#693A8E"
        padding="25px 0"
      >
        Order summary
      </Typography>
      <Grid
        container
        sx={{ padding: "20px 15px" }}
        xs={12}
        display="flex"
        alignItems="center"
      >
        <Grid item xs={8} sm={6}>
          Items ({cartItems.length})
        </Grid>
        <Grid item xs={2} sx={{ display: { xs: "none", sm: "block" } }}>
          Price
        </Grid>
        <Grid item xs={2} sx={{ display: { xs: "none", sm: "block" } }}>
          Quantity
        </Grid>
        <Grid item xs={2} sx={{ display: { xs: "none", sm: "block" } }}>
          Total Amount
        </Grid>
      </Grid>
      <Divider />
      {/* ITEMS */}
      {cartItems?.map((item, key) => {
        return <CartItem item={item} key={key} />;
      })}
      {/* AFTER ITEMS */}
      <Divider />
      <Grid container xs={12} sx={{ padding: "20px 15px" }}>
        <Grid items xs={6}>
          <Typography variant="subtitle1">TOTAL :</Typography>
        </Grid>
        <Grid items xs={6} sx={{ textAlign: "right" }}>
          <Typography variant="subtitle1" fontWeight="700">
            $ {numberWithCommas(finalAmount)}
          </Typography>
        </Grid>
      </Grid>
      {/* PAYMENT METHODS */}
      <Divider />
      <Grid container xs="12" sx={{ padding: "20px 15px" }}>
        <Grid item xs="12">
          {
            paymentOnline || paymentOffline ?
                <Typography
                    variant="h6"
                    color="#693A8E"
                    sx={{ marginBottom: "15px" }}
                >
                  Choose Payment Method
                </Typography> : null
          }
          <FormControl
            required
            error={error}
            component="fieldset"
            sx={{ m: 3 }}
            variant="standard"
            className="paymentType"
            sx={{ width: "100%" }}
          >
            <FormGroup>
              {paymentOffline && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cod}
                      onChange={handleChange}
                      name="cod"
                    />
                  }
                  label="Pay on Delivery"
                  sx={{ width: "100%" }}
                  className={state.cod ? "selectedPayment" : null}
                />
              )}
              {paymentOnline && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={online}
                      onChange={handleChange}
                      name="online"
                    />
                  }
                  label="Pay online"
                  sx={{ width: "100%" }}
                  className={state.online ? "selectedPayment" : null}
                />
              )}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      {
        paymentOnline || paymentOffline ?
          <Grid xs={12}>
            <Button
                onClick={handleClick}
                variant="contained"
                disabled={cartItems?.length === 0 || isDisabled}
                size="large"
                sx={{
                  color: "#fff",
                  background: "#472264",
                  fontWeight: "bold",
                  width: "100%",
                  "&:hover": { background: "#472264bf", color: "#fff" },
                }}
            >
              {isLoading ? <CircularProgress size={30} color="inherit"/> : "Finalize order"}
            </Button>
          </Grid> : null
      }
    </Grid>
  );
};

export default withRouter(FinalOrder);