import React, {useEffect,useState} from 'react';
import Container from '@mui/material/Container';
import { Grid, Button, Typography, Divider, Box, TextField, Autocomplete } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link, withRouter,useSearchParams} from "react-router-dom";
import {checkImg,failedImg} from "./assets";
import {getOrderList} from "./api";
import moment from "moment";
import {removeAllCartItems} from "./assets";
import {useDispatch} from "react-redux";
import queryString from 'query-string'
import { toast } from 'react-toastify';

const OrderOnlineStatus = ({history,match,location}) => {
    const dispatch = useDispatch()
    const parsed = queryString.parse(location.search)
    const [orderDetails, setOrderDetails] = useState({});
    const {id} = match.params
    useEffect(()=>{
        getOrderDetails()
        dispatch(removeAllCartItems())
    },[])

    const getOrderDetails = async () =>{
        const {data} = await getOrderList(parsed.external_reference)
        if(data.errors) {
            data.errors.map((e) => {
                toast.error(e.message)
            })
        }else{
            // @ts-ignore
            if(data?.data[0]?.attributes.status === "in_cart") {
                history.push(`/OrderSummary/${parsed.external_reference}`)
            }else{
                setOrderDetails(data?.data[0]?.attributes)
            }
        }
    }

    return (
    <Grid>
        <Grid sx={{ background: '#fff', display : 'flex', padding : '10px 0'}}>
            <Container >
                <Grid container xs="12" >
                    <Grid item xs="12">
                        <Link to='/' style={{ textDecoration : 'none'}}>
                            <Button  size="large" sx={{ color : '#472264', fontWeight : 'bold'}}  startIcon={<ArrowBackIcon  className="back-btn" style={{ marginRight : '10px'}}  />}>
                                Back to home
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
        <Grid sx={{ background: '#EAEAEA', display : 'flex', padding : '35px 0 55px 0'}}>
            <Container >
                <Grid container >
                    <Grid item xs={12} sm={12} md={8} lg={8}  sx={{ marginBottom : { xs: '15px', md : '15px'} }}>
                        {
                            parsed.status === "approved" ?
                                <Grid display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ background : '#fff', textAlign:'center', borderRadius : '5px', padding : { xs : '5% 10%', sm : '5% 25%'}, boxShadow: 2,  marginRight : { xs: '0', md: '15px'}}}>
                                    <img src={checkImg}  style={{ paddingBottom : '15px' }} />
                                    <Typography variant="h5" sx={{ paddingBottom : '15px', fontWeight:'bold'}}>You have placed order successfully</Typography>
                                    <Typography variant="subtitle2" style={{ paddingBottom : '15px' }}>Thank you for shopping with us, we will send confirmation on your primary email address.</Typography>
                                </Grid> :
                                <Grid display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ background : '#fff', textAlign:'center', borderRadius : '5px', padding : { xs : '5% 10%', sm : '5% 25%'}, boxShadow: 2,  marginRight : { xs: '0', md: '15px'}}}>
                                    <img src={failedImg} height="100px" style={{ paddingBottom : '15px' }} />
                                    <Typography variant="h5" sx={{ paddingBottom : '15px', fontWeight:'bold'}}>Your Order Can Not be placed</Typography>
                                    <Typography variant="subtitle2" style={{ paddingBottom : '15px' }}>There is some issue While placing the order please check your payment method</Typography>
                                </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} sx={{ background : '#fff', borderRadius : '5px', padding : '25px 20px', boxShadow: 2}}>
                        {/* order summary */}
                        <Typography variant="h6" color='#472266' component="span" sx={{fontWeight:"bold"}}>Order summary</Typography>
                        <Grid container sx={{ paddingTop : '15px'}}>
                            <Grid items xs={6} sx={{ paddingBottom : '7px'}}>
                                <Typography variant="subtitle2">Order Date :</Typography>
                            </Grid>
                            <Grid items xs={6} sx={{ textAlign : 'right'}}>
                                <Typography variant="subtitle2" fontWeight="700">{moment(orderDetails?.updated_at).format('Do, MMMM YYYY')}</Typography>
                            </Grid>

                            <Grid items xs={6} sx={{ paddingBottom : '7px'}}>
                                <Typography variant="subtitle2">Order ID :</Typography>
                            </Grid>
                            <Grid items xs={6} sx={{ textAlign : 'right'}}>
                                <Typography variant="subtitle2" fontWeight="700">{orderDetails?.order_number || 123456}</Typography>
                            </Grid>

                            <Grid items xs={6} sx={{ paddingBottom : '7px'}}>
                                <Typography variant="subtitle2">Order Total :</Typography>
                            </Grid>
                            <Grid items xs={6} sx={{ textAlign : 'right'}}>
                                <Typography variant="subtitle2" fontWeight="700">$ {orderDetails?.total}</Typography>
                            </Grid>
                        </Grid>
                        <Divider></Divider>
                        {/* items list */}
                        <Typography variant="h6" color='#472266' component="div" sx={{fontWeight:"bold", padding:'15px 0'}}>Items ({orderDetails?.order_items?.length || 0})</Typography>
                        <Divider></Divider>
                        {
                            orderDetails?.order_items?.map((item,key)=>{
                                return(
                                    <Grid className="notify-grid-main" container display="flex"  alignItems="center" sx={{ padding: '15px 0' , flexWrap:"nowrap" }} key={key} >
                                        <Grid className="notify-grid-img" item style={{ paddingLeft : '0' }}><img width="70px" src={item?.attributes?.catalogue_image?.url} style={{ paddingLeft : '0' }} alt='notification'/></Grid>
                                        <Grid item xs={12} className="notify-grid-content" sx={{ position : 'relative'}}>
                                            <Typography variant="subtitle2" color="#693A8E" sx={{ paddingBottom : '8px'}}>{item.attributes.catalogue_name}</Typography>
                                            <Typography variant="body" sx={{ color : '#472266', fontWeight : 'bold'}} >QTY {item.attributes.quantity}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        <Divider></Divider>
                        {/* Payment method */}
                        <Typography variant="h6" color='#472266' component="div" sx={{fontWeight:"bold", padding:'15px 0'}}>Payment Method</Typography>
                        <Typography variant="subtitle2" component="div" sx={{paddingBottom:'15px'}}>{orderDetails?.payment_method || "Pay on Delivery"}</Typography>
                        <Divider></Divider>
                        {/* ADDRESS */}
                        <Typography variant="h6" color='#472266' component="div" sx={{fontWeight:"bold", padding:'15px 0'}}>Shipping Address</Typography>
                        <Typography variant="body2" sx={{ width : '80%'}} component="div">{orderDetails?.shipping_address?.attributes?.address?.address_line1},{orderDetails?.shipping_address?.attributes?.address?.address_line2} - {orderDetails?.shipping_address?.attributes?.address?.zipcode}, {orderDetails?.shipping_address?.attributes?.address?.state},{orderDetails?.shipping_address?.attributes?.address?.country}.</Typography>
                        <Divider sx={{margin:'15px 0'}}></Divider>
                        <Grid xs={12} sx={{ paddingTop : '15px'}}>
                            <Link to="/" style={{ textDecoration : 'none'}}>
                                <Button variant="contained" size="large" sx={{ color : '#000',background: '#D6B336', fontWeight : 'bold', width:'100%', "&:hover": { background : '#D6B336', color : '#000'} }} >Back to Home</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </Grid>
    );
};

export default withRouter(OrderOnlineStatus);