import React, { useState } from "react";
import { Box, Tab, Tabs, Paper, Typography } from "@mui/material";

import SectionContainer from "./SectionContainer";
import Footer from "./Footer";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 1, md: 3 } }}>{children}</Box>}
    </div>
  );
}

export default function ContentManagement() {
  const [value, setValue] = useState(0);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Home Page Body" sx={{ textTransform: "capitalize" }} />
          <Tab label="Footer" sx={{ textTransform: "capitalize" }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SectionContainer />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Footer />
      </TabPanel>
    </Box>
  );
}
