import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import useShippingAddress from "../../../components/src/hooks/useShippingAddress";
const { ColorButton } = require("./Style");

const ShippingAddress = () => {
  const {
    control,
    errors,
    listOfStates,
    countries,
    submitForm,
    handleSubmit,
    isSaving,
  } = useShippingAddress();

  return (
    <Box className="registerMainDiv" sx={{ padding: "25px 0" }}>
      <Typography variant="h6" color="#693A8E" sx={{ fontWeight: "bold" }}>
        Your Shipping Address
      </Typography>
      <Grid item xs={12} sx={{ marginTop: "20px" }}>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="subtitle1"
            sx={styles.label}
          >
            Name
          </Typography>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors["name"] ? true : false}
                placeholder="Enter your name"
                fullWidth
              />
            )}
          />
          {errors["name"] ? (
            <Typography
              variant="subtitle2"
              component="div"
              sx={styles.errorText}
            >
              {errors["name"]?.message}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="subtitle1"
            sx={styles.label}
          >
            Address Line 1
          </Typography>
          <Controller
            name="address1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Enter your address"
                error={errors["address1"] ? true : false}
                fullWidth
              />
            )}
          />
          {errors["address1"] ? (
            <Typography
              variant="subtitle2"
              sx={styles.errorText}
            >
              {errors["address1"]?.message}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="subtitle1"
            sx={styles.label}
          >
            Address Line 2
          </Typography>
          <Controller
            name="address2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors["address2"] ? true : false}
                placeholder="Enter your address"
                fullWidth
              />
            )}
          />
          {errors["address2"] ? (
            <Typography
              variant="subtitle2"
              sx={styles.errorText}
            >
              {errors["address2"]?.message}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ marginBottom: "15px" }}>
          <Typography
            variant="subtitle1"
            sx={styles.label}
          >
            Country
          </Typography>
          {countries.length > 0 && (
            <FormControl
              sx={{ width: "100%" }}
              error={errors["country"] ? true : false}
            >
              <InputLabel id="bulk-actions">Select country</InputLabel>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="country-select"
                    id="demo-simple-select"
                    label="Select country"
                    displayEmpty
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors["country"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["country"]?.message}
                </Typography>
              ) : null}
            </FormControl>
          )}
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="subtitle1"
            sx={styles.label}
          >
            State
          </Typography>
          <FormControl
            sx={{ width: "100%" }}
            error={errors["state"] ? true : false}
          >
            <InputLabel id="bulk-actions">Select state</InputLabel>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="country-select"
                  id="demo-simple-select"
                  label="Select state"
                  displayEmpty
                >
                  {listOfStates.length > 0 &&
                    listOfStates.map((state) => (
                      <MenuItem key={state.code} value={state.name}>
                        {state.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
            {errors["state"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {errors["state"]?.message}
              </Typography>
            ) : null}
          </FormControl>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="subtitle1"
            sx={styles.label}
          >
            Zipcode
          </Typography>
          <Controller
            name="zipcode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Enter your Zip Code"
                error={errors["zipcode"] ? true : false}
              />
            )}
          />
          {errors["zipcode"] ? (
            <Typography
              variant="subtitle2"
              sx={styles.errorText}
            >
              {errors["zipcode"]?.message}
            </Typography>
          ) : null}
        </Box>
        <Box>
          <ColorButton
            variant="contained"
            color="secondary"
            sx={styles.colorBtn}
            onClick={submitForm(handleSubmit)}
          >
            {isSaving ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              "Save"
            )}
          </ColorButton>
        </Box>
      </Grid>
    </Box>
  );
};

export default ShippingAddress;

const styles = {
  label: { fontWeight: "bold", marginBottom: 1 },
  errorText: { marginBottom: "1", color: "red" },
  colorBtn: { width: "100%", height: "50px" }
};
