export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const { getCatalogeList } = require("./api");
export const productImage = require("../assets/productImage.png");
export const searchImage = require("../assets/search.png")
export const downarrowImage = require("../assets/downarrowthin.png")
export const switchImage = require("../assets/switch.png")
export const menuImage = require("../assets/horizontalMenu.png")
export const plusImage = require("../assets/plus.png")
export const uploadImage = require("../assets/upload.png")
export const calenderImage = require("../assets/calender.png")
export const galleryImage = require("../assets/gallery.png")
export const switchgreyImage = require("../assets/switchgrey.png")
export const cancelImage = require("../assets/cancel.png")
export const eyeImage = require("../assets/eye.png")
export const whiteArrowImage = require("../assets/arrow.png")
export const cowImage = require("../assets/cow.png")
export const cow2Image = require("../assets/cow2.png")
export const circleImage = require("../assets/circle.png")
export const selectCircleImage = require("../assets/selectCircle.png")
export const {
    getBuyerList,
    selectAllBuyer,
    handleSelectBuyer,
    buyerActivate,
    buyerDeactivate,
    bulkActive,
    bulkRemove,
    bulkDeactivate,
    setCurrentPage,
    setShowCount,
    getBuyerListSearch,
    getVendorBuyerList,
    getBuyerDetails,
    setCurrentPageBuyerDetails,
    setShowCountBuyerDetails,
    setShowCountVendor: setShowCountFromVendor,
    setCurrentPageVendor: setCurrentPageFromVendor,
    setSearchTextBuyer,
    setSearchKeyBuyer,
    getBuyerListSearchVendor,
} = require("../../../components/src/Redux/BuyerUsers/reduxBuyerActions")

export const {
    getVendorList,
    setCurrentPageVendor,
    setShowCountVendor,
    vendorActivate,
    vendorDeactivate,
    selectAllVendor,
    handleSelectVendor,
    vendorBulkActive,
    vendorBulkDeactivate,
    getVendorDetails,
    approveVendorRequest,
    rejectVendorRequest,
    currentFilterSet,
    setSearchText,
    setSearchKey,
    getVendorListSearch,
} = require("../../../components/src/Redux/VendorUsers/reduxVendorActions")





