const initialState = {
    orders:[],
    orderDetails:{},
    pagination:{},
    isLoading:true,
    currentPage:1,
    showCount:10,
    searchKey:"",
    searchText:"",
    filter:"all",
    firmName:""
}

export const orderReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case "SET_ORDER_LIST":
            return {
                ...state,
                orders: payload,
            }
        case "SET_ORDER_DETAILS":
            return {
                ...state,
                orderDetails: payload,
            }
        case "SET_PAGINATION":
            return {
                ...state,
                pagination: payload,
            }
        case "SET_LOADING_FALSE":
            return {
                ...state,
                isLoading:false
            }
        case "SET_LOADING_TRUE":
            return {
                ...state,
                isLoading:true
            }
        case "SET_ORDER_FILTER":
            return {
                ...state,
                filter:payload
            }
        case "SET_ORDER_SEARCH_KEY":
            return {
                ...state,
                searchKey: payload
            }
        case "SET_ORDER_SEARCH_TEXT":
            return {
                ...state,
                searchText: payload
            }
        case "SET_CURRENT_ORDERS_PAGE":
            return {
                ...state,
                currentPage:payload
            }
        case "SET_SHOW_ORDERS_COUNT":
            return {
                ...state,
                showCount:payload
            }
        case "SET_ORDER_FIRM_NAME":
            return {
                ...state,
                firmName:payload
            }
        default:
            return state
    }
}