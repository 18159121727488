import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import LoaderPage from "../../../components/src/LoaderPage";
import { DropZone } from "./assets";
import { PreviewButton, SaveButton } from "./Buttons";
import FooterPreview from "./FooterPreview";
import { API, capitalize } from "./SectionOne";

export const addressSchema = yup.object().shape({
  location: yup.string().required("address is required"),
  phone: yup
    .number()
    .required("phone is required")
    .typeError("phone number must be a number"),
  email: yup.string().email("invalid Email").required("email is required"),
});

interface Link {
  id: number;
  name: string;
}
interface FooterCol {
  footer_links_attributes: Link[];
  heading: string;
  id: number;
}
export interface IData {
  location: string;
  phone: string;
  email: string;
}

function formatFooter(arrOfObj: any) {
  // Sort footer section by id
  const sortedArr = arrOfObj.sort((a: { id: string }, b: { id: string }) => {
    return parseInt(a.id) - parseInt(b.id);
  });
  // Customize footer according to the api requirement
  const fomattedFooter = sortedArr.map((col: any) => {
    return {
      id: parseInt(col.id),
      heading: col.attributes.heading,
      footer_links_attributes: col.attributes.footer_links.map((item: any) => {
        // Remove excess keys
        const { created_at, footer_section_id, updated_at, ...rest } = item;
        return rest;
      }),
    };
  });
  return fomattedFooter;
}

const initialLinks = [
  {
    footer_section_id: "",
    name: "",
    url: "",
  },
];

const initialValues = {
  location: "",
  phone: "",
  email: "",
};

export default function Footer() {
  const token = localStorage.getItem("authToken") || "";
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState<string | ArrayBuffer | null>("");
  const [hasRemovedFile, setHasRemovedFile] = useState(false);
  const [logo, setLogo] = useState("");
  const [logoFileName, setLogoFileName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingAddress, setIsSavingAddress] = useState(false);
  const [isSavingFooterLinks, setIsSavingFooterLinks] = useState(false);
  const [open, setOpen] = useState(false);
  const [footer, setFooter] = useState([]);
  const [footerCols, setFooterCols] = useState<FooterCol[]>([]);
  const [links, setLinks] = useState(
    initialLinks.map((l) => Object.assign({}, l))
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(addressSchema),
    defaultValues: initialValues,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Remove file
  const handleRemoveFile = () => {
    setFile("");
    setHasRemovedFile(true);
  };

  // Submit address
  const handleAddressSubmission = (data: IData) => {
    setIsSavingAddress(true);
    const formData = new FormData();
    file && formData.append("logo", file);
    formData.append("location", data.location);
    formData.append("phone_number", data.phone);
    formData.append("email", data.email);
    // Save email, location, logo, phone number
    API.post("/bx_block_contentmanagement/footers", formData, {
      headers: { token },
    })
      .then((response) => {
        const { logo } = response.data.data.attributes;
        setLogo(logo.url);
        setLogoFileName(logo.file_name);
        toast.success("Saved address");
      })
      .catch((err) => {
        if (err.response.status === 422) {
          err.response.data.errors.forEach((e: any) => {
            toast.error(e.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsSavingAddress(false);
        // Remove selected file
        if (file) {
          setFile("");
          setHasRemovedFile(true);
        }
      });
  };

  // Change footer column header
  const handleFooterColHeaderChange = (id: number, index: number) => {
    const data = {
      // @ts-ignore
      heading: footerCols[index].heading,
    };
    API.put(`/bx_block_contentmanagement/footer_sections/${id}`, data)
      .then(() => {
        toast.success("Updated footer heading");
      })
      .catch(() => {
        toast.error("Could not update footer header!");
      });
  };

  // Remove link
  const handleRemoveLink = (id: number, colIndex: number, linkId: number) => {
    API.delete(`/bx_block_contentmanagement/footer_links/${id}`)
      .then(() => {
        setFooterCols((prev) => {
          const updatedFootercols = [...prev];
          const curLinks = updatedFootercols[colIndex].footer_links_attributes;
          updatedFootercols[colIndex].footer_links_attributes = curLinks.filter(
            (link) => link.id !== linkId
          );
          return updatedFootercols;
        });
        toast.success("Link deleted!");
      })
      .catch(() => {
        toast.error("Could not delete link!");
      });
  };

  // Handle add link form
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent,
    index: number,
    key: string
  ) => {
    // @ts-ignore
    if (key !== "footer_section_id") e.persist();
    setLinks((prev) => {
      const updatedLinks = [...prev];
      // @ts-ignore
      updatedLinks[index][key] = e.target.value;
      return updatedLinks;
    });
  };

  // submit footer form
  const handleSubmitFooterLinks = () => {
    setIsSavingFooterLinks(true);
    const data = {
      footer_link: links,
    };
    const headers = {
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
    };
    API.post("/bx_block_contentmanagement/footer_links", data, headers)
      .then((response) => {
        const formattedFooter = formatFooter(response.data.data);
        // @ts-ignore
        setFooterCols(formattedFooter);
        setIsSavingFooterLinks(false);
        setLinks(initialLinks);
        toast.success("Saved footer.");
      })
      .catch((err) => {
        // setIsLoading(false);
        setIsSavingFooterLinks(false);
        if (err.response.status === 422) {
          // @ts-ignore
          err.response.data.errors.forEach((err) => {
            toast.error(err.message);
          });
        } else {
          toast.error("Could not save footer!");
        }
      });
  };

  // Read URL of selected file
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileUrl(reader.result);
      };
    } else {
      setFileUrl("");
    }
  }, [file]);

  // Load data
  useEffect(() => {
    const addressApi = API.get("/bx_block_contentmanagement/footers", {
      headers: { token },
    });
    const footerLinksApi = API.get(
      "/bx_block_contentmanagement/footer_sections",
      { headers: { token } }
    );
    Promise.all([addressApi, footerLinksApi])
      .then(([addressResponse, footerResponse]) => {
        setIsLoading(false);
        // Set address
        const { email, location, logo, phone_number } =
          addressResponse.data.data.attributes;
        setLogo(logo.url);
        setLogoFileName(logo.file_name);
        // Update default values for react hook form
        setValue("location", location);
        setValue("phone", phone_number);
        setValue("email", email);
        setFile("");
        // Set Footer
        setFooter(footerResponse.data.data);
      })
      .catch(() => toast.error("Could not load data!"));
  }, []);

  // Format footer
  useEffect(() => {
    const formattedFooter = formatFooter(footer);
    // @ts-ignore
    setFooterCols(formattedFooter);
  }, [footer]);

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <>
      <Grid sx={styles.container}>
        {/* Section header */}
        <Box sx={styles.headerSection}>
          {/* @ts-ignore */}
          <Typography variant="h6" sx={styles.heading1}>
            Footer
          </Typography>
        </Box>
        {/* col 1 */}
        <Typography sx={{ ...styles.heading2, margin: "15px 0" }}>
          Col 01
        </Typography>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.heading2}>Upload Logo</Typography>
            <DropZone
              setFile={setFile}
              hasRemovedFile={hasRemovedFile}
              setHasRemovedFile={setHasRemovedFile}
              backgroundImgName={logoFileName}
            />
            {file && (
              <Box sx={styles.removeBtnContainer}>
                <Button sx={styles.heading2} onClick={handleRemoveFile}>
                  Remove
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6} sx={styles.location}>
            <Typography sx={styles.heading2}>Location</Typography>
            <Controller
              name="location"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["location"] ? true : false}
                  placeholder="Enter location"
                  size="small"
                  sx={styles.textField}
                />
              )}
            />
            {errors["location"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {capitalize(errors["location"]?.message)}
              </Typography>
            ) : null}
            <Typography sx={styles.heading2}>Phone</Typography>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["phone"] ? true : false}
                  placeholder="Enter phone"
                  size="small"
                  sx={styles.textField}
                />
              )}
            />
            {errors["phone"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {capitalize(errors["phone"]?.message)}
              </Typography>
            ) : null}
            <Typography sx={styles.heading2}>Email</Typography>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["email"] ? true : false}
                  placeholder="Enter email"
                  size="small"
                  sx={styles.textField}
                />
              )}
            />
            {errors["email"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {capitalize(errors["email"]?.message)}
              </Typography>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              ...styles.buttonContainer,
              paddingTop: {
                md: "32px",
                xs: "10px !important",
              },
            }}
          >
            <PreviewButton text="preview" handleOpen={handleOpen} />
            <SaveButton
              text="save"
              handleSubmit={handleSubmit(handleAddressSubmission)}
              isSaving={isSavingAddress}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Footer links */}
      <Grid container spacing={4} sx={styles.linkSection}>
        {footerCols.map((col, index) => (
          // @ts-ignore
          <Grid item xs={12} lg={4} key={col.id} sx={styles.linkGrids}>
            <Typography sx={styles.heading2}>
              {/* @ts-ignore */}
              Col 0{parseInt(col.id) + 1}
            </Typography>
            {/* Heading */}
            <Box sx={{ margin: "30px 0" }}>
              <Typography sx={styles.heading2}>Heading</Typography>
              <TextField
                placeholder="Enter heading"
                size="small"
                sx={styles.textField}
                // @ts-ignore
                value={col.heading}
                onChange={(e) => {
                  e.persist();
                  // @ts-ignore
                  setFooterCols((prevCols) => {
                    const updatedAr = [...prevCols];
                    // @ts-ignore
                    updatedAr[index].heading = e.target.value;
                    return updatedAr;
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        color="success"
                        onClick={() =>
                          // @ts-ignore
                          handleFooterColHeaderChange(col.id, index)
                        }
                      >
                        UPDATE
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {/* @ts-ignore */}
            <Box sx={styles.footerLinks}>
              {col.footer_links_attributes.length > 0 ? (
                col.footer_links_attributes.map((footLink, i) => (
                  // @ts-ignore
                  <Box key={i} sx={styles.linkContainer}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {footLink.name}
                    </Typography>
                    <Button
                      color="error"
                      onClick={() =>
                        handleRemoveLink(footLink.id, index, footLink.id)
                      }
                    >
                      REMOVE
                    </Button>
                  </Box>
                ))
              ) : (
                <Typography sx={styles.noLink}>No Links</Typography>
              )}
            </Box>
          </Grid>
        ))}
        {/* Add link section */}
        <Grid item xs={12} sx={{padding: "0 10px !important"}}>
          <Typography sx={styles.heading2}>Add Link</Typography>
        </Grid>
        {/* Add link form */}
        {links.map((link, index) => (
          <Fragment key={index}>
            <Grid
              item
              xs={12}
              lg={4}
              sx={{ ...styles.paddingLessGrid, ...styles.linkGrids }}
            >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Select Heading
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={links[index].footer_section_id}
                  label="Select Heading"
                  onChange={(e) => handleChange(e, index, "footer_section_id")}
                  sx={styles.select}
                >
                  {footerCols.map((col, index) => (
                    <MenuItem key={col.id} value={col.id}>
                      {col.heading}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              sx={{ ...styles.paddingLessGrid, ...styles.linkGrids }}
            >
              <TextField
                placeholder="Name"
                size="small"
                sx={{ ...styles.textField, margin: 0 }}
                value={links[index].name}
                onChange={(e) => handleChange(e, index, "name")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              sx={{ ...styles.paddingLessGrid, ...styles.linkGrids }}
            >
              <TextField
                placeholder="Page URL://"
                size="small"
                sx={{ ...styles.textField, margin: 0 }}
                value={links[index].url}
                onChange={(e) => handleChange(e, index, "url")}
              />
            </Grid>
          </Fragment>
        ))}
        <Grid item xs={12} sx={styles.paddingLessGrid}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                background: "#693a8e",
                borderColor: "#693a8e",
                "&:hover": { background: "#693a8e" },
              }}
              onClick={() => {
                setLinks((prev) => [
                  ...prev,
                  {
                    footer_section_id: "",
                    name: "",
                    url: "",
                  },
                ]);
              }}
            >
              add link
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* Button container starts */}
      <Grid sx={styles.buttonContainer}>
        <PreviewButton text="preview" handleOpen={handleOpen} />
        <SaveButton
          text="save"
          handleSubmit={handleSubmitFooterLinks}
          isSaving={isSavingFooterLinks}
        />
      </Grid>
      <FooterPreview
        open={open}
        handleClose={handleClose}
        fileUrl={fileUrl}
        logo={logo}
        location={watch("location")}
        phone_number={watch("phone")}
        email={watch("email")}
        footerCols={footerCols}
      />
    </>
  );
}

// Styles
const styles = {
  container: {
    background: "#fff",
    borderRadius: "8px",
    marginBottom: 10,
    padding: {
      xs: "10px",
      md: "20px 30px",
    },
  },
  headerSection: {
    background: "#f1f1f1",
    borderRadius: "8px",
    padding: { xs: "10px 0", md: "20px" },
  },
  heading1: {
    textAlign: "center",
    textTransform: "uppercase",
    color: "#472264",
    fontWeight: "bold",
    fontSize: { xs: "16px", md: "1.25rem" },
  },
  heading2: { color: "#472264", fontWeight: "bold" },
  linkSection: {
    width: "100%",
    background: "#fff",
    borderRadius: "8px",
    padding: "20px 30px",
    marginBottom: 5,
    marginLeft: 0,
  },
  footerLinks: {
    background: "#f1f1f1",
    padding: "15px",
    borderRadius: "8px",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  },
  linkGrids: {
    padding: "10px !important",
  },
  noLink: { textAlign: "center" as "center", color: "grey !important" },
  textField: {
    width: "100%",
    background: "#f1f1f1",
    margin: "10px 0",
  },
  select: { background: "#f1f1f1", border: "1px solid #f1f1f1" },
  paddingLessGrid: { paddingTop: "0 !important", marginTop: 1 },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  removeBtnContainer: { display: "flex", justifyContent: "flex-end" },
  location: {
    paddingTop: {
      md: "80px !important",
      xs: "10px !important",
    },
  },
  errorText: { marginBottom: "1", color: "red" },
};
