import React, {useState} from 'react';
import {Checkbox, IconButton, Menu, MenuItem, Switch} from "@mui/material";
import {handleSelectVendor, vendorActivate, vendorDeactivate} from "./assets";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {useDispatch, useSelector} from "react-redux";
import { styled } from "@mui/material/styles";
import {
    createStyles,
    makeStyles,
    withStyles,
    Theme
} from "@material-ui/core/styles";
import {
    TableRow,
} from "@mui/material";
import TableCell from '@mui/material/TableCell';
import {toast} from "react-toastify";
// @ts-ignore
const VendorRows = ({row,history}) => {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // @ts-ignore
    const {vendorPermission} = useSelector(state => state.landingPage)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // @ts-ignore
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // @ts-ignore
    const manageStatus = (e,id) => {
        if(e.target.checked){
            dispatch(vendorActivate(id))
        }else{
            dispatch(vendorDeactivate(id))
        }
    }
    return (
        <StyledTableRow key={row.name}>
            <StyledTableCell component="th" scope="row">
                {" "}
                <Checkbox
                    color="secondary"
                    checked={row.isChecked}
                    inputProps={{
                        // "aria-labelledby": labelId
                    }}
                    onClick={() => dispatch(handleSelectVendor(row.id))}
                />{" "}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
                <div
                    style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                    }}
                >
                    {row.attributes.firm_name || "-"}
                </div>
            </StyledTableCell>
            <StyledTableCell align="left">{row.attributes.full_name}</StyledTableCell>
            <StyledTableCell align="left">{row.attributes.email}</StyledTableCell>
            <StyledTableCell align="left">
                {" "}
                {"+1 " + row.attributes.full_phone_number}
            </StyledTableCell>
            {
                vendorPermission &&
                <StyledTableCell align="left">
                    <PurpleSwitch
                    disabled={!vendorPermission}
                    checked={row.attributes.activated}
                    onChange={(e) => manageStatus(e,row.id)}
                    name="show-in-menu"
                    />
                </StyledTableCell>
            }
            <StyledTableCell align="left">
                {/* <div style={{display:"flex",alignItems:"start", justifyContent:"start"}}> */}
                {/*  // history.push("/vendor/buyers/buyerDetail/1") */}
                <IconButton onClick={handleClick}>
                    <MoreHorizIcon sx={{ color: "#693a8e" }} />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    sx={{
                        ".css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
                            boxShadow: 0,
                            color: "#693a8e",
                        },
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            history.push(`ViewProfile/${row.id}`);
                            setAnchorEl(null);
                        }}
                    >
                        View Profile
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.push(`Products/${row.id}`);
                            setAnchorEl(null);
                        }}>
                        Products ({row.attributes.product_count})
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.push(`Orders/${row.id}`);
                            setAnchorEl(null);
                        }}
                    >
                        Orders ({row.attributes.order_count})
                    </MenuItem>
                </Menu>
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default VendorRows;

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#472264",
            color: theme.palette.common.white,
            textAlign: "start",
        },
        body: {
            fontSize: 13,
            color: "#472264",
            fontWeight: "bold",
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#f5ebfc",
            },
        },
    })
)(TableRow);

const PurpleSwitch = styled(Switch)(() => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: "#693a8e",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#925BBD",
    },
}));