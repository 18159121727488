import React,{useState} from 'react';
import {Grid, Typography, Button, CardMedia, CardContent, CircularProgress} from "@mui/material";
import {FailedLogo} from './assets'
import {withRouter} from "react-router-dom";
import {sentEmail,sentUnconfirmedEmail} from "../../email-account-login/src/api";
import {toast} from "react-toastify";
import {setLoginModelTrue} from "../../../components/src/Redux/LandingPage/reduxLandingPageActions"
import {useDispatch} from "react-redux";

const EmailFailed = (props) => {
    const dispatch = useDispatch()
    const token =  localStorage.getItem("authToken")
    const profile = JSON.parse(localStorage.getItem("profile"))
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async () => {
        if(token){
            setIsLoading(true)
            let res
            try {
                if(profile.unconfirmed_email){
                    res = await sentUnconfirmedEmail()
                }else{
                    res = await sentEmail()
                }
                if(res.data.success){
                    toast.success("Verification email sent successfully")
                }else{
                    res.data.errors.map((e)=>{
                        toast.error(e.message)
                    })
                }
            }catch (e) {
                toast.error("Something went wrong.")
            }
            setIsLoading(false)
        }else{
            props.history.push("/")
            dispatch(setLoginModelTrue())
        }
    }
    return (
        <Grid container sx={{ height : '100%'}}>
            <Grid item sx={{ height : '100%', padding : '15px'}} xs={12} display="flex" alignItems="center" justifyContent="center">
                <Grid  sx={{ maxWidth: '100%', textAlign : 'center'}}>
                    <CardMedia
                        component="img"
                        alt="right arrow"
                        height="auto"
                        width="auto"
                        image={FailedLogo}
                        sx={{ maxWidth : '80px', minWidth : '80px', margin : '0 auto'}}
                    />
                    <CardContent  sx={{ width:"100%", paddingLeft : '0'}}>
                        <Typography gutterBottom variant="h5" component="div" color="#472264" sx={{ fontWeight : 'bold'}}>
                        Oops, Email Verification failed!
                        </Typography>
                        <Typography variant="subtitle1" color="#693A8E">
                           Your Email Verification Link is expired Click below button to re-send E-Mail Verification Link
                        </Typography>
                        <Grid xs={12} sx={{ paddingTop:'15px'}}>
                            {
                                token ?
                                    <Button variant="contained" size="large" onClick={handleSubmit} sx={{ color : '#fff',background: '#693A8E', fontWeight : 'bold', width:'100%', "&:hover": { background : '#472264bf', color : '#fff'} }} >{isLoading ? <CircularProgress size={30} color="inherit"/> : "Re-send Email Verification"}</Button> :
                                    <Button variant="contained" size="large" onClick={handleSubmit} sx={{ color : '#fff',background: '#693A8E', fontWeight : 'bold', width:'100%', "&:hover": { background : '#472264bf', color : '#fff'} }} >Login</Button>
                            }
                            </Grid>
                    </CardContent>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(EmailFailed);