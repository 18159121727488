import React, { useEffect, useState } from "react";
import { topBanner, getSection1, manageLoading } from "./assets";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @ts-ignore
const Section1 = ({ isLoading }) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { section1 } = useSelector((state) => state.landingPage);

  const manageLoader = () => {
    dispatch(manageLoading());
  };

  return (
    <Grid
      container
      sx={
        isLoading
          ? { display: "none" }
          : {
              display: "flex",
              width: "100%",
              backgroundImage: `url(${section1?.background_image?.url})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }
      }
    >
      <Grid
        item
        xs={12}
        sm={8}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: {
            xs: "50px 0",
            md: "130px 0"
          },
        }}
      >
        <Box sx={{ 
          padding: {
            xs: "0 30px",
            md: "0 50px"  
          }
        }}>
          <Typography
            variant="h2"
            component="div"
            color="white"
            sx={{ fontWeight: "bold", marginBottom: "30px" }}
          >
            {section1?.heading_title}
          </Typography>
          <Typography
            variant="h6"
            component="div"
            color="white"
            sx={{ fontWeight: "bold", marginBottom: "30px" }}
          >
            {section1?.description}
          </Typography>
          <Link to={section1?.link} style={{ textDecoration: "none" }}>
            <Button
              style={{
                width: "150px",
                height: "50px",
                backgroundColor: "#d6b236",
                color: "black",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
            >
              Show More
            </Button>
          </Link>
        </Box>
      </Grid>
      <Grid item xs={0} sm={4} />
      <img
        src={section1?.background_image?.url}
        onLoad={manageLoader}
        style={{ display: "none" }}
      />
    </Grid>
  );
};

export default Section1;
