import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputBase,
  Menu,
  ListItemText,
  Select,
  MenuItem,
  Stack,
  Typography,
  CircularProgress,
  TextField
} from "@mui/material";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { submitQuery } from "./assets";
const { ColorButton } = require("./Styles");

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const contactSupportSchema = yup.object().shape({
  subject: yup.string().trim().required("Subject is required"),
  description: yup.string().trim().required("Description is required"),
});

const initialValues = {
  subject: "",
  description: ""
}

interface IData {
  subject: string;
  description: string;
}

const ContactSupport = () => {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const {
    handleSubmit: validateSubmission,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(contactSupportSchema),
    defaultValues: initialValues,
  });

  const handleSubmit = async (formData: IData) => {
    setIsSaving(true);
    const {subject, description} = formData;
    const data = {
      data: {
        subject,
        description,
      },
    };
    try {
      const response = await submitQuery(data);
      setIsSaving(false);
      if (response.status === 201) {
        // Reset form
        setValue("subject", "");
        setValue("description", "");
        toast.success("Sent");
      } else {
        toast.error("Something went wrong");
      }
    } catch {
      toast.error("Something went wrong");
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Box className={classes.box}>
            <div className={classes.textDiv}>
              <Typography
                variant="subtitle1"
                sx={styles.label}
              >
                Your Query
              </Typography>
              <Controller
                name="subject"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["subject"] ? true : false}
                    placeholder="Subject of your query"
                    sx={{width: "100%"}}
                  />
                )}
              />
              {errors["subject"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["subject"]?.message}
                </Typography>
              ) : null}
            </div>
            <div className={classes.textDiv}>
              <Typography
                variant="subtitle1"
                sx={styles.label}
              >
                Description
              </Typography>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors["description"] ? true : false}
                    placeholder="Describe your problem"
                    multiline
                    minRows={5}
                    sx={{width: "100%"}}
                  />
                )}
              />
              {errors["description"] ? (
                <Typography variant="subtitle2" sx={styles.errorText}>
                  {errors["description"]?.message}
                </Typography>
              ) : null}
            </div>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{...styles.label, color: "gray"}}
            >
              You can submit your Query. Our team will contact on your
              registered email Id
            </Typography>
            <ColorButton
              variant="contained"
              color="secondary"
              sx={{ width: "100%", marginTop: "10px" }}
              onClick={validateSubmission(handleSubmit)}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {isSaving ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  "Submit"
                )}
              </Typography>
            </ColorButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ContactSupport;

const useStyles = makeStyles({
  menuLink: {
    textDecoration: "none",
    color: "#472264",
    marginBottom: 5,
    boxShadow: "none",
  },
  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    marginTop: "20px",
    padding: "30px",
  },

  textDiv: {
    marginBottom: "30px",
  },
});

const styles = {
  inputBase: {
    color: "black",
    fontSize: 14,
    borderRadius: "5px",
    width: "100%",
    padding: "10px",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "1",
    margin: "10px 10px 0px 10px",
  },
  errorText: { marginBottom: "1", color: "red" }
}
