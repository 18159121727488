import React from "react";
import { Grid, Typography, Button, Modal, Box } from "@mui/material";
import { withRouter, RouteComponentProps } from "react-router-dom";
interface IProps extends RouteComponentProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  history: RouteComponentProps["history"]
}

const LogoutModal = ({ openModal, setOpenModal, history }: IProps) => {
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("profile");
    setOpenModal(false);
    history.push("/");
  };

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.modal}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={styles.modalTitle}
        >
          Logout
        </Typography>
        <Box sx={{ padding: 3.5 }}>
          <Typography
            id="modal-modal-description"
            sx={styles.modalText}
          >
            Are you sure to logout your account?
          </Typography>
          <Grid
            sx={styles.btnContainer}
          >
            <Button
              variant="contained"
              sx={styles.cancelBtn}
              onClick={() => setOpenModal(false)}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              sx={styles.logoutBtn}
              onClick={handleLogout}
            >
              logout
            </Button>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default withRouter(LogoutModal);

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    width: {
      sm: 500,
    },
    bgcolor: "#fff",
    boxShadow: 24,
  } as const,
  modalTitle: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "700",
    color: "#693a8e",
    borderBottom: "1px solid #d8d8d8",
    padding: "15px 0",
  } as const,
  modalText: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "400",
    color: "#0d0d0d",
  } as const,
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
  } as const,
  cancelBtn: {
    background: "#d6b336",
    color: "#000",
    borderRadius: "4px",
    fontSize: "18px",
    fontWeight: "500",
    marginRight: 2,
    textTransform: "uppercase",
    "&:hover": {
      background: "#d6b336",
    },
  } as const,
  logoutBtn: {
    background: "#693a8e",
    borderRadius: "4px",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    "&:hover": {
      background: "#693a8e",
    },
  } as const
}
