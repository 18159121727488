import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import LoaderPage from "../../../components/src/LoaderPage";
import {
  getProductList,
  getProductListSearch,
  setCurrentPage,
  setShowCount,
  searchImage,
} from "./assets";
import CatalogeProductItem from "./CatalougeProductItems";
import NoRecordPage from "../../../components/src/NoRecordPage";
import DeletionModal from "./DeletionModal";
import CloseIcon from "@mui/icons-material/Close";
import Alert from '@mui/material/Alert';
import {Box} from "@mui/material";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#472264",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "#472264",
    fontWeight: "bold",
  },
}));

// @ts-ignore
const VenderCatalogue = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [searchPhrase, setSearchPhrase] = useState("");
  const [initiatedSearch, setInitiatedSearch] = useState(false);
  const dispatch = useDispatch();
  // Vendor id
  const { id } = props.match.params;
  // @ts-ignore
  const productList = useSelector((state) => state.products);
  // @ts-ignore
  const {addRemoveProduct,editProduct} = useSelector(state => state.landingPage)
  const {
    products,
    pagination,
    isLoading,
    showCount,
    currentPage,
    firmName,
    category_deactivated
  } = productList;
  // @ts-ignore
  const profile = JSON.parse(localStorage.getItem("profile"));
  const classes = useStyles();

  // Add product handler
  const handleAddProduct = () => {
    if (profile.user_role === "vendor") {
      props.history.push("/vendor/myProducts/addProduct", {
        formType: "add",
      });
    } else if (
      profile.user_role === "administrator" ||
      profile.user_role === "super_administrator"
    ) {
      props.history.push("addProduct", {
        formType: "add",
        vendorId: id,
      });
    }
  };

  // Search products
  const handleSearch = () => {
    if (searchBy && searchPhrase) {
      setInitiatedSearch(true);
      dispatch(getProductListSearch(showCount, 1, searchBy, searchPhrase, id));
    }
  };

  // Clear search
  const clearSearch = () => {
    setInitiatedSearch(false);
    setSearchBy("");
    setSearchPhrase("");
    dispatch(getProductList(id, showCount, 1));
  };

  // Handles pagination
  // @ts-ignore
  const managePagination = (event, value) => {
    dispatch(setCurrentPage(id, value, showCount));
  };

  // Load data
  useEffect(() => {
    dispatch(getProductList(id, showCount, currentPage));
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <>
          <Grid container>
            {/* Show store info only for admin, super admin */}
            {profile.user_role !== "vendor" && (
              <Grid item xs={12} md={12} lg={4}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.storeName}
                  component="div"
                >
                  {`${firmName || ""} `}
                  {firmName && <span className={classes.productCount}>
                    {pagination.total_count}
                  </span>}
                </Typography>
                <Typography variant="subtitle2" display="block" gutterBottom>
                  Vendors <span className={classes.products}>/ Products</span>
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={12}
              lg={profile.user_role !== "vendor" ? 8 : 12}
            >
              <Stack
                sx={
                  {
                    ...styles.searchContainer,
                    justifyContent: {
                      md: "flex-start",
                      lg:
                        profile.user_role !== "vendor"
                          ? "flex-end"
                          : "space-between",
                    },
                  } as const
                }
              >
                <Stack direction="row">
                  <FormControl size="small" sx={styles.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="select-label-id"
                      id="select-id"
                      value={searchBy}
                      label="Select"
                      onChange={(e) => {
                        setSearchBy(e.target.value);
                      }}
                      sx={styles.select}
                    >
                      <MenuItem value="product_number">Product Number</MenuItem>
                      <MenuItem value="name">Name</MenuItem>
                      <MenuItem value="category_name">Category Name</MenuItem>
                      <MenuItem value="sub_category_name">
                        Sub Category Name
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchPhrase}
                    onChange={(e) => {
                      setSearchPhrase(e.target.value);
                    }}
                    sx={styles.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {initiatedSearch && (
                            <IconButton onClick={clearSearch}>
                              <CloseIcon />
                            </IconButton>
                          )}
                          <IconButton onClick={handleSearch}>
                            <img src={searchImage} alt="search" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                {
                  addRemoveProduct ?  <Button
                      variant="contained"
                      size="small"
                      startIcon={<AddIcon sx={{ fontSize: "25px !important" }} />}
                      sx={styles.btn}
                      onClick={handleAddProduct}
                  >
                    <span>Add Product</span>
                  </Button> : null
                }
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {
                  category_deactivated &&
                  <Box sx={{marginTop:"10px"}}>
                    <Alert severity="warning">
                    {
                        profile.user_role === "vendor" ?
                            "Some of your products category is deactivated, please edit your product category." :
                            "Some of vendor products category is deactivated."
                    }
                    </Alert>
                  </Box>
              }
              {products.length > 0 ? (
                <TableContainer
                  sx={{
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    marginTop: 2,
                    width:{
                      md:"100%",
                      sm:"750px",
                      xs:"350px"
                    }
                  }}
                  component={Paper}
                >
                  <Table
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                        </StyledTableCell>
                        <StyledTableCell align="center">Id</StyledTableCell>
                        <StyledTableCell align="center">
                          Products
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Category
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          SubCategory
                        </StyledTableCell>
                        <StyledTableCell align="center">Amount</StyledTableCell>
                        <StyledTableCell align="center">Stock Quantity</StyledTableCell>
                        {
                          editProduct &&
                          <StyledTableCell align="center">
                            Activate/Deactivate
                          </StyledTableCell>
                        }
                        <StyledTableCell align="center">
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {// @ts-ignore
                      products.map((row) => (
                        <CatalogeProductItem
                          key={row.id}
                          vendorId={id}
                          row={row}
                          setOpenModal={setOpenModal}
                          setDeleteId={setDeleteId}
                          history={props.history}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <NoRecordPage text={"Sorry, no results found!"} />
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: 4 }}>
            {/* Show per page */}
            <Grid item xs={12} sm={6} >
              <FormControl size="small" sx={{ width: "150px",marginBottom:"10px" }}>
                <InputLabel id="bulk-actions">Show</InputLabel>
                <Select
                  labelId="show-count"
                  id="demo-simple-select"
                  value={showCount}
                  label="Show:"
                  onChange={(e) =>
                    dispatch(setShowCount(id, 1, e.target.value))
                  }
                  sx={{ background: "#fff" }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Pagination */}
            <Grid item xs={12} sm={6} sx={{display:'flex',justifyContent: {xs:"center",sm:"flex-end"}}}>
              <Stack spacing={5} sx={{alignItems: "flex-end"}}>
                <Pagination
                  count={pagination.total_pages}
                  hidePrevButton
                  page={currentPage}
                  color="secondary"
                  variant="outlined"
                  shape="rounded"
                  onChange={managePagination}
                  sx={styles.pagination}
                />
              </Stack>
            </Grid>
          </Grid>
          <DeletionModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            deleteId={deleteId}
            id={id}
            count={showCount}
            page={currentPage}
          />
        </>
      )}
    </Fragment>
  );
};

export default withRouter(VenderCatalogue);

const useStyles = makeStyles({
  table: {
    minWidth: 300
  },
  paper: {
    color: "black",
    padding: 10,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  menuLink: {
    textDecoration: "none",
    color: "#472264",
    marginBottom: 5,
    boxShadow: "none",
  },
  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    display: "flex",
  },
  select: {
    backgroundColor: "transparent",
    margin: "10px 20px 5px 20px",
    borderRight: "1px solid #737373",
    paddingRight: "10px",
  },
  search: {
    margin: 5,
    display: "flex",
    alignItems: "space-between",
  },
  showselect: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageselect: {
    backgroundColor: "white",
    borderRadius: "5px",
    height: "33px",
    width: "150px",
    display: "flex",
    alignItems: "space-between",
  },
  paragragh: {
    padding: 0,
    marginTop: 4,
    marginLeft: 20,
    fontWeight: "bold",
  },
  storeName: {
    color: "#472264",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
  },
  productCount: {
    background: "#fff",
    color: "#000",
    width: "30px",
    borderRadius: "8px",
    fontSize: "10px",
    display: "inline-block",
    textAlign: "center",
    padding: "3px",
  },
  products: {
    color: "#824bad",
    fontWeight: "normal",
  },
});

const styles = {
  searchContainer: {
    flexDirection: {
      sm: "row",
      xs: "column",
    },
    alignItems: {
      xs: "flex-start",
      md: "center",
    },
  } as const,
  btn: {
    background: "#693a8e",
    borderRadius: "4px",
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginLeft: {
      xs: 0,
      sm: 2,
    },
    marginTop: {
      xs: 1,
      sm: 0,
    },
    "&:hover": { background: "#693a8e" },
  } as const,
  pagination: {
    "& .Mui-selected": {
      color: "#fff !important",
    },
    "& .MuiPaginationItem-root:hover": {
      backgroundColor: "#693A8E",
    },
    ".css-wjh20t-MuiPagination-ul": {
      justifyContent: "flex-end",
    },
  },
  formControl: {
    width: {
      xs: 150,
      md: 200,
    },
  },
  select: {
    background: "#fff",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  textField: {
    background: "#fff",
    width: {
      xs: 150,
      md: 200,
    },
    "& fieldset": {
      borderLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
};
