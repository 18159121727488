import React from 'react';
import Box from "@mui/material/Box";
import {noRecords} from "./importAssets";
import {Typography} from "@mui/material";
// @ts-ignore
const NoRecordPage = ({text}) => {
  return (
    <Box
      style={{
        height: 'auto',
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'White',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin:"30px 0px"
      }}
    >
      <div style={{ height: '100px' }} />
      <img src={noRecords} style={{ margin: '30px' }} />
        <Typography variant="h4" sx={{color:"#472264"}}>{text || "Sorry, no results found!"}</Typography>
      <div style={{ height: '200px' }} />
    </Box>
  );
};

export default NoRecordPage;
