import React, { useEffect, useState } from "react";
import { getSection6, manageLoading } from "./assets";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
const Section6 = ({ setImageLoading, isLoading }) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { section6 } = useSelector((state) => state.landingPage);

  const manageLoader = () => {
    dispatch(manageLoading());
  };
  const [Section, setSection] = useState({
    bg: "",
    title: "Loading...!!!",
    link: "#",
  });

  useEffect(() => {
    fetchData();
  }, [section6]);

  const fetchData = async () => {
    setSection({
      bg: section6?.background_image?.url,
      title: section6?.heading_title,
      link: section6?.link,
    });
  };
  return (
    <Grid
      container
      sx={
        isLoading
          ? { display: "none" }
          : {
              width: "100%",
              height: "450px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "60px",
            }
      }
    >
      <Grid
        item
        sx={{
          width: "90%",
          height: "450px",
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          margin: {
            xs: "15px 0",
            sm: "30px",
          },
          borderRadius: "20px",
          backgroundColor: "#6a3a8e",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            backgroundImage: `url(${Section.bg})`,
            backgroundSize: "cover",
            borderRadius: {
              xs: "20px 20px 0px 0px;",
              sm: "20px 0px 0px 20px;",
            },
            borderRight: {
              xs: 0,
              sm: "10px solid #d6b236",
            },
            borderBottom: {
              xs: "10px solid #d6b236",
              sm: 0,
            },
          }}
        />
        {/* <Box sx={{ width: "10px", backgroundColor: "#d6b236" }} /> */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "Center",
            }}
          >
            <Typography
              variant="h2"
              color="white"
              sx={{
                fontWeight: "bold",
                fontSize: {
                  xs: "2.75rem",
                  sm: "3.75rem",
                },
                textAlign: "center",
                marginBottom: "30px",
              }}
            >
              {Section.title}
            </Typography>
            <Link to={Section.link} style={{ textDecoration: "none" }}>
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  backgroundColor: "#d6b236",
                  color: "black",
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
              >
                Show More
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
      <img src={Section.bg} onLoad={manageLoader} style={{ display: "none" }} />
    </Grid>
  );
};

export default Section6;
