export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const profile_image = require("../assets/profile_image.png");
export const cow_image_1 = require("../assets/cow1.png");
export const cow_image_2 = require("../assets/cow2.png");
export const pen = require("../assets/pen.png");
export const downarrowImage = require("../assets/downarrowthin.png");
export const menuImage = require("../assets/horizontalMenu.png");
export const cancelImage = require("../assets/cancel.png");
export const defaultImage = require("../assets/default_img.png");
export const {
  getVendorFirmDetails,
  getCountries,
  getStates,
  getBankDetails,
  saveFirmDetails,
  updateFirmDetails,
  removeImage,
  updateBankDetails,
  saveBankDetails,
  submitQuery,
} = require("./api");
export const {updateUserProfile} = require("../../../components/src/Redux/LandingPage/reduxLandingPageActions");
export const {getOrderListAsBuyer,setCurrentPageBuyerOrders} = require("../../../components/src/Redux/Orders/reduxOrdersActions");
export const {baseURL} = require("framework/src/config");