import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { Link, withRouter } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import BuyerSubMenu from "./BuyerSubMenu";

const BuyerMenu = () => {
  // getting categories from redux store
  const { categories } = useSelector((state) => state.landingPage);
  return (
    <Toolbar
      sx={{
        display: {
          xs: "none",
          lg: "flex",
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }} />

      {categories.map((c, key) => {
        if (c.attributes.sub_categories.length > 0) {
          return <BuyerSubMenu c={c} key={key} />;
        } else {
          return (
            <Box sx={{ flexGrow: 1, cursor: "pointer" }} key={key}>
              <Link
                to={`/Catalogue/${c?.attributes?.slug}`}
                style={{ textDecoration: "none" }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, color: "black", cursor: "pointer" }}
                >
                  {c.attributes.name}
                </Typography>
              </Link>
            </Box>
          );
        }
      })}
    </Toolbar>
  );
};
export default withRouter(BuyerMenu);
