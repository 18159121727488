export const productImage = require("../assets/productImage.png");
export const searchImage = require("../assets/search.png");
export const downarrowImage = require("../assets/downarrowthin.png");
export const switchImage = require("../assets/switch.png");
export const menuImage = require("../assets/horizontalMenu.png");
export const plusImage = require("../assets/plus.png");
export const uploadImage = require("../assets/upload.png");
export const calenderImage = require("../assets/calender.png");
export const galleryImage = require("../assets/gallery.png");
export const switchgreyImage = require("../assets/switchgrey.png");
export const cancelImage = require("../assets/cancel.png");
export const eyeImage = require("../assets/eye.png");
export const whiteArrowImage = require("../assets/arrow.png");
export const noimg = require("../assets/noimg.png");
export const cowImage = require("../assets/cow.png");
export const cow2Image = require("../assets/cow2.png");
export const DropZone = require("./DropZone.js").default;
export const {
  addToCart,
  updateCartQty,
} = require("../../../components/src/Redux/Cart/reduxCartActions");
export const {
  getOrderList,
  updateOrderStatus,
  getOrderDetails,
  setCurrentPageOrders,
  setShowCountOrders,
  setSearchKeyOrders,
  setSearchTextOrders,
  getOrderListBySearch,
  setOrderFilter,
  updateOrderStatusDetails
} = require("../../../components/src/Redux/Orders/reduxOrdersActions");
export const {
  getProductList,
  productStatusUpdate,
  setCurrentPage,
  setShowCount,
  productDelete,
  getProductDetails,
  getProductListSearch,
} = require("../../../components/src/Redux/Products/reduxProductsActions");
export const {
  getCatalogList,
  setCatalogCurrentPage,
  setCatalogShowCount,
  getCatalogListBySearch,
  getCatalogListBySubCategory,
  getCatalogListByCategory,
} = require("../../../components/src/Redux/Cataloge/reduxCatalogeActions");
export const {baseURL} = require("framework/src/config");