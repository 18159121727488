import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

const { baseURL } = require("framework/src/config");

const baseSchema = {
  name: yup.string().required("Name is required"),
  address1: yup.string().required("Address line 1 is required"),
  address2: yup.string().required("Address line 2 is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  zipcode: yup.string().required("Zipcode is required").min(4),
};

export interface IData {
  name: string;
  address1: string;
  address2: string;
  country: string;
  state: string;
  zipcode: string;
}

export const initialValues = {
  name: "",
  address1: "",
  address2: "",
  country: "",
  state: "",
  zipcode: "",
};

const API = axios.create({
  baseURL: baseURL,
  timeout: 5000,
});

interface Country {
  code: string;
  name: string;
}

interface State {
  code: string;
  name: string;
}

export default function useShippingAddress() {
  const [countries, setCountries] = useState<Country[]>([]);
  const [country, setCountry] = useState("");
  const [listOfStates, setListOfStates] = useState<State[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shippingAddressAlreadyExists, setShippingAddressAlreadyExists] =
    useState(false);
  const token = localStorage.getItem("authToken") || "";
  const [endpointId, setEndpointId] = useState("");
  const [addressId, setAddressId] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [shippingAddrSchema, setShippingAddrSchema] = useState<any>(
    yup.object().shape(baseSchema)
  );
  const {
    handleSubmit: submitForm,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(shippingAddrSchema),
    defaultValues: initialValues,
  });

  // Submit form
  const handleSubmit = (formData: IData) => {
    const data = {
      name: formData.name,
      address_attributes: {
        address_line1: formData.address1,
        address_line2: formData.address2,
        country: formData.country,
        state: formData.state,
        zipcode: formData.zipcode,
      },
    };
    const headers = {
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
    };
    setIsSaving(true);
    // Update shipping address if already exists
    if (shippingAddressAlreadyExists) {
      API.put(
        `/bx_block_customisableuserprofiles/shipping_addresses/${endpointId}`,
        {
          ...data,
          id: addressId,
        },
        headers
      )
        .then(() => {
          toast.success("Shipping address updated");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            err.response.data.errors.forEach((e: any) => {
              toast.error(e.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      // Save shipping address
      API.post(
        "bx_block_customisableuserprofiles/shipping_addresses",
        data,
        headers
      )
        .then(() => {
          toast.success("Shipping address saved");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            err.response.data.errors.forEach((e: any) => {
              toast.error(e.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  // Load shipping address if already exists
  useEffect(() => {
    const getShippingAddress = API.get(
      "bx_block_customisableuserprofiles/shipping_addresses",
      {
        headers: { token },
      }
    );
    const getCountries = API.get("account_block/accounts/country");
    Promise.all([getShippingAddress, getCountries])
      .then((response) => {
        const [shippingAddResp, countryResp] = response;
        // Shipping address
        const { data } = shippingAddResp.data;
        setIsLoading(false);
        if (data) {
          const { name, address } = data.attributes;
          const { address_line1, address_line2, country, state, zipcode } =
            address;
          setValue("name", name);
          setValue("address1", address_line1);
          setValue("address2", address_line2);
          setValue("country", country);
          setValue("zipcode", zipcode);
          setValue("state", state);
          setShippingAddressAlreadyExists(true);
          setEndpointId(data.id);
          setAddressId(address.id);
        } else {
          setShippingAddressAlreadyExists(false);
        }
        // Country
        setCountries(countryResp.data);
      })
      .catch(() => toast.error("Could not load data"));
  }, []);

  // Load states
  useEffect(() => {
    if (country && countries.length > 0) {
      // Get the state of selected country
      const code = countries
        .find((c: Country) => c.name === country)
        ?.code?.toLowerCase();
      if (code) {
        API.get(`account_block/accounts/states/${code}`)
          .then((response) => {
            setListOfStates(response.data);
          })
          .catch(() => {
            toast.error("Could not load states");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [country, countries]);

  // Detect country change
  useEffect(() => {
    setCountry(watch("country"));
    // Make state empty
    setValue("state", "");
    setIsLoading(true);
  }, [watch("country")]);

  // Make state required when a country has states
  // otherwise make it optional
  useEffect(() => {
    if (country && listOfStates.length === 0) {
      setShippingAddrSchema(
        yup.object().shape({
          ...baseSchema,
          state: yup.string(),
        })
      );
    } else if (country && listOfStates.length > 0) {
      setShippingAddrSchema(
        yup.object().shape({
          ...baseSchema,
          state: yup.string().required("State is required"),
        })
      );
    }
  }, [country, listOfStates]);

  return {
    isLoading,
    control,
    errors,
    listOfStates,
    countries,
    submitForm,
    handleSubmit,
    isSaving,
  };
}
