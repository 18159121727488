import {Button, Container, Grid} from '@mui/material';
import { Box } from '@mui/system';
import React, { Fragment,useEffect } from 'react';
import Notify from './Notify';
import './style.css'
import {useSelector,useDispatch} from "react-redux";
import {setUnreadNotifications,setUnreadCountZero,getNotifications} from "./assets"
import LoaderPage from "../../../components/src/LoaderPage";
import {withRouter} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import NoRecordPage from "../../../components/src/NoRecordPage";

// @ts-ignore
const Notifications = ({history}) => {
    const dispatch = useDispatch()
    // @ts-ignore
    const {notifications,unreadIds,isLoading} = useSelector(state => state.notifications)
    useEffect(() => {
        if(unreadIds){
            dispatch(setUnreadNotifications(unreadIds))
            dispatch(setUnreadCountZero())
        }
    }, [unreadIds]);

    return (
        <Fragment >
            <Grid container sx={{ background: "#fff", display: "flex", padding: "10px 0" }}>
                <Container>
                    <Grid container>
                        <Grid item xs={6}>
                                <Button
                                    size="large"
                                    onClick={()=>history.goBack()}
                                    sx={{ color: "#472264", fontWeight: "bold" }}
                                ><ArrowBackIcon className="back-btn" /></Button>
                            <Button
                                disabled
                                size="large"
                                sx={{ color: "#472264", fontWeight: "bold", '&:disabled':{color: "#472264"}}}
                            >
                                Notifications
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                style={{
                                    alignItems: "center",
                                    height: "100%",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                    display: "flex"
                                }}
                            >
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            {
                isLoading ? <LoaderPage/> :
            <Grid sx={{ background : '#f4f4f4', padding : '45px 0'}}>
            <Container >
                {
                    notifications.length > 0 ?
                    // @ts-ignore
                    notifications.map((n) => {
                        return(
                            <Grid className="notifications-page">
                                <Box sx={{ background : '#b6b8b88c', borderRadius : '5px', width : 'max-content', padding : '7px 15px', marginBottom : '15px', color : '#636666'}}>{n.date}</Box>
                                {
                                    // @ts-ignore
                                    n?.notifications.map((notify) => {
                                    return(
                                        <Notify notification={notify}/>
                                    )
                                })}
                            </Grid>
                        )
                    }) :
                        <NoRecordPage text="No Notifications Available" />
                }
            </Container>
            </Grid>
            }
        </Fragment>
    );
};

export default withRouter(Notifications);