import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  CircularProgress,
  Grid,
  Typography,
  Button,
  Modal,
  Box,
  TextField,
  InputAdornment,
  Stack,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Category } from "./Categoriessubcategories.web";
import styles from "./styles";

interface IProps {
  category: Category | null;
  handleSubCategoryNameUpdate: any;
  updatingSubCategory: any;
  handleSubCategoryRemoval: any;
  revomingSubCategory: any;
}

export default function UpdateSubCategories({
  category,
  handleSubCategoryNameUpdate,
  updatingSubCategory,
  handleSubCategoryRemoval,
  revomingSubCategory,
}: IProps) {
  const [subCategorySchema, setSubCategorySchema] = useState(
    yup.object().shape({})
  );
  const {
    handleSubmit: validateSubCategoryUpdate,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(subCategorySchema),
  });

  // Add fields to sub-category schema according to the number of sub-categories
  useEffect(() => {
    if (category && category.attributes.sub_categories.length > 0) {
      const newObj: any = {};
      category.attributes.sub_categories.forEach((_, i: number) => {
        newObj[`subcategory${i}` as keyof any] = yup
          .string()
          .trim()
          .required("Sub-category is required");
      });
      setSubCategorySchema(yup.object().shape(newObj));
    }
  }, [category]);

  // Fill up sub-category names
  useEffect(() => {
    const sucCategoryFields = category
      ? Object.keys(subCategorySchema.fields)
      : [];
    const subCategories = category ? category.attributes.sub_categories : [];
    if (sucCategoryFields.length > 0 && subCategories.length > 0) {
      subCategories.forEach((subCat: any, i: number) => {
        setValue(`${sucCategoryFields[i]}` as const, subCat.name);
      });
    }
  }, [category, subCategorySchema]);

  return (
    <Box sx={styles.updateCatbox}>
      {category && category.attributes.sub_categories.length > 0 && (
        <Typography sx={styles.label}>Sub-categories</Typography>
      )}
      {category?.attributes.sub_categories.map((subCat: any, index: number) => (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          key={subCat.id}
        >
          <Controller
            name={`subcategory${index}` as any}
            control={control}
            render={({ field }) => (
              <Box>
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Enter sub-category"
                  error={errors["name"] ? true : false}
                  size="small"
                  sx={styles.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {updatingSubCategory.id === subCat.id &&
                        updatingSubCategory.isUpdating ? (
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{ width: "70px" }}
                          >
                            <CircularProgress
                              size={20}
                              sx={{ color: "#472264" }}
                            />
                          </Stack>
                        ) : (
                          <Button
                            color="success"
                            onClick={validateSubCategoryUpdate((data: any) =>
                              handleSubCategoryNameUpdate(
                                data,
                                index,
                                subCat.id
                              )
                            )}
                          >
                            UPDATE
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                {errors[`subcategory${index}` as keyof typeof errors] ? (
                  <Typography variant="subtitle2" sx={styles.errorText}>
                    {
                      errors[`subcategory${index}` as keyof typeof errors]
                        ?.message
                    }
                  </Typography>
                ) : null}
              </Box>
            )}
          />
          <IconButton
            aria-label="delete"
            sx={styles.iconBtn}
            disabled={
              revomingSubCategory.id === subCat.id &&
              revomingSubCategory.isRemoving
                ? true
                : false
            }
            onClick={() => handleSubCategoryRemoval(index, subCat.id)}
          >
            {revomingSubCategory.id === subCat.id &&
            revomingSubCategory.isRemoving ? (
              <CircularProgress
                size={20}
                sx={{ color: "#472264" }}
              />
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
        </Stack>
      ))}
    </Box>
  );
}
