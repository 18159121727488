import axios from "axios";
import {baseURL} from "framework/src/config";

// API Call for Admin User
const API = axios.create({
    baseURL: `${baseURL}/bx_block_dashboard/`
});

// API Call for Vendor User
const BuyerAPI = axios.create({
    baseURL: `${baseURL}/bx_block_order_management/vendors/`
});

// Header set for all API Call for vendor User
BuyerAPI.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

// Header set for all API Call for vendor User
API.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

// get Buyer List API Call
export const buyerList = async (count,page) => {
    try {
        return await API.get(`/buyers?per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

// set Active buyer API Call
export const activeBuyer = async (id) => {
    try {
        return await API.post(`/buyers/activate_buyer`,{id});
    } catch (e) {
        return e.response;
    }
}

// set Deactive buyer API Call
export const deactivateBuyer = async (id) => {
    try {
        return await API.post(`/buyers/deactivate_buyer`,{id});
    } catch (e) {
        return e.response;
    }
}

// set Bulk Deactivate buyer API Call
export const bulkDeactivateBuyer = async (id) => {
    try {
        return await API.post(`/buyers/bulk_deactivate_buyer_users`,{id});
    } catch (e) {
        return e.response;
    }
}

// set Bulk activate buyer API Call
export const bulkActiveBuyer = async (id) => {
    try {
        return await API.post(`/buyers/bulk_activate_buyer_users`,{id});
    } catch (e) {
        return e.response;
    }
}

// set Remove buyer API Call
export const bulkRemoveBuyer = async (id) => {
    try {
        return await API.post(`/buyers/bulk_remove_buyer_users`,{id});
    } catch (e) {
        return e.response;
    }
}

// buyer search API Call
export const buyersSearch = async (count,page,type,search) => {
    try {
        return await API.get(`/buyers?search_key=${type}&search=${search}&per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

// get Buyer List API Call for Admin
export const getBuyersDetailsAdmin = async (id,count,page) => {
    try {
        return await API.get(`buyers/${id}?page=${page}&per=${count}`);
    } catch (e) {
        return e.response;
    }
}

// get Buyer List API Call for Vendor
export const getBuyerListVendor = async (count,page) => {
    try {
        return await BuyerAPI.get(`/buyer_list?per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}

// get Buyer List API Call for Vendor search
export const getBuyerListVendorSearch = async (count,page,type,search) => {
    try {
        return await BuyerAPI.get(`/buyer_list?per=${count}&page=${page}&search_key=${type}&search=${search}`);
    } catch (e) {
        return e.response;
    }
}

// get Buyer Details API for Vendor
export const getBuyerDetailsVendor = async (id,count,page) => {
    try {
        return await BuyerAPI.get(`/buyer_detail?id=${id}&per=${count}&page=${page}`);
    } catch (e) {
        return e.response;
    }
}