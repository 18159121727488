'use strict';

const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";

// Development URL
const devURL = "https://bovinegenmarketplace1-95050-ruby.b95050.dev.us-east-1.aws.svc.builder.cafe"

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Check the environment and return the URL as per the environment
exports.baseURL = process.env.NODE_ENV !== "development" ? baseURL : devURL;