import {
  InputBase,
  makeStyles,
  FormControl,
  Typography,
} from "@material-ui/core";
import { Box } from "@mui/system";
import React, { useEffect, useState, Fragment } from "react";
import {
  whiteArrowImage,
  getOrderDetails,
  updateOrderStatusDetails,
} from "./assets";
import { Grid, ListItemText, MenuItem, Container } from "@mui/material";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import LoaderPage from "../../../components/src/LoaderPage";
import moment from "moment";

const initialState = {
  productName: "",
  price: "",
  categoryName: "",
  race: "",
  birthWeight: "",
  weaningWeight: "",
  weightAtTheEnd: "",
  scrotal: "",
  byCountry: "",
  delievery: "",
  FanimalName: "",
  FnickName: "",
  FDateOfBirth: "",
  Fbreed: "",
  FAnalysisCode: "",
  FNumAnalysis: "",
  ManimalName: "",
  MnickName: "",
  MDateOfBirth: "",
  Mbreed: "",
  MAnalysisCode: "",
  MNumAnalysis: "",
  Description: "",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

const orderStatus = [
  {
    name: "Pending",
    key: "placed",
  },
  {
    name: "Payment Proceed",
    key: "payment_proceed",
  },
  {
    name: "Processing",
    key: "processing",
  },
  {
    name: "Shipping",
    key: "shipping",
  },
  {
    name: "Complete",
    key: "complete",
  },
  {
    name: "Out Of Stock",
    key: "out_of_stock",
  },
  {
    name: "Payment Declined",
    key: "payment_declined",
  },
  {
    name: "Address Invalid",
    key: "address_invalid",
  },
];

const OrderDetailScreen = (props: any) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const isLoading = useSelector((state) => state.orders.isLoading);
  // @ts-ignore
  const details = useSelector((state) => state.orders.orderDetails);
  const { id } = props.match.params;
  const [status, setStatus] = React.useState<string>("");
  const [state, setState] = useState("orderDetailScreen");
  // @ts-ignore
  const profile = JSON.parse(localStorage.getItem("profile"));
  const [currentRole, setCurrentRole] = useState("");
  const classes = useStyles();

  useEffect(() => {
    setCurrentRole(profile.user_role);
    dispatch(getOrderDetails(id));
  }, []);

  useEffect(() => {
    setStatus(details?.status);
  }, [details]);

  // @ts-ignore
  const handleStatusUpdate = (value) => {
    dispatch(updateOrderStatusDetails(id, value));
    dispatch(getOrderDetails(id));
  };

  return (
    <Container>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Box sx={{marginBottom: 2}}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  color: "#472264",
                  fontWeight: "bold",
                  margin: 0,
                  padding: 0,
                }}
                component="div"
              >
                #{details?.order_number}
              </Typography>
              <Typography
                variant="subtitle2"
                display="block"
                gutterBottom
                style={{
                  color: "#472264",
                  fontWeight: "normal",
                  margin: 0,
                  padding: 0,
                }}
              >
                Orders{" "}
                <span style={{ color: "#824bad", fontWeight: "normal" }}>
                  / #{details?.order_number}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: { xs: "flex-start", sm: "flex-end" },
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    color: "#472264",
                    fontWeight: "bold",
                    margin: 0,
                    padding: 0,
                  }}
                  component="div"
                >
                  Status
                </Typography>
                <FormControl
                  className={classes.select}
                  style={{
                    // @ts-ignore
                    backgroundColor:
                      (status === "complete" && "#09B345") ||
                      (status === "pending" && "#D6B336") ||
                      (status === "placed" && "#D6B336") ||
                      (status === "payment_proceed" && "#FF8502") ||
                      (status === "processing" && "#925BBD") ||
                      (status === "shipping" && "#693A8E") ||
                      (status === "out_of_stock" && "#A4A4A4") ||
                      (status === "payment_declined" && "#E13535") ||
                      (status === "address_invalid" && "#606060"),
                  }}
                >
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    disabled={currentRole === "vendor" ? false : true}
                    displayEmpty
                    // onSelect={}
                    IconComponent={
                      currentRole === "vendor"
                        ? (_props) => (
                            <div style={{ position: "relative" }}>
                              <img
                                src={whiteArrowImage}
                                width="15px"
                                height="8px"
                              />
                            </div>
                          )
                        : (_props) => (
                            <div style={{ position: "relative" }}></div>
                          )
                    }
                    // defaultValue="completed"
                    value={details.status}
                    onChange={(e) => handleStatusUpdate(e.target.value)}
                    input={
                      <InputBase
                        style={{
                          color: "white",
                          fontSize: 12,
                          textAlign: "start",
                        }}
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    {orderStatus.map((name: any) => (
                      <MenuItem
                        className={classes.menu}
                        key={name.key}
                        value={name.key}
                      >
                        {/* <img src ={circleImage} width="20px" height="20px" style={{marginRight:"5px",borderRadius:"10px"}}/> */}
                        <ListItemText primary={name.name} />
                      </MenuItem>
                    ))}
                    {/*<div>*/}
                    {/*    <Button variant="contained" style={{backgroundColor:"#472264",width:"100%"}}>Submit</Button>*/}
                    {/*</div>*/}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={7} lg={7}>
              <Box
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  padding: "20px",
                }}
              >
                {currentRole !== "vendor" ? (
                  <Box sx={{ marginBottom: "30px" }}>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#472264",
                        fontWeight: "bold",
                        margin: 0,
                        padding: 0,
                      }}
                      display="block"
                      gutterBottom
                    >
                      {details?.vendor_details?.firm_name[0].toUpperCase() +
                        details?.vendor_details?.firm_name.substring(1)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      <span
                        style={{
                          color: "#824bad",
                          fontWeight: "bold",
                          marginRight: "10px",
                        }}
                      >
                        {details?.vendor_details?.email}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      display="block"
                      gutterBottom
                      style={{
                        color: "#472264",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      Vendor Name
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      <span
                        style={{
                          color: "#824bad",
                          fontWeight: "bold",
                          marginRight: "10px",
                        }}
                      >
                        {details?.vendor_details?.name}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      display="block"
                      gutterBottom
                      style={{
                        color: "#472264",
                        fontWeight: "bold",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      Contact Number
                    </Typography>
                    <Typography variant="subtitle2" display="block">
                      <span
                        style={{
                          color: "#824bad",
                          fontWeight: "bold",
                          marginRight: "10px",
                        }}
                      >
                        +{details?.vendor_details?.contact_number}
                      </span>
                    </Typography>
                  </Box>
                ) : null}
                <Typography
                  variant="subtitle1"
                  display="block"
                  gutterBottom
                  style={{
                    color: "#472264",
                    fontWeight: "bold",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  Order Id:{" "}
                  <span style={{ color: "#824bad", fontWeight: "bold" }}>
                    {" "}
                    #{details?.order_number}
                  </span>
                </Typography>
                <Typography variant="subtitle1" display="block" gutterBottom>
                  <span
                    style={{
                      color: "#824bad",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    {" "}
                    {moment(details?.placed_at).format(" DD/MM/YYYY")}
                  </span>
                  <span style={{ color: "#824bad", fontWeight: "bold" }}>
                    {" "}
                    {moment(details.placed_at).format("hh:mm A")}
                  </span>
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                      style={{
                        color: "#472264",
                        fontWeight: "bold",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      ORDER SUMMARY
                    </Typography>
                  </Grid>
                  {
                    // @ts-ignore
                    details?.order_items?.map((item, key) => {
                      return (
                        <Fragment key={key}>
                          <Grid item xs={9}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "start",
                                marginBottom: "20px",
                              }}
                            >
                              <img
                                src={item.attributes?.catalogue_image?.url}
                                width="40px"
                                height="40px"
                                style={{
                                  marginRight: "30px",
                                  borderRadius: "10px",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "start",
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  display="block"
                                  gutterBottom
                                  style={{
                                    color: "#824bad",
                                    fontWeight: "bold",
                                    margin: "0",
                                    padding: "0",
                                  }}
                                >
                                  {item.attributes.catalogue_name}
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  display="block"
                                  gutterBottom
                                  style={{
                                    color: "#824bad",
                                    fontWeight: "bold",
                                    margin: "0",
                                    padding: "0",
                                  }}
                                >
                                  <span style={{ marginRight: "5px" }}>
                                    QTY :
                                  </span>
                                  <span>X {item.attributes.quantity}</span>
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography
                                variant="subtitle2"
                                display="block"
                                gutterBottom
                                style={{
                                  color: "#824bad",
                                  fontWeight: "bold",
                                  margin: "0",
                                  padding: "0",
                                }}
                              >
                                ${item.attributes.total_price}
                              </Typography>
                            </div>
                          </Grid>
                        </Fragment>
                      );
                    })
                  }
                  <Grid item xs={9}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        marginTop: "20px",
                        paddingTop: "10px",
                        borderTop: "1px dashed #dbd9d9",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        display="block"
                        gutterBottom
                        style={{
                          color: "#472264",
                          fontWeight: "bold",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        Total
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                        paddingTop: "10px",
                        borderTop: "1px dashed #dbd9d9",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        style={{
                          color: "#472264",
                          fontWeight: "bold",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        ${details?.total}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        marginTop: "25px",
                        paddingTop: "10px",
                        borderTop: "1px dashed #dbd9d9",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        display="block"
                        gutterBottom
                        style={{
                          color: "#824bad",
                          fontWeight: "bold",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        Total Amount
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        marginTop: "25px",
                        paddingTop: "10px",
                        borderTop: "1px dashed #dbd9d9",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        style={{
                          color: "#824bad",
                          fontWeight: "bold",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        ${details?.total}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <Box
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  padding: "20px",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    color: "#472264",
                    fontWeight: "bold",
                    margin: 0,
                    padding: 0,
                  }}
                  component="div"
                >
                  {details?.buyer_details?.name}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#472264",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    Contact Number
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#824bad",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    +{details?.buyer_details?.contact_number}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#472264",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    Email Id
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#824bad",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {details?.buyer_details?.email}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#472264",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    Shipping Address
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#824bad",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {details?.buyer_details?.name},
                    <br />
                    {
                      details?.buyer_details?.shipping_address?.data.attributes
                        ?.address?.address_line1
                    }
                    <br />
                    {
                      details?.buyer_details?.shipping_address?.data.attributes
                        ?.address?.address_line2
                    }
                    ,{" "}
                    {
                      details?.buyer_details?.shipping_address.data?.attributes
                        ?.address?.state
                    }
                    <br />
                    {
                      details?.buyer_details?.shipping_address?.data.attributes
                        ?.address?.country
                    }
                    ,{" "}
                    {
                      details?.buyer_details?.shipping_address.data?.attributes
                        ?.address?.zipcode
                    }
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: "20px",
                  }}
                >
                  {details?.buyer_details?.transaction_id && (
                    <div>
                      <Typography
                        variant="subtitle1"
                        display="block"
                        gutterBottom
                        style={{
                          color: "#472264",
                          fontWeight: "bold",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        Transaction Id
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                        style={{
                          color: "#824bad",
                          fontWeight: "bold",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        {details?.buyer_details?.transaction_id}
                      </Typography>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#472264",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    Payment Method
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#824bad",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {details?.buyer_details?.payment_method}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    display="block"
                    gutterBottom
                    style={{
                      color: "#472264",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    Payment Status
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    display="block"
                    gutterBottom
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    {details?.buyer_details?.payment_status}
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
};
export default withRouter(OrderDetailScreen);

const useStyles = makeStyles({
  select: {
    width: "200px",
    margin: "10px 0px",
    borderRadius: "10px",
    backgroundColor: "#2c9c17",
    height: "35px",
    textAlign: "center",
    justifyContent: "center",
    padding: "0 15px",
  },
  menu: {
    "&:nth-of-type(even)": {
      backgroundColor: "#f5ebfc",
    },
    height: "40px",
  },
});
