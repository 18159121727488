import axios from "axios";
import {baseURL} from "framework/src/config";

// vendor API for Product data
const vendorAPI = axios.create({
  baseURL: `${baseURL}/bx_block_catalogue/`
});

// Admin API for Product data
const adminAPI = axios.create({
  baseURL: `${baseURL}/bx_block_dashboard/vendors/`
});

// Vendor API Configuration for header Token
vendorAPI.interceptors.request.use((req) => {
  if (localStorage.getItem('authToken')) {
    req.headers.token = `${localStorage.getItem('authToken')}`
  }
  return req
})

// admin API Configuration for header Token
adminAPI.interceptors.request.use((req) => {
  if (localStorage.getItem('authToken')) {
    req.headers.token = `${localStorage.getItem('authToken')}`
  }
  return req
})

// Get Product list for vendor API
export const productListVendor = async (count, page) => {
  try {
    return await vendorAPI.get(`/catalogues?per=${count}&page=${page}`);
  } catch (e) {
    return e.response;
  }
}

// Get product details for Vendor API
export const productDetailsVendor = async (id) => {
  try {
    return await vendorAPI.get(`/catalogues/${id}`);
  } catch (e) {
    return e.response;
  }
}

// set product status vendor
export const productStatusVendor = async (id, status) => {
  try {
    return await vendorAPI.post(`/catalogues/active_deactive_product`, {
      id: id,
      active: status
    });
  } catch (e) {
    return e.response;
  }
}

// Set product status admin API
export const productStatusAdmin = async (id,status) => {
    try {
        return await adminAPI.post(`active_deactive_product`,{
            id:id,
            active:status
        });
    } catch (e) {
        return e.response;
    }
}

// Remove Produdct Vendor API
export const productRemoveVendor = async (id) => {
  try {
    return await vendorAPI.delete(`/catalogues/${id}`);
  } catch (e) {
    return e.response;
  }
}

// Remove product Admin APi
export const productRemoveAdmin = async (id) => {
  try {
    return await adminAPI.delete(`/delete_product?id=${id}`);
  } catch (e) {
    return e.response;
  }
}

// Product list for admin
export const productListAdmin = async (id, count, page) => {
  try {
    return await adminAPI.get(`products?account_id=${id}&per=${count}&page=${page}`);
  } catch (e) {
    return e.response;
  }
}

// Product Detalis for admin
export const productDetailsAdmin = async (id) => {
  try {
    return await adminAPI.get(`/product_detail?id=${id}`);
  } catch (e) {
    return e.response;
  }
}

// get Cataloge List for buyer
export const getCatalogeList = async (count, page) => {
  try {
    return await API.get(`catalogues/product_listing?per=${count}&page=${page}`);
  } catch (e) {
    return e.response;
  }
};

// get product List by search for Admin
export const productListAdminSearch = async (id, count, page, search, key) => {
  try {
    return await adminAPI.get(`products?account_id=${id}&per=${count}&page=${page}&search=${search}&search_key=${key}`);
  } catch (e) {
    return e.response;
  }
}

// get product List by search for Vendor
export const productListVendorSearch = async (count, page, search, key) => {
  try {
    return await vendorAPI.get(`/catalogues?per=${count}&page=${page}&search=${search}&search_key=${key}`);
  } catch (e) {
    return e.response;
  }
}

// Check the product status for the vendor
export const checkProductStatus = async (cartList) => {
  try {
    return await vendorAPI.post(`/catalogues/unavailable_local_cart_product`,{ids:cartList});
  } catch (e) {
    return e.response;
  }
}