import React from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
} from "@mui/material";
import styles from "./styles";

interface IProps {
  bulkActionType: any;
  handleChange: any;
  handleBulkActions: any;
  selected: any;
  isApplyingBulkActions: boolean;
}

export default function BulkActions({
  bulkActionType,
  handleChange,
  handleBulkActions,
  selected,
  isApplyingBulkActions,
}: IProps) {
  return (
    <Box sx={{ display: "flex" }}>
      <FormControl size="small" sx={{ width: "150px" }}>
        <InputLabel id="bulk-actions">Bulk Actions</InputLabel>
        <Select
          labelId="bulk-actions"
          id="demo-simple-select"
          value={bulkActionType}
          label="Bulk Actions"
          onChange={handleChange}
          sx={{ background: "#fff" }}
        >
          <MenuItem value={"show"}>Show in menu</MenuItem>
          <MenuItem value={"hide"}>Hide from menu</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        size="small"
        sx={{ ...styles.btn, width: "auto", marginLeft: 1 }}
        onClick={handleBulkActions}
        disabled={!bulkActionType || selected.length === 0}
      >
        {isApplyingBulkActions ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          "Apply"
        )}
      </Button>
    </Box>
  );
}
