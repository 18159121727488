import React from "react"
import { toast } from 'react-toastify';
import {
    categoryList,
    getSection1,
    getSection2,
    getSection3,
    getSection4,
    getSection5,
    getSection6,
    getFooter,
    getFooterLinks,
    getProfile
} from "./reduxLandingPageApi";
import store from "../store";

// get Landing page Category list
export const getLandingCategoryList = (match) => async dispatch => {
    dispatch({type:'SET_LANDING_PAGE_LOADING_TRUE'})
    const categories = await categoryList()
    dispatch({type:'SET_LANDING_PAGE_CATEGORY',payload:categories.data.data})
    dispatch(getFooterSection())
    dispatch(getLoaderPage())
    if(match.path.split("/")[1] !== ""){
        dispatch({type:'SET_LANDING_PAGE_LOADING_FALSE'})
    }
}

// Manage Loading
export const manageLoading = () => async dispatch => {
    dispatch({type:'SET_LANDING_PAGE_LOADER_COUNTER_PLUS_ONE'})
}

// Get all home page sections
export const getLoaderPage = () => async dispatch => {
    dispatch({type:'SET_LANDING_PAGE_LOADING_TRUE'})
    const section1 = await getSection1()
    dispatch({type:'SET_LANDING_PAGE_SECTION_1',payload:section1?.data?.data?.attributes})
    const section2 = await getSection2()
    dispatch({type:'SET_LANDING_PAGE_SECTION_2',payload:section2?.data?.data})
    const section3 = await getSection3()
    dispatch({type:'SET_LANDING_PAGE_SECTION_3',payload:section3?.data?.data?.attributes})
    const section4 = await getSection4()
    dispatch({type:'SET_LANDING_PAGE_SECTION_4',payload:section4?.data?.data})
    const section5 = await getSection5()
    dispatch({type:'SET_LANDING_PAGE_SECTION_5',payload:section5?.data?.data?.attributes})
    const section6 = await getSection6()
    dispatch({type:'SET_LANDING_PAGE_SECTION_6',payload:section6?.data?.data?.attributes})
}

// get footer section
export const getFooterSection = () => async dispatch => {
    dispatch({type:'SET_LANDING_PAGE_LOADING_TRUE'})
    const footer1 = await getFooter()
    dispatch({type:'SET_LANDING_PAGE_FOOTER_1',payload:footer1?.data?.data?.attributes})
    const footer2 = await getFooterLinks()
    dispatch({type:'SET_LANDING_PAGE_FOOTER_2',payload:footer2?.data?.data})
}

// Check if Session is
export const updateUserProfile = (history) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const currentProfile = store.getState().landingPage
        const profile = await getProfile()
        if(profile.data.errors){
            toast.error("Your Session Expired Please Login Again")
            window.localStorage.removeItem("profile")
            window.localStorage.removeItem("authToken")
            history.push("/")
            dispatch(setLoginModelTrue())
        }else{
            if(profile.data.data.attributes !== currentProfile.profile){
                dispatch({type:'SET_PROFILE',payload:profile.data.data.attributes})
                window.localStorage.setItem(
                    "profile",
                    JSON.stringify(profile.data.data.attributes)
                );
                if(profile.data.data.attributes.user_role !== "super_administrator"){
                    if(profile.data.data.attributes.user_role === "vendor"){
                        dispatch({type:'SET_ADD_REMOVE_PRODUCT_PERMISSION',payload:profile.data.data.attributes.permission.add_remove_product_permission})
                        dispatch({type:'SET_EDIT_PRODUCT_PERMISSION',payload:profile.data.data.attributes.permission.edit_product_permission})
                    }
                    if(profile.data.data.attributes.user_role === "administrator"){
                        dispatch({type:'SET_MANAGE_PERMISSION',payload:profile.data.data.attributes.permission.change_permission})
                        dispatch({type:'SET_ADD_REMOVE_PRODUCT_PERMISSION',payload:profile.data.data.attributes.permission.add_remove_product_permission})
                        dispatch({type:'SET_EDIT_PRODUCT_PERMISSION',payload:profile.data.data.attributes.permission.edit_product_permission})
                        dispatch({type:'SET_VENDOR_PERMISSION',payload:profile.data.data.attributes.permission.add_remove_vendor_user_permission})
                        dispatch({type:'SET_BUYER_PERMISSION',payload:profile.data.data.attributes.permission.add_remove_buyer_user_permission})
                        dispatch({type:'SET_VENDOR_APPROVAL_PERMISSION',payload:profile.data.data.attributes.permission.approve_vendor_request_permission})
                    }if(profile.data.data.attributes.user_role === "buyer"){
                        dispatch({type:'SET_ONLINE_PAYMENT_PERMISSION',payload:profile.data.data.attributes.permission.pay_online_permission})
                        dispatch({type:'SET_OFFLINE_PAYMENT_PERMISSION',payload:profile.data.data.attributes.permission.pay_offline_permission})
                    }
                }
            }
        }
    }
}

// set Login Model True
export const setLoginModelTrue = () => async dispatch => {
    dispatch({type:'SET_LOGIN_MODEL_TRUE'})
}

// set Login Model False
export const setLoginModelFalse = () => async dispatch => {
    dispatch({type:'SET_LOGIN_MODEL_FALSE'})
}

// set Profile state empty
export const setProfileEmpty = () => async dispatch => {
    dispatch({type:'SET_PROFILE_EMPTY'})
}