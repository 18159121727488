import React from 'react';
import Loader from "react-loader-spinner";
import Box from "@mui/material/Box";
import { darkLogo } from "../../blocks/email-account-login/src/assets";
//@ts-ignore
const LoaderPage = () => {
  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'White',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin:"30px 0px"
      }}
    >
      <div style={{ height: '200px' }} />
      <img className="loaderImage" src={darkLogo} width="250px" style={{ margin: '30px' }} />
      <Loader
        type={"ThreeDots" || "MutatingDots"}
        color="#472264"
        secondaryColor="#D6B336"
        height={100}
        width={100}
      />
      <div style={{ height: '200px' }} />
    </Box>
  );
};

export default LoaderPage;
``