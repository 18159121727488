import {
  makeStyles
} from "@material-ui/core/styles";
import { Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {getProductDetails, menuImage, productStatusUpdate} from "./assets";
import IconButton from "@mui/material/IconButton";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// @ts-ignore
const CatalogeProductItem = ({ row, vendorId, setOpenModal, setDeleteId, history }) => {
  // @ts-ignore
  const { showCount, currentPage } = useSelector(state => state.products)
  // @ts-ignore
  const {addRemoveProduct,editProduct} = useSelector(state => state.landingPage)
  const dispatch = useDispatch()
  // @ts-ignore
  const profile = JSON.parse(localStorage.getItem("profile"));

  // @ts-ignore
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // @ts-ignore
  const manageDelete = (deleteId) => {
    setAnchorEl(null);
    setOpenModal(true);
    setDeleteId(deleteId);
  }

  // @ts-ignore
  const manageStatus = (e, rowId) => {
    dispatch(productStatusUpdate(rowId, e.target.checked, vendorId, showCount, currentPage))
  }

  // @ts-ignore
  const manageRedirect = (cat,subCat) => {
    setAnchorEl(null);
    if(subCat){
      window.open(`/Catalogue/${cat}/${subCat}`)
    }else{
      window.open(`/Catalogue/${cat}`)
    }

  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  // @ts-ignore
  return (
    <StyledTableRow key={row.id}>
      <StyledTableCell component="th" scope="row" align="center">
          <img
              src={row?.attributes?.image?.url}
              width="50px"
              style={{ borderRadius: "10px" }}
          />
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
          {row.attributes.product_number}
      </StyledTableCell>
      <StyledTableCell align="center">
        {row.attributes.name}
      </StyledTableCell>
      <StyledTableCell align="center">
        {
          !row.attributes?.category_status ?
              // @ts-ignore
              <strike style={{color:"red"}}>{row.attributes.category_name}</strike> : row.attributes.category_name
        }
      </StyledTableCell>
      <StyledTableCell align="center">
        {
          !row.attributes?.category_status ?
              // @ts-ignore
              <strike style={{color:"red"}}>{row.attributes.sub_category_name}</strike> : row.attributes.sub_category_name
        }
      </StyledTableCell>
      <StyledTableCell align="center">
        {"$" + row.attributes.price}
      </StyledTableCell>
      <StyledTableCell align="center">
        {row.attributes.stock_qty}
      </StyledTableCell>
      {
        editProduct &&
        <StyledTableCell align="center">
          <PurpleSwitch
              checked={row?.attributes?.active}
              disabled={!editProduct}
              onChange={(e) => manageStatus(e, row.id)}
              name="show-in-menu"
          />
        </StyledTableCell>
      }
      <StyledTableCell>
        <div>
          <IconButton sx={{height:"40px"}} onClick={handleClickMenu}>
          <img
              src={menuImage}
              width="25px"
          />
          </IconButton>
          <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              style={{ boxShadow: "none" }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
          >
            <MenuItem
                className={classes.menuLink}
                onClick={() => {
                  setAnchorEl(anchorEl);
                  dispatch(getProductDetails(row.id))
                  if (profile.user_role === "vendor") {
                    history.push(
                        `/vendor/myProducts/ViewProduct/${row.id}`
                    );
                  } else if (
                      profile.user_role === "administrator" ||
                      profile.user_role === "super_administrator"
                  ) {
                    history.push(
                        `/admin/Vendors/ViewProduct/${row.id}`
                    );
                  }
                }}
            >
              View Product
            </MenuItem>
            {
              editProduct ?
                  <MenuItem
                      className={classes.menuLink}
                      onClick={() => {
                        setAnchorEl(anchorEl);
                        if (profile.user_role === "vendor") {
                          history.push(
                              `/vendor/myProducts/editProduct/${row.id}`, {
                                productId: row.id,
                                formType: "edit"
                              }
                          );
                        } else if (
                            profile.user_role === "administrator" ||
                            profile.user_role === "super_administrator"
                        ) {
                          history.push("editProduct", {
                            vendorId: vendorId,
                            productId: row.id,
                            formType: "edit",
                            user: "admin"
                          });
                        }
                      }}
                  >
                    Edit Product
                  </MenuItem> : null
            }
            {
              addRemoveProduct ? <MenuItem className={classes.menuLink}
                                           onClick={() => {manageDelete(row.id)}}>
                Delete Product
              </MenuItem> : null
            }
            <MenuItem className={classes.menuLink} onClick={() => manageRedirect(row?.attributes?.category_slug,row?.attributes?.sub_category_slug)}>
              Preview
            </MenuItem>
          </Menu>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
};
// @ts-ignore
export default CatalogeProductItem;

const PurpleSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#693a8e'
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#925BBD'
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#472264",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "#472264",
    fontWeight: "bold",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#f5ebfc",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  paper: {
    color: "black",
    padding: 10,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  menuLink: {
    textDecoration: "none",
    color: "#472264",
    marginBottom: 5,
    boxShadow: "none",
  },
  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    display: "flex",
  },
  select: {
    width: "40%",
    backgroundColor: "transparent",
    margin: "5px 20px 5px 20px",
    borderRight: "1px solid #737373",
    paddingRight: "10px",
  },
  search: {
    width: "60%",
    margin: 5,
    display: "flex",
    alignItems: "space-between",
  },
  showselect: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageselect: {
    backgroundColor: "white",
    borderRadius: "5px",
    height: "33px",
    width: "150px",
    display: "flex",
    alignItems: "space-between",
  },
  paragragh: {
    padding: 0,
    marginTop: 4,
    marginLeft: 20,
    fontWeight: "bold",
  },
});

