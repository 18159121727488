import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Grid, Typography, Dialog, DialogContent } from "@mui/material";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IProps extends RouteComponentProps {
  open: boolean;
  handleClose: () => void;
  fileUrl1: string | ArrayBuffer | null;
  fileUrl2: string | ArrayBuffer | null;
  fileUrl3: string | ArrayBuffer | null;
  fileUrl4: string | ArrayBuffer | null;
  fileUrl5: string | ArrayBuffer | null;
  img1: string | ArrayBuffer | undefined;
  img2: string | ArrayBuffer | undefined;
  img3: string | ArrayBuffer | undefined;
  img4: string | ArrayBuffer | undefined;
  img5: string | ArrayBuffer | undefined;
  title1: string;
  title2: string;
  title3: string;
  title4: string;
  title5: string;
  link1: string;
  link2: string;
  link3: string;
  link4: string;
  link5: string;
}

function SectionTwoPreview({
  open,
  handleClose,
  fileUrl1,
  fileUrl2,
  fileUrl3,
  fileUrl4,
  fileUrl5,
  title1,
  title2,
  title3,
  title4,
  title5,
  img1,
  img2,
  img3,
  img4,
  img5,
  link1,
  link2,
  link3,
  link4,
  link5,
  history,
}: IProps) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent dividers={true}>
        <CancelIcon
          fontSize="large"
          sx={styles.cancelIcon}
          onClick={handleClose}
        />
        <Box sx={styles.box}>
          <Grid container sx={{ display: "flex" }} spacing={3}>
            <Grid item xs={12} sm={12} md={6} sx={styles.gridItemLeft}>
              <Box
                className="bg-shadow"
                onClick={() => history.push(`/${link1}`)}
                sx={{
                  ...styles.boxItemLeft,
                  backgroundImage: `url(${fileUrl1 ? fileUrl1 : img1})`,
                }}
              >
                <Typography variant="h4" color="white" sx={styles.title}>
                  {title1}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid
                container
                columns={{ xs: 12, sm: 12, md: 6, lg: 6 }}
                columnSpacing={3}
                spacing={3}
                sx={styles.gridItemRightContainer}
              >
                <Grid item xs={12} sm={12} md={6} lg={3} sx={styles.gridItem}>
                  <Box
                    className="bg-shadow"
                    onClick={() => history.push(`/${link2}`)}
                    sx={{
                      ...styles.boxItemRight,
                      backgroundImage: `url(${fileUrl2 ? fileUrl2 : img2})`,
                    }}
                  >
                    <Typography variant="h4" color="white" sx={styles.title}>
                      {title2}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} sx={styles.gridItem}>
                  <Box
                    className="bg-shadow"
                    onClick={() => history.push(`/${link3}`)}
                    sx={{
                      ...styles.boxItemRight,
                      backgroundImage: `url(${fileUrl3 ? fileUrl3 : img3})`,
                    }}
                  >
                    <Typography variant="h4" color="white" sx={styles.title}>
                      {title3}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} sx={styles.gridItem}>
                  <Box
                    className="bg-shadow"
                    onClick={() => history.push(`/${link4}`)}
                    sx={{
                      ...styles.boxItemRight,
                      backgroundImage: `url(${fileUrl4 ? fileUrl4 : img4})`,
                    }}
                  >
                    <Typography variant="h4" color="white" sx={styles.title}>
                      {title4}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} sx={styles.gridItem}>
                  <Box
                    className="bg-shadow"
                    onClick={() => history.push(`/${link5}`)}
                    sx={{
                      ...styles.boxItemRight,
                      backgroundImage: `url(${fileUrl5 ? fileUrl5 : img5})`,
                    }}
                  >
                    <Typography variant="h4" color="white" sx={styles.title}>
                      {title5}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(SectionTwoPreview);

const styles = {
  gridItemLeft: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    height: {
      xs: "250px",
      md: "auto",
    },
  } as const,
  gridItem: {
    paddingLeft: { xs: "0 !important", md: "24px !important" },
  },
  cancelIcon: {
    position: "absolute",
    top: "10px",
    right: "15px",
    color: "#d6b336",
    cursor: "pointer",
    zIndex: "999",
  } as const,
  box: {
    width: "100%",
    height: "580px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    paddingTop: "50px",
    paddingBottom: "50px",
    margin: {
      lg: 0,
      md: "260px 0",
      xs: "385px 0",
    },
  } as const,
  boxItemLeft: {
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    borderRadius: "10px",
    margin: "0 auto",
    cursor: "pointer",
  },
  boxItemRight: {
    width: "100%",
    height: "240px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    borderRadius: "10px",
    cursor: "pointer",
  },
  title: {
    fontWeight: "bold",
    margin: "20px",
    alignSelf: "flex-end",
  },
  gridItemRightContainer: {
    width: {
      xs: "100% !important",
      md: "calc(100% + 24px) !important",
    },
    marginLeft: {
      xs: 0,
      md: "-24px",
    },
  },
};
