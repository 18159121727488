import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import LoaderPage from "../../../components/src/LoaderPage";
import { baseURL } from "./assets";
import BulkActions from "./BulkActions";
import CategoriesTable from "./CategoriesTable";
import CreateCategory from "./CreateCategory";
import CreateSubCategory from "./CreateSubCategory";
import UpdateCategoryModal from "./UpdateCategoryModal";

const API = axios.create({
  baseURL: baseURL,
});

export const createCategorySchema = yup.object().shape({
  name: yup.string().trim().required("Category name is required"),
});

const updateCategorySchema = yup.object().shape({
  name: yup.string().trim().required("Category name is required"),
});

const subCategorySchema = yup.object().shape({
  categoryId: yup.string().required("Category is required"),
  name: yup.string().trim().required("Sub-category name is required"),
});

export const catInitValues = {
  name: "",
};

const subCatInitValues = {
  categoryId: "",
  name: "",
};

export interface Category {
  id: string;
  attributes: {
    id: number;
    name: string;
    sub_categories: Array<Subcategory>;
    show_in_menu: boolean;
  };
}

export interface Subcategory {
  id: number;
  name: string;
  category_id: number;
}

interface ICat {
  name: string;
}

interface ISubCat {
  categoryId: string;
  name: string;
}

function handleError(err: any) {
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  if (err.response) {
    if (err.response.status === 422) {
      err.response.data.errors.forEach((e: any) => {
        toast.error(e.message);
      });
      return;
    } else {
      toast.error("Something went wrong");
      return;
    }
  } else {
    // The request was made but no response was received
    // Or, something happened in setting up the request that triggered an Error
    toast.error("Something went wrong");
    return;
  }
}

export default function Categoriessubcategories() {
  const token = localStorage.getItem("authToken") || "";
  const [categories, setCategories] = useState<Category[]>([]);
  const [category, setCategory] = useState<Category | null>(null);
  const [categoryId, setCategoryId] = useState(0);
  const [addToMenu, setAddToMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingCategory, setIsSavingCategory] = useState(false);
  const [isSavingSubCategory, setIsSavingSubCategory] = useState(false);
  const [isApplyingBulkActions, setIsApplyingBulkActions] = useState(false);
  const [bulkActionType, setBulkActionType] = useState("");
  const [selected, setSelected] = useState<number[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [updatingCategory, setUpdatingCategory] = useState(false);
  const [updatingSubCategory, setUpdatingSubCategory] = useState({
    id: -1,
    isUpdating: false,
  });
  const [revomingSubCategory, setRemovingSubCategory] = useState({
    id: -1,
    isRemoving: false,
  });

  // Request headers
  const headers = {
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
  };

  // Create category validation
  const {
    handleSubmit: handleCategoryFormSubmit,
    control: categoryControl,
    formState: { errors: categoryErrors },
    setValue: setCategoryValueToCreate,
  } = useForm({
    resolver: yupResolver(createCategorySchema),
    defaultValues: catInitValues,
  });

  // Update category validation
  const {
    handleSubmit: handleCategoryUpdateValidation,
    control: updateCategoryControl,
    formState: { errors: updateCategoryErrors },
    setValue: setCategoryValueToUpdate,
    clearErrors,
  } = useForm({
    resolver: yupResolver(updateCategorySchema),
    defaultValues: catInitValues,
  });

  // Sub-category validation
  const {
    handleSubmit: handleSubCategoryFormSubmit,
    control: subCategoryControl,
    formState: { errors: subCategoryErrors },
    setValue: setSubCategoryValue,
  } = useForm({
    resolver: yupResolver(subCategorySchema),
    defaultValues: subCatInitValues,
  });

  // Create category
  const handleCategoryCreation = (catData: ICat) => {
    setIsSavingCategory(true);
    const data = {
      data: {
        name: catData.name,
        show_in_menu: addToMenu,
      },
    };

    API.post("/bx_block_categories/categories", data, headers)
      .then((response) => {
        setCategories((prevCategories: any) => {
          const updatedCategories = [response.data.data, ...prevCategories];
          return updatedCategories;
        });
        setAddToMenu(false);
        // Clear the category name form field
        setCategoryValueToCreate("name", "");
        toast.success("Created category");
        setIsSavingCategory(false);
      })
      .catch((err) => {
        setIsSavingCategory(false);
        handleError(err);
      });
  };

  // Create subcategory
  const handleSubCategoryCreation = (subCatData: ISubCat) => {
    setIsSavingSubCategory(true);
    const data = {
      data: {
        name: subCatData.name,
        category_id: subCatData.categoryId,
      },
    };

    API.post("/bx_block_categories/sub_categories", data, headers)
      .then((response) => {
        setIsSavingSubCategory(false);
        const newSubCategory = response.data.data.attributes;
        // Insert the new subcategory inside categoreis
        setCategories((prevCategories) => {
          const updatedCategories = [...prevCategories];
          const catIndex = updatedCategories.findIndex(
            (cat: Category) => cat.attributes.id === newSubCategory.category_id
          );
          if (catIndex > -1) {
            const curSubCategories =
              updatedCategories[catIndex].attributes.sub_categories;
            updatedCategories[catIndex].attributes.sub_categories = [
              ...curSubCategories,
              newSubCategory,
            ];
            return updatedCategories;
          }
          return updatedCategories;
        });
        setIsSavingSubCategory(false);
        setSubCategoryValue("categoryId", "");
        // Clear the sub-category name form field
        setSubCategoryValue("name", "");
        toast.success("Created sub-category");
      })
      .catch((err) => {
        setIsSavingSubCategory(false);
        handleError(err);
      });
  };

  // Update category name or show in menu status
  const updateCategory = (catId: number, data: any) => {
    API.put(`/bx_block_categories/categories/${catId}`, data, headers)
      .then((response) => {
        const updatedCategory = response.data.data;
        const categoryId = response.data.data.id;
        setCategories((prevCats) => {
          const updatedCategories = [...prevCats];
          const catIndex = updatedCategories.findIndex(
            (cat: Category) => cat.id === categoryId
          );
          if (catIndex > -1) {
            updatedCategories[catIndex] = updatedCategory;
            return updatedCategories;
          }
          return updatedCategories;
        });
        // Display success message
        if ("show_in_menu" in data.data) {
          toast.success(
            `Category ${
              data.data.show_in_menu
                ? "activated successfully"
                : "deactivated successfully"
            }`
          );
        } else {
          toast.success("Updated category name");
        }
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setUpdatingCategory(false);
      });
  };

  // Change show-in-menu option
  const handleShowInMenuChange = (catId: number, currentStatus: boolean) => {
    const data = {
      data: {
        show_in_menu: !currentStatus,
      },
    };
    updateCategory(catId, data);
  };

  // Handle bulk action selection
  // @ts-ignore
  const handleChange = (e) => {
    setBulkActionType(e.target.value);
  };

  // Handle bulk actions
  const handleBulkActions = () => {
    const data = {
      id: selected,
    };

    // Load the spinner
    setIsApplyingBulkActions(true);

    if (bulkActionType === "show") {
      API.post("/bx_block_categories/categories/bulk_show_menu", data, headers)
        .then((response) => {
          setCategories(response.data.data);
          setSelected([]);
          setBulkActionType("");
          toast.success("Updated");
        })
        .catch((err) => {
          setSelected([]);
          setBulkActionType("");
          handleError(err);
        })
        .finally(() => {
          // Stop spinner
          setIsApplyingBulkActions(false);
        });
    } else if (bulkActionType === "hide") {
      API.post("/bx_block_categories/categories/bulk_hide_menu", data, headers)
        .then((response) => {
          setCategories(response.data.data);
          setSelected([]);
          setBulkActionType("");
          toast.success("Updated");
        })
        .catch((err) => {
          setSelected([]);
          setBulkActionType("");
          handleError(err);
        })
        .finally(() => {
          // Stop spinner
          setIsApplyingBulkActions(false);
        });
    }
  };

  // Handle category name update
  const handleCategoryNameUpdate = (catData: ICat, catId: number) => {
    const data = {
      data: {
        name: catData.name,
      },
    };
    updateCategory(catId, data);
    setUpdatingCategory(true);
  };

  // Handle sub-category name update
  const handleSubCategoryNameUpdate = (
    subCatData: any,
    index: number,
    subCatId: number
  ) => {
    const subCategories = Object.keys(subCatData);
    const data = {
      data: {
        name: subCatData[subCategories[index]],
      },
    };

    // Start spinner
    setUpdatingSubCategory({
      id: subCatId,
      isUpdating: true,
    });

    API.put(`/bx_block_categories/sub_categories/${subCatId}`, data, headers)
      .then((response) => {
        // Update sub-category in UI
        const updatedCategories = [...categories];
        const updatedSubCatName = response.data.data.attributes.name;
        const catId = response.data.data.attributes.category_id;
        const subCatId = response.data.data.attributes.id;
        const catIndex = updatedCategories.findIndex(
          (cat) => cat.attributes.id === catId
        );
        let subCatIndex;
        if (catIndex > -1) {
          subCatIndex = updatedCategories[
            catIndex
          ].attributes.sub_categories.findIndex(
            (subCat) => subCat.id === subCatId
          );
          if (subCatIndex > -1) {
            updatedCategories[catIndex].attributes.sub_categories[
              subCatIndex
            ].name = updatedSubCatName;
          }
        }
        toast.success("Updated sub-category");
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        // Stop spinner
        setUpdatingSubCategory({
          id: -1,
          isUpdating: false,
        });
      });
  };

  // Handle sub-category removal
  const handleSubCategoryRemoval = (index: number, subCatId: number) => {
    // Start spinner
    setRemovingSubCategory({
      id: subCatId,
      isRemoving: true,
    });

    API.delete(`/bx_block_categories/sub_categories/${subCatId}`, headers)
      .then(() => {
        // Remove sub-category from category
        if (category) {
          const updatedCategory = { ...category };
          const updatedSubCategories =
            category.attributes.sub_categories.filter(
              (subCat: any) => subCat.id !== subCatId
            );
          updatedCategory.attributes.sub_categories = updatedSubCategories;
          setCategory(updatedCategory);
          toast.success("Removed sub-category");
        }
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        // Stop spinner
        setRemovingSubCategory({
          id: -1,
          isRemoving: false,
        });
      });
  };

  // Load category name into text field everytime edit button is clicked
  useEffect(() => {
    if (category) {
      setCategoryValueToUpdate("name", category.attributes.name);
      setCategoryId(category.attributes.id);
    }
  }, [category]);

  // Clear errors after modal close & load category name erverytime modal is open
  useEffect(() => {
    if (!isOpen) {
      clearErrors("name");
    } else {
      category && setCategoryValueToUpdate("name", category.attributes.name);
    }
  }, [isOpen, category]);

  // Load data
  useEffect(() => {
    API.get("bx_block_categories/categories", { headers: { token } })
      .then((response) => {
        setCategories(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        handleError(err);
      });
  }, []);

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          {/* Add category */}
          <CreateCategory
            categoryControl={categoryControl}
            categoryErrors={categoryErrors}
            addToMenu={addToMenu}
            setAddToMenu={setAddToMenu}
            handleCategoryFormSubmit={handleCategoryFormSubmit}
            handleCategoryCreation={handleCategoryCreation}
            isSavingCategory={isSavingCategory}
          />
          {/* Add sub-category */}
          <CreateSubCategory
            subCategoryControl={subCategoryControl}
            categories={categories}
            subCategoryErrors={subCategoryErrors}
            handleSubCategoryFormSubmit={handleSubCategoryFormSubmit}
            handleSubCategoryCreation={handleSubCategoryCreation}
            isSavingSubCategory={isSavingSubCategory}
          />
        </Grid>
        {/* Table */}
        <Grid item xs={12} lg={8}>
          <BulkActions
            bulkActionType={bulkActionType}
            handleChange={handleChange}
            handleBulkActions={handleBulkActions}
            selected={selected}
            isApplyingBulkActions={isApplyingBulkActions}
          />
          <CategoriesTable
            categories={categories}
            handleShowInMenuChange={handleShowInMenuChange}
            selected={selected}
            setSelected={setSelected}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setCategory={setCategory}
          />
        </Grid>
      </Grid>
      <UpdateCategoryModal
        category={category}
        setCategoryId={setCategoryId}
        updateCategoryControl={updateCategoryControl}
        updateCategoryErrors={updateCategoryErrors}
        handleCategoryUpdateValidation={handleCategoryUpdateValidation}
        categoryId={categoryId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleCategoryNameUpdate={handleCategoryNameUpdate}
        handleSubCategoryNameUpdate={handleSubCategoryNameUpdate}
        updatingSubCategory={updatingSubCategory}
        handleSubCategoryRemoval={handleSubCategoryRemoval}
        revomingSubCategory={revomingSubCategory}
        updatingCategory={updatingCategory}
      />
    </>
  );
}
