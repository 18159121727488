import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
    baseURL:baseURL,
})

export const registerUser = async (loginData) => {
    try{
        return await API.post(`/account_block/accounts`,loginData, {
            headers:{
                'Access-Control-Allow-Origin': "*",
                'Content-Type':'application/json'
            }
        })
    } catch (e) {
        return  e.response
    }
}

export const registrationVendor = async (formData,token) => {
    try{
        return await API.post(`/bx_block_customisableuserprofiles/vendor_firms`,formData, {
            headers:{
                'token':token,
                'Access-Control-Allow-Origin': "*",
                'Content-Type':'application/json'
            }
        })
    } catch (e) {
        return  e.response
    }
}
export const updateShippingAdderess = async (formData,token) => {
    try{
        return await API.post(`/bx_block_customisableuserprofiles/shipping_addresses`,formData, {
            headers:{
                'token':token,
                'Access-Control-Allow-Origin': "*",
                'Content-Type':'application/json'
            }
        })
    } catch (e) {
        return  e.response
    }
}

export const updateBankDetails = async (formData,token) => {
    try{
        return await API.post(`/bx_block_customisableuserprofiles/bank_details`,formData, {
            headers:{
                'token':token,
                'Access-Control-Allow-Origin': "*",
                'Content-Type':'application/json'
            }
        })
    } catch (e) {
        return  e.response
    }
}

export const getCountries = async () => {
    try{
        return await API.get(`/account_block/accounts/country`)
    } catch (e) {
        return  e.response
    }
}

export const getStates = async (country) => {
    try{
        return await API.get(`/account_block/accounts/states/${country}`)
    } catch (e) {
        return  e.response
    }
}


export const getCountryCodes = async () => {
    try{
        return await API.get(`/account_block/accounts//country_code_and_flag`)
    } catch (e) {
        return  e.response
    }
}


