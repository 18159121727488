import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "./style.css";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import {addToCart, updateCartQty, noimg} from "../../catalogue/src/assets";
import {useDispatch, useSelector} from "react-redux";

//@ts-ignore
export default function MediaCard({prod}) {
  // @ts-ignore
  const {currentVendor,cart} = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const [cartNum, setCartNum] = useState(1);
  // @ts-ignore
  const { user_role } = useSelector((state) => state.landingPage.profile);
  // @ts-ignore
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // const handleClick = async () => {
  //   dispatch(addToCart({
  //     id:prod.id,
  //     attributes:{
  //       account_id:prod.attributes.account_id,
  //       quantity:cartNum,
  //       unit_price:prod?.attributes?.price,
  //       total_price:prod?.attributes?.price * cartNum,
  //       catalogue_image:{
  //         url:prod?.attributes?.image?.url,
  //       },
  //       catalogue_name:prod?.attributes?.name,
  //       vendor_name:"",
  //     },
  //     catalogue_id:prod.id,
  //     quantity:cartNum,
  //   }))
  // }

  const handleClick = async () => {
    // @ts-ignore
    const isAlreadyAdded =  cart.filter((value)=>{
      return value.attributes.catalogue_id == prod?.id
    })
    if(isAlreadyAdded.length !== 0){
      let currentCount = 0
      // @ts-ignore
      isAlreadyAdded.map((i)=>{
        currentCount = i.attributes.quantity
      })
      dispatch(updateCartQty(isAlreadyAdded[0]?.id,(currentCount+cartNum)))
    }else{
      dispatch(addToCart({
        current_vendor_id: currentVendor,
        id:prod.id,
        attributes:{
          account_id:prod.attributes.account_id,
          quantity:cartNum,
          catalogue_id:prod?.id,
          unit_price:prod?.attributes?.price,
          total_price:prod?.attributes?.price * cartNum,
          catalogue_image:{
            url:prod?.attributes?.image?.url,
          },
          catalogue_name:prod?.attributes?.name,
          vendor_name:prod?.attributes?.vendor_name,
          stock_qty:prod?.attributes?.stock_qty,
          status:"active"
        },
        catalogue_id:prod.id,
        quantity:cartNum,
      }))
    }
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ marginBottom: "15px" }} key={prod.id}>
      <Card sx={{ maxWidth: 300, paddingBottom: "5px" }}>
        <Link to="/list/view" style={{ textDecoration: "none" }}>
          <CardMedia
            component="img"
            height="250px"
            image={prod?.attributes?.image?.url || noimg}
            alt="green iguana"
          />
          <CardContent sx={{ padding: "10px" }}>
            <Typography
              className="color-main"
              gutterBottom
              component="div"
              variant="h6"
              sx={{ fontWeight: "600", paddingTop: "15px" }}
            >
              {prod?.attributes?.name} - {prod?.attributes?.vendor_name}
            </Typography>
            <Typography
              className="color-main"
              gutterBottom
              variant="h5"
              component="div"
              sx={{ fontWeight: "bolder" }}
            >
              $ {numberWithCommas(prod?.attributes?.price)}
            </Typography>
          </CardContent>
        </Link>
        <CardActions sx={{ padding: "10px" }}>
          <Grid className="cart-items-box" xs={4}>
            <Typography sx={{ paddingRight: "10px" }} variant="h5">
              {cartNum}
            </Typography>
            <Grid className="button-grp">
              <IconButton
                disabled={user_role === "super_administrator" || user_role === "administrator" || user_role === "vendor"}
                onClick={() => (cartNum < prod?.attributes?.stock_qty && cartNum < 10 ? setCartNum(cartNum + 1) : null)}
                className="btns-inc-dec"
              >
                <ExpandLessIcon />
              </IconButton>
              <IconButton
                disabled={user_role === "super_administrator" || user_role === "administrator" || user_role === "vendor"}
                onClick={() => (cartNum > 1 ? setCartNum(cartNum - 1) : null)}
                className="btns-inc-dec"
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid xs={8}>
            <Button
                onClick={handleClick}
              variant="contained"
              className="add-cart-btn"
              sx={{ width: "100%" }}
              disabled={user_role === "super_administrator" || user_role === "administrator" || user_role === "vendor"}
            >
              Add to cart
            </Button>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
}
