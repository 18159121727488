import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {CChart} from '@coreui/react-chartjs'
import CContainer from '@coreui/react-chartjs'
import './style.css'


import {useSelector} from "react-redux";
// @ts-ignore
const VerticalBar = ({year,handleChange}) => {
  // @ts-ignore
  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  // @ts-ignore
  const {order_transactions} = useSelector(state => state?.vendors?.vendorDashboard)

  return (
    <>
      <Grid container spacing={3} marginTop="40px" sx={{width:"99%"}}>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <FormControl sx={{ width: "150px", borderRadius: "10px" }}>
            <InputLabel id="demo-simple-select-label" sx={{ display: "flex" }}>
              <CalendarTodayIcon
                fontSize="small"
                sx={{ marginRight: "10px", backgroundColor: "white" }}
              />
            </InputLabel>
            <Select
              id="demo-simple-select"
              onChange={handleChange}
              value={year}
            >
              <MenuItem value={"yearly"}>Year Wise</MenuItem>
              <MenuItem value={"monthly"}>Month Wise</MenuItem>
              <MenuItem value={"weekly"}>Week Wise</MenuItem>
              <MenuItem value={"daily"}>Day Wise</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Grid item xs={12}  lg={9} sx={{ display: "flex", justifyContent: "center"}}>
          {/* <ReactApexChart options={options} series={series} type="bar" height="100%" width="900"/> */}
          <CChart
              type="bar"
              options={{
                scales:{
                  y: {
                    grid:{
                      color:'#7B7776',
                      borderDash:[5],
                    },
                    ticks: {
                      color: '#D6B336',
                    },
                  },
                  x: {
                    grid:{
                      display:false,
                    },
                    ticks: {
                      color: '#D6B336'
                    },
                  }
                },
                plugins:{
                  tooltip:{
                    enabled:true,
                    bodyAlign:"center",
                    bodyColor:"white",
                    displayColors:false,
                    backgroundColor:"#D6B336",
                    caretSize:10,
                    axis:'xy',
                    yAlign: 'center'
                  },
                  legend:{
                    labels:{
                      color:"#D6B336",
                    }
                  }
                }
              }}
            data={{
              labels: order_transactions?.graph_transactions?.labels,
              datasets: [
                {
                  label: 'Transactions',
                  backgroundColor: '#D6B336',
                  data:  order_transactions?.graph_transactions?.values,
                  barThickness	: 10,
                  borderRadius : 5,
                }
              ],
            }}
            style={{ width : 'auto', color : '#D6B336 !important'}} className="bar-main-ss"
          />
        </Grid>
        <Grid item xs={12} lg={3} sx={{
            width: {
              xs:"100%",
              md:"90%"
            },
            display: "flex",
            flexDirection: {
              xs:"column",
              lg:"column"
            },
            justifyContent: "space-between"
          }}
        >
          <Paper
            elevation={0}
            sx={{ height: "160px", width: "100%", margin: "10px" }}
          >
            <Typography
              variant="h6"
              component="div"
              color="#6a3a8e"
              sx={{ fontWeight: "bold", padding: "20px" }}
            >
              {year.charAt(0).toUpperCase() + year.slice(1)} Transactions
            </Typography>
            <Box
              sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
            >
              <Typography
                variant={
                  nFormatter(order_transactions?.transaction_amount,1).length > 5 ? "h4": "h3"
                }
                component="div"
                color="#6a3a8e"
                sx={{ fontWeight: "bold", marginLeft: "20px" }}
              >
                $ {nFormatter(order_transactions?.transaction_amount,1)}
              </Typography>
            </Box>
          </Paper>
          <Paper
            elevation={0}
            sx={{ height: "160px", width: "100%", margin: "10px" }}
          >
            <Typography
              variant="h6"
              component="div"
              color="#6a3a8e"
              sx={{ fontWeight: "bold", padding: "20px" }}
            >
              {year.charAt(0).toUpperCase() + year.slice(1)} Order
            </Typography>
            <Box
              sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
            >
              <Typography
                variant="h3"
                component="div"
                color="#6a3a8e"
                sx={{ fontWeight: "bold", marginLeft: "20px" }}
              >
                {order_transactions?.interval_order_count}
              </Typography>
            </Box>
          </Paper>
          <Paper
            elevation={0}
            sx={{ height: "160px", width: "100%", margin: "10px" }}
          >
            <Typography
              variant="h6"
              component="div"
              color="#6a3a8e"
              sx={{ fontWeight: "bold", padding: "20px" }}
            >
              Total Transactions
            </Typography>
            <Box
              sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
            >
              <Typography
                variant={
                  nFormatter(order_transactions?.total_transaction_amount,1).length > 5 ? "h4": "h3"
                }
                component="div"
                color="#6a3a8e"
                sx={{ fontWeight: "bold", marginLeft: "20px" }}
              >
                $ {nFormatter(order_transactions?.total_transaction_amount,1)}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default VerticalBar;
