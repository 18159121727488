import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import { Category } from "./Categoriessubcategories.web";
import UpdateCategory from "./UpdateCategory";
import UpdateSubCategories from "./UpdateSubCategories";
import styles from "./styles";
interface IProps {
  category: Category | null;
  updateCategoryControl: any;
  updateCategoryErrors: any;
  handleCategoryUpdateValidation: any;
  categoryId: any;
  setCategoryId: any;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCategoryNameUpdate: any;
  handleSubCategoryNameUpdate: any;
  updatingSubCategory: any;
  handleSubCategoryRemoval: any;
  revomingSubCategory: any;
  updatingCategory: any;
}

export default function UpdateCategoryModal({
  category,
  updateCategoryControl,
  updateCategoryErrors,
  handleCategoryUpdateValidation,
  categoryId,
  setCategoryId,
  isOpen,
  setIsOpen,
  handleCategoryNameUpdate,
  handleSubCategoryNameUpdate,
  updatingSubCategory,
  handleSubCategoryRemoval,
  revomingSubCategory,
  updatingCategory,
}: IProps) {
  // Close the modal
  const closeModalHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModalHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.modal}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.stack}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={styles.header}
          >
            Edit Category
          </Typography>
          <IconButton onClick={closeModalHandler}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <UpdateCategory
          handleCategoryNameUpdate={handleCategoryNameUpdate}
          updateCategoryControl={updateCategoryControl}
          updateCategoryErrors={updateCategoryErrors}
          handleCategoryUpdateValidation={handleCategoryUpdateValidation}
          categoryId={categoryId}
          updatingCategory={updatingCategory}
        />
        <UpdateSubCategories
          category={category}
          handleSubCategoryNameUpdate={handleSubCategoryNameUpdate}
          updatingSubCategory={updatingSubCategory}
          handleSubCategoryRemoval={handleSubCategoryRemoval}
          revomingSubCategory={revomingSubCategory}
        />
      </Box>
    </Modal>
  );
}