import React, {useState,useEffect,Fragment} from "react";
import {
  IconButton,
  Button,
  Box,
  FormControl,
  Grid,
  NativeSelect,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { withRouter } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux'
import {getVendorDetails,approveVendorRequest,rejectVendorRequest} from "./assets"
import Dashboard from '../../dashboard/src/Dahboard.web';
import LoaderPage from "../../../components/src/LoaderPage";
const styles = {
  sectionHeader: {
    color: "#472264",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
  },
  boldText: {
    color: "#824bad",
    fontWeight: "bold",
  },
  boldDark: {
    color: "#472264",
    fontWeight: "bold",
    margin: "0",
    padding: "0",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginY: 2,
  },
  box: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
  },
};

// @ts-ignore
function VendorProfile({history,match}) {
  const dispatch = useDispatch()
  // @ts-ignore
  const {vendorDetails,isLoading,isButtonLoadingApprove,isButtonLoadingReject} = useSelector(state => state.vendors)
  // @ts-ignore
  const {vendorApproval} = useSelector(state => state.landingPage)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {id} = match.params
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(getVendorDetails(id))
  }, []);

  return (
      <Fragment>
        {
          isLoading ?
              <LoaderPage/> :
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            color: "#472264",
            fontWeight: "bold",
            margin: 0,
            padding: 0,
          }}
          component="div"
        >
          {vendorDetails?.firm_id?.attributes?.name}
        </Typography>
        <Typography variant="subtitle2" display="block" gutterBottom>
          Vendors{" "}
          <span style={{ color: "#824bad", fontWeight: "normal" }}>
            / View Profile
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: '0 !important'
      }}>
        <IconButton onClick={handleClick}>
          <MoreHorizIcon sx={{ color: "#693a8e" }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            ".css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
              boxShadow: 0,
              color: "#693a8e",
            },
          }}
        >
          <MenuItem onClick={() => {
            setAnchorEl(null);
            history.goBack();
          }}>Back to vendor</MenuItem>
          <MenuItem onClick={() => {
            setAnchorEl(null);
            history.push(`/admin/Vendors/Products/${id}`);
          }}>Products ({vendorDetails?.firm_detail?.total_product_list})</MenuItem>
          <MenuItem onClick={() => {
            setAnchorEl(null);
            history.push(`/admin/Vendors/Orders/${id}`);
          }}>Orders ({vendorDetails?.firm_detail?.total_make_orders})</MenuItem>
        </Menu>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Box
          style={{
            ...styles.box,
            minHeight: "300px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={styles.sectionHeader}
            component="div"
          >
            Firm Detail
          </Typography>
          {/* Total product list */}
          <Box sx={styles.row}>
            <Typography sx={styles.boldText}>Total Product List</Typography>
            <Typography sx={styles.boldText}>{vendorDetails?.firm_detail?.total_product_list}</Typography>
          </Box>
          {/* Total make orders */}
          <Box sx={styles.row}>
            <Typography sx={styles.boldText}>Total Make Orders</Typography>
            <Typography sx={styles.boldText}>{vendorDetails?.firm_detail?.total_make_orders}</Typography>
          </Box>
          {/* Transaction completed */}
          <Box sx={styles.row}>
            <Typography sx={styles.boldText}>Transaction completed</Typography>
            <Typography sx={styles.boldText}>{vendorDetails?.firm_detail?.transaction_completed}</Typography>
          </Box>
          {/* Cancelled order */}
          <Box sx={styles.row}>
            <Typography sx={styles.boldText}>Cancelled Order</Typography>
            <Typography sx={styles.boldText}>{vendorDetails?.firm_detail?.cancelled_order}</Typography>
          </Box>
          {/* Completed order */}
          <Box sx={styles.row}>
            <Typography sx={styles.boldText}>Completed Order</Typography>
            <Typography sx={styles.boldText}>{vendorDetails?.firm_detail?.completed_order}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Box style={{...styles.box, minHeight: "300px"}}>
          <Typography
            variant="h6"
            gutterBottom
            sx={styles.sectionHeader}
            component="div"
          >
            {vendorDetails?.firm_id?.attributes?.name}
          </Typography>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Typography
              variant="subtitle2"
              display="block"
              gutterBottom
              sx={styles.boldDark}
            >
              Contact Number
            </Typography>
            <Typography
              variant="subtitle2"
              display="block"
              gutterBottom
              sx={styles.boldText}
            >
              +{vendorDetails?.account_information?.contact_number}
            </Typography>
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Typography
              variant="subtitle2"
              display="block"
              gutterBottom
              sx={styles.boldDark}
            >
              Email Id
            </Typography>
            <Typography
              variant="subtitle2"
              display="block"
              gutterBottom
              sx={styles.boldText}
            >
              {vendorDetails?.account_information?.email}
            </Typography>
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Typography
              variant="subtitle2"
              display="block"
              gutterBottom
              sx={styles.boldDark}
            >
              Address
            </Typography>
            <Typography
              variant="subtitle2"
              display="block"
              gutterBottom
              sx={styles.boldText}
            >
              {vendorDetails?.account_information?.address}
            </Typography>
          </div>
        </Box>
      </Grid>
      {/* Id */}
      <Grid item xs={12} md={6} lg={6}>
        <Box
          style={{
            ...styles.box,
            overflow: "hidden",
            minHeight:"520px"
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={styles.sectionHeader}
            component="div"
          >
            Vendor Id Proofs
          </Typography>
          <Typography sx={{ ...styles.boldText, marginY: 2 }}>Image</Typography>
          <Box sx={{
            width: "100%",
            border: "2px dashed orange",
            minHeight:"350px",
            borderRadius: "10px",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          }}>
            <img
                height="340px"
                width="99%"
                src={vendorDetails?.firm_id?.attributes?.registration_id?.url}
            />
          </Box>
          {
            vendorApproval && vendorDetails.firm_id ?
                <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginTop: 1,
                    }}
                >
                  {
                    vendorDetails?.firm_id?.attributes?.approval_status === "rejected" ?
                        <Button disabled sx={{ color: "#824bad", marginRight: 2 }}>Rejected</Button> :
                        vendorDetails?.firm_id?.attributes?.approval_status === "approved" ?
                        <Button disabled sx={{ color: "#824bad", marginRight: 2 }}>Reject</Button> :
                        <Button sx={{ color: "#824bad", marginRight: 2,width:"100px" }} onClick={()=> dispatch(rejectVendorRequest(id))}>{isButtonLoadingReject ? <CircularProgress size={20} color="inherit"/> : "Reject"}</Button>
                  }
                  {
                    vendorDetails?.firm_id?.attributes?.approval_status === "approved" ?
                        <Button disabled variant="contained" sx={{
                          background: "#824bad",
                          "&:hover": {
                            background: "#824bad",
                          },
                        }}
                        >
                          {"Approved"}
                        </Button> :
                        <Button
                            onClick={()=> dispatch(approveVendorRequest(id))}
                            variant="contained"
                            sx={{
                              background: "#824bad",
                              "&:hover": {
                                background: "#824bad",
                              },
                              width:"100px"
                            }}
                        >
                          {isButtonLoadingApprove ? <CircularProgress size={20} color="inherit"/> : "Approve"}
                        </Button>}
                </Box> : null
          }
        </Box>
      </Grid>
      {/* Bank details */}
      <Grid item xs={12} md={6} lg={6}>
        <Box style={{...styles.box,minHeight:"520px"}}>
          <Typography
            variant="h6"
            gutterBottom
            sx={styles.sectionHeader}
            component="div"
          >
            Bank Details
          </Typography>
          <Box
            sx={{
              background: "#f1f1f1",
              border: "1px solid #e4dede",
              borderRadius: "8px",
              padding: 6,
              marginTop: 2,
              minHeight:"400px"
            }}
          >
            <Typography sx={{ ...styles.boldDark, fontSize: "25px" }}>
              {vendorDetails?.bank_details?.bank_name || "Details Not Found"}
            </Typography>
            <Box sx={{ marginY: 2 }}>
              {
                vendorDetails?.bank_details?.account_name ?
                    <>
                    <Typography sx={styles.boldDark}>Account Name</Typography>
                    <Typography sx={styles.boldText}>{vendorDetails?.bank_details?.account_name}</Typography>
                    </>
                    : null
              }
            </Box>
            {
              vendorDetails?.bank_details?.account_number ?
                  <Box sx={{ marginY: 2 }}>
                    <Typography sx={styles.boldText}>Account Number</Typography>
                    <Typography sx={styles.boldDark}>{vendorDetails?.bank_details?.account_number}</Typography>
                  </Box> : null
            }
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{marginTop: 3}}>
        <Dashboard />
      </Grid>
    </Grid>
        }
    </Fragment>
  );
};

export default withRouter(VendorProfile);