import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import styles from "./styles";

interface IProps {
  handleCategoryNameUpdate: any;
  updateCategoryControl: any;
  updateCategoryErrors: any;
  handleCategoryUpdateValidation: any;
  categoryId: any;
  updatingCategory: any;
}

export default function UpdateCategory({
  handleCategoryNameUpdate,
  updateCategoryControl,
  updateCategoryErrors,
  handleCategoryUpdateValidation,
  categoryId,
  updatingCategory,
}: IProps) {
  return (
    <Box sx={styles.updateCatbox}>
      <Typography sx={styles.label}>Category Name</Typography>
      <Controller
        name="name"
        control={updateCategoryControl}
        render={({ field }) => (
          <TextField
            {...field}
            error={updateCategoryErrors["name"] ? true : false}
            placeholder="Enter category name"
            size="small"
            sx={{ ...styles.textField, width: {xs: "100%", sm: "70% !important"} }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {updatingCategory ? (
                    <CircularProgress size={20} sx={{ color: "#472264" }} />
                  ) : (
                    <Button
                      color="success"
                      onClick={handleCategoryUpdateValidation((data: any) =>
                        handleCategoryNameUpdate(data, categoryId)
                      )}
                    >
                      UPDATE
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {updateCategoryErrors["name"] ? (
        <Typography variant="subtitle2" sx={styles.errorText}>
          {updateCategoryErrors["name"]?.message}
        </Typography>
      ) : null}
    </Box>
  );
}