import React, { useState, useEffect, Fragment } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";

import { defaultImage,getOrderListAsBuyer,setCurrentPageBuyerOrders } from "./assets";
import NoRecordPage from "../../../components/src/NoRecordPage";
import LoaderPage from "../../../components/src/LoaderPage";
import { API } from "./BuyerProfile";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Container from "@mui/material/Container";
import {useDispatch, useSelector} from "react-redux";

export interface OrderItem {
  id: string;
  attributes: {
    id: number;
    catalogue_image: {
      url: string;
    };
    catalogue_name: string;
    quantity: number;
  };
}

interface Order {
  id: string;
  attributes: {
    id: number;
    order_number: string;
    total: string;
    shipping: string;
    status: string;
    complete_at: string;
    order_items: OrderItem[];
    payment_declined_at: string;
    processing_at: string;
    payment_proceed_at: string;
    placed_at: string;
    out_of_stock_at: string;
    address_invalid_at: string;
    shipping_at: string;
    order_status: string;
  };
}

export function getOrderStatusInfo(statusMsg: string, date: string) {

  return (
    <>
      <Typography sx={styles.heading}>{statusMsg}</Typography>
      <Typography sx={styles.heading}>
        {date && new Date(date).toISOString().split("T")[0]}
      </Typography>
    </>
  );
}

export function viewOrderStatusCircle(status: string) {
  let background;
  if (status === "placed") {
    background = "#D6B336";
  } else if (status === "processing") {
    background = "#925BBD";
  } else if (status === "payment_proceed") {
    background = "#FF8502";
  } else if (status === "shipping") {
    background = "#693A8E";
  } else if (status === "complete") {
    background = "#09B345";
  } else if (status === "out_of_stock") {
    background = "#A4A4A4";
  } else if (status === "payment_declined") {
    background = "#E13535";
  } else if (status === "address_invalid") {
    background = "#606060";
  } else if (status === "processing") {
    background = "#925BBD";
  }

  return (
    <Box component="span" sx={{
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      backgroundColor: background,
      display: "block",
      marginRight: "10px",
    }} />
  )
}

// @ts-ignore
export default function Order({ handleSidebarClick }) {
  const dispatch = useDispatch()
  // @ts-ignore
  const {orders,pagination,currentPage,isLoading} = useSelector(state => state.orders)
  console.log("PAGINATION",pagination)
  // const [orders, setOrders] = useState<Order[]>([]);
  // const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("authToken") || "";


  // Load data
  useEffect(() => {
    dispatch(getOrderListAsBuyer(1))
    API.get("/bx_block_order_management/orders/my_orders", {
      headers: { token },
    })
      .then((response) => {
        // setIsLoading(false);
        // setOrders(response.data.data);
      })
      .catch();
  }, []);

  if (isLoading) {
    return (
      <Grid sx={styles.container}>
        <LoaderPage />
      </Grid>
    );
  }

  // @ts-ignore
  const handlePagination = (event, value) => {
    dispatch(setCurrentPageBuyerOrders(value))
  };

  return (
    <>
      {orders.length > 0 && (
        <Typography sx={styles.topText}>
          {
            pagination.total_count > 1 ?
                `${pagination.total_count} orders placed in` :
                `${pagination.total_count} order placed in`
          }
        </Typography>
      )}
      {orders.length === 0 ? (
        <Grid sx={styles.container}>
          <NoRecordPage text={"No Orders Found"}/>
        </Grid>
      ) : (
        <Box>
          {
            // @ts-ignore
            orders.map((order) => (
              <Box sx={styles.container} key={order.id}>
                {/* Order header */}
                <Grid container sx={styles.gridContainer}>
                  <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2.5}
                      lg={2}
                      sx={styles.orderHeaderItem}
                  >
                    <Typography sx={styles.heading}>ORDER ID</Typography>
                    <Typography sx={styles.heading}>
                      {order.attributes.order_number}
                    </Typography>
                  </Grid>
                  <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2.5}
                      lg={2}
                      sx={styles.orderHeaderItem}
                  >
                    <Typography sx={styles.heading}>TOTAL</Typography>
                    <Typography sx={styles.heading}>
                      ${order.attributes.total}
                    </Typography>
                  </Grid>
                  <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2.5}
                      lg={2}
                      sx={styles.orderHeaderItem}
                  >
                    <Typography sx={styles.heading}>Ship to</Typography>
                    <Typography sx={styles.heading}>
                      {order.attributes.shipping}
                    </Typography>
                  </Grid>
                  <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2.5}
                      lg={2}
                      sx={styles.orderHeaderItem}
                  >
                    {order.attributes.status === "complete" &&
                        getOrderStatusInfo("Delivered on", order.attributes.complete_at)}
                    {order.attributes.status === "placed" &&
                        getOrderStatusInfo("Placed on", order.attributes.placed_at)}
                    {order.attributes.status === "processing" &&
                        getOrderStatusInfo("Processed on", order.attributes.processing_at)}
                    {order.attributes.status === "payment_proceed" &&
                        getOrderStatusInfo(
                            "Payment processed on",
                            order.attributes.payment_proceed_at
                    )}
                    {order.attributes.status === "shipping" &&
                        getOrderStatusInfo("Shipped on", order.attributes.shipping_at)}
                    {order.attributes.status === "payment_declined" &&
                        getOrderStatusInfo(
                            "Cancelled On",
                            order.attributes.payment_declined_at
                        )}
                    {order.attributes.status === "out_of_stock" &&
                        getOrderStatusInfo("Cancelled on", order.attributes.out_of_stock_at)}
                    {order.attributes.status === "address_invalid" &&
                        getOrderStatusInfo(
                            "Cancelled on",
                            order.attributes.address_invalid_at
                        )}
                  </Grid>
                  <Grid item xs={12} md={2} lg={4} sx={styles.detailContainer}>
                    <Button
                        variant="contained"
                        sx={styles.btn}
                        onClick={() =>
                            handleSidebarClick(
                                "orderDetails",
                                "my_orders",
                                order.attributes.id
                            )
                        }
                    >
                      View Detail
                    </Button>
                  </Grid>
                </Grid>
                {/* Order items */}
                {
                  // @ts-ignore
                  order.attributes.order_items.map((item) => (
                    <Fragment key={item.id}>
                      <Grid container sx={styles.itemContainer}>
                        <Grid item sx={styles.imgContainer}>
                          <img
                              src={
                                item.attributes.catalogue_image
                                    ? item.attributes.catalogue_image.url
                                    : defaultImage
                              }
                              alt="Cow"
                              style={styles.img}
                          />
                        </Grid>
                        <Grid item sx={{marginLeft: "20px"}}>
                          <Typography sx={{fontWeight: "bold"}}>
                            {item.attributes.catalogue_name}
                          </Typography>
                          <Typography>QTY: {item.attributes.quantity}</Typography>
                        </Grid>
                      </Grid>
                    </Fragment>
                ))}
                <Box sx={styles.statusContainer}>
                  {viewOrderStatusCircle(order.attributes.status)}
                  <Typography>
                    {order.attributes.order_status.toUpperCase()}
                  </Typography>
                </Box>

              </Box>
          ))}
        </Box>
      )}
      <Container>
        <Grid container >
          <Grid
              item
              xs={12}
              md={6}
              sx={{
                alignItems: {
                  paddingBottom: "10px",
                  xs: "center",
                  md: "end"
                }
              }}
          >
          </Grid>
          <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
          >
            <Stack
                spacing={2}
                width="100%"
                display="flex"
                sx={{ alignItems: { xs: "left", md: "end" } }}
                justifyContent="center"
            >
              <Pagination
                  count={pagination.total_pages}
                  page={pagination.current_page}
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    "& .Mui-selected": {
                      color: "#fff !important",
                    },
                    "& .MuiPaginationItem-root:hover": {
                      backgroundColor: "#693A8E",
                    },
                    ".css-wjh20t-MuiPagination-ul": {
                      justifyContent: "flex-end",
                    },
                  }}
                  onChange={handlePagination}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const styles = {
  container: {
    background: "#FFF",
    padding: "15px 20px",
    borderRadius: "5px",
    marginBottom: "15px",
  },
  topText: {
    fontWeight: "bold",
    fontSize: 20,
    color: "#693A8E",
    marginBottom: "15px",
  },
  gridContainer: {
    background: "#693A8E",
    padding: "10px 20px",
    borderRadius: "5px",
  },
  heading: { color: "#FFF", fontWeight: "bold" },
  detailContainer: {
    display: "flex",
    justifyContent: {
      xs: "flex-start",
      md: "flex-end",
    },
    alignItems: "center",
  },
  btn: {
    background: "#d6b336",
    color: "#000",
    fontWeight: "bold",
    "&:hover": {
      background: "#d6b336",
    },
  },
  itemContainer: { margin: "20px 0", padding: "0 15px" },
  imgContainer: {
    width: "70px",
    height: "80px",
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    objectFit: "cover",
  } as const,
  orderHeaderItem: {
    marginBottom: {
      xs: 2,
      md: 0,
    },
  },
  orderStatus: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    background: "green",
    display: "block",
    marginRight: "10px",
  },
  statusContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
};
