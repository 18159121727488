import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import "./style.css"
import moment from "moment";

function TabPanel(props:any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index:any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// @ts-ignore
export default function BasicTabs({ProductDetails}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Product tabs" centered >
          <Tab sx={{ fontSize : '15px', color : '#9c27b0', fontWeight : '700'}} label="Family History" {...a11yProps(0)} />
          <Tab sx={{ fontSize : '15px', color : '#9c27b0', fontWeight : '700'}} label="Product Details" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Container>
      <TabPanel value={value} index={0}>
        <Grid container>
            <Grid item xs={12} sm={6} className="single-page-list">
                <Typography variant="h6" sx={{ color : '#693A8E', fontWeight : 'bold'}}>FATHER DATA</Typography>
                <Typography variant="subtitle2"><strong>Animal Name</strong> : {ProductDetails?.father_data?.attributes.animal_name || " -"}</Typography>
                <Typography variant="subtitle2"><strong>Nick Name</strong> :  {ProductDetails?.father_data?.attributes.nick_name || " -"}</Typography>
                <Typography variant="subtitle2"><strong>Gender</strong> :  MALE</Typography>
                <Typography variant="subtitle2"><strong>Breed</strong> :  {ProductDetails?.father_data?.attributes.breed || " -"}</Typography>
                <Typography variant="subtitle2"><strong>Date of birth</strong> :  {ProductDetails?.father_data?.attributes.date_of_birth ? moment(ProductDetails?.father_data?.attributes.date_of_birth).format("DD/MM/YYYY") : " -"}</Typography>
                <Typography variant="subtitle2"><strong>DNA analysis code</strong> :  {ProductDetails?.father_data?.attributes.dna_analysis_code || " -"}</Typography>
                <Typography variant="subtitle2"><strong>DNA analysis number</strong> :  {ProductDetails?.father_data?.attributes.number_of_dna_analysis || " -"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} className="single-page-list">
                <Typography variant="h6" sx={{ color : '#693A8E', fontWeight : 'bold'}}>MOTHER DATA</Typography>
                <Typography variant="subtitle2"><strong>Animal Name</strong> :  {ProductDetails?.mother_data?.attributes.animal_name || " -"}</Typography>
                <Typography variant="subtitle2"><strong>Nick Name</strong> :  {ProductDetails?.mother_data?.attributes.nick_name || " -"}</Typography>
                <Typography variant="subtitle2"><strong>Gender</strong> : FEMALE</Typography>
                <Typography variant="subtitle2"><strong>Breed</strong> : {ProductDetails?.mother_data?.attributes.breed || " -"}</Typography>
                <Typography variant="subtitle2"><strong>Date of birth</strong> :  {ProductDetails?.mother_data?.attributes.date_of_birth ? moment(ProductDetails?.mother_data?.attributes.date_of_birth).format("DD/MM/YYYY") : " -"}</Typography>
                <Typography variant="subtitle2"><strong>DNA analysis code</strong> : {ProductDetails?.mother_data?.attributes.dna_analysis_code || " -"}</Typography>
                <Typography variant="subtitle2"><strong>DNA analysis number</strong> : {ProductDetails?.mother_data?.attributes.number_of_dna_analysis || " -"}</Typography>
            </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container>
            <Grid item xs={12} sm={6} className="single-page-list" sx={{ padding : '0px 0'}}>
                <Typography variant="subtitle2"><strong>Race</strong> : {ProductDetails.race || " -"}</Typography>
                <Typography variant="subtitle2"><strong>Birth weight</strong> : {ProductDetails.birth_weight || " -"}</Typography>
                <Typography variant="subtitle2"><strong>Weaning weight</strong> : {ProductDetails.weaning_weight || " -"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} className="single-page-list">
                <Typography variant="subtitle2"><strong>Weight at the end</strong> : {ProductDetails.weight_at_the_end || " -"}</Typography>
                <Typography variant="subtitle2"><strong>Scrotal circumference </strong> : {ProductDetails.scrotal_circumference || " -"}</Typography>
                <Typography variant="subtitle2"><strong>By country</strong> : {ProductDetails.by_country || " -"}</Typography>
                <Typography variant="subtitle2"><strong>Delivery</strong> : {ProductDetails.delivery || " -"}</Typography>
            </Grid>
        </Grid>
      </TabPanel>
      </Container>
    </Box>
  );
}