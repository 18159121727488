import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { ColorButton } from "./Style";
import { withRouter } from "react-router-dom";
import {setLoginModelFalse, SuccessCheck} from "./assets";
import {sentEmail} from "./api";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";

const RegisterForm = ({ setFormType,history }) => {
  const dispatch = useDispatch()
  const handleNext = async () => {
    dispatch(setLoginModelFalse())
  };

  return (
    <Grid item xs={12} className="registerMainDiv">
      <Box className="registerFormFlex" style={{marginLeft:"27px"}}>
        <Grid item xs={12} sx={{ marginTop: "100px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "90%"
            }}
          >
            <img
              src={SuccessCheck}
              alt="success"
              width="100px"
              style={{ marginBottom: "15px" }}
            />
            <Typography
              variant="h5"
              component="div"
              color="#472264"
              sx={{ fontWeight: "bold", margin: "15px 0px", textAlign:"center" }}
            >
              Email sent successfully
            </Typography>
            <Typography
              variant="h6"
              component="span"
              color="#925BBD"
              sx={{ textAlign: "center", fontWeight: "bold"}}
            >
              Verification email is successfully sent to your email id, please verify your email.
            </Typography>
          </Box>
          <Box>
            <ColorButton
              size="large"
              variant="contained"
              color="secondary"
              sx={{ width: "95%", height: "64px", marginTop: "20px",backgroundColor:"#693A8E"}}
              onClick={handleNext}
            >
              Close
            </ColorButton>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default withRouter(RegisterForm);
