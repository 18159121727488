import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import { flexBox, ColorButton } from "./Style";
import { login,addCart } from "./api";
import { withRouter } from "react-router-dom";
import {setCart,setLoginModelFalse} from "./assets";
import {useDispatch} from "react-redux";
import * as yup from 'yup'
import {useForm,Controller} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {updateUserProfile} from "components/src/importAssets";

const loginObject = {
  email: "",
  password: "",
}

const LoginForm = ({ setFormType, setOpenLoginModel, history }) => {
  const loginSchema = yup.object().shape({
    "Email":yup.string().email().required("Please Enter Email id"),
    "Password":yup.string().required("Please Enter Password"),
  })

  const { handleSubmit, formState: { errors },control:loginControl } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues:loginObject
  });
  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("profile"))
    const authToken = localStorage.getItem("authToken")
    if(profile && authToken){
      history.push("/")
    }
  }, []);


  const dispatch = useDispatch()
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleSubmit1 = async (formData) => {
    setIsLoading(true);
      const finalData = {
        data: {
          attributes: {
            email: formData.Email,
            password: formData.Password,
            remember_password: checkBox
          }
        }
      };
      try {
        const { data } = await login(finalData);
        if (data.errors) {
          setError(data.errors);
        } else {
          window.localStorage.setItem("authToken", data.meta.token);
          window.localStorage.setItem(
            "profile",
            JSON.stringify(data.data.attributes)
          );
          dispatch(updateUserProfile(history))
          if (
            data.data.attributes.user_role === "super_administrator" ||
            data.data.attributes.user_role === "administrator"
          ) {
            history.push("/admin/dashboard");
          } else if (data.data.attributes.user_role === "buyer") {
            const cart = JSON.parse(localStorage.getItem("cart"))
            if(cart){
              const updatedCart = cart.map((c)=>{
                return{
                  catalogue_id:c.catalogue_id,
                  quantity:c.quantity
                }
              })
              const cartAdd = await addCart({cart:updatedCart},data.meta.token)
              if(cartAdd.status === 200){
                dispatch(setCart())
              }
            }
            if(data.data.attributes.email_confirmed){
              dispatch(setLoginModelFalse())
              // history.push("/");
            }else{
              setFormType("emailVerification")
            }
          } else if (data.data.attributes.user_role === "vendor") {
            if(data.data.attributes.vendor_status !== "approved"){
              history.push("/vendor/profile");
            }else{
              history.push("/vendor/dashboard");
            }
          }
          if(data.data.attributes.user_role === "buyer" && !data.data.attributes.email_confirmed){
            setOpenLoginModel(true);
          }else{
            dispatch(setLoginModelFalse())
            setOpenLoginModel(false);
          }
        }
      } catch (e) {
        setError([
          {
            failed_login: "Something Went Wrong"
          }
        ]);
      }
    setIsLoading(false);
  };

  return (
    <Grid item xs={12} className="loginMainDiv">
      <Box className="loginFormFlex" style={{ padding: "25px 0 25px 0" }}>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#412360" }}
        >
          Welcome !
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          gutterBottom
          color="purple"
        >
          Login in to your account
        </Typography>

        <Grid item xs={12} sx={{ marginTop: "15px" }}>
          {error ? (
            <Alert severity="error" sx={{ width: "90%", alignSelf: "center" }}>
              {error.map(e => {
                return e.failed_login;
              })}
            </Alert>
          ) : null}
          <Box sx={{ marginBottom: "15px", marginTop: "15px" }} >
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Email
            </Typography>
            <Controller
            name="Email"
            control={loginControl}
            render={({ field }) => (
                <TextField
                    error={errors["Email"]}
                    block
                    {...field}
                    name="Email"
                    id="Email"
                    placeholder="Email"
                    sx={{ width: "90%" }}
                    // value={values.email}
                    // onChange={handleChange("email")}
                />
            )}
            />
            <br/>
            {errors["Email"] ?
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ marginBottom: "1",color:"red" }}
                >
                  {errors["Email"]?.message}
                </Typography> : null
            }
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: "1" }}
            >
              Password
            </Typography>
            <FormControl sx={{ m: 0, width: "90%" }} >
              <Controller
                  name="Password"
                  control={loginControl}
                  render={({ field }) => (
                      <OutlinedInput
                          {...field}
                          name="Password"
                          id="password-login"
                          type={values.showPassword ? "text" : "password"}
                          // value={values.password}
                          error={errors["Password"]}
                          placeholder="Password"
                          // onChange={handleChange("password")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                              >
                                {values.showPassword ? (
                                    <Visibility color="secondary" />
                                ) : (
                                    <VisibilityOff color="secondary" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                      />
                  )}
              />
              {errors["Password"] ?
                  <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{ marginBottom: "1",color:"red" }}
                  >
                    {errors["Password"]?.message}
                  </Typography> : null
              }
            </FormControl>
          </Box>
          <Box sx={flexBox}>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center"
              }}>
              <Grid item xs={12} sm={6} sx={{display:'flex',flexDirection:"row"}}>
                <Checkbox
                    sx={{padding:"0px 5px 0px 0px"}}
                    checked={checkBox}
                    icon={<RadioButtonUncheckedIcon sx={{color:"#412360"}} />}
                    checkedIcon={<CheckCircleIcon sx={{color:"#D6B336"}}/>}
                    onChange={() => {
                      setCheckBox(!checkBox);
                    }}
                />
                <Typography
                    variant="body1"
                    component="div"
                    alignSelf="center"
                    sx={{ margin: "0px" , cursor:"pointer"}}
                    onClick={()=>setCheckBox(!checkBox)}
                >
                  Keep me signed in
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{display:'flex',flexDirection:"row",justifyContent:"flex-end",marginTop:{xs:"5px",sm:"0px"}}}>
                <Typography
                    variant="body1"
                    component="a"
                    color="#d8b434"
                    alignSelf="center"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => history.push("ForgotPassword")}
                >
                  Forgot Password?
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <ColorButton
              variant="contained"
              sx={{ width: "90%", height: "50px" }}
              onClick={handleSubmit(handleSubmit1)}
            >
              {isLoading ? <CircularProgress color="inherit" /> : "Login"}
            </ColorButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "space-between",
              justifyContent: "center",
              margin: "20px 0px"
            }}
          >
            <Typography
              variant="body2"
              component="div"
              alignSelf="center"
              sx={{ fontWeight: "bold" }}
            >
              Don't have an account ?
            </Typography>
            <Typography
              variant="body2"
              component="div"
              color="#d8b434"
              alignSelf="center"
              marginLeft={1}
              sx={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => setFormType("register1")}
            >
              Signup
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default withRouter(LoginForm);
