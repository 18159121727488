import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
  baseURL: `${baseURL}/bx_block_contentmanagement/`
});

export const getSection1 = async () => {
  try {
    return await API.get(`/section_ones`);
  } catch (e) {
    return e.response;
  }
};

export const getSection2 = async () => {
  try {
    return await API.get(`/section_twos`);
  } catch (e) {
    return e.response;
  }
};

export const getSection3 = async () => {
  try {
    return await API.get(`/section_threes`);
  } catch (e) {
    return e.response;
  }
};

export const getSection4 = async () => {
  try {
    return await API.get(`/section_fours`);
  } catch (e) {
    return e.response;
  }
};

export const getSection5 = async () => {
  try {
    return await API.get(`/section_fives`);
  } catch (e) {
    return e.response;
  }
};

export const getSection6 = async () => {
  try {
    return await API.get(`/section_sixes`);
  } catch (e) {
    return e.response;
  }
};
