import React, { useState, useEffect, Fragment } from 'react';
import Container from '@mui/material/Container';
import { AsNavFor } from './assests';
import Product from './Product';
import BasicTabs from './Tabs';
import "./style.css";
import {Grid, Button, Typography, Divider, Modal, Box, CircularProgress} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { getCatalogeInfo,handleBuy } from './assests';
import LoaderPage from "../../../components/src/LoaderPage";
import {addToCart, updateCartQty} from "../../catalogue/src/assets";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {finalOrder, sentEmail, setLoginModelTrue, updateUserProfile} from "../../ShoppingCart/src/assets";
// @ts-ignore
const ProductInfo = ({ history, location, match }) => {
  // @ts-ignore
  const currentVendor = useSelector(state => state.cart.currentVendor)
  // @ts-ignore
  const { user_role } = useSelector((state) => state.landingPage.profile);
  // @ts-ignore
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch()
  // @ts-ignore
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const { id } = match.params;
  const [cartNum, setCartNum] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  // ts-ignore
  const [ProductDetails, setProductDetails] = useState({
    price: undefined,
    name: undefined,
    product_details: undefined,
    related_to_the_product: undefined,
    vendor_name: undefined,
    stock_qty: undefined
  });
  useEffect(() => {
    fetchData();
  }, []);

  // @ts-ignore
  const handleCartClick = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const isAlreadyAdded =  cart.filter((value:any)=>{
      return value.attributes.catalogue_id == id
    })
    if(isAlreadyAdded.length !== 0){
      let currentCount = 0
      // @ts-ignore
      isAlreadyAdded.map((i)=>{
        currentCount = i.attributes.quantity
      })
      dispatch(updateCartQty(isAlreadyAdded[0]?.id,(currentCount+cartNum)))
    }else{
      dispatch(addToCart({
        current_vendor_id: currentVendor,
        id:id,
        attributes:{
          // @ts-ignore
          account_id:ProductDetails?.account_id,
          quantity:cartNum,
          unit_price:ProductDetails.price,
          // @ts-ignore
          total_price:ProductDetails.price * cartNum,
          catalogue_image:{
            //@ts-ignore
            url:ProductDetails.images[0].url,
          },
          catalogue_name:ProductDetails.name,
          vendor_name:ProductDetails.vendor_name,
          stock_qty:ProductDetails?.stock_qty,
          status:"active"
        },
        catalogue_id:id,
        quantity:cartNum,
      }))
    }
    setIsLoading(false)
  }

  const handleBuyClick = async () => {
    dispatch(updateUserProfile())
    //@ts-ignore
    const profile = JSON.parse(localStorage.getItem("profile"))
    if(profile){
      if(profile.email_confirmed){
        const {data} = await handleBuy(id,cartNum)
        if(data?.data?.order?.data.id){
          history.push(`/OrderSummary/${data?.data?.order?.data.id}`)
        }else{
          toast.error("Product not found")
        }
      }else{
        toast.error("Your Email id is not verified Please verify your email")
        handleOpen1()
      }
    }else{
      handleOpen()
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    try{
      const { data } = await getCatalogeInfo(id);
      if(data.message){
        history.push("/404")
      }else{
        setProductDetails(data.data.attributes);
      }
    }catch (e) {
      toast.error("Something went Wrong..")
    }

    setIsLoading(false);
  };

  const manageLogin = () => {
    dispatch(setLoginModelTrue())
    setOpen(false)
  }

  const manageResentEmail = async () =>{
    setIsLoading(true)
    const res = await sentEmail()
    if(res.data.success){
      toast.success("Verification Email sent successfully ")
      handleClose1()
    }else{
      toast.error("Something went wrong Please try again")
      handleClose1()
    }
    setIsLoading(false)
  }

  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);

  // @ts-ignore
  return (
    <Fragment>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Grid>
          <Grid sx={{ background: '#fff', display: 'flex', padding: '10px 0' }}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <Link to="/Catalogue">
                    <Button
                      size="large"
                      sx={{ color: '#472264', fontWeight: 'bold' }}
                      startIcon={<ArrowBackIcon className="back-btn" />}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid sx={{ borderTop: '2px solid #EAEAEA', padding: '45px 25px' }}>
            <Container
              sx={{
                display: { xs: 'flex' },
                justifyContent: { xs: 'center', lg: 'center' },
              }}
            >
              <Grid container xs={12} spacing={6}>
                <Grid item xs={12} md={5} sm={12} lg={5}>
                  <Grid item xs={12}>
                    <AsNavFor
                        // @ts-ignore
                        prodImages={ProductDetails?.images}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{ position: 'relative' }}
                  xs={12}
                  md={7}
                  sm={12}
                  lg={7}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      sx={{
                        color: '#693A8E',
                        paddingBottom: '15px',
                        fontWeight: 'bold'
                      }}
                    >
                      {ProductDetails?.name} - {ProductDetails?.vendor_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{ color: '#D6B336', fontWeight: 'bold' }}
                    >
                      $ {numberWithCommas(ProductDetails?.price||0)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ color: '#693A8E' }}>
                      Including Tax
                    </Typography>
                  </Grid>
                  <Divider sx={{ margin: '25px 0' }} />
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        sx={{ color: '#693A8E', fontWeight: 'bold' }}
                      >
                        Description
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ padding: '15px 0', fontWeight: '600' }}
                      >
                        {ProductDetails.product_details}.
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        paddingTop: '15px',
                        position: {
                          xs:"relative",
                          md:"absolute"
                        },
                        bottom: '0',
                        marginBottom: '1em'
                      }}
                    >
                      <Grid item xs={4} md={2} sm={2} lg={2}>
                        <div className="cart-items-box">
                          <Typography sx={{ paddingLeft: '5px' }} variant="h5">
                            {cartNum}
                          </Typography>
                          <Grid className="button-grp">
                            <IconButton
                              disabled={user_role === "super_administrator" || user_role === "administrator" || user_role === "vendor"}
                              onClick={() =>
                                // @ts-ignore
                                cartNum < 10 && cartNum <= ProductDetails?.stock_qty? setCartNum(cartNum + 1) : null
                              }
                              className="btns-inc-dec"
                            >
                              <ExpandLessIcon />
                            </IconButton>
                            <IconButton
                              disabled={user_role === "super_administrator" || user_role === "administrator" || user_role === "vendor"}
                              onClick={() =>
                                cartNum > 1 ? setCartNum(cartNum - 1) : null
                              }
                              className="btns-inc-dec"
                            >
                              <KeyboardArrowDownIcon />
                            </IconButton>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={8} md={4} sm={5} lg={5}>
                        <Button
                          disabled={user_role === "super_administrator" || user_role === "administrator" || user_role === "vendor"}
                          variant="contained"
                          className="add-cart-btn"
                          sx={{ width: '100%' }}
                          onClick={handleCartClick}
                        >
                          Add to cart
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={4} sm={5} lg={5}>
                        <Button
                          disabled={user_role === "super_administrator" || user_role === "administrator" || user_role === "vendor"}
                          variant="contained"
                          className="purple-bg"
                          onClick={handleBuyClick}
                          sx={{
                            width: '100%',
                            backgroundColor: 'purple',
                            padding: '12px'
                          }}
                        >
                          Buy Now
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid
            sx={{ borderTop: '2px solid #EAEAEA', padding: '0 15px 15px 45px' }}
          >
            <BasicTabs ProductDetails={ProductDetails}/>
          </Grid>
          {
            // @ts-ignore
            ProductDetails?.related_to_the_product?.length > 0 ?
                <Grid container sx={{ background: '#EAEAEA', padding: '50px 15px' }}>
                  <Container>
                    <Typography
                        variant="h4"
                        sx={{ marginBottom: '20px', color: '#472264' }}
                    >
                      Related to the product
                    </Typography>
                    <Grid container spacing={3}>
                      {
                        // @ts-ignore
                        ProductDetails?.related_to_the_product?.map((prod)=>{
                          return(
                              <Product prod={prod} key={prod.id}/>
                          )
                        })
                      }
                    </Grid>
                  </Container>
                </Grid> : null
          }
        </Grid>
      )}
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        {
          //@ts-ignore
          <Box sx={modalStyle}>
            <Typography
                variant="h6"
                component="h1"
                color="#472264"
                sx={{
                  borderBottom: "1px solid #b6b8b894",
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: "15px",
                  fontWeight: "bold"
                }}
            >
              Checkout
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              You need account in order to checkout.
            </Typography>
            <Grid
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width="100%"
                sx={{ paddingTop: "25px" }}
            >
              <Button
                  variant="contained"
                  onClick={handleClose}
                  className="cancel-btn"
              >
                Cancel
              </Button>
              <Button
                  onClick={manageLogin}
                  variant="contained"
                  className="checkout-btn bg-purple-light"
              >
                Login Page
              </Button>
            </Grid>
          </Box>
        }
      </Modal>
      <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        {
          //@ts-ignore
          <Box sx={modalStyle1}>
            <Typography
                variant="h6"
                component="h1"
                color="#472264"
                sx={{
                  borderBottom: "1px solid #b6b8b894",
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: "15px",
                  fontWeight: "bold"
                }}
            >
              Your Email ID is not verified
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              You have to verify the email in order to checkout.
            </Typography>
            <Grid
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width="100%"
                sx={{ paddingTop: "25px" }}
            >
              <Button
                  variant="contained"
                  onClick={handleClose1}
                  className="cancel-btn"
              >
                Cancel
              </Button>
              <Button
                  onClick={manageResentEmail}
                  sx={{minWidth:"250px"}}
                  variant="contained"
                  className="checkout-btn bg-purple-light"
              >
                {
                  isLoading ? <CircularProgress size={25} /> : "Resent Verification email"
                }
              </Button>
            </Grid>
          </Box>
        }
      </Modal>
    </Fragment>
  );
};

export default withRouter(ProductInfo);

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "10px"
};

const modalStyle1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs:"100%",
    md:"500px"
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: "10px"
};