const initialState = {
  productName: "",
  price: "",
  categoryId: "",
  categoryName: "",
  subCategoryId: "",
  subCategoryName: "",
  race: "",
  birthWeight: "",
  weaningWeight: "",
  weightAtTheEnd: "",
  scrotal: "",
  byCountry: "",
  delivery: "",
  FanimalName: "",
  FnickName: "",
  FDateOfBirth: undefined,
  Fbreed: "",
  FAnalysisCode: "",
  FNumAnalysis: "",
  ManimalName: "",
  MnickName: "",
  MDateOfBirth: undefined,
  Mbreed: "",
  MAnalysisCode: "",
  MNumAnalysis: "",
  description: "",
  stockQty: 1,
  images: [],
};

export default initialState;
