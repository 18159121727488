export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.png");
export const darkLogo = require("../assets/darkLogo.png");
export const VisiblityOn = require("../assets/VisiblityOn.png");
export const arrowBuyer = require("../assets/arrowBuyer.png");
export const arrowVendor = require("../assets/arrowVendor.png");
export const background = require("../assets/background.png");
export const buyer = require("../assets/buyer.png");
export const vendor = require("../assets/vendor.png");
export const check = require("../assets/check.png");
export const cross = require("../assets/cross.png");
export const SideImage = require("../assets/SideImage.png");
export const {setCart} = require("../../../components/src/Redux/Cart/reduxCartActions")
export const SuccessCheck = require("../assets/successCheck.png")
export const exlamation = require("../assets/exlamation.png")
export const  {setLoginModelTrue,setLoginModelFalse,setRegistrationModelTrue} = require("../../../components/src/Redux/LandingPage/reduxLandingPageActions")
export const LoginForm = require("./LoginForm").default