import React from 'react';
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StyledMenu from "./StyledMenu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {Link, withRouter} from "react-router-dom";

const BuyerSubMenu = ({c,key,history}) => {
    // submenu for buyer
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClickE1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (cat,sub) => {
        if(sub !== "backdropClick"){
            if(cat && sub){
                history.push(`/Catalogue/${cat}/${sub}`)
            }else{
                if(cat){
                    history.push(`/Catalogue/${cat}`)
                }
            }
            setAnchorEl(null);
        }else{
            setAnchorEl(null);
        }
    };
    return (
        <Box sx={{ flexGrow: 1,cursor:"pointer" }} key={key}>
            <Typography
                id="demo-customized-button1"
                aria-controls="demo-customized-menu1"
                variant="h6"
                component="div"
                sx={{
                    flexGrow: 1,
                    color: 'black',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                }}
                onClick={handleClickE1}
            >
                {c.attributes.name} <KeyboardArrowDownIcon />
            </Typography>
            <StyledMenu
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose}
            >
                <MenuItem onClick={()=>handleClose(c.attributes.slug)} disableRipple key={key} sx={{cursor:"pointer"}}>
                    All
                </MenuItem>
                {c.attributes.sub_categories.map((sub,key)=>{
                    return(
                        <MenuItem onClick={()=>handleClose(c.attributes.slug,sub.slug)} disableRipple key={key} sx={{cursor:"pointer"}}>
                            {sub.name}
                        </MenuItem>
                    )
                })}
            </StyledMenu>
        </Box>
    );
};

export default withRouter(BuyerSubMenu);
