import React from "react";
import { Typography, Grid, Box, Button, Modal } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";

import { style, propsInterface } from "./SectionOnePreview";

export default function SectionFivePreview({
  open,
  handleClose,
  backgroundImg,
  fileUrl,
  title,
  link,
}: propsInterface) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* Cancel icon */}
        <Box sx={{ position: "relative" }}>
          <CancelIcon
            fontSize="large"
            sx={styles.cancelIcon}
            onClick={handleClose}
          />
        </Box>
        <Box
          sx={{
            ...styles.container,
            backgroundImage: `url(${fileUrl ? fileUrl : backgroundImg})`,
          }}
        >
          <Grid item xs={12} sx={styles.item}>
            <Typography variant="h2" color="white" sx={styles.typography}>
              {title}
            </Typography>
            <Link
              to={`/${link}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button sx={styles.btn}>Show More</Button>
            </Link>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

const styles = {
  cancelIcon: {
    position: "absolute",
    top: "0",
    right: "0",
    color: "#d6b336",
    cursor: "pointer",
  } as const,
  container: {
    width: "100%",
    height: "550px",
    backgroundSize: "cover",
    display: "flex",
  },
  item: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: {
      xs: "100px 0",
      sm: "200px 0",
    },
  } as const,
  typography: { 
    fontWeight: "bold", 
    textAlign: "center", 
    marginBottom: "30px" 
  } as const,
  btn: {
    width: "150px",
    height: "50px",
    backgroundColor: "#d6b236",
    color: "black",
    borderRadius: "5px",
    fontWeight: "bold",
  },
};
