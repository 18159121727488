import React from "react"
import { toast } from 'react-toastify';
import {
    addCart,
    getCartList,
    removeCart,
    updateQty,
    removeFromCart,
    getOrderList,
    updateFinalCartQty,
    removeFromFinalCart
} from "./reduxCartApi";

import {
    checkProductStatus
} from "../Products/reduxProductsApi";

// Get Cart list from Local storage or from API if not available
export const setCart = () => async dispatch => {
    const cart = JSON.parse(localStorage.getItem("cart"))
    const authToken = localStorage.getItem("authToken")
    try{
        if(authToken){
            const {data} = await getCartList()
            if(!data.errors){
                if(data?.data?.attributes?.cart_items){
                    dispatch({type:'SET_CART_ID', payload: data?.data?.id})
                    dispatch({type:'SET_CART', payload: data?.data?.attributes?.cart_items})
                    dispatch({type:'SET_VENDOR_ID_FOR_CART', payload: data?.data?.attributes?.vendor_id})
                    localStorage.setItem("cart",JSON.stringify(data?.data?.attributes?.cart_items))
                }
            }
        }else {
            if (cart) {
                dispatch({type: 'SET_CART', payload: cart})
            }
        }
    }catch (e) {
        toast.error("Something went wrong.. Please check your internet connection")
    }
}

// Add to cart Action for adding product to cart (Local storage and API)
export const addToCart = (cartItem) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(cartItem.attributes.stock_qty >= cartItem.quantity){
        try{
            if(!cartItem.current_vendor_id){
                dispatch({type:'SET_VENDOR_ID_FOR_CART', payload: cartItem.attributes.account_id})
                if(authToken){
                    const cartData = {
                        cart:[
                            {
                                catalogue_id:cartItem.catalogue_id,
                                quantity:cartItem.quantity,
                            }
                        ]
                    }
                    const {data} = await addCart(cartData)
                    dispatch(setCart())
                }
                dispatch({type:'ADD_TO_CART', payload: cartItem})
                dispatch({type: "ADD_COUNT"})
                toast.success("Item added in cart successfully");
            }else{
                if(cartItem.current_vendor_id === cartItem.attributes.account_id){
                    if(authToken){
                        const cartData = {
                            cart:[
                                {
                                    catalogue_id:cartItem.catalogue_id,
                                    quantity:cartItem.quantity,
                                }
                            ]
                        }
                        const {data} = await addCart(cartData)
                        dispatch(setCart())
                    }
                    dispatch({type:'ADD_TO_CART', payload: cartItem})
                    dispatch({type: "ADD_COUNT"})
                    toast.success("Item added in cart successfully");
                }else{
                    toast.error("You can only add products of the same vendor in cart");
                }
            }
        }catch (e) {
            toast.error("Something went wrong.. Please check your internet connection")
        }
    }else{
        toast.error("Stock is not available for selected qty")
    }
}

// Update cart item Qty Add Action for updating cart item qty (Local storage and API)
export const updateAddCartItemQty = (id,count) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await updateQty(id,count+1)
    }
    dispatch({type:'UPDATE_CART_ITEM_COUNT_PLUS', payload:{id,count}})
}

// Update cart item Qty Minus Action for updating cart item qty (Local storage and API)
export const updateMinusCartItemQty = (id,count) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await updateQty(id,count-1)
    }
    dispatch({type:'UPDATE_CART_ITEM_COUNT_MINUS', payload:{id,count}})
}

// Remove all Items from cart (Local storage and API)
export const removeAllCartItems = (id) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await removeCart(id)
    }
    dispatch({type:'REMOVE_ALL_ITEMS_CART', payload:{id}})
}

// Set cart Zero of Redux state
export const cartZero = () => async dispatch => {
    dispatch({type:'REMOVE_ALL_ITEMS_CART'})
}

// remove Single Item from cart (Local storage and API)
export const removeCartItem = (id) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await removeFromCart(id)
    }
    dispatch({type:'REMOVE_ITEM_CART', payload:{id}})
}

// Final Order Page Actions

// get Order Finalize page details from API
export const getFinalCart = (id,history) => async dispatch => {
    const {data} = await getOrderList(id)
    if(data){
        if(data?.data[0]?.attributes?.status === "in_cart"){
            dispatch({type:'SET_FINAL_CART', payload: data?.data[0]?.attributes?.order_items})
        }else{
            history.push(`/OrderStatus/${id}`)
        }

    }
}

// Final Order Page Actions (Remove Cart item)
export const removeFinalCartItem = (id) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await removeFromFinalCart(id)
    }
    dispatch({type:'REMOVE_ITEM_FINAL_CART', payload:{id}})
    dispatch(setCart())
}

// Final Order Page Actions (Update Qty add for Cart items)
export const updateAddFinalCartItemQty = (id,count,cartId) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await updateFinalCartQty(id,count+1)
    }
    dispatch({type:'UPDATE_FINAL_CART_ITEM_COUNT_PLUS', payload:{id,count}})
    dispatch(setCart())
}

// Final Order Page Actions (Update Qty minus for Cart items)
export const updateMinusFinalCartItemQty = (id,count,cartId) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await updateFinalCartQty(id,count-1)
    }
    dispatch({type:'UPDATE_FINAL_CART_ITEM_COUNT_MINUS', payload:{id,count}})
    dispatch(setCart())
}

// Update Cart Qty (Local storage and API)
export const updateCartQty = (id,count) => async dispatch => {
    const authToken = localStorage.getItem("authToken")
    if(authToken){
        const {data} = await updateQty(id,count)
    }
    dispatch({type:'UPDATE_CART_ITEM_COUNT_QTY', payload:{id,count}})
    toast.success("Cart updated successfully")
}

// Validate Cart Products confirmation from API and Manage local Storage and Redux State
export const manageCartActiveProducts = () => async dispatch => {
    const cart = JSON.parse(localStorage.getItem("cart"))
    const ids = cart.map((items) => {
        return items.catalogue_id
    })
    try{
        const response = await checkProductStatus(ids)
        if(response?.data.ids.length > 0){
            dispatch({type:'VALIDATE_CART_ITEMS', payload:response.data.ids})
        }else{
            dispatch({type:'CHANGE_STATUS_IF_VALIDATION_RESPONSE_GIVE_ZERO', payload:response.data.ids})
        }
    }catch (e) {
        console.log(e)
    }
}