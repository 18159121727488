const initialState = {
    buyers:[],
    buyerDetails:{},
    buyerOrders:[],
    orderPage:1,
    orderShowCount:[],
    pagination:{},
    isLoading:false,
    selected:[],
    currentPage:1,
    showCount:10,
    searchKey:"",
    searchText:"",
}

export const buyerReducer = (state = initialState, {type,payload}) =>{
    switch (type){
        case "SET_BUYER_LIST":
            const updatedData = payload.map((item) => {
                return{
                    ...item,
                    isChecked:false
                }
            })
            return {
                ...state,
                buyers: updatedData,
            }
        case "SET_BUYER_DETAILS":
            return {state}
        case "SET_BUYER_PAGINATION":
            return {
                ...state,
                pagination: payload,
            }
        case "SET_LOADING_FALSE":
            return {
                ...state,
                isLoading:false
            }
        case "SET_LOADING_TRUE":
            return {
                ...state,
                isLoading:true
            }
        case "SET_CURRENT_PAGE":
            return {
                ...state,
                currentPage:payload
            }
        case "SET_BUYER_SEARCH_KEY":
            return {
                ...state,
                searchKey: payload,
            }
        case "SET_BUYER_SEARCH_TEXT":
            return {
                ...state,
                searchText: payload,
            }
        case "SET_SHOW_COUNT":
            return {
                ...state,
                showCount:payload
            }
        case "SET_ORDER_PAGE":
            return {
                ...state,
                orderPage:payload
            }
        case "SET_ORDER_COUNT":
            return {
                ...state,
                orderShowCount:payload
            }
        case "SET_BUYER_ORDER_DETAILS":
            return {
                ...state,
                buyerDetails:payload.detail[0],
                buyerOrders:payload.data,
                pagination: payload.meta.pagination,
            }
        case "HANDLE_SELECT":
            const flag = state.selected.indexOf(payload)
            let updateSelectedArray = []
            if(flag !== -1)
            {
                updateSelectedArray = state.selected.filter((item)=>{
                    return item !== payload
                })

            }else{
                updateSelectedArray = [
                    ...state.selected,
                    payload
                ]
            }
            const updateList = state.buyers.map((item) => {
                if(item.id === payload) {
                    return{
                        ...item,
                        isChecked: !item.isChecked
                    }
                }else {
                    return item
                }
            })
            return {
                ...state,
                selected:updateSelectedArray,
                buyers: updateList
            }
        case "SELECT_ALL":
            let selectAll = []
            let selectedList = []
            if(state.selected.length > 0){
                selectedList = []
                selectAll = state.buyers.map((item)=>{
                    return{
                        ...item,
                        isChecked: false
                    }
                })
            }else{
                selectedList = state.buyers.map((item)=>{
                    return item.id
                })
                selectAll = state.buyers.map((item)=>{
                    return{
                        ...item,
                        isChecked: true
                    }
                })
            }
            return {
                ...state,
                buyers:selectAll,
                selected:selectedList
            }
        case "EMPTY_SELECTED_BUYER":
            return {
                ...state,
                selected: []
            }
        default:
            return state
    }
}