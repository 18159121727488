import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoaderPage from "../../../components/src/LoaderPage";
import { DropZone } from "./assets";
import { PreviewButton, SaveButton } from "./Buttons";
import SectionFourPreview from "./SectionFourPreview";
import { API } from "./SectionOne";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { capitalize } from "./SectionOne";
import { validateLeadingSlash } from "./SectionTwo";
import RouteLeavingGuard from "./RouteLeavingGuard";

// Add validateLeadingSlash to yup
yup.addMethod(yup.string, "validateLeadingSlash", validateLeadingSlash);

const sectionTwoSchema = yup.object().shape({
  title1: yup
    .string()
    .trim()
    .max(25, "title must be less than 25 chars")
    .required("title is required"),
  link1: yup
    .string()
    .trim()
    // @ts-ignore
    .validateLeadingSlash()
    .required("link is required"),
  title2: yup
    .string()
    .trim()
    .max(25, "title must be less than 25 chars")
    .required("title is required"),
  link2: yup
    .string()
    .trim()
    // @ts-ignore
    .validateLeadingSlash()
    .required("link is required"),
});

const initialValues = {
  title1: "",
  link1: "",
  title2: "",
  link2: "",
};

interface IData {
  title1: string;
  link1: string;
  title2: string;
  link2: string;
}

interface IProps {
  history: any;
  location: any;
}

function SectionFour({ history, location: currentLocation }: IProps) {
  const token = localStorage.getItem("authToken") || "";
  // Col 1
  const [file1, setFile1] = useState("");
  const [fileUrl1, setFileUrl1] = useState<string | ArrayBuffer | null>("");
  const [hasRemovedFile1, setHasRemovedFile1] = useState(false);
  const [img1, setImg1] = useState("");
  const [img1Name, setImg1Name] = useState("");
  // Col 2
  const [file2, setFile2] = useState("");
  const [fileUrl2, setFileUrl2] = useState<string | ArrayBuffer | null>("");
  const [hasRemovedFile2, setHasRemovedFile2] = useState(false);
  const [img2, setImg2] = useState("");
  const [img2Name, setImg2Name] = useState("");
  // Others
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    handleSubmit: validateSubmission,
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(sectionTwoSchema),
    defaultValues: initialValues,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Submit section four
  const handleSubmit = (data: IData) => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append("section_fours_attributes[0][id]", "1");
    formData.append("section_fours_attributes[0][heading_title]", data.title1);
    formData.append("section_fours_attributes[0][link]", data.link1);
    file1 && formData.append("section_fours_attributes[0][image]", file1);
    formData.append("section_fours_attributes[1][id]", "2");
    formData.append("section_fours_attributes[1][heading_title]", data.title2);
    formData.append("section_fours_attributes[1][link]", data.link2);
    file2 && formData.append("section_fours_attributes[1][image]", file2);
    API.post("/bx_block_contentmanagement/section_fours", formData, {
      headers: { token },
    })
      .then((response) => {
        const { data } = response.data;
        setImg1(data["1"].image);
        setImg2(data["2"].image);
        setImg1Name(data["1"].file_name);
        setImg2Name(data["2"].file_name);
        setHasSaved(true);
        toast.success("Saved");
      })
      .catch((err) => {
        if (err.response.status === 422) {
          err.response.data.errors.forEach((e: any) => {
            toast.error(e.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsSaving(false);
        // Remove selected files
        if (file1) {
          setFile1("");
          setHasRemovedFile1(true);
        }
        if (file2) {
          setFile2("");
          setHasRemovedFile2(true);
        }
      });
  };

  const shouldBlockNavigation = (nextLocation: any) => {
    return isDirty && currentLocation.pathname !== nextLocation.pathname
      ? true
      : false;
  };

  const navigate = (nextLocation: any) => {
    history.push(nextLocation.pathname);
  };

  // Remove messages when file changes
  useEffect(() => {
    if (file1) {
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(file1);
      reader.onloadend = () => {
        setFileUrl1(reader.result);
      };
    } else {
      setFileUrl1("");
    }

    if (file2) {
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(file2);
      reader.onloadend = () => {
        setFileUrl2(reader.result);
      };
    } else {
      setFileUrl2("");
    }
  }, [file1, file2]);

  // Remove file 1
  const removeFile1Handler = () => {
    setFile1("");
    setHasRemovedFile1(true);
  };

  // Remove file 2
  const removeFile2Handler = () => {
    setFile2("");
    setHasRemovedFile2(true);
  };

  // Load data
  useEffect(() => {
    API.get("/bx_block_contentmanagement/section_fours", { headers: { token } })
      .then((response) => {
        setImg1(response.data.data["1"].image);
        setValue("title1", response.data.data["1"].title);
        setValue("link1", response.data.data["1"].link);
        setImg1Name(response.data.data["1"].file_name);
        setImg2(response.data.data["2"].image);
        setValue("title2", response.data.data["2"].title);
        setValue("link2", response.data.data["2"].link);
        setImg2Name(response.data.data["2"].file_name);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Could not load data");
      });
  }, []);

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <Box sx={{ padding: "20px 0" }}>
      <Grid container spacing={10}>
        <Grid item xs={12} sm={6}>
          <Typography sx={styles.label}>Col 01</Typography>
          <Typography sx={styles.label}>Background Image</Typography>
          <DropZone
            setFile={setFile1}
            hasRemovedFile={hasRemovedFile1}
            setHasRemovedFile={setHasRemovedFile1}
            backgroundImgName={img1Name}
          />
          {file1 && (
            <Box sx={styles.removeBtnContainer}>
              <Button sx={styles.label} onClick={removeFile1Handler}>
                Remove
              </Button>
            </Box>
          )}
          {/* form */}
          <Grid>
            <Typography sx={styles.label}>Title</Typography>
            <Controller
              name="title1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["title1"] ? true : false}
                  placeholder="Enter Title"
                  size="small"
                  sx={styles.textField}
                />
              )}
            />
            {errors["title1"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {capitalize(errors["title1"]?.message)}
              </Typography>
            ) : null}
          </Grid>
          <Grid>
            <Typography sx={styles.label}>Link</Typography>
            <Controller
              name="link1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["link1"] ? true : false}
                  placeholder="Url://"
                  size="small"
                  sx={styles.textField}
                />
              )}
            />
            {errors["link1"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {capitalize(errors["link1"]?.message)}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        {/* Right side */}
        <Grid item xs={12} sm={6} sx={styles.rightGridItem}>
          <Typography sx={styles.label}>Col 02</Typography>
          <Typography sx={styles.label}>Background Image</Typography>
          <DropZone
            setFile={setFile2}
            hasRemovedFile={hasRemovedFile2}
            setHasRemovedFile={setHasRemovedFile2}
            backgroundImgName={img2Name}
          />
          {file2 && (
            <Box sx={styles.removeBtnContainer}>
              <Button sx={styles.label} onClick={removeFile2Handler}>
                Remove
              </Button>
            </Box>
          )}
          {/* form */}
          <Grid>
            <Typography sx={styles.label}>Title</Typography>
            <Controller
              name="title2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["title2"] ? true : false}
                  placeholder="Enter Title"
                  size="small"
                  sx={styles.textField}
                />
              )}
            />
            {errors["title2"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {capitalize(errors["title2"]?.message)}
              </Typography>
            ) : null}
          </Grid>
          <Grid>
            <Typography sx={styles.label}>Link</Typography>
            <Controller
              name="link2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["link2"] ? true : false}
                  placeholder="Url://"
                  size="small"
                  sx={styles.textField}
                />
              )}
            />
            {errors["link2"] ? (
              <Typography variant="subtitle2" sx={styles.errorText}>
                {capitalize(errors["link2"]?.message)}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {/* Button container starts */}
      <Grid sx={styles.btnContainer}>
        <PreviewButton
          text="preview"
          handleOpen={validateSubmission(handleOpen)}
        />
        <SaveButton
          text="save"
          handleSubmit={validateSubmission(handleSubmit)}
          isSaving={isSaving}
        />
      </Grid>
      {/* Button container ends */}
      <SectionFourPreview
        open={open}
        handleClose={handleClose}
        fileUrl1={fileUrl1}
        fileUrl2={fileUrl2}
        title1={watch("title1")}
        title2={watch("title2")}
        img1={img1}
        img2={img2}
        link1={watch("link1")}
        link2={watch("link2")}
      />
      <RouteLeavingGuard
        when={isDirty && !hasSaved}
        shouldBlockNavigation={shouldBlockNavigation}
        navigate={navigate}
      />
    </Box>
  );
}

export default withRouter(SectionFour);

const styles = {
  label: { color: "#472264", fontWeight: "bold" },
  rightGridItem: {
    paddingTop: {
      sm: "80px !important",
      xs: "40px !important",
    },
  },
  removeBtnContainer: { display: "flex", justifyContent: "flex-end" },
  textField: { width: "100%", background: "#f1f1f1", margin: "10px 0" },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
  },
  errorText: { marginBottom: "1", color: "red" },
};
