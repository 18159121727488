import axios from "axios";
import {baseURL} from "framework/src/config";

// API Initialization
const API = axios.create({
    baseURL: baseURL
});

// API Initialization for set Header Token
API.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

// API for category list
export const categoryList = async () => {
    try {
        return await API.get(`/bx_block_categories/categories/category_list`);
    } catch (e) {
        return e.response;
    }
}

// get section 1
export const getSection1 = async () => {
    try {
        return await API.get(`/bx_block_contentmanagement/section_ones`);
    } catch (e) {
        return e.response;
    }
};

// get section 2
export const getSection2 = async () => {
    try {
        return await API.get(`/bx_block_contentmanagement/section_twos`);
    } catch (e) {
        return e.response;
    }
};

// get section 3
export const getSection3 = async () => {
    try {
        return await API.get(`/bx_block_contentmanagement/section_threes`);
    } catch (e) {
        return e.response;
    }
};

// get section 4
export const getSection4 = async () => {
    try {
        return await API.get(`/bx_block_contentmanagement/section_fours`);
    } catch (e) {
        return e.response;
    }
};

// get section 5
export const getSection5 = async () => {
    try {
        return await API.get(`/bx_block_contentmanagement/section_fives`);
    } catch (e) {
        return e.response;
    }
};

// get section 6
export const getSection6 = async () => {
    try {
        return await API.get(`/bx_block_contentmanagement/section_sixes`);
    } catch (e) {
        return e.response;
    }
};

// get footer API
export const getFooter = async () => {
    try{
        return await API.get(`/bx_block_contentmanagement/footers`)
    } catch (e) {
        return  e.response
    }
}

// get footer links API
export const getFooterLinks = async () => {
    try{
        return await API.get(`/bx_block_contentmanagement/footer_sections`)
    } catch (e) {
        return  e.response
    }
}

// get Profile from token
export const getProfile = async () => {
    try{
        return await API.get(`/account_block/accounts`)
    } catch (e) {
        return  e.response
    }
}