import { Grid, Container, Typography } from "@mui/material";
import React , {useEffect,useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { phone, email } from "./assets";
import {getFooter} from "../../../components/src/importAssets";
const Contactus = () => {

  useEffect(() => {
    getDetails()
  }, []);
  const [contactDetails, setContactDetails] = useState({});
  const getDetails = async () => {
    const {data} = await getFooter()
    setContactDetails(data?.data?.attributes)
  }
  return (
    <Grid>
      <Grid sx={{ background: "#fff", display: "flex", padding: "10px 0" }}>
        <Container>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6">Contact Us</Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid
        sx={{
          borderTop: "2px solid #EAEAEA",
          padding: "45px 25px",
          background: "#EAEAEA",
          height:'60vh'
        }}
      >
        <Container
          sx={{
            display: { xs: "flex" },
            justifyContent: { xs: "center", lg: "center" }
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Card sx={{ maxWidth: "100%", padding: "45px 55px" }}>
                <CardMedia
                  component="img"
                  alt="green iguana"
                  height="auto"
                  width="100%"
                  image={email}
                  sx={{ maxWidth: "60px", minWidth: "60px" }}
                />
                <CardContent
                  sx={{ paddingLeft: "0", paddingBottom: "0 !important" }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "bold" }}
                  >
                    Email
                  </Typography>
                  <Typography variant="h5">
                    {
                      // @ts-ignore
                      contactDetails?.email || "info@genbov.com"
                    }
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Card sx={{ maxWidth: "100%", padding: "45px 55px" }}>
                <CardMedia
                  component="img"
                  alt="green iguana"
                  height="auto"
                  width="100%"
                  image={phone}
                  sx={{ maxWidth: "53px", minWidth: "53px" }}
                />
                <CardContent
                  sx={{ paddingLeft: "0", paddingBottom: "0 !important" }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "bold" }}
                  >
                    Phone
                  </Typography>
                  <Typography variant="h5">{
                    // @ts-ignore
                    contactDetails?.phone_number || "+1 8989-9889-0909"
                  }</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Contactus;
