import React, { Fragment, useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  logoDark,
  Contact,
  locationPin,
  mail,
  getFooter,
  getFooterLinks,
} from "./importAssets";
import { useSelector } from "react-redux";
const Footer = () => {
  const { footer1, footer2 } = useSelector((state) => state.landingPage);
  return (
    <Fragment>
      <Grid
        container
        bgcolor="#d6b236"
        marginTop="0"
        paddingLeft="10px"
        paddingY="30px"
        zIndex="0"
      >
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={4} sx={{ paddingBottom: "15px" }}>
          <Box sx={{}}>
            <img
              src={footer1?.logo?.url}
              width="120px"
              alt="Brand Logo"
              style={{ alignSelf: "left", marginBottom: "20px" }}
            />
            <Box
              display="flex"
              alignItems="center"
              marginBottom={1}
              width="80%"
            >
              <img
                src={locationPin}
                width="20px"
                height="max-content"
                alt="Brand Logo"
                style={{  height: "max-content",marginRight:"10px" }}
              />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ flexGrow: 1, color: "black", cursor: "pointer" }}
              >
                {footer1.location}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={1}>
              <img
                src={Contact}
                width="20px"
                height="max-content"
                alt="Brand Logo"
                style={{ height: "max-content",marginRight:"10px" }}
              />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ flexGrow: 1, color: "black", cursor: "pointer" }}
              >
                {footer1.phone_number}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <img
                src={mail}
                width="20px"
                height="max-content"
                alt="Brand Logo"
                style={{ height: "max-content",marginRight:"10px" }}
              />
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ flexGrow: 1, color: "black", cursor: "pointer" }}
              >
                {footer1.email}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {footer2?.map((f, key) => {
          return (
            <Grid item xs={12} md={2} key={key}>
              <Box
                sx={{
                  textAlign: { xs: "start", sm: "start" },
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#6a3a8e",
                    fontWeight: "bold",
                    marginBottom: "6px",
                  }}
                >
                  {f?.attributes.heading}
                </Typography>
                {f.attributes.footer_links.map((link, key) => {
                  return (
                    <Typography
                      key={key}
                      variant="subtitle1"
                      component="a"
                      href={`https://${link.url}`}
                      target="_blank"
                      sx={{ color: "black", textDecorationLine: "none" }}
                    >
                      {link.name}
                    </Typography>
                  );
                })}
              </Box>
            </Grid>
          );
        })}
        <Grid item xs={0} md={1} />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: "#6a3a8e",
              padding: "20px 0",
            }}
          >
            <Typography variant="body1" component="span" color="white">
              © GenBov All rights reserved
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withRouter(Footer);
