export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.png");
export const darkLogo = require("../assets/darkLogo.png");
export const VisiblityOn = require("../assets/VisiblityOn.png");
export const arrowBuyer = require("../assets/arrowBuyer.png");
export const arrowVendor = require("../assets/arrowVendor.png");
export const background = require("../assets/background.png");
export const buyer = require("../assets/buyer.png");
export const vendor = require("../assets/vendor.png");
export const check = require("../assets/check.png");
export const cross = require("../assets/cross.png");
export const SideImage = require("../assets/SideImage.png");