import React, {Fragment} from 'react';
import { Typography, Grid, Box, Button, Modal } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { style } from './SectionOnePreview';
import {locationPin, Contact, mail} from './assets';

export interface propsInterface {
  open: boolean;
  handleClose: () => void;
  fileUrl: string | ArrayBuffer | null;
  logo: string;
  phone_number: string;
  email: string;
  location: string;
  footerCols: Array<{}>;
}

export default function FooterPreview({
  open,
  handleClose,
  fileUrl,
  logo,
  phone_number,
  email,
  location,
  footerCols
}: propsInterface) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        {/* Cancel icon */}
        <Box sx={{ position: 'relative' }}>
          <CancelIcon
            fontSize='large'
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              color: '#000',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          />
        </Box>
        <Fragment>
          <Grid
            container
            bgcolor="#d6b236"
            marginTop="0"
            paddingLeft="10px"
            paddingY="30px"
          >
            <Grid item xs={0} md={1} />
            <Grid item xs={12} md={4} sx={{ paddingBottom: '15px' }}>
              <Box sx={{}}>
                <img
                  // @ts-ignore
                  src={fileUrl ? fileUrl : logo}
                  width="120px"
                  alt="Brand Logo"
                  style={{ alignSelf: 'left', marginBottom: '20px' }}
                />
                <Box display="flex" alignItems="center" marginBottom={1}>
                  <img
                    src={locationPin}
                    height="max-content"
                    alt="Location icon"
                    style={{ marginRight: '20px' }}
                  />
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ flexGrow: 1, color: 'black', cursor: 'pointer' }}
                  >
                    {location}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" marginBottom={1}>
                  <img
                    src={Contact}
                    height="max-content"
                    alt="Contact icon"
                    style={{ marginRight: '20px' }}
                  />
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ flexGrow: 1, color: 'black', cursor: 'pointer' }}
                  >
                    {phone_number}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" marginBottom={2}>
                  <img
                    src={mail}
                    height="max-content"
                    alt="Email icon"
                    style={{ marginRight: '20px' }}
                  />
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ flexGrow: 1, color: 'black', cursor: 'pointer' }}
                  >
                    {email}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {
              footerCols.map((f)=>{
                return(
                  // @ts-ignore
                  <Grid item xs={6} md={2} key={f.id}>
                    <Box
                      sx={{
                        textAlign: { xs: 'start', sm: 'start' },
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ color: '#6a3a8e', fontWeight: 'bold', marginBottom: '6px' }}
                      >
                        {/* @ts-ignore */}
                        {f?.heading}
                      </Typography>
                      {
                        // @ts-ignore
                        f.footer_links_attributes.map((link)=>{
                          return(
                            <Typography
                              variant="subtitle1"
                              component="a"
                              href={`https://${link.url}`}
                              sx={{ color: 'black', textDecorationLine: 'none' }}
                              key={link.name}
                            >
                              {link.name}
                            </Typography>
                          )
                        })
                      }
                    </Box>
                  </Grid>
                )
              })
            }
            <Grid item xs={0} md={1} />
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  textAlign: 'center',
                  backgroundColor: '#6a3a8e',
                  padding: '20px 0'
                }}
              >
                <Typography variant="body1" component="span" color="white">
                  © GenBov All rights reserved
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Fragment>
      </Box>
    </Modal>
  );
}
