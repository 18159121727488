import React, { useState, useEffect } from "react";
import {
  Alert,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { withRouter } from "react-router-dom";

import { SaveButton } from "../../ContentManagement/src/Buttons";
import { Permission } from "./ChangeRolePermissions";
import { API } from "./RolesPermissions2.web";

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: { color: "#693a8e" },
  editHeader: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#693a8e",
    marginBottom: 2,
  },
  btnContainer: { display: "flex", justifyContent: "flex-end", marginTop: 2 },
};

// Urls for getting or updating user permissions
const GET_BUYER_USER_PERMISSION_URL =
  "bx_block_dashboard/user_buyer_permissions";
const GET_VENDOR_USER_PERMISSION_URL =
  "bx_block_dashboard/user_vendor_permissions";
const GET_ADMIN_USER_PERMISSION_URL =
  "bx_block_dashboard/user_admin_permissions";
const POST_BUYER_USER_PERMISSION_URL =
  "bx_block_dashboard/user_buyer_permissions/change_permission";
const POST_VENDOR_USER_PERMISSION_URL =
  "bx_block_dashboard/user_vendor_permissions/change_permission";
const POST_ADMIN_USER_PERMISSION_URL =
  "bx_block_dashboard/user_admin_permissions/change_permission";

// @ts-ignore
function ChangeUserPermissions(props) {
  const [role, setRole] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  // @ts-ignore
  const [permission, setPermission] = useState<Permission>({});
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState<{ message: string }[]>([]);
  const [getUrl, setGetUrl] = useState("");
  const [postUrl, setPostUrl] = useState("");
  const [postData, setPostData] = useState({});
  const token = localStorage.getItem("authToken") || "";

  // Change checkboxes
  const handleChange = (key: string) => {
    setPermission((prev) => ({
      ...prev,
      // @ts-ignore
      [key]: !prev[key],
    }));
  };

  // Submit
  const handleSubmit = () => {
    setIsSaving(true);
    const headers = {
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
    };
    API.post(`${postUrl}?account_id=${id}`, postData, headers)
      .then((response) => {
        setPermission(response.data.data.attributes);
        setIsSaving(false);
        setMessage("Updated");
        setError([]);
      })
      .catch(() => {
        setIsSaving(false);
        setMessage("");
        setError([{ message: "Failed to update!" }]);
      });
  };

  // Set role to load data
  useEffect(() => {
    setRole(props.location.state.role);
    setId(props.location.state.id);
    setName(props.location.state.name);
  }, [
    props.location.state.role,
    props.location.state.id,
    props.location.state.name,
  ]);

  // Set url according to role
  useEffect(() => {
    if (role === "Buyer") {
      setGetUrl(GET_BUYER_USER_PERMISSION_URL);
      setPostUrl(POST_BUYER_USER_PERMISSION_URL);
    } else if (role === "Vendor") {
      setGetUrl(GET_VENDOR_USER_PERMISSION_URL);
      setPostUrl(POST_VENDOR_USER_PERMISSION_URL);
    } else if (role === "Administrator") {
      setGetUrl(GET_ADMIN_USER_PERMISSION_URL);
      setPostUrl(POST_ADMIN_USER_PERMISSION_URL);
    }
  }, [role]);

  // Set data to post with
  useEffect(() => {
    if (role === "Buyer") {
      setPostData({
        pay_online_permission: permission.pay_online_permission,
        pay_offline_permission: permission.pay_offline_permission,
      });
    } else if (role === "Vendor") {
      setPostData({
        add_remove_product_permission: permission.add_remove_product_permission,
        edit_product_permission: permission.edit_product_permission,
      });
    } else if (role === "Administrator") {
      setPostData({
        add_remove_product_permission: permission.add_remove_product_permission,
        add_remove_vendor_user_permission: permission.add_remove_vendor_user_permission,
        add_remove_buyer_user_permission: permission.add_remove_buyer_user_permission,
        approve_vendor_request_permission: permission.approve_vendor_request_permission,
        edit_product_permission: permission.edit_product_permission,
        change_permission: permission.change_permission,
      });
    }
  }, [permission]);

  // Load data
  useEffect(() => {
    getUrl &&
      API.get(`${getUrl}/${id}`, {
        headers: { token },
      })
        .then((response) => {
          setIsLoading(false);
          setPermission(response.data.data.attributes);
        })
        .catch(() => {
          setIsLoading(false);
          setMessage("");
          setError([{ message: "Failed to load data!" }]);
        });
  }, [getUrl]);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Typography
        sx={{ fontWeight: "bold", fontSize: "20px", color: "#693a8e" }}
      >
        Change Permissions
      </Typography>
      <Typography>
        <span style={{ color: "#693a8e" }}>
          Roles & Persmissions / View Users
        </span>{" "}
        <span style={{ color: "#693a8e" }}>/ Change Roles and Permissions</span>
      </Typography>
      {/* Message container */}
      <Grid container>
        <Grid item md={6} xs={12}>
          {/* Error messages */}
          {error.length > 0 && (
            <Alert severity="error">{error.map((e) => e.message)}</Alert>
          )}
          {/* Info messages */}
          {message && <Alert severity="success">{message}</Alert>}
        </Grid>
      </Grid>
      {/* Main content */}
      <Grid container>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "8px",
              padding: "20px 30px",
              marginTop: 3,
            }}
          >
            {/* Name */}
            <Typography sx={styles.editHeader}>{`${name} `}Role</Typography>
            {/* Role */}
            <FormControl
              size="small"
              sx={{ width: "250px", marginY: 2 }}
              disabled
            >
              <InputLabel id="bulk-actions">{role}</InputLabel>
              <Select
                labelId="bulk-actions"
                id="demo-simple-select"
                value={role}
                label={role}
                sx={{ background: "#fff" }}
              >
                <MenuItem value="Administrator">Administrator</MenuItem>
                <MenuItem value="Buyer">Buyer</MenuItem>
                <MenuItem value="Vendor">Vendor</MenuItem>
              </Select>
            </FormControl>
            {/* Admin */}
            {role === "Administrator" && (
              <>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Edit Product</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.edit_product_permission
                        ? permission.edit_product_permission
                        : false
                    }
                    onChange={() => handleChange("edit_product_permission")}
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Add/Remove Product</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.add_remove_product_permission
                        ? permission.add_remove_product_permission
                        : false
                    }
                    onChange={() => handleChange("add_remove_product_permission")}
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>
                    Add/Remove Vendor User
                  </Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.add_remove_vendor_user_permission
                        ? permission.add_remove_vendor_user_permission
                        : false
                    }
                    onChange={() => handleChange("add_remove_vendor_user_permission")}
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>
                    Add/Remove Buyer User
                  </Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.add_remove_buyer_user_permission
                        ? permission.add_remove_buyer_user_permission
                        : false
                    }
                    onChange={() => handleChange("add_remove_buyer_user_permission")}
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>
                    Approve Vendor Request
                  </Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.approve_vendor_request_permission
                        ? permission.approve_vendor_request_permission
                        : false
                    }
                    onChange={() => handleChange("approve_vendor_request_permission")}
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Change Permissions</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.change_permission
                        ? permission.change_permission
                        : false
                    }
                    onChange={() => handleChange("change_permission")}
                  />
                </Box>
              </>
            )}
            {/* Buyer */}
            {role === "Buyer" && (
              <>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Pay Online</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.pay_online_permission
                        ? permission.pay_online_permission
                        : false
                    }
                    onChange={() => handleChange("pay_online_permission")}
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Pay Offline</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.pay_offline_permission
                        ? permission.pay_offline_permission
                        : false
                    }
                    onChange={() => handleChange("pay_offline_permission")}
                  />
                </Box>
              </>
            )}
            {/* Vendor */}
            {role === "Vendor" && (
              <>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Add/Remove Product</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.add_remove_product_permission
                        ? permission.add_remove_product_permission
                        : false
                    }
                    onChange={() =>
                      handleChange("add_remove_product_permission")
                    }
                  />
                </Box>
                <Box sx={styles.row}>
                  <Typography sx={styles.label}>Edit Product</Typography>
                  <Checkbox
                    color="secondary"
                    checked={
                      permission.edit_product_permission
                        ? permission.edit_product_permission
                        : false
                    }
                    onChange={() => handleChange("edit_product_permission")}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box sx={styles.btnContainer}>
            <SaveButton
              text="save"
              handleSubmit={handleSubmit}
              isSaving={isSaving}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(ChangeUserPermissions);
