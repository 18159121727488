import {
  Box,
  Button,
  Checkbox,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import * as React from "react";
import { Category } from "./Categoriessubcategories.web";
// @ts-ignore
import EnhancedTableHead from "./TableHead";
// @ts-ignore
import styles from "./styles";

const PurpleSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#693a8e",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#925BBD",
  },
}));

interface IProps {
  categories: Category[];
  handleShowInMenuChange: (catId: number, currentStatus: boolean) => void;
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCategory: React.Dispatch<React.SetStateAction<Category | null>>;
}

export default function CategoriesTable({
  categories,
  handleShowInMenuChange,
  selected,
  setSelected,
  isOpen,
  setIsOpen,
  setCategory,
}: IProps) {
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = categories.map((cat) => cat.attributes.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleEditClick = (category: any) => {
    setCategory(category);
    setIsOpen(true);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: '100%', marginTop: 2}}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{width:{
            md:"100%",
            sm:"700px",
            xs:"350px"
          }}}>
          <Table
            sx={{ minWidth: 650 }}
            aria-labelledby="Categorytable"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={categories.length}
            />
            <TableBody>
              {categories.map((cat, index) => {
                const isItemSelected = isSelected(cat.attributes.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={cat.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox" sx={{ width: "10%" }}>
                      <Checkbox
                        color="secondary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) =>
                          handleClick(event, cat.attributes.id)
                        }
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      align="left"
                      id={labelId}
                      scope="row"
                      sx={{ width: "33%", paddingX: 0 }}
                    >
                      <Typography sx={styles.text}>
                        {cat.attributes.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ width: "33%", paddingX: 0 }}>
                      {cat.attributes.sub_categories.length === 0 && (
                        <RemoveIcon sx={styles.text} />
                      )}
                      {cat.attributes.sub_categories.map((subCat, key) => {
                        return (
                          <Typography key={key} sx={styles.text}>
                            - {subCat.name}
                          </Typography>
                        );
                      })}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "33%" }}>
                      <PurpleSwitch
                        checked={cat.attributes.show_in_menu}
                        onChange={() =>
                          handleShowInMenuChange(
                            cat.attributes.id,
                            cat.attributes.show_in_menu
                          )
                        }
                        name="show-in-menu"
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ width: "10%" }}>
                      <IconButton onClick={() => handleEditClick(cat)}>
                        <EditIcon sx={styles.text} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
