import React, {useEffect, useState} from 'react';
import {Grid, IconButton, Typography} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {deleteImg,productImg,updateMinusFinalCartItemQty,updateAddFinalCartItemQty,removeFinalCartItem} from "./assets";
import {useDispatch} from "react-redux";
import {noimg} from "../../Notifications/src/assets"
// @ts-ignore
const CartItem = ({item}) => {
    const dispatch = useDispatch()
    useEffect(()=>{
        setCartNum(item.attributes.quantity)
    },[item])
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [cartNum, setCartNum] = useState(1)
    const increaseCart = () => {
        if(cartNum < 10){
            dispatch(updateAddFinalCartItemQty(item.id,cartNum,item.attributes.cart_item_id))
        }
    }
    const decreaseCart = () => {
        if(cartNum > 1){
            dispatch(updateMinusFinalCartItemQty(item.id,cartNum,item.cart_item_id))
        }
    }
    return (
        <Grid container className="cart-content" xs={12} display="flex" alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={6} md={6} lg={6} display="flex" alignItems="center" sx={{ paddingBottom : { xs : '15px' }}}>
                <img src={item?.attributes?.catalogue_image?.url || noimg} height='50px' width='auto' alt="product"/>
                <Typography variant="subtitle1" fontWeight="700" color="#693A8E" sx={{ padding : '5px 10px'}}>{item.attributes.catalogue_name}- {item.attributes.vendor_name}</Typography>
            </Grid>
            <Grid item xs={6} sm={2} md={2} lg={2} sx={{ paddingBottom : { xs : '15px' }}}>
                <Typography variant="subtitle2" color="#693A8E" sx={{ display : { xs : 'flex', sm : 'none'}}}>Price</Typography>
                <Typography variant="subtitle2" color="#693A8E" fontWeight="700" sx={{ padding : '5px 0'}}>$ {numberWithCommas(item.attributes.unit_price)}</Typography>
            </Grid>
            <Grid item className="cart-box" xs={6} sm={2} md={2} lg={2}  display="flex" sx={{ paddingBottom : { xs : '15px' }, justifyContent : { xs : 'right', sm : 'left'}}}>
                <Grid className="cart-items-box" xs={8}>
                    <Typography sx={{ padding : '0 5px'}} variant="h6">{cartNum}</Typography>
                    <Grid className="button-grp">
                        <IconButton onClick={increaseCart} className="btns-inc-dec"  ><ExpandLessIcon /></IconButton>
                        <IconButton onClick={decreaseCart} className="btns-inc-dec" ><KeyboardArrowDownIcon  /></IconButton>
                    </Grid>
                </Grid>
                <Grid display="flex" alignItems="center" justifyContent="center" padding="0 0 0 10px">
                    <IconButton onClick={(e)=>{
                        e.preventDefault()
                        dispatch(removeFinalCartItem(item.id))
                    }}>
                        <img src={deleteImg} width='auto' height="auto" alt="landing page"/>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} sx={{ justifyContent : { xs : 'left', sm : 'center'}, paddingBottom : { xs : '15px' }}}>
                <Typography variant="subtitle2" color="#693A8E" sx={{ display : { xs : 'flex', sm : 'none'}}}>Total Amount</Typography>
                <Typography variant="subtitle2" color="#693A8E" fontWeight="700" sx={{ padding : '5px 0', textAlign : 'right'}}>$ {numberWithCommas(item.attributes.total_price)}</Typography>
            </Grid>
        </Grid>
    );
};

export default CartItem;
