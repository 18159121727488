import React from 'react';
import Loader from "react-loader-spinner";
import Box from "@mui/material/Box";
import {darkLogo} from "../../email-account-login/src/assets";
//@ts-ignore
const LoaderPage = ({isLoading}) => {
    return (
        <Box style={ !isLoading ? {display:'none'} : {height:'100vh',width:'100vw',flexDirection:'column',backgroundColor:'White',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div style={{height:"200px"}}>
            </div>
            <img src={darkLogo} width='250px' style={{margin:'30px'}}/>
            <Loader
                type={"ThreeDots" || "MutatingDots"}
                color="#472264"
                secondaryColor="#D6B336"
                height={100}
                width={100}
            />
            <div style={{height:"200px"}}></div>
        </Box>
    );
};

export default LoaderPage;
