import axios from "axios";
import {baseURL} from "framework/src/config";
const API = axios.create({
  baseURL: baseURL,
  timeout: 5000
});

const emailLink = axios.create({
  baseURL: `${baseURL}/account_block/accounts/`,
  timeout: 5000
})

emailLink.interceptors.request.use((req) => {
  if (localStorage.getItem('authToken')) {
    req.headers.token = `${localStorage.getItem('authToken')}`
  }
  return req
})


export const login = async loginData => {
  try {
    return await API.post(`/bx_block_login/logins`, loginData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    });
  } catch (e) {
    return e.response;
  }
};

export const addCart = async (cartData,token) => {
  try {
    return await API.post(`/bx_block_order_management/carts`,cartData,{
      headers:{
        token:token
      }
    });
  } catch (e) {
    return e.response;
  }
};


export const forgotPassword = async loginData => {
  try {
    return await API.post(
      `/bx_block_forgot_password/forgot_password_link`,
      loginData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    );
  } catch (e) {
    return e.response;
  }
};

export const resatPassword = async formData => {
  try {
    return await API.post(
      `/bx_block_forgot_password/reset_password`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    );
  } catch (e) {
    return e.response;
  }
};

export const sentEmail = async () => {
  try {
    return await emailLink.post(`resend_email_verification_link`);
  } catch (e) {
    return e.response;
  }
}

export const sentUnconfirmedEmail = async () => {
  try {
    return await emailLink.post(`unconfirmed_email_confirmation`);
  } catch (e) {
    return e.response;
  }
}

