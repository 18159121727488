import axios from "axios";
import {baseURL} from "framework/src/config";

const API = axios.create({
  baseURL: baseURL,
  timeout: 5000
});

export const login = async loginData => {
  try {
    return await API.post(`/bx_block_login/logins`, loginData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    });
  } catch (e) {
    return e.response;
  }
};

export const forgotPassword = async loginData => {
  try {
    return await API.post(
      `/bx_block_forgot_password/forgot_password_link`,
      loginData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    );
  } catch (e) {
    return e.response;
  }
};

export const resatPassword = async formData => {
  try {
    return await API.post(
      `/bx_block_forgot_password/reset_password`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    );
  } catch (e) {
    return e.response;
  }
};
