import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Avatar from "@mui/material/Avatar";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  arrowDown,
  logoDark,
  profilePic,
  setProfileEmpty,
  updateUserProfile,
} from "./importAssets";
import { useDispatch, useSelector } from "react-redux";
const drawerWidth = 290;

function ResponsiveDrawer(props) {
  const dispatch = useDispatch();
  const localProfile = JSON.parse(localStorage.getItem("profile"));
  const { profile, managePermissions } = useSelector(
    (state) => state.landingPage
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { window, Component, name, history, showBackBtn } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [auth, setAuth] = useState("");
  const [currentPath, setCurrentPath] = useState("");

  // Managing User Access As per role
  useEffect(() => {
    if (localProfile === null) {
      history.push("/");
    } else {
      if (localProfile.user_role === "vendor") {
        history.push("/vendor/dashboard");
      }
      if (localProfile.user_role === "buyer") {
        history.push("/");
      }
    }
  }, []);

  // Logout Function
  const handleNavigation = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("profile");
    dispatch(setProfileEmpty());
    history.push("/");
  };

  // Checking the current path and setting the active class
  useEffect(() => {
    const currentpath = props.match.path.split("/");
    setCurrentPath(currentpath[2]);
  }, [currentPath, props.match]);

  // Dispatching the profile data to redux to check the token is valid or not
  useEffect(() => {
    dispatch(updateUserProfile(history));
  }, [currentPath]);

  // Managing the menu items
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Managing the menu items
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // Managing the menu items
  const handleClose = () => {
    setAnchorEl(null);
  };

  // drawer menu items
  const drawer = (
    <Box sx={styles.drawer}>
      <Box>
        <Toolbar>
          <img width="60%" src={logoDark} alt={"logo"} />
        </Toolbar>
        <List sx={styles.list}>
          <ListItem
            button
            className={currentPath === "Dashboard" ? "active" : null}
            onClick={() => props.history.push("/admin/dashboard")}
          >
            <ListItemIcon>
              <DashboardIcon sx={{ color: "#6a3a8e" }} />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} sx={{ color: "#6a3a8e" }} />
          </ListItem>
          <ListItem
            button
            className={currentPath === "orders" ? "active" : null}
            onClick={() => props.history.push("/admin/orders/")}
          >
            <ListItemIcon>
              <ShoppingCartIcon sx={{ color: "#6a3a8e" }} />
            </ListItemIcon>
            <ListItemText primary={"Orders"} sx={{ color: "#6a3a8e" }} />
          </ListItem>
          {managePermissions &&
          localProfile.user_role === "super_administrator" ? (
            <ListItem
              button
              className={currentPath === "Payments" ? "active" : null}
              onClick={() => props.history.push("/Admin/Payments")}
            >
              <ListItemIcon>
                <AccountBalanceWalletIcon sx={{ color: "#6a3a8e" }} />
              </ListItemIcon>
              <ListItemText primary={"Payments"} sx={{ color: "#6a3a8e" }} />
            </ListItem>
          ) : null}
          <ListItem
            button
            className={currentPath === "Vendors" ? "active" : null}
            onClick={() => props.history.push("/admin/Vendors/")}
          >
            <ListItemIcon>
              <PeopleAltIcon sx={{ color: "#6a3a8e" }} />
            </ListItemIcon>
            <ListItemText primary={"Vendors"} sx={{ color: "#6a3a8e" }} />
          </ListItem>
          <ListItem
            button
            className={currentPath === "Buyers" ? "active" : null}
            onClick={() => props.history.push("/admin/Buyers/")}
          >
            <ListItemIcon>
              <PersonIcon sx={{ color: "#6a3a8e" }} />
            </ListItemIcon>
            <ListItemText primary={"Buyers"} sx={{ color: "#6a3a8e" }} />
          </ListItem>
          <ListItem
            button
            className={
              currentPath === "Categoriessubcategories" ? "active" : null
            }
            onClick={() => props.history.push("/admin/Categoriessubcategories")}
          >
            <ListItemIcon>
              <ListAltIcon sx={{ color: "#6a3a8e" }} />
            </ListItemIcon>
            <ListItemText primary={"Category"} sx={{ color: "#6a3a8e" }} />
          </ListItem>
          {managePermissions ? (
            <ListItem
              button
              className={currentPath === "RolesPermissions" ? "active" : null}
              onClick={() => props.history.push("/admin/RolesPermissions")}
            >
              <ListItemIcon>
                <SettingsIcon sx={{ color: "#6a3a8e" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Roles & Permissions"}
                sx={{ color: "#6a3a8e" }}
              />
            </ListItem>
          ) : null}
          {localProfile.user_role === "super_administrator" && (
            <ListItem
              button
              className={currentPath === "ContentManagement" ? "active" : null}
              onClick={() =>
                props.history.push("/admin/ContentManagement/section/1")
              }
            >
              <ListItemIcon>
                <BorderColorIcon sx={{ color: "#6a3a8e" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Content Management"}
                sx={{ color: "#6a3a8e" }}
              />
            </ListItem>
          )}
        </List>
      </Box>
      <Box sx={{ padding: "0 24px" }}>
        <Typography sx={styles.copyright}>GenBov @ 2021</Typography>
        <Typography sx={styles.copyright}>All Rights Reserved</Typography>
      </Box>
    </Box>
  );

  // Main Menu Container
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          ...styles.appbar,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <Box sx={styles.toolbar}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={styles.toggleBtn}
              >
                <MenuIcon sx={styles.menuIcon} />
              </IconButton>
              {name && (
                <Typography variant="h4" color="#6a3a8e" sx={styles.name}>
                  {name}
                </Typography>
              )}
              {showBackBtn && (
                <IconButton
                  sx={styles.backBtn}
                  onClick={() => props.history.goBack()}
                >
                  <ArrowBackIcon sx={styles.backIcon} />
                </IconButton>
              )}
            </Stack>
            <Box
              sx={styles.profile}
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Avatar
                alt="Profile picture"
                src={profile?.avatar?.url || profilePic}
                sx={{ marginLeft: 2 }}
                onClick={handleClick}
              />
              <Box id="basic-button" sx={styles.userInfo}>
                <Typography
                  variant="h6"
                  noWrap
                  component="span"
                  color="#6a3a8e"
                  sx={styles.fullName}
                >
                  {profile?.full_name || "loading.."}
                </Typography>
                <Typography
                  variant="caption"
                  noWrap
                  component="span"
                  color="#6a3a8e"
                  marginTop="-7px"
                >
                  {profile?.role_name || "loading.."}
                </Typography>
              </Box>
              <Box sx={styles.downArrowIcon}>
                <img src={arrowDown} alt="Arrow down icon" />
              </Box>
            </Box>
          </Box>
        </Toolbar>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              history.push("/admin/profile");
              handleClose();
            }}
          >
            My Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/admin/password");
              handleClose();
            }}
          >
            Change Password
          </MenuItem>
          <MenuItem onClick={handleNavigation}>Logout</MenuItem>
        </Menu>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { xs: 0 } }}
        aria-label="Admin menu"
      >
        {/* The `implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#f3f3f3",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Toolbar />
        <Component />
      </Box>
    </Box>
  );
}

const styles = {
  list: { paddingLeft: "20px", paddingRight: "20px" },
  drawer: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  appbar: {
    backgroundColor: "#f3f3f3",
    boxShadow: "none",
    border: "none",
  },
  toolbar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  toggleBtn: { display: { md: "none" } },
  menuIcon: {
    backgroundColor: "#6a3a8e",
    borderRadius: "3px",
    padding: "2px",
    fontSize: "2rem",
  },
  name: {
    fontWeight: "bold",
    fontSize: {
      xs: "1.5rem",
      md: "2.125rem",
    },
  },
  backBtn: { color: "#472264", fontWeight: "bold" },
  backIcon: {
    backgroundColor: "#6a3a8e",
    color: "#fff",
    borderRadius: "3px",
    padding: "2px",
    fontSize: "2rem",
  },
  profile: { display: "flex", alignItems: "center", cursor: "pointer" },
  userInfo: {
    margin: "0px 10px",
    display: {
      xs: "none",
      sm: "flex",
    },
    flexDirection: "column",
    cursor: "pointer",
  },
  fullName: {
    fontWeight: "bold",
    fontSize: {
      md: "1.25rem",
      xs: "1rem",
    },
  },
  downArrowIcon: { display: { xs: "none", sm: "block" } },
  copyright: { fontSize: "18px !important", color: "#d8b434" },
};

export default withRouter(ResponsiveDrawer);
