import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  Typography,
  Alert,
  Button,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import LoaderPage from "../../../components/src/LoaderPage";
import { pen, updateUserProfile, defaultImage,baseURL } from "./assets";
import ProfileForm from "./ProfileForm";

const profileSchema = yup.object().shape({
  fullName: yup.string().required("name is required"),
  email: yup.string().email("invalid Email").required("email is required"),
  phoneNo: yup.string(),
});

export interface IData {
  fullName: string;
  email: string;
  phoneNo: string;
}

export const initialValues = {
  fullName: "",
  email: "",
  phoneNo: "",
};

export function capitalize(str: string | undefined) {
  if (str) {
    return str[0].toUpperCase() + str.slice(1);
  }
}

export const API = axios.create({
  baseURL: baseURL,
  timeout: 5000,
});

export default function BuyerProfile({ setOrderCount }: any) {
  const dispatch = useDispatch();
  const [displayName, setDisplayName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [file, setFile] = useState("");
  const [fileUrl, setFileUrl] = useState<string | ArrayBuffer | null>("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [unconfirmedEmail, setUnconfirmedEmail] = useState("");
  const [existingEmail, setExistingEmail] = useState("");
  const [changedEmail, setChangedEmail] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(profileSchema),
    defaultValues: initialValues,
  });

  // Load profile data
  useEffect(() => {
    const token = localStorage.getItem("authToken") || "";
    API.get("/account_block/accounts", { headers: { token } })
      .then((response) => {
        const { data } = response.data;
        const {
          full_name,
          email,
          unconfirmed_email,
          full_phone_number,
          role_name,
          orders_count,
          avatar,
        } = data.attributes;
        full_name && setDisplayName(full_name);
        email && setExistingEmail(email);
        unconfirmed_email && setUnconfirmedEmail(unconfirmed_email);
        role_name && setUserRole(role_name);
        orders_count && setOrderCount(orders_count);
        avatar && setAvatarUrl(avatar.url);
        setValue("fullName", full_name);
        setValue("email", email);
        setValue("phoneNo", full_phone_number);
      })
      .catch(() => {
        toast.error("Could not load data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // Update profile data
  const handleSubmitForm = (data: IData) => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append("full_name", data.fullName);
    changedEmail && formData.append("unconfirmed_email", data.email);
    formData.append("full_phone_number", data.phoneNo);
    file && formData.append("avatar", file);
    const token = localStorage.getItem("authToken") || "{}";
    // @ts-ignore
    API.put("/account_block/accounts", formData, { headers: { token } })
      .then((response) => {
        const { data } = response.data;
        const { full_name, user_role, avatar, email, unconfirmed_email } =
          data.attributes;
        if (unconfirmed_email) {
          setUnconfirmedEmail(unconfirmed_email);
        }
        setValue("email", email);
        setDisplayName(full_name);
        setUserRole(user_role);
        setAvatarUrl(avatar?.url);
        setChangedEmail(false);
        setFile("");
        setIsSaving(false);
        dispatch(updateUserProfile());
        toast.success("Profile updated");
      })
      .catch((err) => {
        setIsSaving(false);
        if (err.response?.status === 422) {
          err.response.data.errors.forEach((err: { message: string }) => {
            toast.error(err.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  // Resend verification link to email
  const handleResendVerificationLink = () => {
    setIsSending(true);
    const data = {
      unconfirmed_email: unconfirmedEmail,
    };
    const token = localStorage.getItem("authToken") || "{}";
    API.post(
      "account_block/accounts/resend_unconfirmed_email_verification_link",
      data,
      { headers: { token } }
    )
      .then((response) => {
        response.data.success.forEach((item: { message: string }) => {
          toast.success(item.message);
        });
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          err.response.data.errors.forEach((err: { message: string }) => {
            toast.error(err.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  // Detect email change
  useEffect(() => {
    const email = watch("email");
    if (email && existingEmail) {
      if (email !== existingEmail) {
        setChangedEmail(true);
      } else {
        setChangedEmail(false);
      }
    }
  }, [watch("email"), existingEmail]);

  // Get URL of selected file
  useEffect(() => {
    if (typeof file === "object") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileUrl(reader.result);
      };
    }
  }, [file]);

  return (
    <Grid sx={styles.buyerProfileContainer}>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Grid container spacing={10} sx={styles.gridContainer}>
          <Grid item xs={12} sx={styles.leftGridItem}>
            {unconfirmedEmail && (
              <Alert
                severity="info"
                icon={false}
                sx={{ marginBottom: "10px" }}
                action={
                  <Button
                    variant={isSending ? "text" : "outlined"}
                    size="small"
                    onClick={() => handleResendVerificationLink()}
                    disabled={isSending}
                  >
                    {isSending ? (
                      <CircularProgress size={20} sx={{ color: "#014361" }} />
                    ) : (
                      "Resend link"
                    )}
                  </Button>
                }
              >
                Please check {unconfirmedEmail} for the confirmation link
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} lg={5} sx={{ paddingTop: "40px !important" }}>
            <Box sx={styles.flexContainer}>
              <Box
                sx={{
                  ...styles.imgContainer,
                  backgroundImage: `url("${
                    fileUrl ? fileUrl : avatarUrl ? avatarUrl : defaultImage
                  }")`,
                }}
              >
                <label>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      // @ts-ignore
                      setFile(e.target.files[0]);
                    }}
                  />
                  <img
                    src={pen}
                    alt="Pen"
                    style={{
                      width: "50px",
                      height: "50px",
                      position: "absolute",
                      bottom: 20,
                      right: 5,
                      cursor: "pointer",
                    }}
                  />
                </label>
              </Box>
              <Typography variant="h5" sx={styles.name}>
                {displayName}
              </Typography>
              <Typography variant="h5" sx={styles.role}>
                {capitalize(userRole)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ paddingTop: "40px !important" }}>
            <ProfileForm
              // @ts-ignore
              handleSubmit={handleSubmit}
              handleSubmitForm={handleSubmitForm}
              isSaving={isSaving}
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

const styles = {
  buyerProfileContainer: {
    background: "#FFF",
    padding: {
      xs: "10px 0",
      sm: "50px 30px",
    },
    borderRadius: "8px",
    marginBottom: "15px",
  },
  gridContainer: {
    padding: "20px",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
  } as const,
  leftGridItem: {
    paddingTop: {
      xs: "70px !important",
      sm: "30px !important",
    },
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  } as const,
  imgContainer: {
    display: "inline-block",
    width: "240px",
    height: "240px",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    borderRadius: "50%",
    position: "relative",
  } as const,
  name: {
    color: "#693a8e",
    fontWeight: "700",
    marginTop: "10px",
    fontSize: "36px",
    textAlign: "center",
  } as const,
  role: {
    color: "#693a8e",
    fontWeight: "400",
    fontSize: "36px",
    textAlign: "center",
  } as const,
};
