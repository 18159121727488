import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import WarningModal from "./WarningModal";

interface IProps {
  when: any,
  shouldBlockNavigation: any,
  navigate: any
}

export default function RouteLeavingGuard({ 
  when,
  shouldBlockNavigation,
  navigate,
 }: IProps) {
  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleRouteTransition = (location: any) => {
    if (!confirmedNavigation && shouldBlockNavigation(location)) {
      setShowModal(true);
      setNextLocation(location);
      return false;
    }
    return true;
  };

  const handleConfirmNavigation = () => {
    setShowModal(false);
    setConfirmedNavigation(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      navigate(nextLocation);
    }
  }, [nextLocation, confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleRouteTransition} />
      <WarningModal
        showModal={showModal}
        handleClose={handleClose}
        handleConfirmNavigation={handleConfirmNavigation}
      />
    </>
  )
}
