import axios from "axios";
import {baseURL} from "framework/src/config";

// Cart API Initialization
const API = axios.create({
    baseURL: `${baseURL}/bx_block_order_management/`
});

// Add to cart API Configuration for API
API.interceptors.request.use((req) => {
    if (localStorage.getItem('authToken')) {
        req.headers.token = `${localStorage.getItem('authToken')}`
    }
    return req
})

// Add to cart API
export const addCart = async (cartData) => {
    try {
        return await API.post(`/carts`,cartData);
    } catch (e) {
        return e.response;
    }
};

// Get Cart List API
export const getCartList = async () => {
    try {
        return await API.get(`/carts`);
    } catch (e) {
        return e.response;
    }
};

// Get Final Order API
export const getOrderList = async (id) => {
    try {
        return await API.get(`/orders?id=${id}`);
    } catch (e) {
        return e.response;
    }
};

// Remove from Cart API
export const removeFromCart = async (id) => {
    try {
        return await API.delete(`/carts/delete_cart_item?id=${id}`);
        return await API.delete(`/carts/delete_cart_item?id=${id}`);
    } catch (e) {
        return e.response;
    }
};

// Remove Complete cart API
export const removeCart = async (id) => {
    try {
        return await API.delete(`/carts/${id}`);
    } catch (e) {
        return e.response;
    }
};

// Update Cart QTY API
export const updateQty = async (id,qty) => {
    try {
        return await API.get(`/carts/update_quantity?id=${id}&quantity=${qty}`);
    } catch (e) {
        return e.response;
    }
};

// Update Final Order QTY API
export const updateFinalCartQty = async (id,qty) => {
    try {
        return await API.get(`/orders/update_quantity?id=${id}&quantity=${qty}`);
    } catch (e) {
        return e.response;
    }
};

// Remove from Final Order API
export const removeFromFinalCart = async (id) => {
    try {
        return await API.delete(`/orders/delete_order_item?id=${id}`);
    } catch (e) {
        return e.response;
    }
};

