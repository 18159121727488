const styles = {
  btn: {
    background: "#693a8e",
    borderRadius: "4px",
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "100%",
    "&:hover": { background: "#693a8e" },
  } as const,
  box: {
    background: "#fff",
    borderRadius: "8px",
    padding: 4,
    marginTop: 7,
  },
  boxHeader: {
    color: "#472264",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: 1,
  },
  errorText: { marginBottom: "1", color: "red" },
  tableContainer: { width: "100%", marginTop: 2 },
  text: { color: "#693a8e" },
  tableCellOne: { color: "#fff", width: "10%", borderRadius: "8px 0 0 0" },
  tableCell: { color: "#fff", width: "33%", paddingX: 0 },
  header: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "700",
    color: "#472264",
    padding: "15px 0"
  } as const,
  updateCatbox: { margin: "30px 0", padding: {xs: "0 15px", sm: "0 30px"} },
  label: { color: "#472264", fontWeight: "bold" },
  textField: {
    width: "100%",
    background: "#f1f1f1",
    margin: "10px 0",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    width: {
      xs: "80%",
      sm: 500,
    },
    backgroundColor: "#fff",
    boxShadow: 24,
  } as const,
  stack: { borderBottom: "1px solid #472264", padding: {xs: "0 15px", sm: "0 30px"} } as const,
  iconBtn: {
    ml: 2,
  },
};

export default styles;
